import DarkSwitch from '@xfinity/dark-switch';

import localFlags from './local';
import getConfig from '../../config';
import lastKnown from './lastKnown.json';

const { env, featureFlagEndpoint } = getConfig();

const options = {
  allowQueryToggle: env !== 'prod',
};

if (env === 'local') {
  // Use local flags for local dev so we don't
  // need to make any CMS changes at the beginning.
  options.bootstrapFlags = localFlags;
} else {
  options.remoteUrl = featureFlagEndpoint;
  options.fallbackFlags = lastKnown;
}

const client = DarkSwitch.initialize(options);

// CMS JSON sends all values as string,
// so we'll parse our own booleans.
const defaultBooleanCustomEnable = (value) => {
  if (value === 'true') return true;
  if (value === 'false') return false;

  return value;
};

const APP_PATH = 'bill-pay';

export const flagEnabled = (flagNameOrPath, { customEnable, ...rest } = {}) => {
  // Could also do a composition of the defaultBooleanCustomEnable and
  // default the customEnable param here to an identity function.
  const enabler = typeof customEnable === 'function' ? customEnable : defaultBooleanCustomEnable;

  // autoprefix the path if not global because it's kinda annoying
  const flag = flagNameOrPath.startsWith('global.')
    ? flagNameOrPath
    : `${APP_PATH}.${flagNameOrPath}`;

  return client.flagEnabled(flag, { customEnable: enabler, ...rest });
};

/**
 * Get a full feature status for a complex feature flag
 * @param {String} flagNameOrPath partial path to the flag
 * @param {Object} opts same as for `flagEnabled`
 * @returns {Object} complex state for flag, containing a minimum of `{ enabled, featurePath }`
 */
export const featureStatus = (flagNameOrPath, { customEnable: userEnable, ...opts } = {}) => {
  const featurePath = flagNameOrPath.startsWith('global.')
    ? flagNameOrPath
    : `${APP_PATH}.${flagNameOrPath}`;
  const output = {
    featurePath,
  };
  const walk = (value) => {
    if (typeof value === 'object') {
      const ret = {};
      Object.keys(value).forEach((k) => {
        ret[k] = walk(value[k]);
      });
      return ret;
    }
    if (!Number.isNaN(+value)) {
      return parseFloat(value);
    }
    if ((/^true$|^false$/i).test(value)) {
      return value.toLowerCase() === 'true';
    }
    if (userEnable) {
      return userEnable(value);
    }
    return value;
  };
  const customEnable = (value) => {
    if (typeof value === 'string') {
      output.enabled = defaultBooleanCustomEnable(value);
    } else {
      Object.assign(output, walk(value));
    }
  };
  client.flagEnabled(featurePath, { ...opts, customEnable });
  return output;
};


// Gets a list of feature flags that have disabled feature shingles and are disabled
export const getShingledFeatures = () => {
  const flags = client.allFlags();
  const { global } = flags;
  const local = flags[APP_PATH];
  const hasShingle = feature => (
    typeof feature === 'object'
    && !defaultBooleanCustomEnable(feature.enabled)
    && defaultBooleanCustomEnable(feature.disabledShingle)
  );
  return (
    Object.keys(global)
      .map(name => hasShingle(global[name]) && `global.${name}`)
      .concat(Object.keys(local).map(name => hasShingle(local[name]) && name))
      .filter(a => a)
  );
};

export default client;
