import React from 'react';

const Account = () => (
  <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="21" cy="21" r="21" fill="#0272B6" />
    <path fillRule="evenodd" clipRule="evenodd" d="M20.6498 20.53C24.2831 20.53 26.2498 22.2154 26.2498 26.3245C26.2498 29.7985 15.0498 29.7985 15.0498 26.3245C15.0498 22.2035 17.008 20.53 20.6498 20.53ZM20.6498 21.23C17.3723 21.23 15.7498 22.6166 15.7498 26.3245C15.7498 28.8652 25.5498 28.8652 25.5498 26.3245C25.5498 22.628 23.9184 21.23 20.6498 21.23ZM20.6479 13.53C22.5744 13.53 24.1498 14.9297 24.1498 16.68C24.1498 18.4304 22.5744 19.83 20.6479 19.83C18.7246 19.83 17.1498 18.4298 17.1498 16.68C17.1498 14.9303 18.7246 13.53 20.6479 13.53ZM20.6479 14.23C19.0941 14.23 17.8498 15.3364 17.8498 16.68C17.8498 18.0237 19.0941 19.13 20.6479 19.13C22.205 19.13 23.4498 18.0241 23.4498 16.68C23.4498 15.3359 22.205 14.23 20.6479 14.23Z" fill="white" />
  </svg>

);

export default Account;
