import { p2pProps } from '../../helpers/p2p';
import {
  avoidConsequencesPay,
} from './messages';
import { flagEnabled } from '../../helpers/featureFlags';

const P2PReviewMessage = ({ children, account, ...props }) => {
  const def = children || null;
  const p2pMessaging = flagEnabled('p2pMessaging');
  if (!p2pMessaging) return def;
  const {
    isP2P,
    softDisconnected,
    pastDue,
    amount,
    xapExit,
  } = p2pProps(props);
  if ((!isP2P && !xapExit) || pastDue <= amount) return def;
  return avoidConsequencesPay({
    account,
    softDisconnected,
    pastDue,
    amount,
    xapExit,
  });
};

export default P2PReviewMessage;
