import { p2pProps } from '../../helpers/p2p';
import {
  thanks,
  payMore,
  moreTime,
} from './messages';
import { flagEnabled } from '../../helpers/featureFlags';

const P2PConfirmSubtitle = ({ children, ...props }) => {
  const def = children || null;
  const p2pMessaging = flagEnabled('p2pMessaging');
  if (!p2pMessaging) return def;
  const {
    isP2P,
    softDisconnected,
    pastDue,
    qualifies,
    laterAmount,
    xapExit,
    xapCurrent,
    xapExitInProgress,
    today,
  } = p2pProps(props);
  if (!isP2P || xapExit || xapCurrent || xapExitInProgress) return def;
  if (!qualifies) {
    if (laterAmount) return null;
    return [
      payMore({
        softDisconnected,
        pastDue,
      }),
      moreTime,
    ];
  }
  return thanks({ softDisconnected, xapExit, today });
};

export default P2PConfirmSubtitle;
