import React from 'react';

const icon = ({ className }) => (
  <span className={`svg-icon ${className}`}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 6 10" style={{ transform: 'rotate(180deg)' }}>
      <path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M.83.85l4.12 4.12L.83 9.08" />
    </svg>
  </span>
);

export default icon;
