
export const overlaps = (box1, box2) => !(
  box1.left > (box2.left + box2.width)
  || box2.left > (box1.left + box1.width)
  || box1.top > (box2.top + box2.height)
  || box2.top > (box1.top + box1.height)
);

export const getViewport = () => {
  const client = document.documentElement;
  const view = document.defaultView;
  return {
    left: view.pageXOffset || client.scrollLeft,
    top: view.pageYOffset || client.scrollTop,
    width: client.clientWidth,
    height: client.clientHeight,
  };
};

export const restoreViewport = (vp) => {
  Object.assign(document.documentElement, {
    scrollLeft: vp.left,
    scrollTop: vp.top,
  });
};
