import memoized from '../memoized';

const NUMBERS = /[0-9]+/;
const SYMBOLS = /[^0-9a-zA-Z]+/g;

const NonNumericFactory = memoized(message => (value = '') => (
  NUMBERS.test(value.replace(SYMBOLS, ''))
    ? message
    : undefined
));

export default NonNumericFactory;
