import dateFormat from 'dateformat';
import { formatCurrency } from './formatText';
import { flagEnabled } from './featureFlags';
import { getIsToday } from './date';

export const getPaymentMessage = ({
  bill,
  payment,
  status,
  type,
  isXapCurrent,
}) => {
  if (!bill || !payment || !bill.summary || !type) {
    return '';
  }
  const {
    summary,
    promiseToPay: {
      customerEligibilityChecked,
    } = {},
  } = bill;
  const { eligibleForPromiseToPay } = customerEligibilityChecked || {};
  const dueDate = summary.dueDateInMillis;
  const date = +new Date(payment.date);
  const pastDuePayment = date && !getIsToday(date) && date > dueDate;
  const latePayment = (summary.pastDueBalanceRemaining > 0) && pastDuePayment;
  const stillPastDue = summary.pastDueBalanceRemaining - payment.amount;
  const remainingBalance = summary.balanceDue - payment.amount;
  const lowPayment = stillPastDue > 0;
  const restartService = !isXapCurrent && summary.softDisconnected && lowPayment;
  const now = +Date.now();
  const dueDateString = dueDate && dateFormat(dueDate, 'mmmm d, yyyy', true);
  const avoidInterruption = latePayment || lowPayment;
  const pendingDisconnect = !summary.softDisconnected && summary.pastDueBalanceRemaining > 0;
  const disconnected = status === 'DISCONNECTED';
  const takeAction = (() => {
    if (summary.pastDueBalanceRemaining <= 0) {
      return 'avoid late fees';
    }
    if (summary.softDisconnected) {
      return 'restore your service';
    }
    return 'avoid service suspension';
  })();
  const scheduleAtThisTime = (dueDate && dueDate >= now)
    ? `the latest payment date you should select is ${dueDateString}`
    : 'schedule a payment as soon as possible';
  const whenToSchedule = (dueDate && dueDate >= now)
    ? `by ${dueDateString}`
    : 'as soon as possible';
  const calcSeverity = () => {
    // Overpayment beats late/insufficient payments.
    if (remainingBalance < 0) {
      return disconnected ? undefined : 'danger';
    }
    if (summary.softDisconnected || eligibleForPromiseToPay) {
      return 'danger';
    }
    if (latePayment || summary.balanceDue > payment.amount) {
      return 'warning';
    }
    return 'success';
  };
  const calcMessage = () => {
    if (restartService) {
      if (type === 'review' || type === 'confirm') {
        return `To reactivate your service, please make an additional
        payment of ${formatCurrency(stillPastDue)} as soon as possible.`;
      }
      return `To reactivate your service, please make a minimum
      payment of ${formatCurrency(summary.pastDueBalanceRemaining)}.`;
    }

    // Overpayment beats late/insufficient payments.
    if (remainingBalance < 0) {
      return (
        disconnected
          ? undefined
          : `This payment exceeds the total amount due. A credit balance
            of ${formatCurrency(-remainingBalance)} will be reflected on your account.`
      );
    }

    if (avoidInterruption || pastDuePayment || remainingBalance > 0) {
      if (eligibleForPromiseToPay && stillPastDue <= 0) return undefined;
      if (stillPastDue > 0) {
        const fmtBalance = formatCurrency(stillPastDue > 0 ? stillPastDue : remainingBalance);
        if (flagEnabled('global.xapSoftDisco') && (pendingDisconnect || isXapCurrent)) {
          return (
            `This payment is less than the minimum amount due.
            Please pay an additional ${fmtBalance} now.`
          );
        }
        return (
          `This payment is less than the minimum amount due.
          You'll need to pay an additional ${fmtBalance}
          ${whenToSchedule} to ${takeAction}.`
        );
      }
      if (pastDuePayment) {
        return (
          `To ${takeAction}, ${scheduleAtThisTime}.`
        );
      }
    }
    if (type === 'confirm') {
      return 'Your balance is now paid in full.';
    }
    return undefined;
  };

  return {
    message: calcMessage(),
    severity: calcSeverity(),
  };
};

export default getPaymentMessage;
