import apiClient from '../middleware/apiClient';
import { AUTOPAY, AUTOPAY_V4, SSM_AUTOPAY } from '../helpers/apis';
import { dispatchSendTransaction } from './adobe';
import { addInstrument } from './instruments';
import { formatBillingAddress } from '../helpers/address';
import { shouldShowLateFeeCredit } from '../helpers/lateFeeCredit';
import { enrollLateFeeCredit } from './latefeecredit';
import { getApplePaySession } from './applePay';
import { acceptAutopayContract } from './contracts';
import { sendChannelTracking } from './channelTracking';

import {
  AUTOPAY_ENROLL_COMPLETE,
  AUTOPAY_UNENROLL_COMPLETE,
  AUTOPAY_ENROLL_FAILED,
  AUTOPAY_UNENROLL_FAILED,
} from '../helpers/channelTracking';

export const GET_AUTOPAY = 'GET_AUTOPAY';
export const GET_AUTOPAY_SUCCESS = 'GET_AUTOPAY_SUCCESS';
export const GET_AUTOPAY_FAILURE = 'GET_AUTOPAY_FAILURE';


function dispatchGetAutopay() {
  return {
    type: GET_AUTOPAY,
    payload: apiClient.fetch(AUTOPAY),
  };
}

function dispatchGetAutopaySuccess(payload) {
  return {
    type: GET_AUTOPAY_SUCCESS,
    payload,
  };
}

function dispatchGetAutopayFailure(error) {
  return {
    type: GET_AUTOPAY_FAILURE,
    payload: error,
  };
}

export const getAutopay = (force = false) => async (dispatch, getState) => {
  // If forced, ignore existing state.
  if (!force) {
    const { autopay: { submitPromise } } = getState();
    // If we're updating autopay state, wait on the submission to complete.
    // Don't worry about its success / failure.
    if (submitPromise) {
      await (submitPromise.then(() => {}, () => {}));
    }
    const { autopay: { loadPromise } } = getState();
    if (loadPromise) {
      await loadPromise;
    }
    // If it's already cached (or just loaded), return the cached value
    const { autopay: { cached, autopay } } = getState();
    if (cached) {
      return autopay;
    }
  }

  try {
    const response = await dispatch(dispatchGetAutopay()).payload;
    dispatch(dispatchGetAutopaySuccess(response));
    return getState().autopay;
  } catch (error) {
    const { status } = error;

    // For a 404 error, autopay's just off.  Resolve successfully, but with an undefined payload.
    if (parseInt(status, 10) === 404) {
      dispatch(dispatchGetAutopaySuccess());
      return getState().autopay;
    }

    // Other errors
    dispatch(dispatchGetAutopayFailure(error));
    throw error;
  }
};

// Enable autopay
export const ENABLE_AUTOPAY = 'ENABLE_AUTOPAY';
export const ENABLE_AUTOPAY_SUCCESS = 'ENABLE_AUTOPAY_SUCCESS';
export const ENABLE_AUTOPAY_FAILURE = 'ENABLE_AUTOPAY_FAILURE';

function dispatchEnableAutopay({ isUpdate, ...data } = {}) {
  return {
    type: ENABLE_AUTOPAY,
    payload: apiClient.fetch(AUTOPAY_V4, {
      method: isUpdate ? 'PUT' : 'POST',
      body: JSON.stringify(data),
    }),
  };
}

function dispatchEnableAutopaySuccess(payload = {}) {
  return {
    type: ENABLE_AUTOPAY_SUCCESS,
    payload,
  };
}

function dispatchEnableAutopayFailure(error) {
  return {
    type: ENABLE_AUTOPAY_FAILURE,
    payload: error,
  };
}

// can enable autopay using token ~ saved payment method ex - combined payment flow, setup autopay
// if new instrument such as paymentCard or bank, save the payment method first
// use the token from the response to enable autopay. ex autopay setup with new instrument
// Apple pay can also be used to setup autopay
// This method is shared between the combined flow and setup autopay
// any change made to this method will impact both the flows.
export const enableAutopay = ({
  paymentMethodOption,
  isUpdate,
  billingAddress,
  ...data
} = {}) => async (dispatch, getState) => {
  let isLateFeeEligible;
  const sidePromises = [];
  if (!isUpdate) {
    const {
      bill: { bill },
      latefeecredit: { latefeecredit },
    } = getState();

    isLateFeeEligible = shouldShowLateFeeCredit(bill, latefeecredit);
  }

  const {
    applePaySession,
    ...autoPayData
  } = await (async () => {
    if (paymentMethodOption === 'Apple Pay') {
      // For apple pay, we grab the session and the payment token it creates, and add
      //  it to data, later, we'll make the autopay call.
      const { payload, applePaySession: appleSession } = await dispatch(getApplePaySession(''));
      return {
        billingAddress,
        isUpdate,
        // Gotta wrangle it like this to avoid var name collisions
        applePaySession: appleSession,
        ...payload,
      };
    }
    return { ...data, billingAddress, isUpdate };
  })();

  // always accept contract for now,
  // but can optimize with checkbox check
  sidePromises.push(dispatch(acceptAutopayContract()));
  try {
    await dispatch(dispatchEnableAutopay(autoPayData)).payload;
    if (!isUpdate) {
      dispatch(sendChannelTracking({ id: AUTOPAY_ENROLL_COMPLETE, interactionType: 'BILLING_PAYMENTS' }));
    }
  } catch (error) {
    dispatch(sendChannelTracking({ id: AUTOPAY_ENROLL_FAILED, error, interactionType: 'BILLING_PAYMENTS' }));
    const { status, data: { code } = {} } = error || {};
    // 'SH404.77.14' is "Bill information not available"
    // In these cases, autopay sign-up _did_ succeed, but we're in the first billing
    // cycle, so CSP can't look up the autopay date.  If that's not the error,
    // handle things like the failure it is.
    if (!(status === 404 && (code === 'SH404.77.14'))) {
      if (applePaySession) {
        applePaySession.abort();
      }
      dispatch(dispatchEnableAutopayFailure(error));
      throw error;
    }
    // Otherwise, fall through to the happy path.
  }
  if (applePaySession) {
    applePaySession.completePayment(applePaySession.STATUS_SUCCESS);
  }
  dispatch(dispatchSendTransaction({
    name: 'self service transaction',
    action: 'self service transaction',
    transaction: 'billing:autopay:signup',
  }));

  // if they are late fee eligable enroll them in the credit
  // we'll read from latefee reducer for success/error
  if (isLateFeeEligible) {
    sidePromises.push(dispatch(enrollLateFeeCredit()).catch(() => Promise.resolve()));
  }
  if (sidePromises.length) {
    await Promise.all(sidePromises);
  }
  return dispatch(dispatchEnableAutopaySuccess());
};

export const enableAutopayWithInstrument = (type, values) => async (dispatch, getState) => {
  const {
    account,
  } = getState();
  const paymentToken = await dispatch(addInstrument(type, values));
  const {
    token,
  } = paymentToken;
  await dispatch(enableAutopay({
    token,
    billingAddress: formatBillingAddress(account, values),
  }));
};
// Disable autopay
export const DISABLE_AUTOPAY = 'DISABLE_AUTOPAY';
export const DISABLE_AUTOPAY_SUCCESS = 'DISABLE_AUTOPAY_SUCCESS';
export const DISABLE_AUTOPAY_FAILURE = 'DISABLE_AUTOPAY_FAILURE';

function dispatchDisableAutopay() {
  return {
    type: DISABLE_AUTOPAY,
    payload: apiClient.fetch(SSM_AUTOPAY, { method: 'DELETE' }),
  };
}

function dispatchDisableAutopaySuccess(payment) {
  return {
    type: DISABLE_AUTOPAY_SUCCESS,
    payload: payment,
  };
}

function dispatchDisableAutopayFailure(error) {
  return {
    type: DISABLE_AUTOPAY_FAILURE,
    payload: error,
  };
}

export const disableAutopay = () => async (dispatch) => {
  try {
    const response = await dispatch(dispatchDisableAutopay()).payload;
    dispatch(sendChannelTracking({ id: AUTOPAY_UNENROLL_COMPLETE, interactionType: 'BILLING_PAYMENTS' }));
    dispatch(dispatchSendTransaction({
      action: 'Save',
      transaction: 'billing:autopay:cancel',
    }));
    return dispatch(dispatchDisableAutopaySuccess(response));
  } catch (error) {
    dispatch(sendChannelTracking({ id: AUTOPAY_UNENROLL_FAILED, error, interactionType: 'BILLING_PAYMENTS' }));
    dispatch(dispatchDisableAutopayFailure(error));
    throw error;
  }
};
