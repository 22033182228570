import 'core-js/fn/array/find';
import 'core-js/fn/array/find-index';
import 'core-js/fn/string/starts-with';
import 'core-js/fn/string/repeat';
import 'core-js/fn/string/pad-start';
import 'core-js/fn/number/is-integer';
import 'react-app-polyfill/ie11';
// Added for the CPC
// This is a generic polyfill for
// web components
import './vendor/native-shim';
import sessionID from 'adc-session-id';

import React from 'react';
import { render } from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import Root from './containers/Core/Root';
import store from './store/index';
import { CpcProvider } from './hooks/useCpc';

require('./sass/style.scss');
require('./helpers/logger');

// Create a MAW session ID for following users across properties.
// We're doing this here because we want to start tracking asap.
sessionID();


render(
  <Router basename="/">
    <CpcProvider>
      <Root store={store} />
    </CpcProvider>
  </Router>,
  document.getElementById('root'),
);
