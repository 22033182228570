import {
  GET_AUTOPAY,
  GET_AUTOPAY_SUCCESS,
  GET_AUTOPAY_FAILURE,
  DISABLE_AUTOPAY,
  DISABLE_AUTOPAY_SUCCESS,
  DISABLE_AUTOPAY_FAILURE,
  ENABLE_AUTOPAY,
  ENABLE_AUTOPAY_SUCCESS,
  ENABLE_AUTOPAY_FAILURE,
} from '../actions/autopay';

const INITIAL_STATE = { loading: true, cached: false };

export default function autopayReducer(state = INITIAL_STATE, { type, payload }) {
  switch (type) {
    case GET_AUTOPAY:
      return {
        ...state,
        loading: true,
        loadPromise: payload,
      };
    case GET_AUTOPAY_SUCCESS:
      return {
        ...state,
        loading: false,
        cached: true,
        error: false,
        autopay: payload,
        loadPromise: null,
      };
    case GET_AUTOPAY_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        loadPromise: null,
      };
    case ENABLE_AUTOPAY:
      return {
        ...state,
        submitting: true,
        submitPromise: payload,
        error: false,
        autopaySignupSuccess: false,
        autopaySignupFailure: false,
      };
    case ENABLE_AUTOPAY_SUCCESS:
      return {
        ...state,
        submitting: false,
        loading: false,
        autopaySignupSuccess: true,
        submitError: false,
        autopaySignupPromise: null,
        cached: false,
      };
    case ENABLE_AUTOPAY_FAILURE:
      return {
        ...state,
        submitting: false,
        loading: false,
        autopaySignupFailure: true,
        autopaySignupPromise: null,
        cached: false,
      };
    case DISABLE_AUTOPAY:
      return {
        ...state,
        submitting: true,
        error: false,
        cancelSuccess: false,
        cancelFailure: false,
      };
    case DISABLE_AUTOPAY_SUCCESS:
      return {
        ...state,
        autopay: undefined,
        cancelSuccess: true,
        submitting: false,
      };
    case DISABLE_AUTOPAY_FAILURE:
      return {
        ...state,
        submitting: false,
        cancelFailure: true,
        error: payload,
      };
    default:
      return state;
  }
}
