export const billStateCheck = (bill = {}) => ({
  p2pEligible:
    bill.promiseToPay
    && bill.promiseToPay.requirements
    && bill.promiseToPay.requirements.length
    && bill.promiseToPay.requirements[0].date,

  autopayEnabled: bill.summary && bill.summary.autoPayEnabled && bill.summary.autoPayValid,
  autopaySuspend: bill.summary && !!(bill.summary.autoPayEnabled && !bill.summary.autoPayValid),

  pastDue: bill.summary && bill.summary.pastDueBalanceRemaining > 0,

  delinquent: !!(bill.delinquency && bill.delinquency.delinquencyStatus === 'DELINQUENT'),
});

export const toggleEcobillValue = deliveryMethod => (deliveryMethod === 'ELECTRONIC' ? 'PAPER' : 'ELECTRONIC');
