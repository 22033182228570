import React from 'react';

const icon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" version="1" viewBox="0 0 50 32">
    <path d="M3 0h44a3 3 0 0 1 3 3v26a3 3 0 0 1-3 3H3a3 3 0 0 1-3-3V3a3 3 0 0 1 3-3z" fill="#e6eaed" />
    <path d="M38.667 23.833h-.834v-13l2-2.5-13.5-4.833h-2.666l-13.5 5L12 10.833v13h-.667l-2 4.5 1.334.834H39.5l1.333-.834z" fill="#f2f4f5" />
    <path d="M39.833 27.667h-29l.667-2H39zM12 9.5L24.5 5H26l12.333 4.5zm17.833 6V24H27.5v-8.5c0-3.5-4.667-3.5-4.667 0V24h-2v-8.5c0-3.5-4.833-3.5-4.833 0V24h-2.333V11.167h23V24H34.5v-8.5c0-3.5-4.667-3.5-4.667 0zm11.5 11.5l-.833-2c-.333-.667-1-1.167-1.667-1.167h-.5V11.167H39.5a1.5 1.5 0 0 0 .667-2.834l-13.5-5C25.5 3 25 3 23.833 3.333l-13.666 5c-1.5.5-.834 3 .666 3H12v12.5h-.333c-.834 0-1.5.5-1.834 1l-.666 2c-.667 1.5.333 2.667 1.666 2.667h28.834c1.666 0 2.166-1.333 1.666-2.5z" fill="#646a70" />
  </svg>

);

export default icon;
