import {
  getTrackingData,
  mapRouteToName,
  mapRouteToSubCategory,
  flushComponent,
} from '../helpers/adobe';
import { mergeDataFromEvent } from '../helpers/dataLayer';

export const PRE_SEND_TRACKING = 'PRE_SEND_TRACKING';
export const SEND_TRACKING = 'SEND_TRACKING';

function dispatchPreSendTracking(match, otherData) {
  return {
    type: PRE_SEND_TRACKING,
    payload: { match, otherData },
  };
}

export function dispatchSendTracking(event, data) {
  (event === 'c-tracking-log-page'
    ? document
    : document.getElementById('root')
  ).dispatchEvent(new CustomEvent(event, {
    detail: data,
    bubbles: true,
  }));
  return {
    type: SEND_TRACKING,
    payload: {
      event,
      data,
    },
  };
}

export const dispatchSendTransaction = (data = {}) => {
  const transaction = {
    eventName: data.name || 'Click',
    eventAction: data.action,
    eventMethod: 'self-ServiceTransaction',
    transaction: {
      attributes: {
        selfServiceTransactions: data.transaction,
      },
    },
  };
  return dispatchSendTracking('c-tracking-log-dom', transaction);
};

export const dispatchSendErrorEvent = ({ code, message } = {}) => (
  dispatchSendTracking('c-tracking-log-dom', {
    eventMethod: 'send-Error',
    errorMessage: message,
    eventPage: window.location.toString(),
    errorID: code,
  })
);

export const sendPageloadEvent = (match = {}, eventData = {}) => async (dispatch, getState) => {
  if (!match.isExact) return false; // do not send analytics for parent components
  const {
    auth: { promise: authPromise },
    account: { promise: accountPromise },
  } = getState();
  await Promise.all([authPromise, accountPromise]);
  // yield execution briefly to allow templates to include impressions
  await new Promise(r => setTimeout(r, 0));
  // Wait for any components before fetching current state
  const component = await flushComponent(eventData.component);
  // Re-fetch the state, since we've waited.
  const {
    auth: {
      macaroon,
      crs,
    },
    harness: { isHarness },
    account: { account },
  } = getState();
  const digitalData = getTrackingData(macaroon, account, isHarness, crs);
  const trackingData = mergeDataFromEvent({ ...eventData, component }, digitalData);
  // always make sure we have match
  trackingData.page.pageInfo.screenName = mapRouteToName(match);

  const subCategory = mapRouteToSubCategory(match);
  if (subCategory) {
    trackingData.page.category.subCategory2 = subCategory;
  }

  // send to the store before mixing with digitalData
  dispatch(dispatchPreSendTracking(match, eventData));
  return dispatch(dispatchSendTracking('c-tracking-log-page', trackingData));
};

/*
data = {
  name: String, // name of the transaction
  action: String, // The button text that was clicked to trigger the transaction, e.g. 'Submit'
  transaction: String // The transaction from analytics specification,
                        e.g. 'myaccount:appointments:schedulenewconfirm'
}
*/
export const sendTransaction = data => dispatch => dispatch(dispatchSendTransaction(data));

export const sendErrorEvent = data => dispatch => dispatch(dispatchSendErrorEvent(data));

export const sendImpression = component => (dispatch, getState) => {
  const {
    auth: { macaroon, crs },
    account: { account },
    harness: { isHarness },
  } = getState();

  const eventData = {
    eventName: 'impression tracking',
    eventMethod: 'component-Track',
    deleteComponent: true,
    component,
  };

  const digitalData = getTrackingData(macaroon, account, isHarness, crs);
  const trackingData = mergeDataFromEvent(eventData, digitalData);

  dispatch(dispatchPreSendTracking(null, eventData));

  return dispatch(dispatchSendTracking('c-tracking-log-dom', trackingData));
};
