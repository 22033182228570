import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import { addComponent } from '../helpers/adobe';
import Dismiss from './svgs/Dismiss';

const NbaLink = ({
  linkTarget,
  link,
  children,
  ...props
}) => {
  if (linkTarget) {
    return <a href={link} target={linkTarget} {...props}>{children}</a>;
  }

  return <Link to={link}>{children}</Link>;
};

const NextBestAction = ({
  nba,
  nba: { trackId } = {},
  dismissNba,
}) => {
  useEffect(() => {
    if (trackId) {
      addComponent({
        componentName: trackId,
        placementName: 'next-best-actions',
      });
    }
  }, [trackId]);

  if (!nba) return null;
  const {
    color,
    svgIcon: SvgIcon = () => null,
    id,
    name,
    link,
    linkTarget,
    linkText,
    linkOnClick,
    dismissible,
    description,
  } = nba;

  return (
    <div tracking-module={name} className="card-group__item">
      <div id={`nba-${id}`} className={`card card--${color} card--next-best-action card--has-media-at-768 card--action-right`}>
        <div className="card__media">
          <div className={`circle circle--${color}`}>
            <SvgIcon className="svg-icon svg-icon--40" />
          </div>
        </div>

        <div className="card__content">
          <div className="hgroup">
            <h4 className="body2">{name}</h4>
            <p>
              {description}
              {link && (
                <>
                  {' '}
                  <NbaLink
                    link={link}
                    linkTarget={linkTarget}
                    onClick={linkOnClick}
                  >
                    {linkText}
                  </NbaLink>
                </>
              )}
            </p>
          </div>
        </div>

        {dismissible && (
          <div className="card__action">
            <button
              type="button"
              className="card__dismiss button button--text"
              custom-tracking="true"
              onClick={() => dismissNba(nba)}
            >
              <Dismiss className="svg-icon svg-icon--22" />
              <span className="visuallyhidden">Dismiss</span>
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default NextBestAction;
