import React from 'react';
import LegacyFooter from './LegacyFooter';
import PolarisFooter from './PolarisFooter';
import { usePolaris } from '../../../helpers/polaris';

const PageFooter = (props) => {
  const isGlobalNav = usePolaris();
  if (isGlobalNav === undefined) return null;
  if (!isGlobalNav) return <LegacyFooter {...props} />;
  return <PolarisFooter {...props} />;
};

export default PageFooter;
