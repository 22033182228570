import React from 'react';
import { LoadingCard } from 'adc-ui-components';
import { Redirect, Link } from 'react-router-dom';

import CompactScheduledPayment from './CompactScheduledPayment';
import PageTitle from './PageTitle';
import { NEW_PAYMENT } from '../helpers/routes';

const ListScheduled = ({
  loading,
  scheduledPayments,
  account,
  history,
}) => {
  if (loading) {
    return <LoadingCard />;
  }
  const payments = scheduledPayments.filter(a => a);
  if (!payments.length) {
    return <Redirect to={NEW_PAYMENT} />;
  }

  return (
    <>
      <PageTitle>Scheduled Payments</PageTitle>
      <div className="card-group">
        {payments.map(payment => (
          <div className="card-group__item" key={payment.id}>
            <CompactScheduledPayment payment={payment} account={account} history={history} />
          </div>
        ))}
      </div>

      <div className="action action--right-at-768">
        <Link to={NEW_PAYMENT} className="button button--primary">Make a Payment</Link>
      </div>
    </>
  );
};

export default ListScheduled;
