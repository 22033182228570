import React from 'react';
import { FormBoolean } from 'adc-ui-components';

import Card from '../../Card';
import useUid from '../../../hooks/useUid';

const Plan = ({
  plan,
  field,
}) => {
  const uid = useUid(`${field && field.name}_${plan.value}`);
  if (!plan) return null;
  return (
    <div className="card-group__item">
      <Card className={!plan.enabled && 'is-disabled'}>
        <FormBoolean
          labelClass="body2"
          inputId={uid}
          label={(
            <span className="payment-plan-option-label">
              <span className="body3 mb6">{plan.label}</span>
              <span className="heading2 mb6">{plan.amountLabel}</span>
              <span className="body4 mb0">{plan.subLabel}</span>
            </span>
          )}
        >
          {field && (
            <input
              type="radio"
              id={uid}
              {...field}
              value={plan.value}
              checked={plan.value === field.value}
            />
          )}
        </FormBoolean>
      </Card>
    </div>
  );
};

export default Plan;
