import apiClient from '../middleware/apiClient';
import { CONSENT } from '../helpers/apis';
import { normalizeCard } from '../helpers/payments';
import { getPreferredEmail } from '../helpers/account';
import { cleanInstrumentNumber } from '../helpers/paymentInstrument';
import encryptRsaOaep from '../helpers/encryption';
import { sendTransaction } from './adobe';

import {
  SUBMIT_AUTOPAY_SIGNUP,
  SUBMIT_AUTOPAY_SIGNUP_SUCCESS,
  SUBMIT_AUTOPAY_SIGNUP_FAILURE,
  TOGGLE_ECOBILL,
  TOGGLE_ECOBILL_SUCCESS,
  TOGGLE_ECOBILL_FAILURE,
} from './bill/types';
import {
  ADD_INSTRUMENT,
  ADD_INSTRUMENT_SUCCESS,
  ADD_INSTRUMENT_FAILURE,
} from './instruments';

export const CONTRACT_ACCEPT = 'ACCEPT_CONTRACT';
export const CONTRACT_ACCEPT_SUCCESS = 'ACCEPT_CONTRACT_SUCCESS';
export const CONTRACT_ACCEPT_FAILURE = 'ACCEPT_CONTRACT_FAILURE';

export const autopaySignup = data => async (dispatch) => {
  const payload = apiClient.fetch(CONSENT.AUTOPAY, { method: 'POST', body: JSON.stringify(data) });
  dispatch({ type: SUBMIT_AUTOPAY_SIGNUP, payload });
  try {
    await payload;
    dispatch({ type: SUBMIT_AUTOPAY_SIGNUP_SUCCESS });
    sendTransaction({
      name: 'self service transaction',
      action: 'Add',
      transaction: 'billing:autopay:signup',
    })(dispatch);
    // SSM autopay also adds instrument
    sendTransaction({
      name: 'self service transaction',
      action: 'Add',
      transaction: 'billing:ewallet:signup',
    })(dispatch);
  } catch (error) {
    dispatch({
      type: SUBMIT_AUTOPAY_SIGNUP_FAILURE,
      error,
    });
    throw error;
  }
};

export const addInstrument = data => async (dispatch) => {
  const payload = apiClient.fetch(CONSENT.ADD_INSTRUMENT, { method: 'POST', body: JSON.stringify(data) });
  dispatch({ type: ADD_INSTRUMENT, payload });
  try {
    await payload;
    dispatch({ type: ADD_INSTRUMENT_SUCCESS });
    sendTransaction({
      name: 'self service transaction',
      action: 'Add',
      transaction: 'billing:ewallet:signup',
    })(dispatch);
  } catch (error) {
    dispatch({
      type: ADD_INSTRUMENT_FAILURE,
      error,
    });
    throw error;
  }
};

export const ecobillSignup = () => async (dispatch) => {
  const payload = apiClient.fetch(CONSENT.ECOBILL, {
    method: 'POST',
    body: JSON.stringify({
      deliveryMethod: 'ELECTRONIC',
    }),
  });
  dispatch({ type: TOGGLE_ECOBILL, payload });
  try {
    dispatch({
      type: TOGGLE_ECOBILL_SUCCESS,
      payload: await payload,
    });
    sendTransaction({
      name: 'self service transaction',
      action: 'Save',
      transaction: 'billing:ecobill:signup',
    })(dispatch);
  } catch (error) {
    dispatch({
      type: TOGGLE_ECOBILL_FAILURE,
      error,
    });
    throw error;
  }
};

const dispatchAcceptContract = contractId => ({
  type: CONTRACT_ACCEPT,
  payload: apiClient.fetch(CONSENT.CONTRACT_ACCEPT(contractId), {
    method: 'POST',
  }),
});

const dispatchAcceptContractSuccess = () => ({
  type: CONTRACT_ACCEPT_SUCCESS,
});

const dispatchAcceptContractFailure = () => ({
  type: CONTRACT_ACCEPT_FAILURE,
});

export const acceptContract = contractId => async (dispatch) => {
  try {
    const response = await dispatch(dispatchAcceptContract(contractId)).payload;
    dispatch(dispatchAcceptContractSuccess(response));
  } catch (error) {
    dispatch(dispatchAcceptContractFailure(error));
  }
};

export const autopaySignupFromForm = ({
  paymentMethodOption,
  firstName,
  lastName,
  autopay,
  account: accountType,
  cardNumber,
  cardType,
  bankNumber,
  routeNumber,
  expirationMonth,
  expirationYear,
  cvv,
  ecobill,
  line1,
  line2,
  city,
  state,
  zip,
}) => async (dispatch, getState) => {
  // Update state SUBMIT_AUTOPAY_SIGNUP
  const {
    auth: { macaroon },
    instruments: { instruments: { jwkMap } },
    account: { account } = {},
  } = getState();
  const paymentInfo = (paymentMethodOption === 'PaymentCard' && cardNumber)
                      || (paymentMethodOption === 'Bank' && bankNumber)
                      || paymentMethodOption === 'Apple Pay'
                      || paymentMethodOption !== '';
  const promises = {};
  if (ecobill) {
    promises.ecobill = dispatch(ecobillSignup()).then(() => true, () => false);
  }
  if (paymentInfo) {
    const emailAddress = getPreferredEmail(account, macaroon);
    const address = (line1 && city && state && zip)
      ? {
        line1, line2, city, state, zip,
      }
      : (({
        addressLine1, addressLine2, ...rest
      } = {}) => ({
        line1: addressLine1,
        line2: addressLine2,
        city: rest.city,
        state: rest.state,
        zip: rest.zip,
      }))(account.serviceAddress);
    const commonInfo = {
      defaultPaymentInstrument: true,
      name: {
        firstName,
        lastName,
      },
      ...(emailAddress ? {
        contact: {
          emailAddress,
        },
      } : {}),
      address,
    };
    let instrumentPayload;
    if (paymentMethodOption === 'PaymentCard' && cardNumber) {
      if (cardNumber) {
        const card = normalizeCard({
          cardNumber,
          cardType,
          expirationMonth,
          expirationYear,
          cvv,
        });
        if (card.cardNumber) {
          instrumentPayload = {
            ...commonInfo,
            processingNetwork: 'Credit',
            cardNumber: await encryptRsaOaep(
              card.cardNumber,
              jwkMap.addInstrument,
            ),
            cardType: card.cardType,
            cvv: card.cvv,
            expirationDate: card.expirationDate,
          };
        }
      }
    } else if (paymentMethodOption === 'Bank') {
      if (bankNumber) {
        instrumentPayload = {
          ...commonInfo,
          processingNetwork: 'Bank',
          accountNumber: await encryptRsaOaep(
            cleanInstrumentNumber(bankNumber),
            jwkMap.addInstrument,
          ),
          accountType,
          bankRoutingNumber: cleanInstrumentNumber(routeNumber),
        };
      }
    } else if (autopay) {
      instrumentPayload = {
        ...commonInfo,
        token: paymentMethodOption,
      };
    }
    if (autopay) {
      promises.autopay = dispatch(autopaySignup(instrumentPayload));
    } else if (instrumentPayload) {
      promises.addInstrument = dispatch(addInstrument({
        ...instrumentPayload,
        paymentMode: 'One Time',
      }));
    }
    // Wait for the main call to succeed.
    if (promises.autopay || promises.addInstrument) {
      await (promises.autopay || promises.addInstrument);
    }
  }
  return {
    success: true,
    ecobill: await promises.ecobill,
  };
};
