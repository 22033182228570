import React from 'react';

import SvgIcon from './svgs/SvgIcon';

import { isAutoPayInstrument } from '../helpers/instruments';
import { last4 } from '../helpers/paymentInstrument';

const PaymentInstrument = (props) => {
  const {
    autopay = false,
    instrument,
    hideBadges,
    hideIcon,
  } = props;

  if (!instrument) return null;

  const { type } = instrument;

  let icon;
  let label;

  if (type === 'ApplePay') {
    icon = 'ApplePay';
    label = 'Apple Pay';
  }

  if (type === 'PaymentCard') {
    const { cardType, last4DigitsCardNumber } = instrument;
    icon = cardType;
    label = `${cardType} ending in ${last4DigitsCardNumber}`;
  }

  if (type === 'Bank') {
    icon = 'BankPayment';
    const { bankAccountType, bankAccountNumber } = instrument;
    label = `${bankAccountType} account ending in ${last4(bankAccountNumber)}`;
  }
  return (
    <span className="payment-instrument">
      {!hideIcon && <span className="payment-instrument__icon"><SvgIcon icon={icon} /></span>}
      <span className="payment-instrument__content">
        <span className="payment-instrument__text">{label}</span>
        {!hideBadges && autopay && isAutoPayInstrument(instrument, autopay) && (
          <span className="payment-instrument__badge badge">Automatic Payments</span>
        )}
      </span>
    </span>
  );
};

export default PaymentInstrument;
