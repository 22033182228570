import React from 'react';
import { FormControl } from 'adc-ui-components';
import { Field } from 'formik';

import SelectField from '../form-elements/SelectField';
import InputField from '../form-elements/InputField';
import vAll from '../../helpers/validation/all';
import { states } from '../../helpers/address';
import { RequiredFactory } from '../../helpers/validation/required';
import ConsecutiveNumericAddressFactory from '../../helpers/validation/consecutiveNumericAddress';
import NonNumericFactory from '../../helpers/validation/nonNumeric';
import ZipCodeFactory from '../../helpers/validation/zipCode';

const vRequired = RequiredFactory();
const vConsecutiveNumericAddress = ConsecutiveNumericAddressFactory();
const vNonNumeric = NonNumericFactory();
const vZipCode = ZipCodeFactory();

const renderStates = () => states.map(state => <option key={state} value={state}>{state}</option>);

const Address = () => (
  <>
    <div className="form-control-group form-control-group--flex-at-768">
      <div className="form-control-group__item">
        <FormControl label="Address line 1" inputId="line1">
          <Field
            id="line1"
            name="line1"
            component={InputField}
            type="text"
            aria-required
            aria-describedby="addressError"
            validate={vAll(vRequired('Please enter an address'),
              vConsecutiveNumericAddress('Invalid entry'))}
          />
        </FormControl>
      </div>

      <div className="form-control-group__item">
        <FormControl label="Address line 2" inputId="line2">
          <Field
            id="line2"
            name="line2"
            component={InputField}
            type="text"
            validate={vConsecutiveNumericAddress('Invalid entry')}
          />
        </FormControl>
      </div>
    </div>

    <div className="form-control-group form-control-group--flex-at-768">
      <div className="form-control-group__item">
        <FormControl label="City" inputId="city">
          <Field
            id="city"
            name="city"
            component={InputField}
            type="text"
            aria-required
            aria-describedby="cityError"
            validate={vAll(vRequired('Please enter a city'), vNonNumeric('Invalid entry'))}
          />
        </FormControl>
      </div>

      <div className="form-control-group__item">
        <div className="form-control-group form-control-group--flex-at-768">
          <div className="form-control-group__item">
            <FormControl label="State" inputId="state">
              <Field
                component={SelectField}
                id="state"
                name="state"
                aria-required
                aria-describedby="stateError"
                validate={vAll(vRequired('Please select a state'), vNonNumeric('Invalid entry'))}
              >
                <option value="">Select</option>
                {renderStates()}
              </Field>
            </FormControl>
          </div>

          <div className="form-control-group__item">
            <FormControl label="ZIP Code" inputId="zip">
              <Field
                id="zip"
                name="zip"
                component={InputField}
                type="text"
                aria-required
                aria-describedby="zipError"
                validate={vAll(vRequired('Please enter a ZIP code'), vZipCode('Invalid entry'))}
              />
            </FormControl>
          </div>
        </div>
      </div>
    </div>
  </>
);


export default Address;
