import apiClient from '../middleware/apiClient';
import {
  PAYMENT_INSTRUMENTS, ADD_INSTRUMENT_URL,
  DELETE_INSTRUMENT_URL, UPDATE_INSTRUMENT_URL,
  CONSENT,
} from '../helpers/apis';
import { encryptNewInstrument } from '../helpers/payments';
import { formatBillingAddress } from '../helpers/address';
import { acceptInstrumentContract } from './contracts';
import { getErrorFromResponse } from '../helpers/errors';
import { flagEnabled } from '../helpers/featureFlags';

// Fetch Payment
export const GET_PAYMENT_INSTRUMENTS = 'GET_PAYMENT_INSTRUMENTS';
export const GET_PAYMENT_INSTRUMENTS_SUCCESS = 'GET_PAYMENT_INSTRUMENTS_SUCCESS';
export const GET_PAYMENT_INSTRUMENTS_FAILURE = 'GET_PAYMENT_INSTRUMENTS_FAILURE';

function dispatchGetPaymentInstruments(crsId, showLoading) {
  let payload;

  if (crsId && flagEnabled('consent.enabled')) {
    // Massages the simple public key response to match
    // what's expected of the instruments response.
    payload = apiClient.fetch(CONSENT.GET_INSTRUMENTS).then(({ instruments, jwk, walletId }) => ({
      instruments: instruments.map(({
        defaultPaymentInstrument,
        cardNumber: last4DigitsCardNumber,
        paymentInstrumentToken: token,
        accountNumber: bankAccountNumber,
        accountType: bankAccountType,
        paymentInstrumentCustomerDefinedName: name,
        type,
        ...rest
      }) => ({
        default: defaultPaymentInstrument,
        token,
        name,
        type,
        ...rest,
        ...(type === 'PaymentCard' ? {
          last4DigitsCardNumber,
        } : {}),
        ...(type === 'Bank' ? {
          bankAccountType,
          bankAccountNumber,
        } : {}),
      })),
      jwkMap: {
        addInstrument: jwk,
        makePayment: jwk,
      },
      walletId,
    }));
  } else {
    payload = apiClient.fetch(PAYMENT_INSTRUMENTS);
  }

  return {
    type: GET_PAYMENT_INSTRUMENTS,
    showLoading,
    payload,
  };
}

function dispatchGetPaymentInstrumentsSuccess(payment) {
  return {
    type: GET_PAYMENT_INSTRUMENTS_SUCCESS,
    payload: payment,
  };
}

function dispatchGetPaymentInstrumentsFailure(error) {
  return {
    type: GET_PAYMENT_INSTRUMENTS_FAILURE,
    payload: error,
  };
}

export const getPaymentInstruments = (overrides = {}) => async (dispatch, getState) => {
  // TODO update to cache
  const { instruments, auth } = getState();

  const options = {
    showLoading: true,
    ...overrides,
  };

  if (instruments.loaded && !options.force) {
    return null;
  }

  try {
    const response = (
      await dispatch(dispatchGetPaymentInstruments(auth && auth.crsId, options.showLoading)).payload
    );
    // TODO: make separate logic for initial load (full page loading indicator)
    // vs refresh load (loading card) then we don't need to wait for the refresh before success
    await dispatch(dispatchGetPaymentInstrumentsSuccess(response));
    return response;
  } catch (error) {
    dispatch(dispatchGetPaymentInstrumentsFailure(error));
    throw error;
  }
};

// POST Add Instrument
export const ADD_INSTRUMENT = 'ADD_INSTRUMENT';
export const ADD_INSTRUMENT_SUCCESS = 'ADD_INSTRUMENT_SUCCESS';
export const ADD_INSTRUMENT_FAILURE = 'ADD_INSTRUMENT_FAILURE';

function dispatchAddInstrument(data) {
  return {
    type: ADD_INSTRUMENT,
    payload: apiClient.fetch(ADD_INSTRUMENT_URL, { method: 'POST', body: JSON.stringify(data) }),
  };
}

function dispatchAddInstrumentSuccess(payment) {
  return {
    type: ADD_INSTRUMENT_SUCCESS,
    payload: payment,
  };
}

function dispatchAddInstrumentFailure(error) {
  return {
    type: ADD_INSTRUMENT_FAILURE,
    payload: error,
  };
}

export const addInstrument = (type, formData = {}) => async (dispatch, getState) => {
  const {
    instruments: { instruments },
    account: { account },
  } = getState();

  const payload = {
    ...await encryptNewInstrument(type, formData, instruments.jwkMap.addInstrument),
    billingAddress: formatBillingAddress(account, formData),
    name: null,
    default: formData.isDefault === 'yes',
  };

  // Promise all?
  dispatch(acceptInstrumentContract()).catch((e) => {});
  try {
    const response = await dispatch(dispatchAddInstrument(payload)).payload;
    // refresh the instruments
    dispatch(getPaymentInstruments({ force: true, showLoading: false }));
    dispatch(dispatchAddInstrumentSuccess(response));
    return response;
  } catch (error) {
    dispatch(dispatchAddInstrumentFailure(error));
    throw getErrorFromResponse(error);
  }
};

// POST Delete Instrument
export const DELETE_INSTRUMENT = 'DELETE_INSTRUMENT';
export const DELETE_INSTRUMENT_SUCCESS = 'DELETE_INSTRUMENT_SUCCESS';
export const DELETE_INSTRUMENT_FAILURE = 'DELETE_INSTRUMENT_FAILURE';

function dispatchDeleteInstrument(token) {
  return {
    type: DELETE_INSTRUMENT,
    payload: apiClient.fetch(DELETE_INSTRUMENT_URL, {
      method: 'POST',
      body: JSON.stringify({
        instrument: {
          token,
        },
      }),
    }),
  };
}

function dispatchDeleteInstrumentSuccess(token) {
  return {
    type: DELETE_INSTRUMENT_SUCCESS,
    payload: token,
  };
}

function dispatchDeleteInstrumentFailure(error) {
  return {
    type: DELETE_INSTRUMENT_FAILURE,
    payload: error,
  };
}

export const deleteInstrument = token => async (dispatch) => {
  try {
    await dispatch(dispatchDeleteInstrument(token)).payload;
    return dispatch(dispatchDeleteInstrumentSuccess(token));
  } catch (error) {
    dispatch(dispatchDeleteInstrumentFailure(error));
    throw error;
  }
};

// POST Update Instrument
export const UPDATE_INSTRUMENT = 'UPDATE_INSTRUMENT';
export const UPDATE_INSTRUMENT_SUCCESS = 'UPDATE_INSTRUMENT_SUCCESS';
export const UPDATE_INSTRUMENT_FAILURE = 'UPDATE_INSTRUMENT_FAILURE';

function dispatchUpdateInstrument(data) {
  return {
    type: UPDATE_INSTRUMENT,
    payload: apiClient.fetch(UPDATE_INSTRUMENT_URL, { method: 'POST', body: JSON.stringify(data) }),
  };
}

function dispatchUpdateInstrumentSuccess(payload) {
  return {
    type: UPDATE_INSTRUMENT_SUCCESS,
    payload,
  };
}

function dispatchUpdateInstrumentFailure(error) {
  return {
    type: UPDATE_INSTRUMENT_FAILURE,
    payload: error,
  };
}

export const updateInstrument = data => async (dispatch) => {
  try {
    await dispatch(dispatchUpdateInstrument(data)).payload;
    return dispatch(dispatchUpdateInstrumentSuccess(data));
  } catch (error) {
    dispatch(dispatchUpdateInstrumentFailure(error));
    throw error;
  }
};
