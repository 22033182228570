import React from 'react';

const ModalText = ({
  heading,
  paragraph,
}) => (
  <div className="mb24 text-center">
    <h2 className="mb6">{heading}</h2>
    {paragraph && <p className="body2 mb0">{paragraph}</p>}
  </div>
);

export default ModalText;
