import dateformat from 'dateformat';
import { formatCurrency, formatFrequency } from './formatText';
import { addDays, today, createDateObject } from './date';

export const formatDateRange = (from, to) => {
  const fromMonth = dateformat(from, 'mmm');
  const toMonth = dateformat(to, 'mmm');
  const fromDay = dateformat(from, 'd');
  const toDay = dateformat(to, 'd');
  if (fromMonth === toMonth) return `${fromMonth} ${fromDay}-${toDay}`;
  return `${fromMonth} ${fromDay}-${toMonth} ${toDay}`;
};

const matchYearPlan = ({ numberOfPayments, noOfPayments }) => (
  numberOfPayments === 12
  || noOfPayments === 12
);

export const calculatePlans = ({
  bill: {
    summary: {
      pastDueBalanceRemaining,
      // balanceDue,
    } = {},
    promiseToPay: {
      customerEligibilityChecked: {
        eligibleForPromiseToPay,
        minP2PAmtBeforeCycle,
        maxP2PDateBeforeCycle,
        minP2PAmtAfterCycle,
        maxP2PDateAfterCycle,
      } = {},
    } = {},
  } = {},
  ltip: {
    options: {
      initialPaymentAmount, paymentOptionsPlan,
    } = {},
    minimum: {
      initialPaymentAmount: baseMinimumPayment,
      paymentOptionsPlan: basePaymentOptionsPlan,
    } = {},
  } = {},
}) => {
  const plans = [];
  if (eligibleForPromiseToPay) {
    if (minP2PAmtBeforeCycle && maxP2PDateBeforeCycle) {
      const value = 'p2p-early';
      const start = today();
      const end = createDateObject(maxP2PDateBeforeCycle);
      plans.push({
        value,
        validDates: [start, end],
        otherAmount: false,
        minimumPayment: minP2PAmtBeforeCycle,
        isP2P: true,
        enabled: true,
      });
    }
    if (minP2PAmtAfterCycle && maxP2PDateAfterCycle) {
      const value = 'p2p-late';
      const start = addDays(maxP2PDateBeforeCycle, 1);
      const end = createDateObject(maxP2PDateAfterCycle);
      plans.push({
        value,
        validDates: [start, end],
        otherAmount: false,
        minimumPayment: minP2PAmtAfterCycle,
        isP2P: true,
        enabled: true,
      });
    }
  } else if (pastDueBalanceRemaining > 0) {
    const value = 'past-due';
    plans.push({
      value,
      // Force user to pay today if past-due.
      validDates: null,
      otherAmount: false,
      minimumPayment: pastDueBalanceRemaining,
      enabled: true,
    });
  }
  const baseYearPlan = (basePaymentOptionsPlan || []).find(matchYearPlan);
  const currentPlan = (paymentOptionsPlan || []).find(matchYearPlan);
  const yearPlan = (paymentOptionsPlan || []).find(matchYearPlan) || baseYearPlan;
  if (baseYearPlan && (baseYearPlan.paymentAmount || baseYearPlan.amount) > 0) {
    const value = 'ltip';
    plans.push({
      value,
      // LTIP initial payment due today
      validDates: null,
      otherAmount: true,
      minimumPayment: currentPlan ? initialPaymentAmount : baseMinimumPayment,
      currentPayment: yearPlan.initialPaymentAmount,
      noOfPayments: yearPlan.numberOfPayments || yearPlan.noOfPayments,
      installment: yearPlan.paymentAmount || yearPlan.amount,
      trackingCode: yearPlan.trackingCode,
      frequency: yearPlan.frequency,
      freqUnit: formatFrequency(yearPlan.frequency, 'unit'),
      isLtip: true,
      enabled: initialPaymentAmount < pastDueBalanceRemaining && !!currentPlan,
    });
  }
  return plans;
};

export const enumeratePlans = ({
  content,
  ...data
}) => {
  if (typeof content !== 'function') return [];
  const ret = calculatePlans(data).map((plan) => {
    const {
      value,
      validDates,
      minimumPayment,
      installment,
      noOfPayments,
      frequency = 'MONTHLY',
    } = plan;
    const [start, end] = validDates || [];

    const msgs = content(value);
    const info = {
      date: end && dateformat(end, 'mmm d'),
      dateRange: start && end && formatDateRange(start, end),
      paymentAmount: formatCurrency(minimumPayment).replace(/\.00$/, ''),
      installmentAmount: installment && formatCurrency(installment),
      noOfPayments,
      frequency: frequency && formatFrequency(frequency),
      freqType: frequency && formatFrequency(frequency, 'long'),
    };
    return msgs && {
      ...plan,
      label: msgs('label', info),
      amountLabel: msgs('amountLabel', info),
      subLabel: msgs('subLabel', info),
    };
  }).filter(a => a);
  return ret;
};
