import React, { Component } from 'react';
import { render } from 'react-dom';
import IconCircleI from '../svgs/CircleI';
import { overlaps, getViewport, restoreViewport } from './utility';
import TooltipContent from './TooltipContent';
import TooltipMask from './TooltipMask';
import { randomId } from '../../helpers/uids';

export default class TooltipButton extends Component {
  constructor() {
    super();
    this.close = this.close.bind(this);
    this.open = this.open.bind(this);
    this.reposition = this.reposition.bind(this);
    this.uid = `tooltip_${randomId().substring(0, 8)}`;
    this.state = {
      open: false,
    };
  }

  reposition() {
    const { button } = this;
    const tip = this.rootNode().querySelector('.tooltip-button__content');
    if (!tip) {
      return;
    }
    let box;
    const currentScroll = {
      top: tip.scrollTop,
      left: tip.scrollLeft,
    };
    tip.style.left = '';
    tip.style.top = '';
    tip.style.width = '';
    tip.style.height = '';

    const scroll = getViewport();
    const btn = button.querySelector('svg').getBoundingClientRect();

    const isOffscreenHoriz = () => (
      box.left < 0
      || box.right > scroll.width
    );

    const isOffscreenVert = () => (
      box.top < 0
      || box.bottom > scroll.height
    );

    box = tip.getBoundingClientRect();
    // Tip should not exceed window width
    tip.style.width = `${Math.min(scroll.width - 12, box.width)}px`;
    // Preferred: left of tip matches 6px to the right of button box
    tip.style.left = `${btn.right + 6 + scroll.left}px`;

    // Update the client rect
    box = tip.getBoundingClientRect();
    if (isOffscreenHoriz()) {
      // Next: right of tip matches 6px to the left of button box
      tip.style.left = `${btn.left - box.width - 6 + scroll.left}px`;
      box = tip.getBoundingClientRect();
      if (isOffscreenHoriz()) {
        // Fallback to horizontally centered
        tip.style.left = `${(scroll.width - box.width) / 2 + scroll.left}px`;
      }
    }

    // Tip should not exceed window height
    box = tip.getBoundingClientRect();
    tip.style.height = `${Math.min(scroll.height - 12, box.height)}px`;
    // Preferred: top of tip matches top of button box
    tip.style.top = `${btn.top + scroll.top}px`;

    box = tip.getBoundingClientRect();
    if (isOffscreenVert()) {
      // fallback to bottoms match
      tip.style.top = `${btn.bottom - box.height + scroll.top}px`;
      box = tip.getBoundingClientRect();
    }

    // Tooltip should not overlap its button.
    box = tip.getBoundingClientRect();
    if (overlaps(btn, box)) {
      const spaceBelow = scroll.height - btn.top - btn.height - 12;
      const spaceAbove = btn.top - 12;
      const belowTop = `${btn.top + btn.height + 6 + scroll.top}px`;
      const aboveTop = `${btn.top - box.height - 6 + scroll.top}px`;
      // If there's enough room below the button, move down
      if (box.height < spaceBelow) {
        tip.style.top = belowTop;
      } else if (box.height < spaceAbove) { // If enough above, move up
        tip.style.top = aboveTop;
        // if neither, select the largest space of the two, use that, and shrink the box to fit
      } else if (spaceAbove > spaceBelow) {
        tip.style.top = `${6 + scroll.top}px`;
        tip.style.height = `${spaceAbove}px`;
      } else {
        tip.style.top = belowTop;
        tip.style.height = `${spaceBelow}px`;
      }
    }
    // Restore scroll position
    tip.scrollTop = currentScroll.top;
    tip.scrollLeft = currentScroll.left;

    requestAnimationFrame(() => {
      tip.style.opacity = 1;
      restoreViewport(scroll);
    });
  }

  rootNode() {
    const { root } = this.props;
    if (root) {
      return root;
    }
    let container = document.body.querySelector('.tooltip-button__container');
    if (!container) {
      container = document.createElement('div');
      container.className = 'tooltip-button__container';
      document.body.appendChild(container);
    }
    return container;
  }

  open(event) {
    const { target } = event;
    const { open, closing } = this.state;
    if (open || closing) {
      return;
    }
    this.button = target.closest('button');
    this.setState({ open: true });
    render(<span>{this.renderModal()}</span>, this.rootNode());
    window.addEventListener('scroll', this.reposition);
    window.addEventListener('resize', this.reposition);
  }

  close(e) {
    const { closing } = this.state;
    this.button && this.button.focus();
    e.preventDefault();
    if (closing) {
      return false;
    }
    this.setState(state => ({ ...state, closing: true }));

    window.removeEventListener('resize', this.reposition);
    window.removeEventListener('scroll', this.reposition);
    this.rootNode().querySelector('.tooltip-button__content').style.opacity = 0;

    setTimeout(() => {
      render(<span />, this.rootNode());
      this.button = null;
      this.setState(state => ({
        ...state,
        open: false,
        closing: false,
      }));
    }, 400);
    return false;
  }

  renderModal() {
    const {
      children,
    } = this.props;
    const { uid } = this;
    return (
      <>
        <TooltipMask uid={uid} onClick={this.close} />
        <TooltipContent id={uid} onMount={this.reposition}>
          {children}
        </TooltipContent>
      </>
    );
  }

  render() {
    const {
      title = 'More information',
    } = this.props;
    const { uid } = this;
    return (
      <button
        type="button"
        className="button button--text tooltip-button"
        onClick={this.open}
        title={title}
        aria-describedby={uid}
      >
        <IconCircleI />
      </button>
    );
  }
}
