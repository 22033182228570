import React from 'react';
import { connect } from 'react-redux';
import { IconCircleExclamation } from 'adc-ui-components';

import { joinClass } from '../../helpers/component';
import { SCHEDULED_PAYMENT_CANCEL_CONFIRM } from '../../helpers/routes';

import { setSubmit, setError, setSuccess } from '../../actions/userMessages';
import { cancelScheduledPayment } from '../../actions/scheduledPayments';
import { sendChannelTracking } from '../../actions/channelTracking';

import Modal from '../../components/Modal';
import ModalText from '../../components/ModalText';

const P2pModal = ({
  submitting,
  closeModal,
  onCancelClick,
}) => (
  <>
    <IconCircleExclamation className="mb6 svg-icon--42" />

    <ModalText
      heading="If you cancel this scheduled payment, your services may be interrupted!"
      paragraph="Please pay your bill before canceling this scheduled payment. If you cancel this payment, service suspension could occur."
    />

    <div className="action action--right-centered">
      <div className="action__item">
        <button
          type="button"
          className="button button--primary"
          onClick={closeModal}
        >
          Keep Payment
        </button>
      </div>
      <div className="action__item">
        <button
          type="button"
          className={joinClass('button', submitting && 'is-loading')}
          disabled={submitting}
          onClick={onCancelClick}
        >
          Cancel Payment
        </button>
      </div>
    </div>
  </>
);

const CancelModal = ({
  submitting,
  closeModal,
  onCancelClick,
}) => (
  <>
    <ModalText
      heading="Are you sure you want to permanently remove this scheduled payment?"
    />

    <div className="action action--right-centered">
      <div className="action__item">
        <button
          type="button"
          className={joinClass('button button--primary', submitting && 'is-loading')}
          disabled={submitting}
          onClick={onCancelClick}
        >
          Cancel Payment
        </button>
      </div>
      <div className="action__item">
        <button
          type="button"
          className="button"
          onClick={closeModal}
        >
          Keep Payment
        </button>
      </div>
    </div>
  </>
);

const CancelPaymentModal = ({
  submitting,
  closeModal,
  payment,
  // new
  handleSetSubmit,
  handleCancelScheduledPayment,
  handleSetError,
  handleSetSuccess,
  history,
  handleSendChannelTracking,
}) => {
  const onCancelClick = () => {
    handleSetSubmit();
    if (payment.promisedToPay) {
      handleSendChannelTracking({ id: 'P2P_CANCEL_INITIATED', interactionType: 'BILLING_PAYMENTS' });
    }

    return handleCancelScheduledPayment(payment.id)
      .then(() => {
        handleSetSuccess();
        if (payment.promisedToPay) {
          handleSendChannelTracking({ id: 'P2P_CANCEL_COMPLETED', interactionType: 'BILLING_PAYMENTS' });
        }
        return history.push(SCHEDULED_PAYMENT_CANCEL_CONFIRM);
      }).catch(({ data: { code } = {} } = {}) => {
        if (code === 'SH400.11.49') {
          handleSetSuccess();
          return history.push(SCHEDULED_PAYMENT_CANCEL_CONFIRM);
        }
        if (payment.promisedToPay) {
          handleSendChannelTracking({ id: 'P2P_CANCEL_FAILED', interactionType: 'BILLING_PAYMENTS' });
        }
        handleSetError({
          showModal: true,
        });
        return null;
      });
  };
  return (
    <Modal onRequestClose={closeModal} isOpen>
      <div className="text-center">
        {payment.promisedToPay && (
        <P2pModal
          onCancelClick={onCancelClick}
          closeModal={closeModal}
          submitting={submitting}
        />
        )}

        {!payment.promisedToPay && (
        <CancelModal
          onCancelClick={onCancelClick}
          closeModal={closeModal}
          submitting={submitting}
        />
        )}
      </div>
    </Modal>
  );
};

const mapStateToProps = ({ scheduledPayments: { submitting } }) => ({ submitting });
const mapDispatchToProps = {
  handleSetSubmit: setSubmit,
  handleSetError: setError,
  handleSetSuccess: setSuccess,
  handleCancelScheduledPayment: cancelScheduledPayment,
  handleSendChannelTracking: sendChannelTracking,
};

export default connect(mapStateToProps, mapDispatchToProps)(CancelPaymentModal);
