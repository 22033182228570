import { buildChannelPayload, CHANNEL_TRACKING_URL } from '../helpers/channelTracking';
import apiClient from '../middleware/apiClient';
import { flagEnabled } from '../helpers/featureFlags';

export const SEND_CHANNEL_TRACKING = 'SEND_CHANNEL_TRACKING';
export const LITE_CHANNEL_TRACKING_DISABLED = 'LITE_CHANNEL_TRACKING_DISABLED';

export const sendChannelTracking = ({
  id,
  error,
  interactionType,
  metadata,
}) => async (dispatch, getState) => {
  if (!flagEnabled('global.channelTracking')) {
    return {
      id: SEND_CHANNEL_TRACKING,
      payload: Promise.resolve(),
    };
  }
  const {
    harness: { isHarness },
    auth: { macaroon },
    account: { account },
  } = getState();
  if (macaroon.lite && !flagEnabled('global.channelTrackingLite')) {
    return { id: LITE_CHANNEL_TRACKING_DISABLED };
  }
  return {
    id: SEND_CHANNEL_TRACKING,
    payload: apiClient.fetch(CHANNEL_TRACKING_URL, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(buildChannelPayload({
        id,
        error,
        macaroon,
        account,
        isHarness,
        interactionType,
        metadata,
      })),
    }),
  };
};
