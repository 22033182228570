import memoized from '../memoized';

export const RequiredFactory = memoized(
  message => (value) => {
    if (typeof value !== 'string') {
      return value ? undefined : (message || true);
    }
    return ((value && value.trim()) ? undefined : (message || true));
  },
);

const vRequired = value => (value ? undefined : true);

export default vRequired;
