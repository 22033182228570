import apiClient from '../middleware/apiClient';
import { SSM_NOW_WALLET_INSTRUMENTS } from '../helpers/apis';

export const GET_NOW_INSTRUMENTS = 'GET_NOW_INSTRUMENTS';
export const GET_NOW_INSTRUMENTS_SUCCESS = 'GET_NOW_INSTRUMENTS_SUCCESS';
export const GET_NOW_INSTRUMENTS_FAILURE = 'GET_NOW_INSTRUMENTS_FAILURE';

function dispatchGetNowInstruments() {
  return {
    type: GET_NOW_INSTRUMENTS,
    payload: apiClient.fetch(SSM_NOW_WALLET_INSTRUMENTS),
  };
}

function dispatchGetNowInstrumentsSuccess(nowInstruments) {
  return {
    type: GET_NOW_INSTRUMENTS_SUCCESS,
    payload: nowInstruments,
  };
}

function dispatchGetNowInstrumentsFailure(error) {
  return {
    type: GET_NOW_INSTRUMENTS_FAILURE,
    payload: error,
  };
}


export const getNowInstruments = () => async (dispatch, getState) => {
  try {
    const response = await dispatch(dispatchGetNowInstruments()).payload;
    return dispatch(dispatchGetNowInstrumentsSuccess(response));
  } catch (error) {
    dispatch(dispatchGetNowInstrumentsFailure(error));
    throw error;
  }
};
