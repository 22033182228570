import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getCMSSegments, getCMSCampaigns, getCMSPackage } from '../actions/cms';
import useImpression, { normalizeComponent } from '../hooks/useImpression';
import { filterCampaigns } from '../helpers/cms';
import CmsPromo from '../components/CmsPromo';

const mapStateToProps = (state, { page }) => {
  const {
    cms: {
      segments: { error: segmentsError, loading: segmentsLoading, data: segments } = {},
      campaigns: {
        error: campaignsError,
        loading: campaignsLoading,
        data: { campaigns } = {},
      } = {},
      packages,
    },
  } = state;
  let campaign;
  if (segments && campaigns) {
    [campaign] = filterCampaigns(segments, campaigns, page);
  }
  const { content: layout, loading: layoutLoading, error: layoutError } = (
    campaign?.targeted?.segment
      ? packages[campaign?.targeted?.segment] || {}
      : {}
  );
  return {
    loading: segmentsLoading || campaignsLoading || layoutLoading,
    layout,
    campaign,
    impressionReady: (
      !segmentsLoading
      && !campaignsLoading
      && !!(segments || segmentsError)
      && !!(campaigns || campaignsError)
    ),
    needSegments: !segmentsLoading && !segments && !segmentsError,
    needCampaigns: !campaignsLoading && !campaigns && !campaignsError,
    needLayout: !!segments && !!campaign && !layout && !layoutLoading && !layoutError,
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  getCMSSegments,
  getCMSCampaigns,
  getCMSPackage,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(({
  children,
  layout,
  impressionReady,
  loading,
  getCMSSegments: getSegments,
  getCMSCampaigns: getCampaigns,
  getCMSPackage: getLayout,
  needSegments,
  needCampaigns,
  needLayout,
  campaign,
  preload,
}) => {
  const [placementName, componentName] = normalizeComponent({ l3: campaign?.tracking?.impression || '' });
  useImpression({
    placementName: (
      placementName
        ? `myaccountweb|${placementName}`
        : 'myaccountweb|offer'
    ),
    componentName,
    when: impressionReady,
    // If preloading, never send the impression; just time out immediately instead.
    // making hooks conditional can break things, so we do the condition here.
    if: !preload && !!campaign,
    timeout: preload ? 0 : 10_000,
    id: 'cms-promo',
  });
  useEffect(() => {
    if (needSegments) getSegments();
  }, [needSegments, getSegments]);
  useEffect(() => {
    if (needCampaigns) getCampaigns();
  }, [needCampaigns, getCampaigns]);
  const layoutName = campaign?.targeted?.segment;
  useEffect(() => {
    if (needLayout) getLayout(layoutName);
  }, [needLayout, getLayout, layoutName]);

  return !preload && (
    <CmsPromo
      {...{
        show: !!campaign,
        layout,
        loading,
      }}
    />
  );
});
