import apiClient from '../middleware/apiClient';
import {
  ACCEPT_INSTRUMENT_CONTRACT_URL,
  ACCEPT_ECOBILL_CONTRACT_URL,
  ACCEPT_AUTOPAY_CONTRACT_URL,
  CONTRACT_TERMS,
  ACCEPTED_CONTRACTS,
  CONSENT,
} from '../helpers/apis';
import { flagEnabled } from '../helpers/featureFlags';

// POST accept instruments contract
export const ACCEPT_INSTRUMENT_CONTRACT = 'ACCEPT_INSTRUMENT_CONTRACT';
export const ACCEPT_INSTRUMENT_CONTRACT_SUCCESS = 'ACCEPT_INSTRUMENT_CONTRACT_SUCCESS';
export const ACCEPT_INSTRUMENT_CONTRACT_FAILURE = 'ACCEPT_INSTRUMENT_CONTRACT_FAILURE';

function dispatchAcceptInstrumentContract() {
  return {
    type: ACCEPT_INSTRUMENT_CONTRACT,
    payload: apiClient.fetch(ACCEPT_INSTRUMENT_CONTRACT_URL, { method: 'POST' }),
  };
}

function dispatchAcceptInstrumentContractSuccess(payment) {
  return {
    type: ACCEPT_INSTRUMENT_CONTRACT_SUCCESS,
    payload: payment,
  };
}

function dispatchAcceptInstrumentContractFailure(error) {
  return {
    type: ACCEPT_INSTRUMENT_CONTRACT_FAILURE,
    payload: error,
  };
}

export const acceptInstrumentContract = () => async (dispatch) => {
  try {
    const response = await dispatch(dispatchAcceptInstrumentContract()).payload;
    return dispatch(dispatchAcceptInstrumentContractSuccess(response));
  } catch (error) {
    dispatch(dispatchAcceptInstrumentContractFailure(error));
    throw error;
  }
};

// POST accept autopay contract
export const ACCEPT_AUTOPAY_CONTRACT = 'ACCEPT_AUTOPAY_CONTRACT';
export const ACCEPT_AUTOPAY_CONTRACT_SUCCESS = 'ACCEPT_AUTOPAY_CONTRACT_SUCCESS';
export const ACCEPT_AUTOPAY_CONTRACT_FAILURE = 'ACCEPT_AUTOPAY_CONTRACT_FAILURE';

function dispatchAcceptAutopayContract() {
  return {
    type: ACCEPT_AUTOPAY_CONTRACT,
    payload: apiClient.fetch(ACCEPT_AUTOPAY_CONTRACT_URL, { method: 'POST' }),
  };
}

function dispatchAcceptAutopayContractSuccess() {
  return {
    type: ACCEPT_AUTOPAY_CONTRACT_SUCCESS,
  };
}

function dispatchAcceptAutopayContractFailure() {
  return {
    type: ACCEPT_AUTOPAY_CONTRACT_FAILURE,
  };
}

export const acceptAutopayContract = () => async (dispatch) => {
  try {
    const response = await dispatch(dispatchAcceptAutopayContract()).payload;
    return dispatch(dispatchAcceptAutopayContractSuccess(response));
  } catch (error) {
    dispatch(dispatchAcceptAutopayContractFailure(error));
    throw error;
  }
};

// POST accept ecobill contract
export const ACCEPT_ECOBILL_CONTRACT = 'ACCEPT_ECOBILL_CONTRACT';
export const ACCEPT_ECOBILL_CONTRACT_SUCCESS = 'ACCEPT_ECOBILL_CONTRACT_SUCCESS';
export const ACCEPT_ECOBILL_CONTRACT_FAILURE = 'ACCEPT_ECOBILL_CONTRACT_FAILURE';

function dispatchAcceptEcobillContract() {
  return {
    type: ACCEPT_ECOBILL_CONTRACT,
    payload: apiClient.fetch(ACCEPT_ECOBILL_CONTRACT_URL, { method: 'POST' }),
  };
}

function dispatchAcceptEcobillContractSuccess() {
  return {
    type: ACCEPT_ECOBILL_CONTRACT_SUCCESS,
  };
}

function dispatchAcceptEcobillContractFailure() {
  return {
    type: ACCEPT_ECOBILL_CONTRACT_FAILURE,
  };
}

export const acceptEcobillContract = () => async (dispatch) => {
  try {
    const response = await dispatch(dispatchAcceptEcobillContract()).payload;
    return dispatch(dispatchAcceptEcobillContractSuccess(response));
  } catch (error) {
    dispatch(dispatchAcceptEcobillContractFailure(error));
    throw error;
  }
};

export const GET_TERMS = 'GET_TERMS';
export const GET_TERMS_SUCCESS = 'GET_TERMS_SUCCESS';
export const GET_TERMS_FAILURE = 'GET_TERMS_FAILURE';

const dispatchGetTerms = (contractId, consent) => ({
  type: GET_TERMS,
  payload: {
    contractId,
    promise: apiClient.fetch(
      consent
        ? CONSENT.CONTRACT_TERMS(contractId)
        : CONTRACT_TERMS(contractId),
    ),
  },
});

const dispatchGetTermsSuccess = (contractId, terms) => ({
  type: GET_TERMS_SUCCESS,
  payload: {
    terms,
    contractId,
  },
});

const dispatchGetTermsFailure = payload => ({
  type: GET_TERMS_FAILURE,
  payload,
});

export const getTerms = contractId => async (dispatch, getState) => {
  const { auth: { crs }, contracts: { terms } } = getState();
  // TODO update to cache
  if (terms[contractId]) {
    return null;
  }
  try {
    const response = await dispatch(dispatchGetTerms(contractId, !!crs)).payload.promise;
    return dispatch(dispatchGetTermsSuccess(contractId, response));
  } catch (error) {
    dispatch(dispatchGetTermsFailure(error));
    throw error;
  }
};

export const GET_ACCEPTED_CONTRACTS = 'GET_ACCEPTED_CONTRACTS';
export const GET_ACCEPTED_CONTRACTS_SUCCESS = 'GET_ACCEPTED_CONTRACTS_SUCCESS';
export const GET_ACCEPTED_CONTRACTS_FAILURE = 'GET_ACCEPTED_CONTRACTS_FAILURE';

const dispatchGetAccepted = consent => ({
  type: GET_ACCEPTED_CONTRACTS,
  payload: apiClient.fetch(
    consent
      ? CONSENT.ACCEPTED_CONTRACTS
      : ACCEPTED_CONTRACTS,
  ),
});

const dispatchGetAcceptedSuccess = payload => ({
  type: GET_ACCEPTED_CONTRACTS_SUCCESS,
  payload,
});

const dispatchGetAcceptedFailure = payload => ({
  type: GET_ACCEPTED_CONTRACTS_FAILURE,
  payload,
});

export const getAccepted = () => async (dispatch, getState) => {
  const { auth: { crs }, contracts: { accepted } } = getState();
  // skip if we've got it already, or if we shouldn't.
  if (accepted || (crs && !flagEnabled('consent.acceptedContracts'))) {
    return null;
  }
  try {
    const response = await dispatch(dispatchGetAccepted(!!crs)).payload;
    return dispatch(dispatchGetAcceptedSuccess(response));
  } catch (error) {
    return dispatch(dispatchGetAcceptedFailure(error));
  }
};
