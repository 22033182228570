/**
 * Generate a random string containing [0-9a-z], 11-12 chars long,
 * suitable for adding to IDs.
 * @returns {String} random string
 */
export const randomId = () => Math.random().toString(36).substring(2);

/**
 * Generate a UUID version 4, variant 1 using regular randoms.
 * Not for cryptographic use.
 * @see https://en.wikipedia.org/wiki/Universally_unique_identifier#Version_4_(random)
 * @returns {String} the generated UUID
 */
export const generateUUID = () => {
  let d = '';
  while (d.length < 32) d += Math.random().toString(16).substring(2);
  // block 4, nib 1 should be 10xxb, or 8..b inclusive.
  const variant = (8 + parseInt(d.substring(16, 17), 16) % 4).toString(16);
  return `${d.substring(0, 8)}-${d.substring(8, 12)}-4${d.substring(13, 16)}-${variant}${d.substring(17, 20)}-${d.substring(20, 32)}`;
};
