import React from 'react';
import { PageFooter } from 'adc-ui-components';
import { Link } from 'react-router-dom';

import {
  HOME,
  NEW_PAYMENT,
  PAYMENT_PLAN,
  AUTOPAY,
  METHODS,
  SCHEDULED_PAYMENT,
  PAYMENT_SETUP,
  TERMS_AUTOPAY,
  TERMS_STORED_PAYMENT,
  TERMS_ECOBILL,
} from '../../../helpers/routes';

const LegacyFooter = () => (
  <>
    <PageFooter showCPPFooter />
    {(process.env.NODE_ENV !== 'production') && (
      <ul>
        <li><Link to={HOME}>Home</Link></li>
        <li><Link to={NEW_PAYMENT}>New Payment</Link></li>
        <li><Link to={PAYMENT_PLAN}>Payment Plan</Link></li>
        <li><Link to={AUTOPAY}>Autopay</Link></li>
        <li><Link to={METHODS}>Saved Payment Methods</Link></li>
        <li><Link to={SCHEDULED_PAYMENT}>Scheduled Payments</Link></li>
        <li><Link to={PAYMENT_SETUP}>Consent Payment Setup</Link></li>
        <li><Link to={TERMS_AUTOPAY}>Terms: Autopay</Link></li>
        <li><Link to={TERMS_STORED_PAYMENT}>Terms: Stored Payment</Link></li>
        <li><Link to={TERMS_ECOBILL}>Terms: Ecobill</Link></li>
      </ul>
    )}
  </>
);

export default LegacyFooter;
