import React from 'react';

/**
 * Convert a numeric date string into a UNIX ms timestamp at noon of the same day
 * @param {String} dateStr A date in any of the following formats:
 *    ISO 8601: YYYY-MM-DDTHH:mm:ss.ms or YYYY-MM-DD
 *    Locale (US): M/D/YY or MM/DD/YYYY
 *    Unix ms timestamp string
 *    Anything supported by Date.parse (varies by browser)
 * @returns a UNIX ms timestamp fixed at noon of the local time zone
 */
export const parseDate = (dateStr) => {
  let parts;
  let day;
  let month;
  let year;
  let delimiter;
  // Fallback to today if dateString is falsy
  let dateString = (dateStr || new Date()).toString();
  if (dateString.match(/^\d+-\d+-\d+[T\s]\d+:\d+:\d+$/)) { // MAW-814: Removing timepart in case when date is like "2016-08-04 14:28:03"
    [dateString] = dateString.split(' ');
  }
  if (dateString.match(/^\d{4}\D\d+\D\d+$/)) { // Case when date has format yyyy-MM-dd
    [delimiter] = dateString.match(/\D/);
    parts = dateString.split(delimiter);
    [year, month, day] = parts;
  } else if (dateString.match(/^\d+\D\d+\D\d+$/)) { // Case when date is a timestamp or formatted like MM/dd/yy or MM/dd/yyyy
    [delimiter] = dateString.match(/\D/);
    parts = dateString.split(delimiter);
    [month, day, year] = parts;
  } else if (dateString.match(/^[-+]?\d+$/)) { // Should match any positive or negative number string to dates before 1970
    return new Date(+dateString).setHours(12, 0, 0, 0);
  } else {
    return new Date(dateString).setHours(12, 0, 0, 0);
  }
  // Move to local noon and return timestamp.
  return new Date(year, month - 1, day, 12, 0, 0, 0).getTime();
};

export const noonOf = d => new Date(d.setHours(12, 0, 0, 0));

export const today = () => noonOf(new Date());

export const isDate = d => d && (d instanceof Date || d.toISOString instanceof Function);

export const createDateObject = (date) => {
  if (!date && typeof date !== 'number') return null;
  if (isDate(date)) return date;
  return new Date(parseDate(String(date)));
};

export const addDays = (date, days = 0) => {
  const thisDate = createDateObject(date);
  const returnDate = new Date();
  const timeOffset = days * 864e5;

  returnDate.setTime(thisDate.getTime() + timeOffset);

  return returnDate;
};

export const countDays = (startDate, endDate) => {
  const oneDay = 864e5; // hours*minutes*seconds*milliseconds
  const firstDate = parseDate(startDate);
  const secondDate = parseDate(endDate);
  const diffDays = Math.ceil(Math.abs((firstDate - secondDate) / (oneDay)));

  return diffDays;
};

export const compareDays = (date1, date2, comparator) => {
  // IE11 doesn't support passing a Date object into the Date constructor
  // so we do a type-check before formatting into a yyyy-mm-dd string
  const a = noonOf(createDateObject(date1)).toJSON().substring(0, 10);
  const b = noonOf(createDateObject(date2)).toJSON().substring(0, 10);
  return comparator(a, b);
};

export const isSameYear = (d1, d2) => (!!d1 && !!d2) && d1.getFullYear() === d2.getFullYear();
export const isSameMonth = (d1, d2) => isSameYear(d1, d2) && d1.getMonth() === d2.getMonth();
export const isSameDay = (d1, d2) => isSameMonth(d1, d2) && d1.getDate() === d2.getDate();

export const createRange = (start, end, leadingZero) => {
  const array = [];

  let i; let
    string;

  for (i = start; i <= end; i += 1) {
    string = '';

    if (leadingZero && i < 10) {
      string = '0';
    }

    string += String(i);

    array.push(string);
  }

  return array;
};

export const months = createRange(1, 12, true);
export const years = createRange(new Date().getFullYear(), new Date().getFullYear() + 10);

export const renderOptions = values => values.map(item => (
  <option key={item} value={item}>{item}</option>
));

export const getIsToday = date => isSameDay(today(), createDateObject(date));

export const isWithinNDays = (date, days) => (
  compareDays(date, today(), (a, b) => a >= b)
  && compareDays(date, addDays(today(), days), (a, b) => a < b)
);

export const getLocaleDate = date => (
  new Date(+new Date(date) + (new Date().getTimezoneOffset()) * 6e4)
);

export const addMonths = (date, count) => {
  let returnDate = new Date();
  let thisDate;
  let Y;
  let M;
  let d;
  let h;
  let m;
  let s;
  let ms;

  if (date) {
    thisDate = createDateObject(date);
    Y = thisDate.getFullYear();
    M = thisDate.getMonth();
    d = thisDate.getDate();
    h = thisDate.getHours();
    m = thisDate.getMinutes();
    s = thisDate.getSeconds();
    ms = thisDate.getMilliseconds();

    if ((M + count) > 11) {
      returnDate.setFullYear(Y + 1);
      returnDate.setMonth((M + count) - 12);
      returnDate.setDate(d);
    } else if (M === 0 && count < 0) {
      returnDate.setFullYear(Y - 1);
      returnDate.setMonth(12 + count);
      returnDate.setDate(d);
    } else {
      returnDate.setFullYear(Y);
      returnDate.setMonth(M + count);
      returnDate.setDate(d);
    }
    returnDate.setHours(h);
    returnDate.setMinutes(m);
    returnDate.setSeconds(s);
    returnDate.setMilliseconds(ms);

    if (Y === returnDate.getFullYear() && returnDate.getMonth() !== M + count) {
      returnDate.setDate(0);
    }
  } else if (!date && count) {
    M = returnDate.getMonth();
    returnDate.setMonth(M + count);
  } else {
    returnDate = null;
  }

  return returnDate;
};
