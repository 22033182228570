import apiClient from '../middleware/apiClient';
import { PAYMENT_PLAN_LTIP } from '../helpers/apis';

export const GET_LTIP_OPTIONS = 'GET_LTIP_OPTIONS';
export const GET_LTIP_OPTIONS_SUCCESS = 'GET_LTIP_OPTIONS_SUCCESS';
export const GET_LTIP_OPTIONS_FAILURE = 'GET_LTIP_OPTIONS_FAILURE';

export const getLtipOptions = otherPaymentAmount => async (dispatch, getState) => {
  const amount = parseFloat(otherPaymentAmount) || undefined;
  const { ltip: { cache = {}, minimum } = {} } = getState();
  const cached = minimum && minimum.initialPaymentAmount === amount
    // Consider the minimum a cached value for any otherPaymentAmount that parses to the same amount
    ? minimum
    : cache[amount];
  const promisor = (
    cached
      ? () => Promise.resolve(cached)
      : () => apiClient.fetch(PAYMENT_PLAN_LTIP(amount))
  );
  try {
    const response = await dispatch({
      type: GET_LTIP_OPTIONS,
      payload: promisor(),
    }).payload;
    dispatch({
      type: GET_LTIP_OPTIONS_SUCCESS,
      payload: {
        params: { otherPaymentAmount: amount },
        response,
      },
    });
  } catch (error) {
    dispatch({
      type: GET_LTIP_OPTIONS_FAILURE,
      payload: error,
    });
  }
};
