import React from 'react';

import AmericanExpress from './AmericanExpress';
import ApplePay from './ApplePay';
import ArrowLeft from './ArrowLeft';
import BankPayment from './BankPayment';
import Bill from './Bill';
import BillClock from './BillClock';
import BillOutline from './BillOutline';
import BoxClosed from './BoxClosed';
import Calendar from './Calendar';
import CalendarBill from './CalendarBill';
import CardPayment from './CardPayment';
import CaretLeft from './CaretLeft';
import Cash from './Cash';
import Check from './Check';
import CircleI from './CircleI';
import Discover from './Discover';
import Dismiss from './Dismiss';
import IconCircleExclamationRed from './IconCircleExclamationRed';
import MasterCard from './MasterCard';
import Plus from './Plus';
import Visa from './Visa';
import XfinityAssistant from './XfinityAssistant';
import XfinityFlex from './XfinityFlex';

const table = {
  AmericanExpress,
  'american-express': AmericanExpress,
  'American Express': AmericanExpress,
  ApplePay,
  'apple-pay': ApplePay,
  ArrowLeft,
  'arrow-left': ArrowLeft,
  BankPayment,
  'bank-payment': BankPayment,
  Bill,
  bill: Bill,
  BillClock,
  'bill-clock': BillClock,
  BillOutline,
  'bill-outline': BillOutline,
  BoxClosed,
  'box-closed': BoxClosed,
  Calendar,
  calendar: Calendar,
  CalendarBill,
  'calendar-bill': CalendarBill,
  CardPayment,
  'card-payment': CardPayment,
  CaretLeft,
  'caret-left': CaretLeft,
  Cash,
  cash: Cash,
  Check,
  check: Check,
  CircleI,
  'circle-i': CircleI,
  Discover,
  discover: Discover,
  Dismiss,
  dismiss: Dismiss,
  IconCircleExclamationRed,
  'icon-circle-exclamation-red': IconCircleExclamationRed,
  MasterCard,
  'master-card': MasterCard,
  Plus,
  plus: Plus,
  Visa,
  visa: Visa,
  XfinityAssistant,
  'xfinity-assistant': XfinityAssistant,
  XfinityFlex,
  'xfinity-flex': XfinityFlex,
};

const SvgIcon = ({ icon, ...props }) => {
  const IconComp = table[icon];
  if (!IconComp) return null;
  return <IconComp {...props} />;
};

export default SvgIcon;
