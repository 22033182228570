/**
 * Given an Object<actionId:string,subReducer:Function>, will return a
 * combined reducer for those actionIds.
 * @param {Object} initialState initial state for the reducer
 * @param {Object} map map of action IDs to subreducer functions
 * @return {Function} The combined reducer function
 */
// eslint-disable-next-line import/prefer-default-export
export const keyedReducer = (initialState, map) => (state = initialState, action) => {
  if (map[action.type] instanceof Function) {
    const newState = map[action.type](state, action);
    return newState;
  }
  return state;
};
