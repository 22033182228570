import React from 'react';
import { useIsGlobalNav } from '../../helpers/polaris';

const PageApp = ({ children, className, ...props }) => {
  const isGlobalNav = useIsGlobalNav();
  const pageAppClass = [...new Set([
    'page-app',
    isGlobalNav && 'page-app--polaris-global',
    ...(className || '').split(' '),
  ])].filter(a => !!a).join(' ');
  return <div className={pageAppClass} {...props}>{children}</div>;
};

export default PageApp;
