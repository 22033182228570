import { useState } from 'react';
import { randomId } from '../helpers/uids';

/**
 * A unique ID for use in a component that will not change for its lifetime.
 *
 * example:
 *  const LabelledInput = () => {
 *    const uid = useUid('myComponent');
 *    return (
 *      <label htmlFor={uid}>
 *        <input id={uid} ... />
 *      </label>
 *    )
 *  };
 *
 * @param {String} prefix prefix the UID with something so you can pick it out in inspect view.
 */
export default function useUid(prefix = '') {
  return useState(`${prefix}_${randomId()}`)[0];
}
