import {
  GET_COLLECTIONS,
  GET_COLLECTIONS_SUCCESS,
  GET_COLLECTIONS_FAILURE,
  ENROLL_USER_INSTALMMENT,
  ENROLL_USER_INSTALMMENT_SUCCESS,
  ENROLL_USER_INSTALMMENT_FAILURE,
  REVIEW_COLLECTIONS,
  SUBMIT_COLLECTIONS,
  SUBMIT_COLLECTIONS_SUCCESS,
  SUBMIT_COLLECTIONS_FAILURE,
  STORE_FORM_STATE,
} from '../actions/collections';

const INITIAL_STATE = {
  loading: false,
};

export default function collectionsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_COLLECTIONS:
      return {
        ...state,
        loading: true,
      };
    case GET_COLLECTIONS_SUCCESS:
      return {
        ...state,
        collections: action.payload,
        loading: false,
        error: false,
      };
    case GET_COLLECTIONS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case ENROLL_USER_INSTALMMENT:
      return {
        ...state,
        submitting: true,
      };
    case ENROLL_USER_INSTALMMENT_SUCCESS:
      return {
        ...state,
        enrolled: true,
      };
    case ENROLL_USER_INSTALMMENT_FAILURE:
      return {
        ...state,
        enrollmentError: action.payload,
      };
    case REVIEW_COLLECTIONS:
      return {
        ...state,
        submitError: null,
        review: action.payload,
      };
    case SUBMIT_COLLECTIONS:
      return {
        ...state,
        submitting: true,
      };
    case SUBMIT_COLLECTIONS_SUCCESS:
      return {
        ...state,
        review: null,
        confirm: action.payload,
        submitting: false,
        formState: undefined,
      };
    case SUBMIT_COLLECTIONS_FAILURE:
      return {
        ...state,
        review: null,
        confirm: null,
        submitting: false,
        submitError: action.payload,
      };
    case STORE_FORM_STATE:
      return {
        ...state,
        formState: action.payload,
      };
    default:
      return state;
  }
}
