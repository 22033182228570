import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { Formik, Form } from 'formik';
import { bindActionCreators } from 'redux';
import { Link, Redirect } from 'react-router-dom';
import { PageSection } from 'adc-ui-components';
import dateformat from 'dateformat';

import { kibanaLog } from '../../helpers/logger';
import { scrollToInvalid, getErrorFromResponse } from '../../helpers/errors';
import { submitPayment } from '../../actions/payment';
import { sendChannelTracking } from '../../actions/channelTracking';
import { reviewPaymentPlan, recalcPaymentPlan } from '../../actions/paymentPlan';
import useScheduled from '../../hooks/useScheduled';

import { setSubmit, setSuccess, setError } from '../../actions/userMessages';
import { getDefaultInstrumentValue } from '../../helpers/paymentInstrument';
import { openInNewWindow } from '../../helpers/externalLinks';
import { getIsXapExit } from '../../helpers/xap';
import withCmsContent from '../../helpers/withCmsContent';
import { joinClass } from '../../helpers/component';

import ErrorMessage from '../../components/ErrorMessage';
import Card from '../../components/Card';
import PaymentPlanOptions from '../../components/form-fields/PaymentPlanOptions';
import PaymentPlanAmount from '../../components/form-fields/PaymentPlanAmount';
import PaymentMethods from '../../components/form-fields/PaymentMethods';
import PageTitle from '../../components/PageTitle';
import XAPIneligibleInterstitial from '../../components/XAPIneligibleInterstitial';

import getConfig from '../../config';

import { flagEnabled } from '../../helpers/featureFlags';

import {
  PAYMENT_PLAN_CONFIRMATION,
  PAYMENT_PLAN_REVIEW,
  PAYMENT_PLAN_BANK,
  PAYMENT_PLAN_CARD,
  PAYMENT_PLAN,
  METHODS,
  NEW_PAYMENT,
} from '../../helpers/routes';

const { homeDomain } = getConfig();

const PaymentForm = ({
  autopay,
  bill,
  bill: { summary: { pastDueBalance } = {} },
  instruments,
  initialValues,
  history,
  handleReviewPlanSubmit,
  applePay,
  handleSubmitPayment,
  errorMessage,
  paymentFormData,
  handleSetPaymentFormData,
  handleSetError,
  handleSetSubmit,
  handleSetSuccess,
  paymentOptionsPlan,
  minimumPayment,
  minimumPaymentOptions,
  goodFaithPayment,
  handleRecalcPaymentPlan,
  refreshing,
  isXapExit,
  submitting,
  paymentPlanIneligible,
  content,
  handleSendChannelTracking,
}) => {
  const [refreshQueued, getOptionsThrottled] = useScheduled(handleRecalcPaymentPlan, {
    timeout: 500, callOnUnmount: false,
  });

  const onXaClick = useCallback((event) => {
    const rv = openInNewWindow(event);
    handleSendChannelTracking({
      id: 'TRANSFERRED',
      metadata: { targetChannel: 'XA' },
    });
    return rv;
  }, [handleSendChannelTracking]);

  const onFormSubmit = (values, { setSubmitting }) => {
    const {
      paymentMethodOption,
    } = values;

    kibanaLog('payment_form_submit', {
      form: 'PaymentPlan',
    });

    handleSetSubmit();
    // store payment data in the state and submit in addinstrument form
    // or restore initialValues from state if the user comes back
    handleSetPaymentFormData(values);

    if (paymentMethodOption === 'Bank') {
      return history.push(PAYMENT_PLAN_BANK);
    }

    if (paymentMethodOption === 'PaymentCard') {
      return history.push(PAYMENT_PLAN_CARD);
    }

    if (paymentMethodOption === 'Apple Pay') {
      return handleReviewPlanSubmit(values).then(() => (
        handleSubmitPayment(false).then(() => {
          handleSetSuccess();
          history.push(PAYMENT_PLAN_CONFIRMATION);
        }).catch((applePayServerErr) => {
          setSubmitting(false);
          scrollToInvalid();

          if (applePayServerErr.type !== 'cancel') {
            const applePayServerError = getErrorFromResponse(applePayServerErr);

            if (applePayServerError.message) {
              return handleSetError({ text: applePayServerError.message });
            }

            return handleSetError({ showModal: true });
          }
          return false;
        })
      ));
    }

    return handleReviewPlanSubmit(values).then(() => history.push(PAYMENT_PLAN_REVIEW));
  };

  // if not xap exit, send them to main flow
  if (!isXapExit) {
    return <Redirect to={NEW_PAYMENT} />;
  }

  const directOnClick = () => {
    if (content('heading.cta.text') === 'Ask Xfinity Assistant') {
      return onXaClick;
    }
    return openInNewWindow;
  };

  return (
    <PageSection>
      <Formik
        onSubmit={onFormSubmit}
        initialValues={{
          ...initialValues,
          ...paymentFormData,
        }}
      >
        {({
          values,
          errors,
          submitCount,
          setFieldValue,
          setFieldError,
          setTouched,
          touched,
        }) => (
          <Form noValidate>
            <div className="payment-section">
              <PageTitle>{content('heading.title')}</PageTitle>
              <Card>
                <p>
                  {content('heading.description')}
                  {' '}
                  {content('heading.cta.text') && content('heading.cta.href') && (
                    <a
                      href={content('heading.cta.href')}
                      target={content('heading.cta.target')}
                      rel="noopener noreferrer"
                      onClick={content('heading.cta.target') === '_blank' ? directOnClick() : () => undefined}
                    >
                      {content('heading.cta.text')}
                    </a>
                  )}
                </p>
              </Card>
            </div>

            {errorMessage && <ErrorMessage errorMessage={errorMessage} />}

            <PaymentPlanAmount
              goodFaithPayment={goodFaithPayment}
              minimumPayment={minimumPayment}
              setFieldValue={setFieldValue}
              setFieldError={setFieldError}
              setFieldTouched={(name, value) => setTouched({ ...touched, [name]: value })}
              values={values}
              heading={(
                <>
                  <h2>{content('amount.title')}</h2>
                  <Link to={NEW_PAYMENT}>{content('amount.payFull')}</Link>
                </>
              )}
            />

            <PaymentPlanOptions
              error={submitCount > 0 && errors.paymentPlanOption}
              refreshing={refreshing}
              onRecalcPayment={getOptionsThrottled}
              paymentOptionsPlan={values.paymentAmountOption === 'custom' ? paymentOptionsPlan : minimumPaymentOptions}
              paymentAmountOption={values.paymentAmountOption}
              minimumPayment={minimumPayment}
              customAmount={values.customAmount}
              pastDueBalance={pastDueBalance}
              setFieldValue={setFieldValue}
              touched={touched}
              errors={errors}
              disabled={paymentPlanIneligible}
            />

            <div className="payment-section">
              <div className="hgroup hgroup--inline">
                <h2>{content('paymentMethod.title')}</h2>
                <Link
                  to={{
                    pathname: METHODS,
                    state: { previousPage: PAYMENT_PLAN },
                  }}
                >
                  {content('paymentMethod.ctaText')}
                </Link>
              </div>
              <PaymentMethods
                autopay={autopay}
                applePay={applePay}
                instruments={instruments}
                canStoreInstruments
                bill={bill}
                values={values}
              />
            </div>

            <div className="action action--right">
              <div className="action__item">
                <button
                  className={joinClass('button button--primary', submitting && 'is-loading')}
                  disabled={submitting || refreshQueued || refreshing}
                  type="submit"
                >
                  {content('form.submit')}
                </button>
              </div>
              <div className="action__item">
                <a className="button" href={`https://${homeDomain}/`}>
                  {content('form.cancel')}
                </a>
              </div>
            </div>
          </Form>
        )
      }
      </Formik>
      { paymentPlanIneligible && <XAPIneligibleInterstitial /> }
    </PageSection>
  );
};

const mapStateToProps = ({
  autopay: {
    autopay,
  },
  account: {
    account,
  },
  paymentPlan: {
    submitting: paymentPlanSubmitting,
    paymentPlanIneligible,
    data: {
      goodFaithPayment,
      paymentOptionsPlan,
    } = {},
    minimum: {
      goodFaithPayment: minimumPayment,
      paymentOptionsPlan: minimumPaymentOptions,
    } = {},
    refreshing,
  },
  bill: { bill = {} },
  applePay: { applePay },
  payment: { submitting: paymentSubmitting },
  instruments: { instruments },
  userMessages: {
    error: errorMessage,
    showModal,
  },
}) => {
  const isLtip = flagEnabled('global.collections2', { defaultValue: true }) && account.xap.ltip === 'ELIGIBLE';

  const plans = paymentOptionsPlan || [];
  return {
    bill,
    isXapExit: getIsXapExit(account) || isLtip,
    autopay,
    goodFaithPayment,
    instruments,
    applePay,
    refreshing,
    errorMessage: !showModal && errorMessage,
    paymentOptionsPlan: plans,
    minimumPayment,
    minimumPaymentOptions: minimumPaymentOptions || [],
    submitting: paymentPlanSubmitting || paymentSubmitting,
    paymentPlanIneligible: paymentPlanIneligible && !isLtip,
    initialValues: {
      paymentAmountOption: paymentPlanIneligible ? 'custom' : goodFaithPayment,
      paymentPlanOption: plans.length && plans[0].trackingCode,
      date: dateformat(new Date(), 'm/d/yyyy'), // don't make this UTC, should always be today local
      paymentMethodOption: getDefaultInstrumentValue(instruments),
    },
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  handleRecalcPaymentPlan: recalcPaymentPlan,
  handleReviewPlanSubmit: reviewPaymentPlan,
  handleSubmitPayment: submitPayment,
  handleSetSubmit: setSubmit,
  handleSetSuccess: setSuccess,
  handleSetError: setError,
  handleSendChannelTracking: sendChannelTracking,
}, dispatch);

export default withCmsContent('content', {
  'ltip-payment-plan': 'Form',
})(connect(mapStateToProps, mapDispatchToProps)(PaymentForm));
