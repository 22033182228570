import dateformat from 'dateformat';
import { formatCurrency } from '../../helpers/formatText';

import BillOutline from '../svgs/BillOutline';

const PAST_DUE = 'PAST_DUE';

export const getMeetsLegacyP2PPrerequisites = ({ billStateCheck: check = {} } = {}) => (
  !check.autopayEnabled
  && check.p2pEligible
  && check.delinquent
  && check.pastDue
);

export const getLegacyP2PData = ({ summary, promiseToPay = {} }) => {
  // Sort P2P requirements by date, because we always want to use the closest date.
  const requirements = promiseToPay.requirements.sort((a, b) => {
    if (a.date > b.date) return 1;
    if (a.date < b.date) return -1;
    return 0;
  });

  const baseNba = {
    id: PAST_DUE,
    svgIcon: BillOutline,
    color: 'alert',
    name: 'Your payment is late.',
    dismissible: false,
  };

  const amount = formatCurrency(summary.pastDueBalanceRemaining);
  const date = dateformat(requirements[0].date, 'mmmm d', true);

  if (summary.softDisconnected) { // May P2P to restore disconnected services.
    return {
      ...baseNba,
      trackId: 'p2p-soft-disconnect',
      description: `Please schedule a payment of at least ${amount} by ${date} to restore services.`,
    };
  }

  return {
    ...baseNba,
    trackId: 'p2p-delinquent',
    description: `Please pay at least ${amount} by ${date} to avoid a service suspension.`,
  };
};
