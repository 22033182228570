import React from 'react';

const Bill = ({ className = '' }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 38 48">
    <path
      fill="#fff"
      d="M35.6 0H2.4A2.4 2.4 0 0 0 0 2.4v5.17h38V2.4A2.4 2.4 0 0 0 35.6 0zM4.51 5.39a1.32 1.32 0 1 1 1.32-1.32 1.32 1.32 0 0 1-1.32 1.32zm4.3 0a1.32 1.32 0 1 1 1.32-1.32 1.32 1.32 0 0 1-1.31 1.32zm4.3 0a1.32 1.32 0 1 1 1.32-1.32 1.32 1.32 0 0 1-1.31 1.32zM38 8.54H0V45.6A2.4 2.4 0 0 0 2.4 48h33.2a2.4 2.4 0 0 0 2.4-2.4V8.54zM15.21 24.13a1.22 1.22 0 0 1 1.41 1c.27 1.62 2 1.71 2.38 1.71 2.27 0 2.36-1.34 2.36-1.87-.08-.7-.58-1-2.51-1.79-1.56-.56-4.23-1.51-4.23-4.16a4 4 0 0 1 3.15-3.91v-1.69a1.23 1.23 0 1 1 2.45 0V15a4.52 4.52 0 0 1 3.37 3 1.23 1.23 0 1 1-2.3.84 2.13 2.13 0 0 0-2.15-1.49c-.21 0-2.07 0-2.07 1.65 0 .6.42 1.07 2.63 1.86 1.84.71 3.89 1.5 4.11 3.92v.11a4.13 4.13 0 0 1-3.58 4.26v1.91a1.23 1.23 0 1 1-2.45 0v-1.91a4.37 4.37 0 0 1-3.57-3.61 1.23 1.23 0 0 1 1-1.41zm7.92 18.27H8.26a.49.49 0 0 1 0-1h14.87a.49.49 0 0 1 0 1zm6.61-4.61H8.26a.49.49 0 0 1 0-1h21.48a.49.49 0 0 1 0 1z"
    />
  </svg>
);

export default Bill;
