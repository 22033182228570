import { p2pProps } from '../../helpers/p2p';
import {
  avoidConsequencesPay,
} from './messages';

import { flagEnabled } from '../../helpers/featureFlags';

const p2pAmountMessage = ({ base, account, ...props }) => {
  const def = base || null;
  const p2pMessaging = flagEnabled('p2pMessaging');
  if (!p2pMessaging) return def;
  const {
    isP2P,
    softDisconnected,
    pastDue,
    amount,
    qualifies,
    p2pMinAmount,
    balance,
  } = p2pProps(props);
  // Return default messaging if not P2P or date/amount qualifies
  if (!isP2P) return def;
  // Let BAU credit messaging through
  if (amount > balance) return def;
  // Suppress BAU if qualifying payment or payment is
  //  over p2p minimum.
  if (qualifies || amount >= p2pMinAmount) return null;
  // Return messaging about avoiding disconnection / interruption
  return avoidConsequencesPay({
    account,
    softDisconnected,
    pastDue,
    balance,
    amount,
    raw: true,
  });
};

export default p2pAmountMessage;
