import React from 'react';

const icon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 32">
    <path d="M4 0h42a4 4 0 0 1 4 4v24a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z" fill="#e6eaed" />
    <path d="M50 20s-11.7 8.3-33.2 12H46a4 4 0 0 0 4-4v-8z" fill="#f48024" />
    <path d="M6.7 17.6c-.5.4-1 .6-2 .6h-.3v-4.7h.4c.9 0 1.4.2 1.9.6.5.4.8 1 .8 1.7s-.3 1.4-.8 1.8M5 12.3H3v7.1h2c1.1 0 1.9-.3 2.6-.8.8-.7 1.3-1.7 1.3-2.8 0-2-1.6-3.5-3.8-3.5m4.4 7.1H11v-7.1H9.5m4.8 2.7c-.8-.3-1-.5-1-.9s.4-.8 1-.8c.4 0 .8.2 1.1.6l.8-1c-.6-.5-1.4-.7-2.1-.7-1.3 0-2.3.8-2.3 2 0 1 .5 1.5 1.8 2l1 .4c.2.1.4.4.4.7 0 .6-.5 1-1.1 1-.7 0-1.2-.3-1.5-1l-1 1c.7.9 1.5 1.3 2.5 1.3 1.5 0 2.5-1 2.5-2.4 0-1-.5-1.6-2-2.2m2.4.9c0 2 1.7 3.7 3.8 3.7.6 0 1.1-.2 1.8-.4v-1.7c-.6.6-1 .8-1.7.8-1.4 0-2.4-1-2.4-2.5 0-1.3 1-2.4 2.3-2.4.7 0 1.2.2 1.8.8v-1.6a3.8 3.8 0 0 0-5.5 3.3M33.3 17l-1.9-4.8H30l3 7.3h.8l3-7.3h-1.4m2.1 7.1h4v-1.2h-2.6v-2h2.5V15h-2.5v-1.6h2.6v-1.2h-4m6.7 3.3h-.4v-2.2h.4c.9 0 1.4.4 1.4 1 0 .8-.5 1.2-1.4 1.2m2.8-1.2c0-1.3-1-2.1-2.5-2.1h-2.1v7.1h1.4v-2.9h.2l1.9 2.9h1.7l-2.2-3c1-.2 1.6-1 1.6-2" fill="#221f20" />
    <path d="M30.4 15.8a3.8 3.8 0 1 1-7.6 0 3.8 3.8 0 0 1 7.6 0" fill="#f48024" />
    <path d="M47.5 12.5v-.1.1m.2 0c0-.2 0-.3-.2-.3h-.2v.7h.2v-.3l.1.3h.2l-.2-.3.1-.2" fill="#221f20" />
    <path d="M47.5 13a.4.4 0 0 1-.4-.5c0-.2.2-.4.4-.4.3 0 .5.2.5.4 0 .3-.2.5-.5.5m0-1c-.3 0-.5.2-.5.5s.2.6.5.6.6-.3.6-.6-.3-.5-.6-.5" fill="#221f20" />
  </svg>
);

export default icon;
