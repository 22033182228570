import React from 'react';

const icon = () => {
  const st0 = {
    fill: 'none',
    stroke: 'currentColor',
    strokeWidth: '2.5',
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
  };

  return (
    <span className="svg-icon">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60">
        <path d="M15 32.6l8.3 8.3L44 20.1" {...st0} />
      </svg>
    </span>
  );
};

export default icon;
