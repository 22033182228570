import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { IconCircleExclamation } from 'adc-ui-components';

import { messageShown } from '../../actions/userMessages';
import Modal from '../../components/Modal';

class UnhandledError extends Component {
  componentDidMount() {
    const {
      handleMessageShown,
    } = this.props;

    handleMessageShown();
  }

  render() {
    const {
      closeModal,
      dismissable,
    } = this.props;

    return (
      <Modal isOpen onRequestClose={closeModal} dismissable={dismissable}>
        <div className="text-center">
          <IconCircleExclamation className="mb12 svg-icon--42" />
          <div className="hgroup mb24">
            <h2>Looks like something went wrong</h2>
            <p className="body2">Please reload the page and try again.</p>
          </div>
          <button className="button button--primary" type="button" onClick={() => window.location.reload()}>Reload Now</button>
        </div>
      </Modal>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  handleMessageShown: bindActionCreators(messageShown, dispatch),
});

export default connect(null, mapDispatchToProps)(UnhandledError);
