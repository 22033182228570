import React, { useState, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import dateformat from 'dateformat';
import { openInNewWindow, xaLinkWithLogin } from '../../helpers/externalLinks';
import { formatCurrency } from '../../helpers/formatText';
import { getBill } from '../../actions/bill';
import { getAccount } from '../../actions/account';
import { fetchScheduledPayments } from '../../actions/scheduledPayments';
import { getIsDisconnected } from '../../helpers/account';
import { NEW_PAYMENT } from '../../helpers/routes';
import { getMeetsP2PPrerequisites, hasQualifyingScheduledPayments } from '../../helpers/p2p';
import { sendChannelTracking } from '../../actions/channelTracking';
import {
  countDays, addDays, parseDate, createDateObject, compareDays, today,
} from '../../helpers/date';
import { flagEnabled } from '../../helpers/featureFlags';
import { getIsXapExit, getIsXapExitInProgress, getIsXapCurrent } from '../../helpers/xap';

import NextBestAction from '../NextBestAction';
import TooltipButton from '../TooltipButton';
import BillOutline from '../svgs/BillOutline';
import { getMeetsLegacyP2PPrerequisites, getLegacyP2PData } from './legacyP2PNba';

export const PAST_DUE = 'PAST_DUE';

export const getP2PData = ({
  summary: {
    pastDueBalanceRemaining,
    promiseToPayDate,
    softDisconnected,
    softDisconnectedDate,
  },
  promiseToPay: {
    customerEligibilityChecked: {
      maxP2PDateBeforeCycle,
      maxP2PDateAfterCycle,
      minP2PAmtBeforeCycle,
      minP2PAmtAfterCycle,
    } = {},
  } = {},
}) => {
  if (pastDueBalanceRemaining <= 0) {
    return null;
  }
  const requirements = [
    { date: maxP2PDateBeforeCycle, amount: minP2PAmtBeforeCycle },
    { date: maxP2PDateAfterCycle, amount: minP2PAmtAfterCycle },
  ].filter(({ amount }) => !!amount);
  // Edge condition: dates are the same.
  if (
    requirements.length === 2
    && requirements[0].date === requirements[1].date
  ) {
    // Take the higher amount (sort descending, pop the end off)
    requirements
      .sort((a, b) => (b.amount - a.amount))
      .pop();
  }
  const [earlyRequirement, lateRequirement] = requirements;
  const discoDate = dateformat(parseDate(softDisconnectedDate), 'mmmm d, yyyy', true);
  const p2pDue = dateformat(parseDate(earlyRequirement.date), 'mmmm d, yyyy', true);
  const balance = formatCurrency(earlyRequirement.amount);
  const [
    trackId,
    headline,
    maintainYourService,
    access,
  ] = softDisconnected
    ? [
      'p2p-soft-disconnect',
      'Your bill is past due and your service has been disconnected',
      'Reactivate your service',
      'reactivate',
    ]
    : [
      'p2p-delinquent',
      `Your bill is past due and your service will be disconnected on ${discoDate}`,
      'Keep your service on',
      'keep access to',
    ];
  let description = `
    Please pay at least ${balance} today.
    If you need more time, you can set up a payment arrangement. \xa0`;
  let titleTip = `
    You'll need to schedule a payment totaling
    ${balance} today that will process on or
    before ${p2pDue}.
  `;
  if (lateRequirement) {
    const fullPayment = formatCurrency(lateRequirement.amount);
    const nextDay = addDays(earlyRequirement.date, 1);
    const maxDay = createDateObject(lateRequirement.date);
    const fullDate = nextDay.getMonth() === maxDay.getMonth()
      ? `${dateformat(nextDay, 'mmmm d')}-${maxDay.getDate()}`
      : `${dateformat(nextDay, 'mmmm d')} - ${dateformat(maxDay, 'mmmm d')}`;
    description = `
      ${maintainYourService} with a payment arrangement.
      Pay ${balance} by ${p2pDue}, or schedule a
      payment for ${fullPayment} and extend your
      due date to ${fullDate} \xa0.
    `;
    titleTip = `
      You'll need to schedule a payment for at
      least ${balance} that will process by ${p2pDue},
      or schedule a payment for at least ${fullPayment}
      that will process between ${fullDate}.
    `;
  }
  return {
    id: PAST_DUE,
    trackId,
    svgIcon: BillOutline,
    color: 'alert',
    dismissible: false,
    trackName: 'P2P Bill Pay',
    name: (
      <>
        {headline}
        <TooltipButton>
          {`
            A payment arrangement is a convenient
            way to ${access} your Xfinity service if
            you're unable to pay your bill right now.
          `}
          {titleTip}
        </TooltipButton>
      </>
    ),
    description,
  };
};

export const showExistingPromiseNba = (
  {
    summary: {
      pastDueBalanceRemaining,
    } = {},
    promiseToPay: {
      existingPromiseToPayInfo,
    } = {},
  },
  scheduledPayments = [],
) => {
  if (
    pastDueBalanceRemaining <= 0
    || !existingPromiseToPayInfo
  ) {
    return false;
  }
  const {
    brokenReasons,
    promiseToPayAmount,
    promiseToPayDate,
  } = existingPromiseToPayInfo;
  if (
    (brokenReasons || []).length !== 0
    || !promiseToPayAmount
    || !(promiseToPayDate && compareDays(promiseToPayDate, today(), (a, b) => a >= b))
  ) {
    return false;
  }
  // User has not scheduled a payment that matches their promise to pay.
  return hasQualifyingScheduledPayments(existingPromiseToPayInfo, scheduledPayments);
};

export const getExistingPromiseData = (
  {
    summary: {
      softDisconnected,
      pastDueBalanceRemaining,
    },
    promiseToPay: {
      existingPromiseToPayInfo: {
        promiseToPayAmount,
        promiseToPayDate,
      } = {},
      customerEligibilityChecked: {
        minP2PAmtBeforeCycle,
      } = {},
    } = {},
  },
) => {
  const p2pDate = dateformat(parseDate(promiseToPayDate), 'mmm d', true);
  const p2pAmt = formatCurrency(promiseToPayAmount);
  const balance = (minP2PAmtBeforeCycle === 0 || minP2PAmtBeforeCycle === null)
    ? formatCurrency(pastDueBalanceRemaining)
    : formatCurrency(minP2PAmtBeforeCycle);

  const nba = {
    id: PAST_DUE,
    svgIcon: BillOutline,
    dismissible: false,
    trackName: 'P2P Bill Pay',
    link: xaLinkWithLogin('skill.billing.p2p'),
    linkText: 'Chat to manage',
    linkTarget: '_blank',
    linkOnClick: openInNewWindow,
  };

  if (softDisconnected) {
    const [headline,
      access] = ['Your bill is past due and your service has been disconnected',
      'reactivate'];
    return {
      ...nba,
      trackId: 'p2p-promised-soft-disconnect',
      name: (
        <>
          {headline}
          <TooltipButton>
            {`
              A payment arrangement is a convenient
              way to ${access} your Xfinity service if
              you're unable to pay your bill right now.
            `}
          </TooltipButton>
        </>
      ),
      color: 'alert',
      description: `
      Please pay at least ${balance} today.
      If you need more time, you can set up a payment arrangement. \xa0`,
    };
  }
  return {
    ...nba,
    trackId: 'p2p-promised-delinquent',
    name: `You've arranged to pay by ${p2pDate}`,
    color: 'minty',
    description: `
      Pay your full past balance of ${p2pAmt} by ${p2pDate} to avoid possible
      service suspension. \xa0
    `,
  };
};

const getNonP2PData = (account = {}, bill = {}, showLink, macaroon = {}) => {
  const baseNba = {
    id: PAST_DUE,
    trackId: 'delinquent',
    svgIcon: BillOutline,
    color: 'alert',
    name: 'Your payment is late.',
    link: showLink && NEW_PAYMENT,
    linkText: showLink && 'Make a payment',
    dismissible: false,
  };

  const {
    summary: {
      pastDueBalanceRemaining,
      softDisconnected,
      softDisconnectedDate,
    } = {},
  } = bill;

  const { walledgarden, lite } = macaroon;

  const amount = formatCurrency(pastDueBalanceRemaining);
  const isDelinquent = bill.delinquency && bill.delinquency.delinquencyStatus === 'DELINQUENT';
  const isDisconnected = getIsDisconnected(account.status);
  const isSoftDisconnected = softDisconnected;
  const hasBalanceDue = pastDueBalanceRemaining > 0;
  const softDiscoWarningCheck = softDisconnectedDate
  && !softDisconnected && countDays(Date.now(), new Date(softDisconnectedDate)) <= 14;

  // softdisconnect date 14 or less
  if (softDiscoWarningCheck
    && hasBalanceDue) {
    return {
      ...baseNba,
      name: 'Your services may be suspended soon.',
      description: `Make a minimum payment of ${amount} to avoid your services being suspended  on ${dateformat(softDisconnectedDate, 'mmm d')}.`,
    };
  }

  // Late but not delinquent
  if (!isDelinquent
    && !isSoftDisconnected
    && hasBalanceDue
    && !softDiscoWarningCheck

  ) {
    return {
      ...baseNba,
      trackId: 'late',
      description: `Please pay at least ${amount} to avoid late fees.`,
    };
  }

  // Account is delinquent but not disconnected
  if (!isDisconnected
    && isDelinquent
    && !isSoftDisconnected
    && hasBalanceDue
  ) {
    if (flagEnabled('global.xapSoftDisco')) {
      return {
        ...baseNba,
        name: 'Your payment is late.',
        description: `Please pay at least ${amount} now.`,
      };
    }
    return {
      ...baseNba,
      name: 'Your bill is past due and your services may be disconnected soon.',
      description: `Please pay at least ${amount} to avoid service suspension.`,
    };
  }

  // Account is disconnected
  if (isDelinquent
    && isSoftDisconnected
    && hasBalanceDue
  ) {
    if (walledgarden || lite) {
      return {
        ...baseNba,
        name: 'Your bill is past due and service has been suspended',
        description: `Please pay at least ${amount} to restore services.`,
      };
    }
    return {
      ...baseNba,
      name: 'Your payment is late.',
      description: `Please pay at least ${amount} to restore services.`,
    };
  }

  return false;
};

const getNbaData = (account, bill, billStateCheck, showLink, macaroon, scheduledPayments) => {
  if (showExistingPromiseNba(bill, scheduledPayments)) {
    return getExistingPromiseData(bill);
  }
  const p2pMessaging = flagEnabled('p2pMessaging');
  const isXapExit = getIsXapExit(account);
  const isXapExitInProgress = getIsXapExitInProgress(account);
  const isXapCurrent = getIsXapCurrent(account);

  const { lite } = macaroon;
  const { summary: { softDisconnected } = {}, delinquency } = bill;
  const isDelinquent = delinquency && delinquency.delinquencyStatus === 'DELINQUENT';

  if ((!bill
    || isXapCurrent
    || isXapExit
    || isXapExitInProgress)
    && !(softDisconnected || isDelinquent)) {
    return false;
  }
  if (p2pMessaging && getMeetsP2PPrerequisites({ bill }) && !lite) {
    return getP2PData(bill, scheduledPayments);
  }
  if (!p2pMessaging && getMeetsLegacyP2PPrerequisites(billStateCheck && !lite)) {
    return getLegacyP2PData(bill);
  }
  return getNonP2PData(account, bill, showLink, macaroon);
};

const P2PNba = ({
  handleGetBill,
  handleGetAccount,
  handleFetchScheduledPayments,
  needScheduledPayments,
  dismissNba,
  loading,
  nbaData,
  NBAComponent = NextBestAction,
  setLoaded,
  handleSendChannelTracking,
  bill,
}) => {
  const [channelTrackingSent, sendChannelTrackingSent] = useState(false);
  useEffect(() => {
    handleGetBill();
    handleGetAccount();
  }, [handleGetBill, handleGetAccount]);
  useEffect(() => {
    if (needScheduledPayments) {
      handleFetchScheduledPayments();
    }
  }, [needScheduledPayments, handleFetchScheduledPayments]);
  useEffect(() => {
    if (loading) return;

    if (setLoaded) {
      if (!loading && !channelTrackingSent && bill && bill.promiseToPay
        && bill.promiseToPay.customerEligibilityChecked
        && bill.promiseToPay.customerEligibilityChecked.eligibleForPromiseToPay) {
        handleSendChannelTracking({ id: 'P2P_INITIATED', interactionType: 'BILLING_PAYMENTS' });
        sendChannelTrackingSent(true);
      }
      if (!nbaData) {
        setLoaded(PAST_DUE, 'FAILED');
        return;
      }

      setLoaded(PAST_DUE, 'READY');
    }
  }, [loading, nbaData, setLoaded, channelTrackingSent,
    handleSendChannelTracking, bill]);

  if (loading || !nbaData) return null;

  return <NBAComponent nba={nbaData} dismissNba={dismissNba} />;
};

const mapStateToProps = ({
  bill: {
    bill = {},
    billStateCheck,
    loading: billLoading,
    bill: {
      promiseToPay: {
        existingPromiseToPayInfo,
      } = {},
    } = {},
  },
  scheduledPayments: {
    loading: scheduledPaymentsLoading,
    payments: scheduledPayments,
  },
  account: {
    account,
    loading: accountLoading,
  },
  auth: {
    macaroon,
  },
}, { options = {} }) => {
  let loading = billLoading || accountLoading;
  let needScheduledPayments = !!existingPromiseToPayInfo;
  // Don't load scheduled payments if we don't need them.
  if (existingPromiseToPayInfo) {
    const { brokenReasons } = existingPromiseToPayInfo || {};
    if (!brokenReasons) {
      loading = loading || scheduledPaymentsLoading;
      needScheduledPayments = true;
    }
  }

  return {
    bill,
    account,
    loading,
    needScheduledPayments,
    nbaData: !loading && getNbaData(
      account,
      bill,
      billStateCheck,
      options.showLink,
      macaroon,
      scheduledPayments,
    ),
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  handleGetBill: getBill,
  handleGetAccount: getAccount,
  handleFetchScheduledPayments: fetchScheduledPayments,
  handleSendChannelTracking: sendChannelTracking,
}, dispatch);

export const PastDueNbaComp = P2PNba;

export default connect(mapStateToProps, mapDispatchToProps)(P2PNba);
