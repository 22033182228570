/**
 * @typedef {Object} AdobeComponent
 * @property {string} [placementName=undefined] The name of the place this component was seen
 * @property {string} componentName The name of the component
 */

/**
 * If a string, is a representation of an AdobeComponent in the form
 *  `${placementName}>${componentName}` or just `${componentName}`.
 * Null or undefined are ignored.
 * @typedef {String|AdobeComponent|null|undefined} BasicImpression
 */

/**
 * One or more impressions.
 * @typedef {BasicImpression|Array<BasicImpression>} Impressions
 */

/**
 * If a Promise, resolves to one or more impressions.
 *  Rejection is handled, but not the preferred.
 * @typedef {Impressions|Promise<Impressions, Error>} Impression
 */

/**
 * A cache for holding onto Adobe impression components to allow the page load event to
 *  wait on them.
 * @typedef {Object} ImpressionCache
 * @property {ImpressionCache~set} set Set a unique impression by ID
 * @property {ImpressionCache~add} add Add an impression
 * @property {ImpressionCache~flush} flush Flush impressions, waiting for any promised
 *  impressions.
 */

/**
 * Check if something is a Promise or Promise-like object.
 * @private
 */
const isPromise = o => (
  typeof o === 'object'
  && o.then instanceof Function
  && o.catch instanceof Function
);

/**
 * Flattens a component list
 * @private
 */
const flattenList = (comps) => {
  // The final array of component objects
  const result = [];
  const mapper = (comp) => {
    // Array of components; recurse to flatten
    if (Array.isArray(comp)) {
      comp.forEach(mapper);
      return;
    }
    // otherwise, just add it
    result.push(comp);
  };
  comps.forEach(mapper);
  return result;
};

/**
 * Normalizes `l3` component strings to AdobeComponent-style objects.
 * @private
 */
const normalizeComponents = comps => comps.map((comp) => {
  if (typeof comp === 'string') {
    // No placementName
    if (comp.indexOf('>') === -1) {
      return { componentName: comp };
    }
    // placementName>componentName
    const [placementName, componentName] = comp.split('>');
    return { placementName, componentName };
  }
  // AdobeComponent
  if (comp && typeof comp === 'object' && comp.componentName) return comp;
  // Anything else.
  return null;
});

/**
 * Filter empty components from a list.
 * @private
 */
const filterEmpties = comps => comps.filter(comp => !!comp && !!comp.componentName);

/**
 * ImpressionCache factory (intentionally not ES6, to support destructors)
 * @param {object} options
 * @param {Function} options.promiseAll Injection point for Promise.all, to support arbitrary
 *  Promise implementations (e.g., $q vs native Promise).
 * @returns {ImpressionCache}
 */
const ImpressionCache = ({ Promise }) => {
  const list = [];
  const named = {};

  /**
   * Checks if a component is a promise.  If it is, catch and report potential failures,
   *  and return null to skip the impression.
   * @private
   */
  const catchAndReportRejections = (comp) => {
    if (isPromise(comp)) {
      return comp.catch((e) => {
        // Report the error because rejection is not the intended flow.
        // eslint-disable-next-line no-console
        console.warn('[Adobe] Impression failed because of rejected promise', e);
        return null;
      });
    }
    return Promise.resolve(comp);
  };

  /**
   * await the instantaneous set of components
   * @private
   */
  const awaitSome = (more = []) => (
    Promise.all(
      // Flush the `set` components object to a list
      Object.keys(named).map((id) => {
        const comp = named[id];
        // Remove the named component
        delete named[id];
        return comp;
      })
        // Flush the `add`ed components
        .concat(list.splice(0, list.length))
        // Add in components passed from the consuming code
        .concat(more)
        // Catch any promises' rejections as non-impressions to ensure `all` doesn't hang
        .map(catchAndReportRejections),
    )
      .then(flattenList)
      .then(normalizeComponents)
      .then(filterEmpties)
  );

  /**
   * Check for more components
   * @private
   */
  const checkForMore = (comps) => {
    // `add` or `set` has been called while we were waiting on other components
    if (list.length || Object.keys(named).length) {
      return awaitSome().then(added => comps.concat(added)).then(checkForMore);
    }
    return comps;
  };

  /**
   * Set a named component for the page load event.
   * @function ImpressionCache~set
   * @param {String} id unique identifier for the component's slot
   * @param {Impression} component The adobe component(s) or promise resolving to adobe
   *  component(s)
   * @returns undefined
   */
  const set = (id, component) => {
    named[id] = component;
  };

  /**
   * Add an impression component to the page load event
   * @function ImpressionCache~add
   * @param {Impression} component The component to add.
   * @returns undefined
   */
  const add = (component) => {
    list.push(component);
  };

  /**
   * Flush the cache to a promise resolving to a flattened list of AdobeComponents.  Empty promise
   *  resolutions and rejections are removed from the list; rejections show as warnings on the
   *  console.
   * @function ImpressionCache~flush
   * @param {Array<Impression>} more additional impressions (usually passed directly into the
   *   pageLoadEvent as { component })
   * @returns {Array<AdobeComponent>}
   */
  const flush = more => awaitSome(more).then(checkForMore);

  /**
   * @type ImpressionCache
   */
  return { set, add, flush };
};

export default ImpressionCache({ Promise });
