import prod from './prod';

const homeDomain = 'customer.pre-prod.xfinity.com';
const paymentDomain = 'payments.preprod.xfinity.com';

// This config should match prod as closely as possible, with only URL changes to meet
// consistency needs for links back to FE.
const CONFIG = {
  ...prod,
  homeDomain,
  billServicesUrl: `https://${homeDomain}/billing/services`,
  billingUrl: `https://${homeDomain}/#/billing`,
  makePaymentUrl: `https://${paymentDomain}/new`,
  paymentSetup: {
    ...prod.paymentSetup,
    // Where to go if no continueUrl specified (from consent flow)
    defaultContinue: `https://${homeDomain}/`,
  },
  featureFlagEndpoint: 'https://sdx.qa.xfinity.com/cms/data/myAccount/web/preprod/featureFlagsPreProd.json',
  oauthUrl: 'https://oauth.xfinity.com',
  cpcEnvironment: 'preproduction',
  cpcUrl: 'https://common-payment.preprod.xfinity.com/',
};

export default CONFIG;
