import React from 'react';

const Calendar = ({ className = '', ...props }) => (
  <span className={`svg-icon ${className}`} {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
      <path d="M32.5 34.531h-25a2 2 0 0 1-2-2v-17h29v17a2 2 0 0 1-2 2z" fill="#2e99d4" stroke="#2e99d4" strokeMiterlimit="10" />
      <path d="M34.5 15.5h-29v-5a2 2 0 0 1 2-2h25a2 2 0 0 1 2 2v5z" fill="#196992" stroke="#196992" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" />
      <path strokeWidth="2" d="M12 6v6m15.997-6v6" fill="#2e99d4" stroke="#2e99d4" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" />
      <path d="M24.084 23.917h5.917v3.164h-5.917zM23.959 28h5.917v3.164h-5.917zm-6.918-4.083h5.917v3.164h-5.917zM17.045 28h5.917v3.164h-5.917zm-7.066-4.083h5.917v3.164H9.979zm14.115-4.081h5.917V23h-5.917zm-7.043 0h5.917V23h-5.917zm-7.063 0h5.917V23H9.988zM10.135 28h5.917v3.164h-5.917z" fill="#ffffff" />
    </svg>
  </span>
);

export default Calendar;
