// This file holds all possible default configuration
const homeDomain = 'customer.xfinity.com';
const paymentDomain = 'payments.xfinity.com';

const paymentPlanRoute = '/xfinity-assistance-plan';

const CONFIG = {
  paymentPlanRoute, // avoid circle dep
  // Payment setup page
  paymentSetup: {
    // Where to go if no continueUrl specified (from consent flow)
    defaultContinue: `https://${homeDomain}/`,
    // Affiliate IDs that are valid for payment setup flow
    validAffiliates: ['ACSR'],
    // Delay before redirection (for QA only; should always be 0 in production)
    redirectDelay: 0,
  },
  // The name of the cookie that the linked account index is stored on.
  accountSwitcherCookieName: 'maw_accountIndex',
  // TODO: remove the legacy cookie during cleanup after account selection rollout
  legacyAccountSwitcherCookieName: 'maw_cspTokenIndex',
  accountSwitcherCookieDomain: '.xfinity.com',
  accountPopupCookie: 'maw_accountPopupCookie',
  homeDomain,
  billServicesUrl: `https://${homeDomain}/billing/services`,
  makePaymentUrl: `https://${paymentDomain}/new`,
  billingUrl: `https://${homeDomain}/#/billing`,
  paymentPlanUrl: `https://${paymentDomain}${paymentPlanRoute}`,
  cwaRoot: 'https://static.cimcontent.net/common-web-assets/',
  featureFlagEndpoint: 'https://sdx.xfinity.com/cms/data/myAccount/web/featureFlags.json',
  shelbyCMSUrl: 'https://sdx.xfinity.com/cms/data/',
  maxOptionsPaymentPlan: 1, // todo move to CMS
  xaUrl: 'https://www.xfinity.com/xfinityassistant',
  oauthUrl: 'https://oauth.xfinity.com',
  collectionsBack: 'https://www.xfinity.com/buy/plan/xap',
  cpcEnvironment: 'preproduction',
  cpcUrl: 'https://common-payment.preprod.xfinity.com/',
};

export default CONFIG;
