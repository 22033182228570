import apiClient from '../middleware/apiClient';
import { INTERNET_TIER } from '../helpers/apis';

export const GET_INTERNET_TIER = 'GET_INTERNET_TIER';
export const GET_INTERNET_TIER_SUCCESS = 'GET_INTERNET_TIER_SUCCESS';
export const GET_INTERNET_TIER_FAILURE = 'GET_INTERNET_TIER_FAILURE';

function dispatchGetInternetTier() {
  return {
    type: GET_INTERNET_TIER,
    payload: apiClient.fetch(INTERNET_TIER),
  };
}

function dispatchGetInternetTierSuccess(tierData) {
  return {
    type: GET_INTERNET_TIER_SUCCESS,
    payload: tierData,
  };
}

function dispatchGetInternetTierFailure(error) {
  return {
    type: GET_INTERNET_TIER_FAILURE,
    payload: error,
  };
}

export const getInternetTier = () => async (dispatch, getState) => {
  if (getState().internetTier.cached) {
    return null;
  }

  try {
    const response = await dispatch(dispatchGetInternetTier()).payload;
    return dispatch(dispatchGetInternetTierSuccess(response));
  } catch (error) {
    dispatch(dispatchGetInternetTierFailure(error));
    throw error;
  }
};
