import { useCallback, useEffect, useState } from 'react';
// import { logger } from '../helpers/logger';

// const c = logger('useTransform').enable(false);

/**
 * Transform a value to a new state variable.
 * @param {() => T} transformer Function used to transform a value
 * @param {any[]} deps Dependencies for the transform
 * @returns {T} The transformed value
 */
export default function useTransform(transformer, deps) {
  const cachedTransformer = useCallback(transformer, [...deps, transformer]);
  const [value, setValue] = useState(() => {
    const newValue = cachedTransformer();
    // c.log('Initial', newValue);
    return newValue;
  });
  useEffect(() => {
    const newValue = cachedTransformer();
    if (JSON.stringify(newValue) !== JSON.stringify(value)) {
      // c.log('Updated', newValue);
      setValue(newValue);
    }
    // } else {
    //   c.log('Transform changed, but stringvalue is the same; skipping re-render', newValue);
    // }
  }, [cachedTransformer, value]);
  return value;
}
