import React from 'react';
import DayPickerInput from './DayPickerInput';

import { joinClass } from '../../helpers/component';
import 'react-day-picker/lib/style.css';

// use date input for mobile and datepicker for tablet+
const isMobile = !(window.matchMedia('(min-width: 768px)').matches);
const nativeBroken = /i(Pad|Phone);[^)]+OS 1[56]/.test(navigator.userAgent);

const DatePickerField = ({
  value,
  field = {},
  className = '',
  forceError,
  id,
  form: {
    submitCount,
    errors = {},
    values = {},
    touched = {},
  },
  formatDate,
  dayPickerProps,
  ...props
}) => {
  const useNative = isMobile && !nativeBroken;
  const isFormSubmitted = !!submitCount;
  const hasAttemptedInput = !!values[field.name] && touched[field.name];
  const shouldShowError = (isFormSubmitted || hasAttemptedInput) && errors[field.name];
  const shouldShowErrorHighlight = shouldShowError || forceError;
  const errorMessageId = `error_${id}`;
  return (
    <>
      {!useNative && (
        <DayPickerInput
          aria-invalid={!!shouldShowError}
          aria-describedby={errorMessageId}
          inputProps={{
            ...field,
            ...props,
            formatDate,
            id,
            type: 'text',
            className: joinClass(className, shouldShowErrorHighlight && 'error'),
            // Prevent breakage on invalid dates.
            onChange: (event) => {
              if (!Number.isNaN(Date.parse(event.target.value))) {
                return field.onChange(event);
              }
              return false;
            },
          }}
          {...props}
          value={value}
          formatDate={formatDate}
          dayPickerProps={dayPickerProps}
        />
      )}
      {useNative && (
        <input
          className={joinClass(className, shouldShowErrorHighlight && 'error')}
          id={id}
          aria-invalid={!!shouldShowError}
          aria-describedby={errorMessageId}
          type="date"
          {...field}
          {...props}
          value={(new Date(value).toJSON() || '').substring(0, 10)}
          onChange={(event) => {
            if (!Number.isNaN(Date.parse(event.target.value))) {
              if (props.onDayChange) {
                return props.onDayChange(new Date(`${event.target.value}T12:00:00.000Z`));
              }
              return field.onChange(event);
            }
            return false;
          }}
        />
      )}
      {shouldShowError && <span id={errorMessageId} className="form-control__error">{errors[field.name]}</span>}
    </>
  );
};

export default DatePickerField;
