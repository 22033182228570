import React from 'react';
import { IconCheckGreen } from 'adc-ui-components';
import PageTitle from '../../components/PageTitle';

import getConfig from '../../config';

const { homeDomain } = getConfig();

const Confirmation = ({ location: { state } }) => {
  const isRemove = state?.editAction === 'removed';

  return (
    <>
      <div className="hgroup mb24">
        <IconCheckGreen className="mb6 svg-icon--42" />
        <PageTitle className="mb6">
          {`Your payment method has been ${state?.editAction || 'added to your wallet'}`}
        </PageTitle>
        {!isRemove && (
          <p className="body2">
            You can go to Plans and see details to make this your default
            payment method and turn on autopay to resume your service if it&apos;s
            paused.
          </p>
        )}
      </div>

      <div className="action action--responsive">
        <div className="action__item">
          <a href={`https://${homeDomain}/now/billing/wallet`} className="button button--primary">
            Back to Wallet
          </a>
        </div>
        <div className="action__item">
          <a href={`https://${homeDomain}/now/details`} className="button">
            Go to plans
          </a>
        </div>
      </div>
    </>
  );
};

export default Confirmation;
