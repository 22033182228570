import { getIsXapExit } from '../../../helpers/xap';
import { getIsToday } from '../../../helpers/date';

const ConfirmTitle = ({
  confirm: { autopay, date, amount },
  autopay: { autopaySignupSuccess },
  account,
  bill: {
    summary: {
      balanceDue,
    } = {},
  } = {},
}) => {
  const isXapExit = getIsXapExit(account);
  const scheduled = !getIsToday(date);
  const futureBalance = scheduled
    ? Math.max(0, balanceDue - amount)
    : balanceDue;

  if (isXapExit) {
    if (scheduled || (futureBalance > 0)) {
      return 'Your payment was successful';
    }
    return 'Your original service will be restored within 1 hour';
  }
  if (autopay && autopaySignupSuccess) {
    return 'Your transaction was completed successfully';
  }
  return 'Your payment was successful!';
};

export default ConfirmTitle;
