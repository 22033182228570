export const SKIP_PASSIVE_AUTH_SESSIONS = ['LITE', 'CONSENT'];
export const ALLOWED_AUTH_SESSIONS = ['LITE', 'CONSENT', 'LOGIN FULL'];
export const forceLoginLink = (url) => {
  const redirect = `${window.origin}/oauth/callback`;
  const oauthRoot = new URL('https://oauth.xfinity.com/oauth/authorize');
  oauthRoot.searchParams.set('client_id', 'my-account-web');
  oauthRoot.searchParams.set('response_type', 'code');
  oauthRoot.searchParams.set('prompt', 'login');
  oauthRoot.searchParams.set('redirect_uri', redirect);
  oauthRoot.searchParams.set('state', url);
  return oauthRoot.toString();
};
