import useUid from './useUid';
// import { logger } from '../helpers/logger';

// const c = logger('useInjectedStylesheet').enable(false);

/**
 * Inject a CSS link into a given target.  Useful for decorating shadow DOM.
 * @param {DOMNode} target Where to inject the referenced CSS
 * @param {string} url The URL of the injected CSS
 */
export default function useInjectedStylesheet(target, url) {
  const id = useUid();
  if (target && url) {
    let link = target.getElementById(id);
    const haveLink = !!link;
    if (!haveLink) {
      // c.log(`No sheet for ${url} in target; injecting`);
      link = target.ownerDocument.createElement('link');
      link.id = id;
      link.rel = 'stylesheet';
      link.type = 'text/css';
    }
    if (link.href !== url) {
      link.href = url;
    }
    if (!haveLink) {
      target.appendChild(link);
    }
  }
}
