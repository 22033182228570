export const shouldShowLateFeeCredit = (bill, latefeecredit) => (
  latefeecredit
  && latefeecredit.lateFeeCreditStatus !== 'INELIGIBLE'
  && bill && bill.summary && !(
    bill.summary.autoPayEnabled
    && bill.summary.autoPayValid
  )
);
export const shouldShowLateFeeConfirmation = latefeecredit => (
  !!latefeecredit
  && (
    /* User has previously enrolled, but changed autopay */
    latefeecredit.lateFeeCreditStatus === 'ENROLLED'
    || (
      /* User is eligible and has _just_ set up autopay */
      latefeecredit.lateFeeCreditStatus === 'ELIGIBLE'
      && latefeecredit.status
    )
  )
);
