// https://developer.apple.com/reference/applepayjs/paymentrequest
export const getApplePaymentRequest = amount => ({
  currencyCode: 'USD',
  countryCode: 'US',
  merchantCapabilities: ['supports3DS'],
  supportedNetworks: ['amex', 'masterCard', 'visa'],
  total: {
    label: 'Comcast',
    amount: amount || 1.00,
  },
  requiredBillingContactFields: ['name', 'postalAddress', 'phone'],
});

export default getApplePaymentRequest;
