import React from 'react';

import { joinClass } from '../helpers/component';

const Card = ({
  className,
  children,
  action,
  actionClass,
  media,
  mediaClass,
  actionRight,
  severity,
  ...props
}) => (
  <div
    className={joinClass(
      'card',
      severity && `card--${severity}`,
      actionRight && (
        actionRight === 'desktop' ? 'card--action-right-at-768' : 'card--action-right'
      ),
      className,
    )}
    {...props}
  >
    {media && (
      <div className={joinClass('card__media', mediaClass)}>
        {media}
      </div>
    )}
    <div className="card__content">
      {children}
    </div>
    {action && (
      <div className={joinClass('card__action', actionClass)}>
        {action}
      </div>
    )}
  </div>
);

export default Card;
