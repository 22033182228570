import React from 'react';
import { formatCurrency } from '../../../helpers/formatText';
import { getIsToday } from '../../../helpers/date';
import { getIsXapExit, getIsXapCurrent, getIsXapExitInProgress } from '../../../helpers/xap';

const ConfirmSubtitle = ({
  confirm: {
    date,
    amount,
  },
  bill: {
    summary: {
      balanceDue,
      softDisconnected,
    } = {},
  } = {},
  account,
  showAvoidLateFees,
}) => {
  const scheduled = !getIsToday(date);
  const isXapExit = getIsXapExit(account);
  const isXapCurrent = getIsXapCurrent(account);
  const isXapExitInProgress = getIsXapExitInProgress(account);
  const showAvoidLateFeeMessage = (
    !isXapCurrent
    && !isXapExit
    && !isXapExitInProgress
    && showAvoidLateFees
  );
  const futureBalance = scheduled
    ? Math.max(0, balanceDue - amount)
    : balanceDue;
  const renderFutureBalance = () => (
    <>
      <b>{`Your balance is now ${formatCurrency(balanceDue)}`}</b>
      <br />
      {isXapExit
        && <span>Please make an additional payment.</span>}
      {showAvoidLateFeeMessage
        && <span>Please make an additional payment to avoid late fees.</span>}
    </>
  );

  if (isXapExit) {
    if (futureBalance > 0) {
      return renderFutureBalance();
    }
    return 'Your payment is all set and your next bill will reflect your original charges.';
  }

  if (futureBalance > 0) {
    return renderFutureBalance();
  }

  if (!softDisconnected) {
    return null;
  }
  return `Your Internet service will be restored within 5 minutes of payment. TV and voice service
          will be restored within 1 hour of payment.`;
};

export default ConfirmSubtitle;
