export const go = count => new Promise((resolve, reject) => {
  const location = window.location.toString();
  const handler = () => {
    window.removeEventListener('popstate', handler);
    resolve(location !== window.location.toString());
  };
  window.addEventListener('popstate', handler);
  window.history.go(count);
});

export const goBack = () => go(-1);
export const goForward = () => go(1);
