import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';
import { FormBoolean } from 'adc-ui-components';

import PageTitle from '../../components/PageTitle';
import Card from '../../components/Card';
import ErrorMessage from '../../components/ErrorMessage';
import BooleanField from '../../components/form-elements/BooleanField';
import NextBestActions from '../../components/nbas';

import { joinClass } from '../../helpers/component';
import { enableAutopay } from '../../actions/autopay';
import { formatBillingAddress } from '../../helpers/address';
import { setSubmit, setSuccess, setError } from '../../actions/userMessages';
import { scrollToInvalid, getErrorFromResponse } from '../../helpers/errors';
import { getAutopaySuccessMessage } from '../../helpers/userMessages';
import { isGlobalNavForClassComp } from '../../helpers/polaris';

import { RequiredFactory } from '../../helpers/validation/required';
import {
  AUTOPAY,
  AUTOPAY_NEW_BANK,
  AUTOPAY_NEW_CARD,
  METHODS,
  AUTOPAY_EDIT,
  TERMS_STORED_PAYMENT,
  TERMS_AUTOPAY,
} from '../../helpers/routes';

import PaymentMethods from '../../components/form-fields/PaymentMethods';

import { isNewMethod, isAutoPayInstrument } from '../../helpers/instruments';

import getConfig from '../../config';
import { flagEnabled } from '../../helpers/featureFlags';

const { billServicesUrl } = getConfig();

const vRequired = RequiredFactory();

const validatePaymentMethodOption = (values) => {
  if (!values.paymentMethodOption) {
    return {
      formError: 'Please select a payment option',
    };
  }

  return {};
};

class AutopayForm extends Component {
  constructor() {
    super();
    this.state = {
      isGlobalNav: false,
    };
    this.onAutopayFormSubmit = this.onAutopayFormSubmit.bind(this);
  }

  async componentDidMount() {
    const isGlobalNav = await isGlobalNavForClassComp();
    this.setState({ isGlobalNav });
  }

  onAutopayFormSubmit(values, { setSubmitting, setErrors }) {
    const {
      bill,
      handleEnableAutopay,
      account,
      history,
      handleSetSubmit,
      handleSetSuccess,
      handleSetError,
      latefeecredit,
    } = this.props;

    handleSetSubmit();

    const isUpdate = bill.summary.autoPayEnabled;

    const {
      autopayContract,
      paymentMethodOption,
    } = values;

    if (flagEnabled('stepUpEnabled')) {
      sessionStorage.setItem('maw_autopayTermsChecked', `${autopayContract}`);
    }

    if (paymentMethodOption === 'Bank') {
      return history.push(AUTOPAY_NEW_BANK);
    }

    if (paymentMethodOption === 'PaymentCard') {
      return history.push(AUTOPAY_NEW_CARD);
    }

    const token = paymentMethodOption;

    return handleEnableAutopay({
      paymentMethodOption,
      isUpdate,
      token,
      billingAddress: formatBillingAddress(account, values),
    }).then(() => {
      const {
        enrollSuccess,
      } = this.props;

      history.push(AUTOPAY);
      handleSetSuccess(getAutopaySuccessMessage(
        isUpdate,
        enrollSuccess && latefeecredit.lateFee,
        bill.summary,
        latefeecredit.lateFeeCreditStatus === 'ELIGIBLE',
      ));
    }).catch((err = {}) => {
      const error = getErrorFromResponse(err);
      setSubmitting(false);

      const submitError = {};

      if (error.isMapped) {
        if (error.fields) {
          error.fields.forEach((name) => {
            // Allows for the error map to contain grouped errors with only
            // one field reporting the error for the group (e.g., ['expirationMonth',
            // '+expirationYear', '+cvv'])
            if (name[0] === '+') {
              submitError[name.substring(1)] = ' ';
            } else {
              submitError[name] = error.message;
            }
          });
        }
      }

      scrollToInvalid(submitError);

      if (Object.keys(submitError).length) {
        setErrors(submitError);
        throw error;
      }

      if (error.message) {
        // persist the unmapped error in the state
        // then clear them on resubmit
        handleSetError({ text: error.message });
        throw error;
      }

      handleSetError({ showModal: true });
      throw error;
    });
  }

  render() {
    const {
      bill = {},
      instruments,
      autopay,
      applePay,
      errorMessage,
      initialValues,
      onTermsChecked,
      nextBestActions,
    } = this.props;

    const {
      isGlobalNav,
    } = this.state;

    const hasInstruments = instruments.instruments && instruments.instruments.length > 0;
    const isUpdate = bill.summary && bill.summary.autoPayEnabled;
    const heading = isUpdate
      ? 'Update your automatic payment method'
      : 'Choose your automatic payment method';
    const subheading = isUpdate
      ? 'Select a payment method below or add a new one.'
      : 'When automatic payments are turned on, your full balance will be automatically charged on your bill due date.';

    let buttonText = 'Set up';

    if (isUpdate) {
      buttonText = 'Update';
    }

    const cancelLink = isUpdate
      ? AUTOPAY
      : billServicesUrl;

    const updatedCancelLink = isGlobalNav
      ? billServicesUrl
      : cancelLink;

    return (
      <>
        {!!nextBestActions.length && <NextBestActions nbaList={nextBestActions} />}
        <Formik
          initialValues={initialValues}
          onSubmit={this.onAutopayFormSubmit}
          validate={validatePaymentMethodOption}
        >
          {({
            values,
            errors,
            submitCount,
            setFieldValue,
          }) => (
            <Form
              noValidate
            >
              <div className="hgroup hgroup--inline">
                <span>
                  <PageTitle>{heading}</PageTitle>
                  <p>{subheading}</p>
                </span>
                {hasInstruments && <Link className="hgroup--anchor" to={{ pathname: METHODS, state: { previousPage: AUTOPAY_EDIT } }}>Manage Payment Methods</Link>}
              </div>

              {errorMessage && <ErrorMessage errorMessage={errorMessage} />}

              <div className="payment-section">
                <PaymentMethods
                  autopay={autopay}
                  applePay={applePay}
                  instruments={instruments}
                  bill={bill}
                  values={values}
                  error={!!submitCount && errors.formError}
                />

                <Card>
                  <FormBoolean>
                    <Field
                      id="autopayContract"
                      name="autopayContract"
                      type="checkbox"
                      component={BooleanField}
                      forceError={!!submitCount}
                      onChange={(e) => {
                        setFieldValue('autopayContract', e.target.checked);
                        onTermsChecked(e.target.checked);
                      }}
                      suppressError
                      validate={vRequired((
                        isNewMethod(values.paymentMethodOption)
                          ? 'Please accept the Automatic Payments and the Stored Payment Methods Terms & Conditions'
                          : 'Please accept the Automatic Payments Terms & Conditions'
                      ))}
                    />
                    <label className="form-control__label" htmlFor="autopayContract">
                      <span>
                        {'I agree to the '}
                        {isNewMethod(values.paymentMethodOption) && (
                          <>
                            <a href={TERMS_AUTOPAY} target="_blank" rel="noopener noreferrer">Automatic Payments</a>
                            {' '}
                            and the
                            {' '}
                            <a href={TERMS_STORED_PAYMENT} target="_blank" rel="noopener noreferrer">Stored Payment Methods</a>
                            {' '}
                            Terms &amp; Conditions
                          </>
                        )}

                        {!isNewMethod(values.paymentMethodOption) && (
                          <a href={TERMS_AUTOPAY} target="_blank" rel="noopener noreferrer">Automatic Payments Terms &amp; Conditions</a>
                        )}
                      </span>
                    </label>
                    {!!submitCount && errors.autopayContract && (
                      <span className="form-control__error">{errors.autopayContract}</span>
                    )}
                  </FormBoolean>
                </Card>
              </div>

              <div className="action action--right">
                <div className="action__item">
                  <button
                    className={joinClass('button button--primary', autopay.submitting && 'is-loading')}
                    type="submit"
                    disabled={autopay.submitting}
                  >
                    {isNewMethod(values.paymentMethodOption) ? 'Continue' : buttonText}
                  </button>
                </div>
                <div className="action__item">
                  <a className="button" href={updatedCancelLink}>Cancel</a>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </>
    );
  }
}

const mapStateToProps = ({
  instruments: { instruments = { instruments: [] } },
  autopay,
  account: { account },
  bill: { bill = {} },
  applePay: { applePay },
  userMessages: {
    error: errorMessage,
    showModal,
  },
  latefeecredit: { latefeecredit, enrollSuccess },
}) => ({
  instruments,
  autopay,
  account,
  bill,
  applePay,
  enrollSuccess,
  latefeecredit,
  initialValues: {
    autopayContract: false,
    paymentMethodOption: (instruments.instruments.find(isAutoPayInstrument) || {}).token,
  },
  errorMessage: !showModal && errorMessage,
});


const mapDispatchToProps = dispatch => ({
  handleEnableAutopay: bindActionCreators(enableAutopay, dispatch),
  handleSetSubmit: bindActionCreators(setSubmit, dispatch),
  handleSetSuccess: bindActionCreators(setSuccess, dispatch),
  handleSetError: bindActionCreators(setError, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AutopayForm);
