import React, { Component } from 'react';
import { PageSection, IconCircleExclamation } from 'adc-ui-components';
import { kibanaLog } from '../../helpers/logger';

import PageTitle from '../../components/PageTitle';

class NoServices extends Component {
  componentDidMount() {
    const { productClass } = this.props;
    kibanaLog(`NO_${productClass}_SERVICES_error`);
  }

  render() {
    const { productClass } = this.props;
    const title = `You don't have ${productClass} services`;
    const article = productClass === 'Xfinity' ? 'an' : 'a';
    const body = `There isn't ${article} ${productClass} service associated with this Xfinity ID.`;
    const linkText = `Learn more about ${productClass}`;
    const linkUrl = productClass === 'Xfinity'
      ? 'http://www.xfinity.com/overview'
      : 'http://www.xfinity.com/now';

    return (
      <PageSection className="ui-grey page-section--banner">
        <IconCircleExclamation className="mb6 svg-icon--42" />

        <div className="hgroup">
          <PageTitle>{title}</PageTitle>
          <p className="body1">{body}</p>
        </div>

        <a className="button button--primary" href={linkUrl}>
          {linkText}
        </a>
      </PageSection>
    );
  }
}

export default NoServices;
