import {
  SET_APPLE_PAY,
  SET_APPLE_PAY_SUCCESS,
  SET_APPLE_PAY_FAILURE,
} from '../actions/applePay';

const INITIAL_STATE = { loading: true };

export default function applePayReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_APPLE_PAY:
      return {
        ...state,
        loading: true,
      };
    case SET_APPLE_PAY_SUCCESS:
      return {
        ...state,
        applePay: true,
        loading: false,
      };
    case SET_APPLE_PAY_FAILURE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
