import React, { Component, createRef } from 'react';
import { FormControl, FormBoolean } from 'adc-ui-components';
import PaymentAmountField from './PaymentAmountField';
import {
  normalizeDollarAmount,
  vDollarAmount,
  vMinimumPaymentAmount,
} from '../../helpers/validation/amount';
import { bindListeners, joinClass } from '../../helpers/component';
import { formatCurrency } from '../../helpers/formatText';
import { storeSelection, completeSelection } from '../../helpers/selection';
import withCmsContent from '../../helpers/withCmsContent';

class PaymentPlanAmount extends Component {
  constructor() {
    super();
    bindListeners(this, [
      'updateAmountWarning',
      'storeSelection',
      'updateViewport',
    ]);
    this.formErrorRef = createRef(null);
    this.state = {
      showWarning: false,
      isViewportWiderThan768: window.matchMedia('(min-width: 768px)').matches,
    };
  }

  componentDidMount() {
    const { minimumPayment, setFieldValue } = this.props;
    window.addEventListener('resize', this.updateViewport, false);
    if (!minimumPayment) {
      setFieldValue('paymentAmountOption', 'custom');
    }
  }

  componentDidUpdate() {
    const { error } = this.state;

    if (!error) return;
    this.formErrorRef.current && this.formErrorRef.current.focus();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateViewport, false);
  }

  setWarning(name, message) {
    this.setState({
      showWarning: name,
      error: message,
    });
  }

  updateAmountWarning() {
    const {
      values: {
        customAmount,
        paymentAmountOption,
      },
      goodFaithPayment,
      setFieldError,
      setFieldTouched,
    } = this.props;
    if (customAmount && paymentAmountOption === 'custom') {
      // Errors here
      const paymentError = vMinimumPaymentAmount(goodFaithPayment, customAmount);
      setFieldTouched('customAmount', !!paymentError);
      setFieldError('customAmount', paymentError);
      this.setWarning(false);
    } else {
      setFieldTouched('customAmount', false);
      setFieldError('customAmount', undefined);
    }
  }

  updateViewport() {
    this.setState(state => ({
      ...state,
      isViewportWiderThan768: window.matchMedia('(min-width: 768px)').matches,
    }));
  }

  storeSelection(event) {
    return storeSelection(this, event, /[$.\s]/g);
  }

  render() {
    const {
      values: { paymentAmountOption },
      setFieldValue,
      setFieldTouched,
      minimumPayment,
      content,
      onChange,
      heading,
    } = this.props;

    const vCustomAmount = (value) => {
      if (paymentAmountOption === 'custom') {
        const customError = vDollarAmount(value) || vMinimumPaymentAmount(minimumPayment, value);
        return customError;
      }

      return undefined;
    };


    const normalizeAmount = (value) => {
      if (/[^$0-9.]/.test(value)) {
        this.setWarning('customAmount', 'Please enter numeric characters only');
      } else {
        this.setWarning(false);
      }

      const result = normalizeDollarAmount(value);
      completeSelection(this, 'customAmount', value, result);
      return result;
    };

    const {
      showWarning,
      error,
      formErrorRef,
      isViewportWiderThan768,
    } = this.state;

    const shouldShowCustomInput = isViewportWiderThan768 || paymentAmountOption === 'custom';

    return (
      <div className="payment-section">
        {heading && (
          <div className="hgroup hgroup--inline">
            {heading}
          </div>
        )}

        <div className="card-group">
          {(!!minimumPayment || minimumPayment === 0) && (
            <div className="card-group__item">
              <div className="card card--action-right-at-768">
                <div className="card__content">
                  <FormBoolean
                    label={content('minimum')}
                    inputId="minimumPayment"
                    labelClass="body2"
                  >
                    <input
                      id="minimumPayment"
                      name="paymentAmountOption"
                      type="radio"
                      value={minimumPayment}
                      checked={paymentAmountOption === minimumPayment.toString()}
                      onChange={(e) => {
                        const { target: { name, value, checked } } = e;
                        if (checked) {
                          setFieldValue(name, value);
                          onChange && onChange({ ...e, meta: { value } });
                        }
                      }}
                    />
                  </FormBoolean>
                </div>

                <div className="card__action pt0">
                  <span className="heading2 ml40">
                    {formatCurrency(minimumPayment)}
                  </span>
                </div>
              </div>
            </div>
          )}

          <div className="card-group__item">
            <div className="card card--action-right-at-768">
              <div className="card__content">
                <FormBoolean
                  label={content('custom')}
                  inputId="paymentAmountOptionUserDefined"
                  labelClass="body2"
                >
                  <input
                    id="paymentAmountOptionUserDefined"
                    name="paymentAmountOption"
                    type="radio"
                    value="custom"
                    onChange={(e) => {
                      const { target: { name, value, checked } } = e;
                      if (checked) {
                        const customAmount = document.getElementById('customAmount');
                        customAmount.focus();
                        setFieldValue(name, value);
                        onChange && onChange({ ...e, meta: { value: customAmount.value } });
                      }
                    }}
                    onClick={() => setFieldTouched('paymentAmountOption', true)}
                    checked={paymentAmountOption === 'custom'}
                  />
                </FormBoolean>
              </div>
              <div className={joinClass('card__action', !shouldShowCustomInput && 'hidden')}>
                <FormControl>
                  <label htmlFor="customAmount" className="visuallyhidden">Payment amount</label>
                  <PaymentAmountField
                    model="customAmount"
                    aria-required={paymentAmountOption === 'custom'}
                    aria-describedby="error_customAmount"
                    tabIndex={paymentAmountOption === 'custom' ? '' : '-1'}
                    validate={vCustomAmount}
                    onBlur={this.updateAmountWarning}
                    onFocus={() => {
                      const radio = document.getElementById('paymentAmountOptionUserDefined');
                      if (!radio.checked) radio.click();
                    }}
                    onChange={(e) => {
                      const value = normalizeAmount(e.target.value);
                      setFieldValue('customAmount', value);
                      onChange && onChange({ ...e, meta: { value } });
                    }}
                  />
                  {showWarning === 'customAmount' && (
                  // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
                  <span role="alert" ref={formErrorRef} tabIndex="0" id="error_customAmount" className="form-control__error hide-focus">{error}</span>
                  )}
                </FormControl>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withCmsContent('content', {
  'ltip-payment-plan': 'amount',
})(PaymentPlanAmount);
