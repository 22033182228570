import {
  GET_CHANNEL_DATA,
  GET_CHANNEL_DATA_SUCCESS,
  GET_CHANNEL_DATA_FAILURE,
} from '../actions/cpc';

import { keyedReducer } from '../helpers/redux';

export default keyedReducer({
  channelData: null,
  loading: false,
  promise: null,
  error: null,
}, {
  [GET_CHANNEL_DATA]: (state, { payload }) => ({
    ...state,
    loading: true,
    promise: payload,
  }),
  [GET_CHANNEL_DATA_SUCCESS]: (state, { payload }) => ({
    ...state,
    loading: false,
    channelData: payload,
  }),
  [GET_CHANNEL_DATA_FAILURE]: (state, { payload }) => ({
    ...state,
    loading: false,
    error: payload,
    promise: null,
  }),
});
