import React from 'react';
import { joinClass } from '../helpers/component';

const makeItem = (child, index) => (
  <div className="key-value-group__item" key={index}>
    { child }
  </div>
);

const KeyValueGroup = ({
  children,
  className,
}) => (
  <div className={joinClass('key-value-group', className)}>
    { Array.isArray(children) ? children.map(makeItem) : makeItem(children) }
  </div>
);

export default KeyValueGroup;
