import React from 'react';

const CtaLink = ({
  label,
  url,
  behavior = 'external',
  linkClass,
  onClick,
}) => {
  if (!label || !url) return null;

  return (
    <div className="action__item">
      <a
        className={linkClass}
        onClick={onClick}
        href={url}
        target={behavior === 'default' ? '_self' : '_blank'}
        rel="noopener noreferrer"
      >
        {label}
      </a>
    </div>
  );
};

export default CtaLink;
