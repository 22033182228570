import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link, Route } from 'react-router-dom';
import { PageSection } from 'adc-ui-components';
import { Formik, Form } from 'formik';
import { joinClass } from '../../helpers/component';
import { reviewPaymentPlan } from '../../actions/paymentPlan';
import { getPaymentInstruments } from '../../actions/instruments';
import {
  PAYMENT_PLAN,
  PAYMENT_PLAN_BANK,
  PAYMENT_PLAN_CARD,
  PAYMENT_PLAN_REVIEW,
} from '../../helpers/routes';
import AddBank from '../../components/AddBank';
import AddCard from '../../components/AddCard';
import useCpc from '../../hooks/useCpc';
import { cpcDataToPaymentFormData } from '../../helpers/payments';
import { kibanaLog } from '../../helpers/logger';
import { flagEnabled } from '../../helpers/featureFlags';
import withStepUp from '../../helpers/withStepUp';
import useSessionStorageCleanup from '../../hooks/useSessionStorageCleanup';
import ErrorMessage from '../../components/ErrorMessage';

const AddInstrumentForm = ({
  isHarness,
  initialValues,
  paymentFormData: paymentFormDataProp,
  history,
  handleReviewPaymentPlan,
  handleGetPaymentInstruments,
}) => {
  const [isCpcLoading, setCpcLoading] = useState(true);
  const cpc = useCpc();
  const paymentFormData = flagEnabled('stepUpEnabled')
    ? JSON.parse(sessionStorage.getItem('maw_paymentFormData')) || {}
    : paymentFormDataProp;

  useEffect(() => {
    if (!Object.keys(paymentFormData).length) {
      history.push(PAYMENT_PLAN);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useSessionStorageCleanup('maw_paymentFormData');

  const cpcLoadingEventHandler = ({ isLoading }) => {
    // We need a timer here for the loading state of the button
    // to match the loading state of the component.
    // See why this is necessary on L#15 of src/hooks/useCpcDispatcher.js.
    setTimeout(() => setCpcLoading(isLoading), 1000);
  };

  const onAddInstrumentSubmit = async () => {
    try {
      const cpcPromise = await cpc.submitForm();
      const { cpcData: { submissionDetails: { cpcStatus } } } = cpcPromise;
      if (cpcStatus === 'ERROR') {
        // CPC component is handling displaying error msg
        return;
      }
      // force a get instruments here so that if the user goes back
      // clicking back or clicking "Make Another Payment"
      // on the confirmation page they will see the instrument they
      // just added.
      kibanaLog('cpc_submit_success');
      handleGetPaymentInstruments({ force: true, showLoading: false });
      handleReviewPaymentPlan({
        ...paymentFormData,
        ...cpcDataToPaymentFormData(cpcPromise),
      });
      history.push(PAYMENT_PLAN_REVIEW);
    } catch (e) {
      // CPC component will handle displaying error msg
    }
  };

  return (
    <PageSection>
      <Formik
        initialValues={initialValues}
        onSubmit={onAddInstrumentSubmit}
        setSubmitting={false}
      >
        {({
          submitCount,
          isValid,
          isSubmitting,
        }) => (
          <Form
            noValidate
          >
            {!isValid && submitCount > 0 && (
            // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
            <div tabIndex="0" className="hide-focus">
              <ErrorMessage id="form-error" errorMessage="Please fix the form errors to continue" className="visuallyhidden" />
            </div>
            )}
            <div className="payment-section">
              <>
                <Route
                  exact
                  path={PAYMENT_PLAN_BANK}
                  render={() => (
                    <AddBank
                      displayStoredPaymentOption
                      onCpcLoading={cpcLoadingEventHandler}
                    />
                  )}
                />
                <Route
                  exact
                  path={PAYMENT_PLAN_CARD}
                  render={() => (
                    <AddCard
                      displayStoredPaymentOption
                      onCpcLoading={cpcLoadingEventHandler}
                    />
                  )}
                />
              </>
            </div>

            <div className="action action--right pt6">
              <div className="action__item">
                <button
                  type="submit"
                  className={joinClass('button button--primary', isSubmitting && 'is-loading')}
                  disabled={isCpcLoading || isSubmitting}
                >
                  Continue
                </button>
              </div>
              {!isHarness && (
              <div className="action__item">
                <Link to={PAYMENT_PLAN} className="button">Back</Link>
              </div>
              )}
            </div>
          </Form>
        )}
      </Formik>
    </PageSection>
  );
};


const mapStateToProps = (state, ownProps) => {
  const {
    userMessages: {
      error: errorMessage,
      showModal,
    },
  } = state;

  const { location } = ownProps;

  const type = location.pathname === PAYMENT_PLAN_BANK ? 'Bank' : 'PaymentCard';

  const initialValues = {};

  if (type === 'Bank') {
    initialValues.account = 'Checking';
  }

  return {
    type,
    initialValues: {
      ...initialValues,
      billingAddressCheckbox: false,
      savePayment: false,
      isDefault: 'no',
    },
    errorMessage: !showModal && errorMessage,
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  handleGetPaymentInstruments: getPaymentInstruments,
  handleReviewPaymentPlan: reviewPaymentPlan,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withStepUp(AddInstrumentForm));
