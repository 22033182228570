import React, { Component } from 'react';

export default class TooltipContent extends Component {
  componentDidMount() {
    const { onMount } = this.props;
    onMount();
  }

  render() {
    const {
      children,
      id,
    } = this.props;
    return (
      <div id={id} className="tooltip-button__content">
        {children}
      </div>
    );
  }
}
