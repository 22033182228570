import React from 'react';
import { FormControl } from 'adc-ui-components';
import { Field } from 'formik';
import dateformat from 'dateformat';

import { RequiredFactory } from '../../helpers/validation/required';
import { today, addDays, isWithinNDays } from '../../helpers/date';
import { joinClass } from '../../helpers/component';
import DatePickerField from '../form-elements/DatePickerField';
import Card from '../Card';
import P2PPaymentDate from '../p2p/P2PPaymentDate';

const vRequired = RequiredFactory();
const vDateSelection = (day) => {
  const date = new Date(day);
  return isWithinNDays(date, 61) ? undefined : 'Your payment date must be in the next 60 days';
};
const DateOverlay = ({
  children,
  selectedDay,
  month,
  input,
  classNames: {
    overlay,
  },
  ...props
}) => (
  <div className={joinClass('DayPickerInput-Overlay', overlay)} {...props}>
    {children}
    <div className="DayPicker__Key">
      <div className="DayPicker__selected">Selected date</div>
      <div className="DayPicker__due-date">Due date</div>
    </div>
  </div>
);

const isoDate = d => dateformat(d, 'yyyy-mm-dd');
const prettyDate = d => dateformat(d || new Date(), 'mm/dd/yyyy');
const getIsDatePastDueDate = (date, dueDate) => {
  try {
    return isoDate(date) > isoDate(dueDate);
  } catch (e) {
    return false;
  }
};
const dayMatches = (a, b) => {
  try {
    return isoDate(a) === isoDate(b);
  } catch (e) {
    return false;
  }
};
const getDueDate = ({ summary: { dueDate } = {} } = {}) => (dueDate || '').substring(0, 10);
const getHasAutopay = ({ summary: { autoPayEnabled } = {} } = {}) => autoPayEnabled;
const getHasBalanceButNotPastDue = ({
  summary: { pastDueBalanceRemaining, balanceDue } = {},
} = {}) => pastDueBalanceRemaining === 0 && balanceDue > 0;

export const PaymentDateField = ({
  setFieldValue,
  values: { date = new Date() },
  bill,
  label,
  className = '',
}) => {
  const dueDate = getDueDate(bill);
  const initialValue = new Date(date);

  return (
    <FormControl
      className={`date-picker ${className}`}
      label={label}
      inputId="date"
    >
      <Field
        id="date"
        name="date"
        component={DatePickerField}
        value={initialValue}
        placeholder={prettyDate()}
        formatDate={d => prettyDate(d)}
        onDayChange={value => value && setFieldValue('date', value)}
        overlayComponent={DateOverlay}
        validate={value => (
          vRequired('Please select a date')(value)
          || vDateSelection(value)
        )}
        min={isoDate(today())}
        max={isoDate(addDays(today(), 60))}
        dayPickerProps={{
          initialMonth: initialValue,
          modifiers: {
            disabled: day => !isWithinNDays(day, 61),
            billDueDate: day => dayMatches(day, dueDate),
            selected: day => dayMatches(day, date),
          },
          renderDay: (day) => {
            if (dayMatches(day, dueDate)) {
              return <span aria-label="Current bill due date">{day.getDate()}</span>;
            }
            return day.getDate();
          },
        }}
      />
      {!label && (
        <label
          className="visuallyhidden"
          htmlFor="date"
        >
          {`Enter payment date here up to 60 days in the future. Payment due date: ${dateformat(dueDate, 'fullDate')}`}
        </label>
      )}
    </FormControl>
  );
};

const PaymentDate = (props) => {
  const {
    values,
    plan,
    values: { date },
    bill,
    preamble = null,
    keepItShort,
  } = props;

  const dueDate = getDueDate(bill);
  /*
    1. does not have autopay
    2. has a balance but not past due balance
    3. the chosen date is past the due date
    4. The due date is not in the past
  */
  const shouldShouldMessage = (
    !getHasAutopay(bill)
    && getHasBalanceButNotPastDue(bill)
    && getIsDatePastDueDate(date, dueDate)
    && !getIsDatePastDueDate(today(), dueDate)
  );

  return (
    <div className="payment-section payment-date">
      <h2>Payment Date</h2>
      <div className="card-group">
        {preamble}
        <div className="card-group__item">
          <div className="card card--action-right-at-768">
            <div className="card__content hide-for-mobile">
              <p className="label">Select payment date</p>
            </div>
            <div className="card__action">
              <PaymentDateField {...props} />
            </div>
          </div>
        </div>
      </div>

      {shouldShouldMessage && (
        <Card severity="warning">
          <p className="form-control__warning">
            This payment will process after your next due date. Please make a payment before then.
          </p>
        </Card>
      )}
      <P2PPaymentDate
        {...{
          bill,
          values,
          plan,
          keepItShort,
        }}
      />
    </div>
  );
};

export default PaymentDate;
