/*
  * normalizes the user-entered characters by dropping numeric punctuation and filling in
  *  a dollar amount from right to left, for example:
  *  1 => $0.01
  *  23 => $0.23
  *  $1.45 => $1.45
  * @param {String} value a list of user-entered characters
  * @return {String} a number formatted as a dollar amount, effectively `$%.2d` in printf
  * Note: This function does not accept numbers.  If you need to provide a value to a field
  *  normalizing with this function, pre-normalize the number with value.toFixed(2), so
  *  that the cents digits are filled.
*/
export const normalizeDollarAmount = (value) => {
  const numbers = value.replace(/[^\d]+/g, '').replace(/^0+/, '');
  if (!numbers) {
    return '';
  }
  if (numbers.length <= 1) {
    return `$.0${numbers}`;
  }
  if (numbers.length === 2) {
    return `$.${numbers}`;
  }
  const decimal = numbers.substring(numbers.length - 2);
  const whole = numbers.substring(0, numbers.length - 2);
  return `$${whole}.${decimal}`;
};

export const cleanDollarAmount = value => value.replace(/^\$/, '').replace(/^\./, '0.');

const fromDollarAmount = value => parseFloat(value.replace(/^\$/, '').replace(/^\./, '0.'));

export const vDollarAmount = (value) => {
  if (!/^\$?\d*\.\d{2}$/.test(value)) {
    return 'Please enter a valid amount';
  }
  // Prefer global parseFloat. IE doesn't support Number.parseFloat
  const fValue = parseFloat(value.replace(/^\$/, ''));
  if (fValue <= 0) {
    return 'Please enter 0.01 or greater';
  }
  return undefined;
};

export const vPaymentAmount = ({ summary: { balanceDue } = {} } = {}, amount) => {
  if (balanceDue !== undefined) {
    const fAmount = fromDollarAmount(amount);
    if (!isNaN(fAmount) && fAmount > balanceDue) {
      return 'The payment amount cannot exceed the balance due';
    }
  }
  return undefined;
};

export const vMinimumPaymentAmount = (minimum, amount) => {
  const fAmount = fromDollarAmount(amount);
  if (!isNaN(fAmount) && fAmount < minimum) {
    return 'Payment amount cannot be less than the minimum due';
  }
  return undefined;
};
