import React from 'react';
import qs from 'qs';
import { useHistory } from 'react-router-dom';
import getConfig from '../config';

const { homeDomain } = getConfig();

const backText = 'Go Back';

const GoBack = ({
  location: { search = '' } = {},
  children,
}) => {
  const history = useHistory();
  const backUrl = qs.parse(search.slice(1)).back;
  const content = children || backText;

  if (backUrl) {
    return (
      <a className="button" href={`https://${homeDomain}${backUrl}`}>
        {content}
      </a>
    );
  }

  return <button type="button" className="button" onClick={history.goBack}>{content}</button>;
};


export default GoBack;
