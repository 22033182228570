import { connect } from 'react-redux';

import XapInterstitial from '../components/XapInterstitial';
import { getCMSPackage } from '../actions/cms';
import { getIsXapCurrent, getIsXapExit, getIsXapExitInProgress } from '../helpers/xap';
import { PAYMENT_PLAN } from '../helpers/routes';
import { flagEnabled } from '../helpers/featureFlags';

const mapStateToProps = ({
  account: { loading: accountLoading, account },
  auth: {
    macaroon: {
      mainAccountRoles = [],
      lite = false,
    } = {},
  } = {},
  cms: {
    packages: {
      xapModal: {
        loading = true,
        error,
        content = {},
      } = {},
    } = {},
  },
  harness: { isHarness } = {},
},
{
  location: { pathname = '' } = {},
}) => {
  const hasBilling = mainAccountRoles && (
    mainAccountRoles.indexOf('PRIMARY') !== -1
    || mainAccountRoles.indexOf('BILLPAY') !== -1
  );

  const isXapCurrent = getIsXapCurrent(account) && !getIsXapExitInProgress(account);
  const isXapExit = flagEnabled('global.xapExitEnabled') && getIsXapExit(account);

  const eligible = (
    flagEnabled('global.xapInterstitial')
    // Don't show the XAP current/exit modals in the payment plan flow
    && !pathname.startsWith(PAYMENT_PLAN)
    && !isHarness
    && hasBilling
    && (isXapCurrent || isXapExit)
  );

  let cmsPackage;
  if (isXapExit) {
    cmsPackage = 'xap-exit-modal';
  } else if (isXapCurrent) {
    cmsPackage = 'xap-current-modal';
  }

  const {
    heading,
    description,
    cta: ctas = [],
    pairs: metadata = [],
  } = content;

  const primaryCta = ctas.find(cta => cta.type === 'primary');
  const secondaryCta = ctas.find(cta => cta.type === 'secondary');

  const componentName = metadata.find((item = {}) => item.key === 'adobeComponentName');
  const dismissedCookieName = metadata.find((item = {}) => item.key === 'dismissedCookieName');

  return {
    eligible,
    cmsPackage,
    loading: (loading || accountLoading) && !error,
    accountLoading,
    content: {
      heading,
      description,
      primaryCta,
      secondaryCta,
      placementName: `${lite ? 'lite|' : ''}xap-interstitial`,
      componentName: componentName && componentName.value,
      dismissedCookieName: dismissedCookieName && dismissedCookieName.value,
    },
  };
};

const mapDispatchToProps = dispatch => ({
  handleGetCopy: packageName => dispatch(getCMSPackage(packageName, { storeKey: 'xapModal' })),
});

export default connect(mapStateToProps, mapDispatchToProps)(XapInterstitial);
