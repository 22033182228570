import React from 'react';

const BillClock = ({ className = '' }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
    <g fill="none" stroke="currentColor">
      <path d="M26.5 26.5h-13m9 3h-9M26.5 23.5h-13m9-18h-12a2 2 0 0 0-2 2v25a2 2 0 0 0 2 2h19a2 2 0 0 0 2-2V19.748" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M28 3.5a8.5 8.5 0 1 0 8.5 8.5A8.5 8.5 0 0 0 28 3.5z" strokeLinecap="round" strokeLinejoin="round" />
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.1" d="M27.998 6.722v5.255l3.004 3.001" />
      <circle cx="28" cy="12" r="1" strokeMiterlimit="10" />
      <path d="M17.049 13.733a1.674 1.674 0 0 0-1.651-1.148 1.495 1.495 0 0 0-1.65 1.435c0 .79.717 1.148 1.722 1.507.933.36 1.579.646 1.65 1.436 0 1.579-1.579 1.579-1.794 1.579a1.752 1.752 0 0 1-1.794-1.364m1.866 2.76v-1.252m0-6.173v-1.292" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25" />
    </g>
  </svg>
);

export default BillClock;
