import React from 'react';
import { FormBoolean } from 'adc-ui-components';

import Card from './Card';
import PaymentInstrument from './PaymentInstrument';

import {
  TERMS_STORED_PAYMENT,
  TERMS_AUTOPAY,
} from '../helpers/routes';

const AutopayCheckbox = ({
  autopayVal,
  handleSetAutopayState,
  autopay,
  review,
}) => (
  <div className="payment-section">
    <h2>Set up automatic payments</h2>

    <div className="card-group">
      <div className="card-group__item">
        <Card className="autopay-enable">
          <FormBoolean>
            <input
              id="autopay"
              name="autopay"
              type="checkbox"
              checked={autopayVal}
              onChange={handleSetAutopayState}
            />
            <label className="form-control__label" htmlFor="autopay">
              <span className="autopay-label">
                <span className="payment-instrument-helper-text">Turn on automatic payments using</span>
                <PaymentInstrument autopay={autopay} instrument={review.instrument} />
              </span>
            </label>
          </FormBoolean>
        </Card>
      </div>
      {autopayVal && (
        <div className="card-group__item">
          <Card>
            Your full balance will be automatically charged on
            your bill due date each month, beginning with your next bill
            {/*
              remove this for now until we figure it out
              review.autopayStartDate && `
              on ${dateFormat(review.autopayStartDate, 'mmmm d, yyyy', true)}`
            */}
            {'. '}
            Cancel anytime.
            <br />
            {review.showStoredPaymentTerms && (
              <>
                <a href={TERMS_AUTOPAY} target="_blank" rel="noopener noreferrer">Terms &amp; Conditions</a>
                {' '}
                apply.
              </>
            )}
            {!review.showStoredPaymentTerms && (
              <>
                <a href={TERMS_AUTOPAY} target="_blank" rel="noopener noreferrer">Automatic Payments</a>
                {' '}
                and
                {' '}
                <a href={TERMS_STORED_PAYMENT} target="_blank" rel="noopener noreferrer">Stored Payment Methods</a>
                {' '}
                Terms &amp; Conditions apply.
              </>
            )}
          </Card>
        </div>
      )}
    </div>
  </div>
);

export default AutopayCheckbox;
