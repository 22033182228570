import { useEffect } from 'react';
import { flagEnabled } from '../helpers/featureFlags';

const useSessionStorageCleanup = (key) => {
  useEffect(() => {
    if (flagEnabled('stepUpEnabled')) {
      const handleBeforeUnload = () => sessionStorage.removeItem(key);

      window.addEventListener('beforeunload', handleBeforeUnload);

      return () => {
        window.removeEventListener('beforeunload', handleBeforeUnload);
      };
    }

    return () => {};
  }, [key]);
};

export default useSessionStorageCleanup;
