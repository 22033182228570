import React from 'react';

const icon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" version="1" viewBox="0 0 50 32">
    <rect width="50" height="32" rx="4" />
    <rect width="47" height="29" rx="2.5" x="1.5" y="1.5" ry="2.5" fill="#fff" />
    <path d="M14.75 8c-.667.083-1.417.417-1.917 1-.416.417-.833 1.167-.666 1.917.666 0 1.416-.417 1.916-.917s.75-1.25.667-2zm8.833 1.083c-1.166 0-2.583.25-2.583.25V20.25h1.083v-4.583l1.25.083c.834 0 2.167-.25 3.084-.917a3.353 3.353 0 0 0 1.25-2.583c0-.917-.25-1.583-.917-2.25s-2-.917-3.167-.917zm0 .834c1.667 0 2.917.666 3 2.416 0 1.667-1.25 2.5-3.166 2.5l-1.334-.083v-4.667zM14.667 11c-.917.083-1.75.583-2.167.583-.583 0-1.333-.583-2.167-.5a3.22 3.22 0 0 0-2.75 1.5c-1.166 1.917-.333 4.667.834 6.25.583.75 1.166 1.584 2.083 1.5.833 0 1.167-.5 2.167-.5 1 0 1.25.5 2.166.5.917 0 1.5-.75 2-1.5.667-.833.917-1.666.917-1.75 0 0-1.75-.666-1.75-2.5 0-1.583 1.333-2.333 1.417-2.333a3.167 3.167 0 0 0-2.75-1.25zm16.416 1.083c-1 0-1.75.25-2.5.75l.334.834A3.646 3.646 0 0 1 31 13c1.417 0 1.917.833 1.917 2.25-2.084 0-4.917.417-4.917 3 0 1.417 1.167 2.167 2.5 2.167 1.167 0 2-.5 2.583-1l.084.833h1C34 19.583 34 18.917 34 18.333V15.25c0-2.833-1.917-3.167-2.917-3.167zm10.25.167l-2.5 6.5-2.666-6.5H35l3.167 7.583V20s-.667 2.167-2.917 3l.417.833c1.916-.666 2.833-2.166 3.666-4.083l3.167-7.5zm-8.416 3.917v1.583c0 .667-.75 1.833-2.25 1.833s-1.584-1.333-1.584-1.333c0-1.083.667-2.167 3.834-2.083z" />
  </svg>
);

export default icon;
