import React from 'react';
import { joinClass } from '../helpers/component';
import Card from './Card';

const CardGroup = ({
  children,
  withCard,
  className,
}) => {
  const makeItem = child => child && (
    <div className="card-group__item">
      {withCard && <Card>{child}</Card>}
      {!withCard && child}
    </div>
  );
  const isArray = Array.isArray(children);
  return (
    <div className={joinClass('card-group', className)}>
      {isArray && React.Children.map(children, makeItem)}
      {!isArray && makeItem(children)}
    </div>
  );
};

export default CardGroup;
