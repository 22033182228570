/* eslint-disable global-require */
import React from 'react';
import { Switch } from 'react-router-dom';
import DefaultLayoutWithTracking from '../Layouts/DefaultLayoutWithTracking';
import { AUTHENTICATION_REQUIRED } from '../../helpers/routes';
import AuthenticationRequired from '../Errors/AuthenticationRequired';


const RootComp = process.env.NODE_ENV === 'production'
  ? require('./Root.prod').default
  : require('./Root.dev').default;

export default function Root(props) {
  return (
    <Switch>
      {/* Unauthenticated routes */}
      <DefaultLayoutWithTracking
        exact
        path={AUTHENTICATION_REQUIRED}
        component={AuthenticationRequired}
        {...props}
      />
      <RootComp {...props} />
    </Switch>
  );
}
