import dateformat from 'dateformat';
import BrowserInterface from '@harness/browser-interface';

import apiClient from '../middleware/apiClient';

import { enableAutopay } from './autopay';
import { refreshBill } from './bill';
import { getApplePaySession } from './applePay';
import { addInstrument } from './instruments';
import { dispatchSendTransaction, dispatchSendErrorEvent } from './adobe';

import { formatBillingAddress } from '../helpers/address';
import { getServiceList } from '../helpers/account';
import { getErrorFromResponse } from '../helpers/errors';
import { encryptPayment, setReviewData } from '../helpers/payments';
import { isNewMethod } from '../helpers/instruments';
import { getIsToday } from '../helpers/date';
import { flagEnabled } from '../helpers/featureFlags';
import { exitXapIfEligible } from './exitXap';
import { sendChannelTracking } from './channelTracking';

import {
  ONE_TIME_PAY,
  SCHEDULED_PAY,
} from '../helpers/apis';

import {
  P2P_COMPLETED,
  P2P_FAILED,
  PAYMENT_FAILED,
  PAYMENT_IMM_COMPLETE,
  PAYMENT_SCH_COMPLETE,
} from '../helpers/channelTracking';

// POST One Time Payment
export const POST_ONE_TIME_PAY = 'POST_ONE_TIME_PAY';
export const POST_ONE_TIME_PAY_SUCCESS = 'POST_ONE_TIME_PAY_SUCCESS';
export const POST_ONE_TIME_PAY_FAILURE = 'POST_ONE_TIME_PAY_FAILURE';
export const POST_XAP_EXIT = 'POST_XAP_EXIT';

function dispatchPostOneTimePay(data) {
  return {
    type: POST_ONE_TIME_PAY,
    payload: apiClient.fetch(ONE_TIME_PAY, { method: 'POST', body: JSON.stringify(data) }),
  };
}

function dispatchPostOneTimePaySuccess(payment) {
  return {
    type: POST_ONE_TIME_PAY_SUCCESS,
    payload: payment,
  };
}

function dispatchPostOneTimePayFailure(error) {
  return {
    type: POST_ONE_TIME_PAY_FAILURE,
    payload: error,
  };
}

export const postOneTimePay = data => async (dispatch) => {
  try {
    const response = await dispatch(dispatchPostOneTimePay(data)).payload;
    dispatch(dispatchPostOneTimePaySuccess(response));
    dispatch(sendChannelTracking({
      id: PAYMENT_IMM_COMPLETE,
      interactionType: 'BILLING_PAYMENTS',
    }));
    return response;
  } catch (error) {
    dispatch(sendChannelTracking({
      id: PAYMENT_FAILED,
      error,
      interactionType: 'BILLING_PAYMENTS',
    }));
    dispatch(dispatchPostOneTimePayFailure(error));
    throw error;
  }
};

// POST Scheduled Payment
export const POST_SCHEDULED_PAY = 'POST_SCHEDULED_PAY';
export const POST_SCHEDULED_PAY_SUCCESS = 'POST_SCHEDULED_PAY_SUCCESS';
export const POST_SCHEDULED_PAY_FAILURE = 'POST_SCHEDULED_PAY_FAILURE';

function dispatchPostScheduledPay(data) {
  return {
    type: POST_SCHEDULED_PAY,
    payload: apiClient.fetch(SCHEDULED_PAY, { method: 'POST', body: JSON.stringify(data) }),
  };
}

function dispatchPostScheduledPaySuccess(payment) {
  return {
    type: POST_SCHEDULED_PAY_SUCCESS,
    payload: payment,
  };
}

function dispatchPostScheduledPayFailure(error) {
  return {
    type: POST_SCHEDULED_PAY_FAILURE,
    payload: error,
  };
}

export const postScheduledPay = data => async (dispatch, getState) => {
  const isP2P = getState()
    .bill?.bill?.promiseToPay?.customerEligibilityChecked?.eligibleForPromiseToPay;
  try {
    const response = await dispatch(dispatchPostScheduledPay(data)).payload;
    dispatch(dispatchPostScheduledPaySuccess(response));
    dispatch(sendChannelTracking({
      id: isP2P ? P2P_COMPLETED : PAYMENT_SCH_COMPLETE,
      interactionType: 'BILLING_PAYMENTS',
    }));
    return response;
  } catch (error) {
    dispatch(sendChannelTracking({
      id: isP2P ? P2P_FAILED : PAYMENT_FAILED,
      error,
      interactionType: 'BILLING_PAYMENTS',
    }));
    dispatch(dispatchPostScheduledPayFailure(error));
    throw error;
  }
};

export const REVIEW_PAYMENT = 'REVIEW_PAYMENT';

function dispatchReviewPayment(data) {
  return {
    type: REVIEW_PAYMENT,
    payload: data,
  };
}

export const reviewPayment = values => async (dispatch, getState) => {
  const {
    instruments: { instruments: { instruments } },
    bill: { bill = {} },
    auth: { macaroon: { preferredEmail } },
  } = getState();

  // should contain all data from
  // both forms
  // TODO get payment form values from somewhere
  const userData = {
    ...values,
  };

  const reviewData = setReviewData(userData, bill, instruments, preferredEmail);

  return dispatch(dispatchReviewPayment(reviewData));
};

export const SUBMIT_PAYMENT = 'SUBMIT_PAYMENT';
export const SUBMIT_PAYMENT_SUCCESS = 'SUBMIT_PAYMENT_SUCCESS';
export const SUBMIT_PAYMENT_FAILURE = 'SUBMIT_PAYMENT_FAILURE';

function dispatchSubmitPayment(data) {
  return {
    type: SUBMIT_PAYMENT,
    payload: data,
  };
}

function dispatchSubmitPaymentSuccess(data) {
  return {
    type: SUBMIT_PAYMENT_SUCCESS,
    payload: data,
  };
}

function dispatchSubmitPaymentFailure(error) {
  return {
    type: SUBMIT_PAYMENT_FAILURE,
    payload: error,
  };
}

export const submitPayment = ({ autopay }) => async (dispatch, getState) => {
  const {
    account: { account },
    instruments: { instruments },
    payment: { review: paymentReview },
    bill: { bill },
    harness: { isHarness },
    paymentPlan: {
      review: paymentPlanReview,
      review: { installmentPlan } = {},
    } = {},
    collections: {
      review: collectionsReview,
    } = {},
  } = getState();
  const review = paymentReview || paymentPlanReview || collectionsReview;
  dispatch(dispatchSubmitPayment(review));

  const {
    amount,
    paymentMethodOption,
    date,
    savePayment,
  } = review;

  const {
    summary: {
      softDisconnected,
      pastDueBalanceRemaining,
    } = {},
  } = bill || {};

  const isApplePay = paymentMethodOption === 'Apple Pay';
  const billingAddress = formatBillingAddress(account, review);

  const isServiceActivationEligible = softDisconnected && amount >= pastDueBalanceRemaining;

  // Normalize the input date from mm/dd/yyyy to yyyy-mm-dd
  const formattedDate = date && dateformat(date, 'yyyy-mm-dd');
  const scheduledToday = !date || getIsToday(formattedDate);
  // All payment calls require billingAddress and amount
  const paymentData = {
    amount,
    billingAddress,
    // Only set the date if we're not scheduling today.
    ...(scheduledToday ? {} : { date: formattedDate }),
  };

  if (isServiceActivationEligible && scheduledToday) {
    paymentData.npFullPayment = true;
  }

  let applePaySession;
  try {
    // Stage 1: depending on paymentMethodOption and savePayment,
    //  decorate `paymentData`
    if (isApplePay) {
      // For apple pay, we grab the session and the payment token it creates, and add
      //  it to paymentData, later, we'll make the `completePayment` call.
      const applePayResponse = await dispatch(getApplePaySession(amount));

      // this is an already-declared var, but eslint wants me to destructure, which I can't.
      // eslint-disable-next-line prefer-destructuring
      applePaySession = applePayResponse.applePaySession;
      Object.assign(
        paymentData,
        applePayResponse.payload,
        { payment: { ...paymentData, ...applePayResponse.payload } },
      );
    } else if (savePayment && review.token) {
      // If we've come off the reviewInstrument page, we expect a token to be
      // on the `review` object.  Put it on paymentData.
      paymentData.token = review.token;
    } else if (isNewMethod(paymentMethodOption)) {
      if (autopay) {
        const instrument = (() => {
          if (review.instrument && (
            review.instrument.cardNumber
            || review.instrument.bankNumber
          )) {
            return review.instrument;
          }
          return review;
        })();
        // If autopay is requested, we must store the payment.  We use the token
        //  it creates for the payment
        //  TODO: autopay v4 _should_ be working with a new instrument now.
        const paymentToken = await dispatch(addInstrument(paymentMethodOption, instrument));
        paymentData.token = paymentToken.token;
      } else {
        // Otherwise, we encrypt the payment and add it to paymentData.
        Object.assign(paymentData, await encryptPayment(
          paymentMethodOption,
          (review.instrument && review.instrument.cardNumber && review.instrument) || review,
          instruments.jwkMap.makePayment,
        ));
      }
    } else {
      // paymentMethodOption is one of a small number of enums,
      //  or a token.  If it's a token, we use it as such.
      //  TODO: we should probably check if paymentMethodOption conforms to some
      //  minimum requirements for a token, whatever those are.  Probably:
      //  numeric string, of length greater than N, and throw an error if non-conformant.
      paymentData.token = review.token || paymentMethodOption;
      // walletId = null if storing the payment Method & D#AccNum if not
      paymentData.walletId = review.walletId;
    }

    // At this point, paymentData is a complete payment payload.

    // Only use the scheduled pay service if date is present and not today.
    const sendPayment = scheduledToday ? postOneTimePay : postScheduledPay;

    // Send the payment
    let response;
    if ((installmentPlan || collectionsReview) && parseFloat(paymentData.amount) === 0) {
      response = {};
    } else {
      response = await dispatch(sendPayment(paymentData));
    }
    // If response is blank, something went very wrong...
    if (!response) {
      throw new Error('Error submitting payment');
    }

    // For ApplePay, we have to complete the payment cycle.
    if (isApplePay) {
      if (isHarness) {
        BrowserInterface.sendMessageToNative({
          type: 'MOBILE_PAYMENT_RESULT',
          payload: {
            type: 'ApplePay',
            status: 'SUCCESS',
          },
        });
      }

      if (applePaySession) applePaySession.completePayment(applePaySession.STATUS_SUCCESS);
    }

    // format the response
    const {
      confirmationNumber, authorizationNumber,
    } = response;
    const confirm = {
      ...review,
      ...response,
      // Include the fact we attempted to enable autopay
      autopay,
      // List of services...
      services: getServiceList(account),
      // Include the formatted confirmation numbers.
      confirmationNumber: confirmationNumber && `#${confirmationNumber}`,
      authorizationNumber: authorizationNumber && `#${authorizationNumber}`,
    };
    const { token } = paymentData;
    // Keep the autopay flow in its own promise.
    // We want the call to happen concurrently with the payment submission,
    // but in the case of apple pay, we'll also want to immediately respond
    // to the sendPayment call, and we don't want to fail if the autopay
    // call fails (yet).

    // If we enabled autopay, wait for it to complete
    if (autopay) {
      // This split is on purpose, because else is a TODO; see note above about
      // autopay v4 with new instrument.
      if (token) {
        // Enable autopay
        await dispatch(enableAutopay({ token, billingAddress })).catch(() => Promise.resolve());
      }
    }

    // Send an SST regarding the payment
    dispatch(dispatchSendTransaction({
      name: 'self service transaction',
      action: 'self service transaction',
      transaction: `billing:singlepay:${scheduledToday ? 'today' : 'scheduled'}:payment:confirm`,
    }));

    // Check XAP-exit eligibility of payment, and exit if possible.
    try {
      if (!(flagEnabled('global.collections2', { defaultValue: true }) && collectionsReview)) {
        await dispatch(exitXapIfEligible());
      }
    // eslint-disable-next-line no-empty
    } catch (e) {}

    // ... refresh the bill; wait on it to finish
    await dispatch(refreshBill()).catch(() => Promise.resolve());

    // ... finally ...
    dispatch(dispatchSubmitPaymentSuccess(confirm));

    return confirm;
  } catch (error) {
    // Oh noes!  Payment failed!

    // error type cancel would only be true of applePay
    // when user cancels applePay we do not want to
    // process the payment and report payment errors.
    const applePayCanceled = isApplePay && error && error.type === 'cancel';
    const errorDetail = getErrorFromResponse(error);

    if (isApplePay) {
      if (isHarness && !applePayCanceled) {
        const details = errorDetail.isMapped ? errorDetail.message : 'Payment declined';
        BrowserInterface.sendMessageToNative({
          type: 'MOBILE_PAYMENT_RESULT',
          payload: {
            type: 'ApplePay',
            status: 'FAILURE',
            details,
          },
        });
      }

      // Cancel the apple pay session if we've started one.
      if (applePaySession) applePaySession.abort();
    }

    dispatch(dispatchSubmitPaymentFailure(errorDetail));
    dispatch(dispatchSendErrorEvent(errorDetail));
    throw error;
  }
};

export const CONFIRM_PAYMENT = 'CONFIRM_PAYMENT';
export const confirmPayment = () => dispatch => dispatch({ type: CONFIRM_PAYMENT });

export const RESET_PAYMENT = 'RESET_PAYMENT';
export const resetPaymentState = () => dispatch => dispatch({ type: RESET_PAYMENT });

export const CLEAR_ERROR = 'CLEAR_ERROR';
export const clearError = () => dispatch => dispatch({ type: CLEAR_ERROR });
