import { luhnChecksum, getPaymentCardType } from '../paymentInstrument';

const vCardNumber = (value) => {
  const message = 'Please enter a valid card number';

  if (!value) {
    // consider empty models to be valid; `vRequired` must be used to disallow empty values.
    return undefined;
  }

  // accept only spaces, digits and dashes
  if (/[^0-9\s-]+/.test(value)) {
    return message;
  }
  if (!luhnChecksum(value)) {
    return message;
  }
  // 'CardPayment' is the "I couldn't detect a type" value.
  if (getPaymentCardType(value) === 'CardPayment') {
    return message;
  }
  return undefined;
};

export default vCardNumber;
