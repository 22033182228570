import React from 'react';

const icon = () => {
  const st0 = {
    fill: 'none',
    stroke: 'currentColor',
    strokeMiterlimit: 10,
  };

  return (
    <svg viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
      <g style={st0}>
        <line x1="20" y1="13.5" x2="20" y2="26.5" />
        <line x1="26.5" y1="20" x2="13.5" y2="20" />
        <path d="M32.5,20A12.5,12.5,0,1,1,20,7.5,12.46,12.46,0,0,1,32.5,20Z" />
      </g>
    </svg>
  );
};

export default icon;
