import { useEffect, useState } from 'react';
import { forceLogout } from '../actions/auth';
import {
  idleTimeout, markActivity, MINUTE, waitForPeriodOfInactivity,
} from '../helpers/activityMonitor';

/**
 * Monitors user activity with an event listener and sets the idle state.
 *
 * @param {boolean} options.enabled - A provided flag to enable the functionality of this hook.
 * @returns {Object} - An object containing the extendActivity function and the isIdle state.
 */
const useActivityMonitor = ({ enabled }) => {
  const [isIdle, setIsIdle] = useState(false);

  const warnAfterIdlePeriod = () => {
    const idleWarningTime = idleTimeout - MINUTE;

    waitForPeriodOfInactivity(idleWarningTime).then(() => {
      setIsIdle(true);
    });
  };

  const extendActivity = () => {
    setIsIdle(false);
    warnAfterIdlePeriod();
  };

  const logoutAfterIdle = () => {
    waitForPeriodOfInactivity(idleTimeout).then(() => {
      forceLogout();
    });
  };

  useEffect(() => {
    if (enabled) {
      ['keydown', 'mousedown', 'touchstart'].forEach((eventName) => {
        window.addEventListener(eventName, markActivity, { passive: true });
      });

      warnAfterIdlePeriod();
      logoutAfterIdle();
    }

    return () => {
      ['keydown', 'mousedown', 'touchstart'].forEach((eventName) => {
        window?.removeEventListener(eventName, markActivity);
      });
    };
  }, [enabled]);

  return enabled && { extendActivity, isIdle };
};

export default useActivityMonitor;
