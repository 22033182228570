import React from 'react';
import { connect } from 'react-redux';
import { PageHeader as ADCPageHeader, HarnessExitButton } from 'adc-ui-components';
import { Link } from 'react-router-dom';

import { getNavTitle } from '../../../helpers/formatText';
import CaretLeft from '../../svgs/CaretLeft';
import ArrowLeft from '../../svgs/ArrowLeft';

import {
  NEW_PAYMENT,
  NEW_PAYMENT_REVIEW,
  NEW_PAYMENT_CARD,
  NEW_PAYMENT_BANK,
  NEW_PAYMENT_CONFIRMATION,
} from '../../../helpers/routes';

import config from '../../../config';

const { oauthUrl } = config();
const oauthLogoutUrl = `${oauthUrl}/oauth/sp-logout?client_id=my-account-web`;

const BackButton = (device, path) => {
  const BackIcon = device === 'android' ? <ArrowLeft className="svg-icon--16" /> : <CaretLeft className="svg-icon--12" />;

  if (
    path === NEW_PAYMENT_REVIEW
    || path === NEW_PAYMENT_CARD
    || path === NEW_PAYMENT_BANK
  ) {
    return (
      <Link to={NEW_PAYMENT} className="button button--text">{BackIcon}</Link>
    );
  }

  return true;
};

const ExitButton = (path) => {
  let buttonText = 'Cancel';
  let intent;

  if (path === NEW_PAYMENT_CONFIRMATION) {
    buttonText = 'Done';
    intent = 'xfinitymyaccount://?page=tabaccount&purgeCache=true';
  }

  return (
    <HarnessExitButton className="button--text button" intent={intent}>{buttonText}</HarnessExitButton>
  );
};

const LegacyHeader = ({
  isHarness, location, device, lite,
}) => (
  <ADCPageHeader
    showLogo={!isHarness}
    headerText={isHarness && getNavTitle(location.pathname)}
    cancel={isHarness && ExitButton(location.pathname)}
    back={isHarness && BackButton(device, location.pathname)}
  >
    {!lite && (
    <a
      className="sign-out button--text button"
      href={!isHarness && `/oauth/disconnect?continue=${encodeURIComponent(oauthLogoutUrl)}`}
      aria-disabled={isHarness}
    >
      Sign Out
    </a>
    )}
  </ADCPageHeader>
);

export default connect(state => ({ lite: !!state.auth?.macaroon?.lite }))(LegacyHeader);
