import throttle from 'lodash.throttle';
import { forceAuth } from 'maw-fetch';

import apiClient from '../middleware/apiClient';
import { SESSION, EXTEND_SESSION } from '../helpers/apis';
import { setAccountIndex } from '../helpers/account';
import { flagEnabled } from '../helpers/featureFlags';

export const GET_SESSION = 'GET_SESSION';
export const GET_SESSION_SUCCESS = 'GET_SESSION_SUCCESS';
export const GET_SESSION_FAILURE = 'GET_SESSION_FAILURE';

export const SESSION_EXTEND = 'SESSION_EXTEND';
export const SESSION_EXTEND_SUCCESS = 'SESSION_EXTEND_SUCCESS';
export const SESSION_EXTEND_FAILURE = 'SESSION_EXTEND_FAILURE';

let forceAuthTimer;
let checkAuthTimer;

function dispatchGetSession() {
  return {
    type: GET_SESSION,
    payload: apiClient.fetch(SESSION),
  };
}

function dispatchGetSessionSuccess(payload) {
  return {
    type: GET_SESSION_SUCCESS,
    payload,
  };
}

function dispatchGetSessionFailure(error) {
  return {
    type: GET_SESSION_FAILURE,
    payload: error,
  };
}

function dispatchExtendSession() {
  return {
    type: SESSION_EXTEND,
    payload: apiClient.fetch(EXTEND_SESSION),
  };
}

function dispatchExtendSessionSuccess(payload) {
  return {
    type: SESSION_EXTEND_SUCCESS,
    payload,
  };
}

function dispatchExtendSessionFailure(error) {
  return {
    type: SESSION_EXTEND_FAILURE,
    payload: error,
  };
}

export const getSession = () => async (dispatch, getState) => {
  const { auth: { crsId } = {} } = getState();
  const isConsent = flagEnabled('consent.enabled') && crsId;

  if (isConsent) {
    return { status: 'CONSENT' };
  }
  try {
    const response = await dispatch(dispatchGetSession()).payload;
    const { session = {} } = response;
    const { auth = {} } = getState();
    if (auth.status && auth.status !== session.status) {
      // If the auth status has changed during the session, then reload the app.
      window.location.reload();
    }
    dispatch(dispatchGetSessionSuccess(response));
    return session;
  } catch (error) {
    dispatch(dispatchGetSessionFailure(error));
    throw error;
  }
};

export const checkSessionTimeout = (session = false) => async (dispatch) => {
  const sessionData = session || await dispatch(getSession());
  // eslint-disable-next-line camelcase
  const { time_left } = sessionData;

  clearTimeout(forceAuthTimer);
  clearTimeout(checkAuthTimer);
  const timeLeftInMilliseconds = parseInt(time_left, 10) * 1e3;

  if (timeLeftInMilliseconds <= 0) {
    return forceAuth();
  }
  // Check the session again when we suspect it will soon expire.
  if (timeLeftInMilliseconds >= 6e4) {
    checkAuthTimer = setTimeout(
      () => dispatch(checkSessionTimeout()),
      timeLeftInMilliseconds - 6e4,
    );
  }
  // Check the session again when we suspect it is expired.
  forceAuthTimer = setTimeout(() => dispatch(checkSessionTimeout()), timeLeftInMilliseconds);
  return session;
};

const throttledExtendSession = throttle(
  async (dispatch, getState) => {
    // If there is a selected account, it should stay selected for a rolling 24 hour period,
    // so update the cookie each time the customer interacts to trigger extendSession.
    const { auth: { accountIndex } } = getState();
    setAccountIndex(accountIndex);

    try {
      const response = await dispatch(dispatchExtendSession()).payload;
      // Call checkSessionTimeout after attempting to extend to check if the session is still valid.
      await dispatch(checkSessionTimeout());

      return dispatch(dispatchExtendSessionSuccess(response));
    } catch (error) {
      dispatch(dispatchExtendSessionFailure(error));
      throw error;
    }
  },
  60e3, // 60 second throttle duration
  { leading: true, trailing: false },
);

// To be properly throttled, we need the thunk to return the throttled action.
// Using the following approach:
//    const extendSession = () => throttle( async (dispatch) => { /* action! */ } )
// creates a _new_ throttled action each time extendSession is called - not great Bob.
// Hence, the throttled action is bound outside the scope of extendSession and used here.
export const extendSession = () => throttledExtendSession;
