import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { joinClass } from '../helpers/component';

import { messageShown } from '../actions/userMessages';

class ErrorMessage extends Component {
  constructor() {
    super();
    this.formErrorRef = createRef(null);
  }

  componentDidMount() {
    const {
      handleMessageShown,
    } = this.props;

    handleMessageShown();
  }

  componentDidUpdate() {
    const { error } = this.state || {};
    if (!error) return;
    this.formErrorRef.current && this.formErrorRef.current.focus();
  }

  render() {
    const {
      errorMessage,
      id,
      className,
    } = this.props;

    return (
      <p
        className={joinClass('form-control__error', className)}
        role="alert"
        id={id}

      >
        <span
          className="visuallyhidden"
          ref={this.formErrorRef}
          // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
          tabIndex="0"
        >
          {'Error: '}

        </span>
        {errorMessage}
      </p>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  handleMessageShown: bindActionCreators(messageShown, dispatch),
});

export default connect(null, mapDispatchToProps)(ErrorMessage);
