import KibanaLogger from '@xfinity/kibanajs';
import { flagEnabled } from './featureFlags';

let guid = '';

const kibana = KibanaLogger.initialize({
  component: 'maw_bill_pay',
  sessionID: (window.MAW && window.MAW.sessionID) ? window.MAW.sessionID : 'unknown',
});

export const setGuid = (currentGuid) => {
  guid = currentGuid;
};

export const kibanaLog = (en, data = {}) => {
  if (flagEnabled('kibana.enabled') && flagEnabled('global.kibana.enabled')) {
    kibana.log(en, { ...data, guid });
  }
};

export const kibanaErrorLog = (data = {}) => {
  if (flagEnabled('kibana.enabled') && flagEnabled('global.kibana.enabled')) {
    kibana.errorLog({ ...data, guid });
  }
};

export const kibanaServiceLog = (data = {}) => {
  if (flagEnabled('kibana.enabled') && flagEnabled('global.kibana.enabled')) {
    kibana.serviceLog({ ...data, guid });
  }
};

const stacktraceFromException = errorObj => errorObj.stack
                                            || errorObj.backtrace
                                            || errorObj.stacktrace;

function generateStacktrace() {
  let generated;
  let stacktrace;

  const MAX_FAKE_STACK_SIZE = 10;
  const ANONYMOUS_FUNCTION_PLACEHOLDER = '[anonymous]';
  const FUNCTION_REGEX = /function\s*([\w\-$]+)?\s*\(/i;

  // Try to generate a real stacktrace (most browsers, except IE9 and below).
  try {
    throw new Error('');
  } catch (exception) {
    generated = '<generated>';
    stacktrace = stacktraceFromException(exception);
  }

  // Otherwise, build a fake stacktrace from the list of method names by
  // looping through the list of functions that called this one (and skip
  // whoever called us).
  if (!stacktrace) {
    generated = '<generated-ie>';
    let functionStack = [];
    try {
      // eslint-disable-next-line no-caller, no-restricted-properties
      let curr = arguments.callee.caller.caller;
      while (curr && functionStack.length < MAX_FAKE_STACK_SIZE) {
        const fn = FUNCTION_REGEX.test(curr.toString())
          ? (RegExp.$1 || ANONYMOUS_FUNCTION_PLACEHOLDER)
          : ANONYMOUS_FUNCTION_PLACEHOLDER;
        functionStack.push(fn);
        curr = curr.caller;
      }
    } catch (e) {
      functionStack = e;
    }
    stacktrace = functionStack.join('|');
  }
  return generated + stacktrace;
}

window.onerror = (errorMsg, script, line, column, errorObj) => kibanaErrorLog({
  name: (errorObj && errorObj.name) || 'window.onerror',
  stacktrace: (errorObj && stacktraceFromException(errorObj)) || generateStacktrace(),
  ...(errorMsg && { error_message: errorMsg }),
  ...(script && { script }),
  ...(line && { line }),
  ...(column && { column }),
});

export const logger = (name) => {
  let enabled = false;

  const l = ['log', 'info', 'error', 'debug', 'warn'].reduce((c, key) => {
    // eslint-disable-next-line no-param-reassign, no-console
    c[key] = (...args) => enabled && console[key].call(console, `[${name}]`, ...args);
    return c;
  }, {
    enable(v) {
      enabled = v;
      return l;
    },
  });
  return l;
};
