import {
  GET_PAYMENT_PLAN,
  GET_PAYMENT_PLAN_SUCCESS,
  GET_PAYMENT_PLAN_FAILURE,
  RECALC_PAYMENT_PLAN,
  RECALC_PAYMENT_PLAN_SUCCESS,
  RECALC_PAYMENT_PLAN_FAILURE,
  REVIEW_PAYMENT_PLAN,
} from '../actions/paymentPlan';

import {
  REVIEW_PAYMENT,
} from '../actions/payment';

import {
  POST_PAYMENT_PLAN,
  POST_PAYMENT_PLAN_SUCCESS,
} from '../actions/exitXap';

const INITIAL_STATE = {
  loading: true,
  options: [],
};

export default function paymentPlanReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_PAYMENT_PLAN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_PAYMENT_PLAN_SUCCESS:
      return {
        ...state,
        data: action.payload,
        minimum: action.payload,
        loading: false,
      };
    case GET_PAYMENT_PLAN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        paymentPlanIneligible: action.payload.paymentPlanIneligible,
      };
    case RECALC_PAYMENT_PLAN:
      return {
        ...state,
        refreshing: true,
        refreshError: null,
      };
    case RECALC_PAYMENT_PLAN_SUCCESS:
      return {
        ...state,
        data: action.payload,
        refreshing: false,
      };
    case RECALC_PAYMENT_PLAN_FAILURE:
      return {
        ...state,
        refreshing: false,
        refreshError: action.payload,
      };
    case REVIEW_PAYMENT_PLAN:
      return {
        ...state,
        review: action.payload,
      };
    case REVIEW_PAYMENT:
      return {
        ...state,
        review: undefined,
      };
    case POST_PAYMENT_PLAN:
      return {
        ...state,
        submitting: true,
        submitError: null,
      };
    case POST_PAYMENT_PLAN_SUCCESS:
      return {
        ...state,
        review: null,
        confirm: action.payload,
        submitting: false,
      };
    default:
      return state;
  }
}
