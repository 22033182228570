import React, { useCallback } from 'react';
import { connect } from 'react-redux';

import XfinityAssistantIcon from './svgs/XfinityAssistant';
import { openInNewWindow } from '../helpers/externalLinks';
import { XA_BILLING_INTENT } from '../helpers/intents';
import getConfig from '../config';
import * as channelTracking from '../actions/channelTracking';

const { xaUrl } = getConfig();

export default connect(null, channelTracking)(({
  intent = XA_BILLING_INTENT,
  sendChannelTracking,
}) => {
  const onXaClick = useCallback((event) => {
    const rv = openInNewWindow(event);
    sendChannelTracking({
      id: 'TRANSFERRED',
      metadata: { targetChannel: 'XA' },
    });
    return rv;
  }, [sendChannelTracking]);

  return (
    <div className="card card--mini-message">
      <div className="card__icon">
        <XfinityAssistantIcon
          className="svg-icon svg-icon--34"
          color1="#1f232e"
        />
      </div>
      <div className="card__content">
        <div className="card__content-inner">
          <p className="mb0">Want to make changes to your service?</p>
        </div>

        <div className="card__action">
          <a
            href={`${xaUrl}/?intent=${encodeURIComponent(intent)}`}
            target="_blank"
            rel="noopener noreferrer"
            onClick={onXaClick}
          >
            Ask Xfinity
          </a>
        </div>
      </div>
    </div>
  );
});
