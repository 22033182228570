import React, { Component } from 'react';
import { PageSection, IconCircleExclamation, HarnessExitButton } from 'adc-ui-components';
import { kibanaLog } from '../../helpers/logger';
import getConfig from '../../config';

import PageTitle from '../../components/PageTitle';

const { homeDomain } = getConfig();

class PermsError extends Component {
  componentDidMount() {
    const { error } = this.props;
    kibanaLog(error);
  }

  render() {
    const { isLite, isHarness, error } = this.props;

    const errorMapping = {
      perms_error: 'As a secondary user, you cannot access restricted areas of My Account',
    };

    const friendlyError = errorMapping[error];

    return (
      <PageSection className="ui-grey page-section--banner">
        <IconCircleExclamation className="mb6 svg-icon--42" />

        <div className="hgroup">
          <PageTitle>Restricted Section</PageTitle>
          {friendlyError && <p>{friendlyError}</p>}
        </div>

        {!isLite && !isHarness && <a className="button button--primary" href={`https://${homeDomain}/#/`}>Back to My Account</a>}
        {!isLite && isHarness && <HarnessExitButton>Back to My Account</HarnessExitButton>}
        {isLite && <a className="button button--primary" href={`https://${homeDomain}/secure/myaccount`}>Sign in to My Account</a>}
      </PageSection>
    );
  }
}

export default PermsError;
