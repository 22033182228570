import React from 'react';
import { Link } from 'react-router-dom';
import { COLLECTIONS, PAYMENT_PLAN } from '../helpers/routes';
import getConfig from '../config';
import Card from './Card';
import { flagEnabled } from '../helpers/featureFlags';
import withCmsContent from '../helpers/withCmsContent';

const { paymentPlanUrl } = getConfig();

const InstallmentPlanBanner = ({
  isHarness,
  className = false,
  content,
  isLtip = false,
}) => {
  const xapExitPlanEnabled = flagEnabled('xapExitPlanEnabled');

  if (!xapExitPlanEnabled) {
    return null;
  }
  const ctaText = content('ctaText');
  return (
    <Card className={className} severity="warning">
      {content('text')}
      {' '}
      {isHarness && <a href={paymentPlanUrl} target="_blank" rel="noopener noreferrer">{ctaText}</a>}
      {!isHarness && <Link to={isLtip ? COLLECTIONS : PAYMENT_PLAN}>{ctaText}</Link>}
    </Card>
  );
};

export default withCmsContent('content', {
  'ltip-payment-plan': 'banner',
})(InstallmentPlanBanner);
