import React, { Component } from 'react';
import { Field } from 'formik';

import InputField from '../form-elements/InputField';
import { normalizeDollarAmount } from '../../helpers/validation/amount';
import { bindListeners } from '../../helpers/component';
import { storeSelection, completeSelection } from '../../helpers/selection';

class PaymentAmountField extends Component {
  constructor() {
    super();
    bindListeners(this, [
      'trackFocusState',
      'storeSelection',
    ]);

    this.state = {
      focused: false,
    };
  }

  storeSelection(event) {
    return storeSelection(this, event, /[$.\s]/g);
  }

  trackFocusState() {
    this.setState({
      focused: true,
    });
  }

  render() {
    const {
      setFieldValue,
      setFieldError,
      model,
      ...props
    } = this.props;

    const {
      focused,
    } = this.state;

    const normalizeAmount = (value) => {
      if (/[^$0-9.]/.test(value)) {
        setFieldError(model, 'Please enter numeric characters only');
      }

      const result = normalizeDollarAmount(value);
      completeSelection(this, model, value, result);
      return result;
    };

    return (
      <Field
        id={model}
        name={model}
        className={`${model} ${model}__condensed`}
        component={InputField}
        placeholder={focused ? '$' : '$0.00'}
        type="text"
        onChange={(e) => {
          setFieldValue(model, normalizeAmount(e.target.value));
        }}
        onFocus={this.trackFocusState}
        {...props}
      />
    );
  }
}

export default PaymentAmountField;
