import React, { useEffect, useState } from 'react';
import sanitizeHtml from 'sanitize-html';
import featureFlags, { featureStatus, getShingledFeatures } from '../helpers/featureFlags';
import PushdownShingle from './PushdownShingle';
import ModalShingle from './ModalShingle';
import useLocalState from '../hooks/useLocalState';

const sanitizer = (allowedTags, Container) => (html) => {
  const props = {
    dangerouslySetInnerHTML: {
      __html: sanitizeHtml(html, { allowedTags }),
    },
  };
  return <Container {...props} />;
};

const sanitizeBlock = sanitizer(sanitizeHtml.defaults.allowedTags.concat(['img']), 'div');
const sanitizeInline = sanitizer(['b', 'i', 'em', 'strong', 'a'], 'span');

const patternToRegExp = pattern => new RegExp(
  pattern.trim()
    .replace(/\*\*/g, '__any__')
    .replace(/\*/g, '__one__')
    .replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&')
    .replace(/__any__/g, '.+')
    .replace(/__one__/g, '[^/]+'),
);

const FeatureShingle = ({ path, location }) => {
  const {
    disabledType = 'pushdown',
    // List of available icons varies by app
    disabledIcon: icon,
    // Allows inline raw HTML; will be sanitized.
    disabledHeadline,
    // Allows block-level raw HTML; will be sanitized.
    disabledMessage,
    // Whether the user can dismiss the shingle.  Defaults to true.
    // WARNING: setting a modal to non-dismissable effectively disables the site
    //  when the feature's off
    disabledDismissable: dismissable,
    // which tracking-module ID to use when showing the shingle
    disabledTracking: tracking,
    // link the user can go to
    disabledAction: action,
    // Text for the link; plain text only
    disabledActionText: actionText,
    // Class for the link
    disabledActionClass: actionClass = 'button button--primary',
    // Comma-separated list of simple path globs where the shingle should be displayed
    //  default is all pages.
    //  `**` globs any characters
    //  `*` globs any but path separator
    disabledPaths,
    featurePath,
  } = featureStatus(path);
  const [dismissed, setDismissed] = useLocalState(`${featurePath}_dismissed`, false);
  const appropriate = !(disabledPaths && disabledPaths.trim()) || disabledPaths.split(',').reduce((r, p) => (
    r || patternToRegExp(p).test(location.pathname)
  ), false);
  if (dismissed || !appropriate) return null;
  const headline = sanitizeInline(disabledHeadline);
  const message = sanitizeBlock(disabledMessage);
  const dismiss = dismissable && (() => setDismissed(true));

  const shingle = {
    icon, headline, message, tracking, dismiss, action, actionText, actionClass,
  };

  if (disabledType === 'pushdown') return <PushdownShingle {...shingle} />;

  if (disabledType === 'modal') return <ModalShingle {...shingle} />;

  return null;
};

const FeatureShingles = ({ location }) => {
  const [flagsReady, setFlagsReady] = useState(false);
  useEffect(() => {
    featureFlags.onReady(() => setFlagsReady(true));
  }, []);
  return (
    flagsReady
      ? getShingledFeatures().map(path => (
        <FeatureShingle key={path} path={path} location={location} />
      ))
      : null
  );
};

export default FeatureShingles;
