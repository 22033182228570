import apiClient from '../middleware/apiClient';
import { getIsXapExit } from '../helpers/xap';
import { p2pProps } from '../helpers/p2p';
import { getIsToday } from '../helpers/date';
import { dispatchSendTransaction } from './adobe';

import {
  PAYMENT_PLAN_SIGNUP,
} from '../helpers/apis';

// post payment plan
export const POST_PAYMENT_PLAN = 'POST_PAYMENT_PLAN';
export const POST_PAYMENT_PLAN_SUCCESS = 'POST_PAYMENT_PLAN_SUCCESS';

function dispatchPostPaymentPlan(data) {
  return {
    type: POST_PAYMENT_PLAN,
    payload: apiClient.fetch(PAYMENT_PLAN_SIGNUP, {
      method: 'POST',
      body: JSON.stringify(data),
    }),
  };
}

function dispatchPostPaymentPlanSuccess(payload) {
  return {
    type: POST_PAYMENT_PLAN_SUCCESS,
    payload,
  };
}

// Just up to a year?
const numberMap = [
  'zero', 'one', 'two', 'three', 'four',
  'five', 'six', 'seven', 'eight', 'nine',
  'ten', 'eleven', 'twelve',
];

export const exitXapIfEligible = () => async (dispatch, getState) => {
  const {
    account: { account },
    payment: { review, review: { amount, date } = {} } = {},
    bill: { bill, bill: { summary: { pastDueBalanceRemaining } = {} } = {} } = {},
    paymentPlan: {
      review: { installmentPlan: plan, billDueDate } = {},
    } = {},
  } = getState();
  const payInfull = !!(
    (amount && parseFloat(amount) >= pastDueBalanceRemaining)
    && (!date || getIsToday(date))
  );
  const installmentPlan = payInfull ? undefined : plan;
  const { isP2P, qualifies } = p2pProps({ account, bill, review });
  const p2p = !installmentPlan && !payInfull && !!isP2P && !!qualifies;
  const eligibleForPlan = getIsXapExit(account) || account.xap.ltip === 'ELIGIBLE';
  if (eligibleForPlan && (payInfull || p2p || installmentPlan)) {
    const payload = {
      installmentPlan,
      payInfull,
      p2p,
    };

    // no try catch because we should always return success
    await dispatch(dispatchPostPaymentPlan(payload)).payload.catch(() => Promise.resolve());

    // Send an SST regarding the mode of XAP exit, if it's via installment.
    if (installmentPlan) {
      const { numberOfPayments } = installmentPlan;
      dispatch(dispatchSendTransaction({
        name: 'self service transaction',
        action: 'self service transaction',
        transaction: `billing:xap installment:${numberMap[numberOfPayments]} months:signup`,
      }));
    }

    return dispatch(dispatchPostPaymentPlanSuccess({
      installmentPlan,
      billDueDate,
    }));
  }
  return null;
};
