import {
  GET_SESSION_SUCCESS,
  GET_SESSION_FAILURE,
} from '../actions/session';

const INITIAL_STATE = {
  timeLeft: 900,
};

const reducers = {
  [GET_SESSION_SUCCESS]: (state, { payload }) => ({
    ...state,
    timeLeft: parseInt(payload.session.time_left, 10),
  }),
  [GET_SESSION_FAILURE]: state => ({
    ...state,
    timeLeft: 0,
  }),
};

export default function sessionReducer(state = INITIAL_STATE, action) {
  return (
    reducers[action.type]
      ? reducers[action.type](state, action)
      : state
  );
}
