import React from 'react';
import { PageSection, IconCheckGreen } from 'adc-ui-components';
import getConfig from '../../config';

import PageTitle from '../../components/PageTitle';

const { billServicesUrl } = getConfig();

const CancelConfirm = () => (
  <PageSection tracking-module="autopay-settings" className="ui-grey page-section--banner">
    <IconCheckGreen className="mb6 svg-icon--42" />
    <PageTitle>Automatic payments have been turned off.</PageTitle>
    <a className="button button--primary" href={billServicesUrl}>Back to Billing</a>
  </PageSection>
);

export default CancelConfirm;
