import React, { Component, createRef } from 'react';
import { FormControl } from 'adc-ui-components';
import { Field } from 'formik';

import Modal from '../Modal';
import IconCircleI from '../svgs/CircleI';
import InputField from '../form-elements/InputField';
import FirstLastName from './FirstLastName';
import ExpirationFields from './ExpirationFields';

import getConfig from '../../config';
import { bindListeners } from '../../helpers/component';
import vAll from '../../helpers/validation/all';
import vCardNumber from '../../helpers/validation/cardNumber';
import { MinFactory } from '../../helpers/validation/minMax';
import { RequiredFactory } from '../../helpers/validation/required';
import { onlyDigits, hasNonDigit } from '../../helpers/validation/nonDigit';
import { storeSelection, completeSelection } from '../../helpers/selection';

import Visa from '../svgs/Visa';
import MasterCard from '../svgs/MasterCard';
import AmericanExpress from '../svgs/AmericanExpress';
import Discover from '../svgs/Discover';
import { getPartialPaymentCardType, normalizeCard } from '../../helpers/paymentInstrument';

const { cwaRoot } = getConfig();
const vRequired = RequiredFactory();
const vMinLength = MinFactory();

class CardFields extends Component {
  constructor() {
    super();
    bindListeners(this, [
      'clearErrors',
      'openModal',
      'closeModal',
      'storeSelection',
    ]);
    this.cardWarningRef = createRef(null);

    this.state = {
      showError: false,
      cardType: undefined,
      modalIsOpen: false,
    };
    this.selections = {};
  }

  storeSelection(event) {
    // Store the user's selection.
    return storeSelection(this, event, /[\s-]/g);
  }

  clearErrors() {
    this.setState(state => ({
      ...state,
      showError: false,
    }));
  }

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  renderModal() {
    const { modalIsOpen } = this.state;

    return (
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={this.closeModal}
        contentLabel="Card CVV"
      >
        <div tracking-module="cvv">
          <p className="mb24 body2">CVV is an anti-fraud security feature to help verify that you are in possession of your credit card.</p>

          <div className="mb24 text-center">
            <img src={`${cwaRoot}svg/cvv-example.svg`} width="260" height="127" alt="CVV" />
          </div>

          <p className="mb24 body2">
            <b>Visa, Discover, Mastercard:</b>
            <br />
            The 3-digit number on the back of your card.
            <br />
            <b>American Express:</b>
            <br />
            The 4-digit number on the front, just above the card number.
          </p>

          <div className="action action--centered">
            <div className="action__item">
              <button type="button" className="button button--primary" onClick={this.closeModal}>Done</button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }

  render() {
    const {
      cardType,
      showError,
      error,
      cardWarningRef,
    } = this.state;

    const {
      setFieldValue,
      required = true,
    } = this.props;
    const cvvLen = cardType === 'American Express' ? 4 : 3;
    const normalizeCvv = (value) => {
      if (hasNonDigit(value)) {
        this.cardWarningRef.current && this.cardWarningRef.current.focus();
        this.setState(state => ({
          ...state,
          showError: 'cvv',
          error: 'Please enter numeric characters only',
        }));
      } else if (value.length > cvvLen) {
        this.cardWarningRef.current && this.cardWarningRef.current.focus();
        this.setState(state => ({
          ...state,
          showError: 'cvv',
          error: `Cannot exceed ${cvvLen} digits`,
        }));
      }
      const result = onlyDigits(value).substring(0, cvvLen);
      if (result === value) {
        this.clearErrors();
      }
      // Restore the user's selection after the value is changed.
      completeSelection(this, 'cvv', value, result);
      return result;
    };

    // Normalizes and format's the card number as the user enters it
    const normalizeCardNumber = (value) => {
      if (/[^\d\-\s]/.test(value)) {
        this.cardWarningRef.current && this.cardWarningRef.current.focus();
        this.setState(state => ({
          ...state,
          showError: 'cardNumber',
          error: 'Please enter numeric characters only',
        }));
      } else {
        this.clearErrors();
      }

      const partialPaymentCardType = getPartialPaymentCardType(value);

      let maxLen = 16;

      if (partialPaymentCardType === 'Visa' || partialPaymentCardType === 'Discover') {
        maxLen = 19;
      } else if (partialPaymentCardType === 'American Express') {
        maxLen = 15;
      }

      if (value.replace(/[^\d+]/g, '').length > maxLen) {
        this.cardWarningRef.current && this.cardWarningRef.current.focus();
        this.setState(state => ({
          ...state,
          showError: 'cardNumber',
          error: partialPaymentCardType
            ? `${partialPaymentCardType} number cannot exceed ${maxLen} digits`
            : `Cannot exceed ${maxLen} digits`,
        }));
      }

      this.setState(state => ({
        ...state,
        cardType: partialPaymentCardType,
      }));

      const result = normalizeCard(value);
      // Smartly restores the user's selection after the value is changed
      completeSelection(this, 'cardNumber', value, result);

      return result;
    };

    return (
      <>
        {this.renderModal()}

        <FirstLastName required={required} />

        <div className="form-control-group form-control-group--flex-at-1024">
          <div className="form-control-group__item">
            <FormControl label="Card number" inputId="cardNumber">
              <Field
                id="cardNumber"
                name="cardNumber"
                component={InputField}
                className={showError === 'cardNumber' && 'error'}
                type="text"
                validate={vAll(
                  required && vRequired('Please enter a valid card number'),
                  vCardNumber,
                )}
                onChange={(e) => {
                  setFieldValue('cardNumber', normalizeCardNumber(e.target.value));
                }}
                onKeyDown={this.storeSelection}
                onBlur={this.clearErrors}
                aria-describedby="validCardNumber"
              />
              <span className="card-type">
                { cardType === 'Visa' && <Visa /> }
                { cardType === 'MasterCard' && <MasterCard /> }
                { cardType === 'American Express' && <AmericanExpress /> }
                { cardType === 'Discover' && <Discover /> }
              </span>
              {showError === 'cardNumber' && (
                /* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */
                <span className="form-control__error" tabIndex="0" role="alert" ref={cardWarningRef}>{error}</span>
              )}
            </FormControl>
          </div>

          <div className="form-control-group__item">
            <div className="form-control-group form-control-group--flex-at-768 mb0">
              <div className="form-control-group__item form-control-group__item--2of3-at-1024">
                <ExpirationFields required={required} />
              </div>

              <div className="form-control-group__item form-control-group__item--1of3-at-1024">
                <FormControl
                  className="form-control--cvv"
                >
                  <div className="form-control__label">
                    <label htmlFor="cvv" className="d-inline-block">
                      Security code
                    </label>
                    <button
                      className="button button--text"
                      type="button"
                      onClick={this.openModal}
                    >
                      <IconCircleI />
                      <span className="visuallyhidden">More information about security code</span>
                    </button>
                  </div>
                  <Field
                    id="cvv"
                    name="cvv"
                    component={InputField}
                    className={showError === 'cvv' && 'error'}
                    type="text"
                    aria-describedby="cardValidCvv"
                    validate={vAll(
                      required && vRequired('Please enter a valid Security code'),
                      vMinLength(3, `Code must be ${cvvLen === 3 ? '3' : 'at least 3'} digits`),
                    )}
                    onChange={(e) => {
                      setFieldValue('cvv', normalizeCvv(e.target.value));
                    }}
                    onKeyDown={this.storeSelection}
                    onBlur={this.clearErrors}
                  />
                  {showError === 'cvv' && (
                    /* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */
                    <span className="form-control__error" tabIndex="0" role="alert" ref={cardWarningRef}>{error}</span>

                  )}
                </FormControl>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default CardFields;
