import React from 'react';
import { PageSection } from 'adc-ui-components';
import IconCircleExclamationRed from '../../components/svgs/IconCircleExclamationRed';

import PageTitle from '../../components/PageTitle';

import { NEW_PAYMENT } from '../../helpers/routes';

const AuthenticationRequired = () => {
  const newPaymentHref = new URL(NEW_PAYMENT, window.location.origin).toString();
  const forceLoginUrl = `/oauth/force_connect/?continue=${encodeURIComponent(newPaymentHref)}`;
  return (
    <>
      <PageSection className="ui-grey page-section--banner">
        <IconCircleExclamationRed className="mb12 svg-icon--42" />

        <div className="hgroup">
          <PageTitle className="mb12">Sign in to complete your payment</PageTitle>
          <p>
            To prove you&apos;re you, sign in to help protect your account.
            {' '}
            If you forget your Xfinity password, you can reset it by visiting
            {' '}
            <a href="https://www.xfinity.com/password">xfinity.com/password</a>
            .
          </p>
        </div>
        <a className="button" href="https://www.xfinity.com/overview">Back</a>
        <a className="button button--primary" href={forceLoginUrl}>Sign in</a>
      </PageSection>
    </>
  );
};
export default AuthenticationRequired;
