import React from 'react';
import dateformat from 'dateformat';

import Card from '../Card';
import { formatCurrency } from '../../helpers/formatText';
import { addDays, createDateObject } from '../../helpers/date';
import { getIsXapCurrent, getIsXapExit, getIsXapExitInProgress } from '../../helpers/xap';

const card = severity => (message, raw) => {
  if (raw) {
    return { message, severity };
  }
  return (
    <Card severity={severity}>
      <p className="form-control__warning">{message}</p>
    </Card>
  );
};
const green = card('success');
const orange = card('warning');
const red = card('danger');

export const moreTime = `
  If you need more time, you can set up a payment arrangement
`;

export const paymentDateOk = ({ qualifyingDate }) => green(`
  This payment date qualifies you for a payment arrangement.
  ${qualifyingDate
    ? `
      If you need more time, you can select a date on
      or before ${dateformat(qualifyingDate, 'mmmm d, yyyy')}.
    `
    : ''}
`);

export const existingPromiseDateWarn = ({
  softDisconnected,
  existingPromiseDate,
  pastDue,

}) => {
  const amount = formatCurrency(pastDue);
  const date = dateformat(existingPromiseDate, 'mmm d');
  return red(`You've arranged to pay by ${date}. Pay your full past due balance of ${amount} by ${date} to ${
    softDisconnected
      ? 'restore your service.'
      : 'avoid possible service suspension.'
  }`);
};

export const paymentDateWarn = ({
  p2pDate,
  laterDate,
  p2pMinAmount,
  laterAmount,
}) => {
  if (laterDate) {
    const nextDay = addDays(p2pDate, 1);
    const maxDay = createDateObject(laterDate);
    const fullDate = nextDay.getMonth() === maxDay.getMonth()
      ? `${dateformat(nextDay, 'mmmm d')}-${maxDay.getDate()}`
      : `${dateformat(nextDay, 'mmmm d')} - ${dateformat(maxDay, 'mmmm d')}`;
    return red(`
      This payment amount and date does not qualify for a payment arrangement.
      Pay ${formatCurrency(p2pMinAmount)} by ${dateformat(p2pDate, 'mmmm d')}, or schedule a payment
      for ${formatCurrency(laterAmount)} and extend your due date to ${fullDate}.
    `);
  }
  return red(`
    To set up a payment arrangement, schedule a payment on or before
    ${dateformat(p2pDate, 'mmmm d, yyyy')}.
  `);
};

export const cancelWarningDisconnect = orange(
  'If you cancel this payment arrangement, your service will be disconnected.',
);

export const cancelWarningNoRestore = orange(
  'If you cancel this payment arrangement, your service will not be restored.',
);

export const cancelWarning = ({ softDisconnected }) => (
  softDisconnected
    ? cancelWarningNoRestore
    : cancelWarningDisconnect
);

export const paymentSuccessfulTitle = 'Your payment was successful!';
export const paymentArrangementTitle = 'Your payment arrangement is all set!';
export const partialPaymentArrangementTitle = 'Thanks for setting up a partial payment arrangement!';
export const thanksNoDisconnect = 'Thank you!  Your service will not be disconnected.';
export const thanksRestored = 'Thank you!  Your service will be restored shortly';
export const thanksXapExit = 'Your payment arrangement is all set and your next bill will reflect your original charges.';
export const thanksNoPPV = 'Please note you\'re unable to make On Demand or pay-per-view purchases until your payment arrangement is complete.';

export const thanks = ({ softDisconnected, xapExit, today }) => {
  if (xapExit) {
    return thanksXapExit;
  }
  if (softDisconnected) {
    if (!today) {
      return `${thanksRestored}. ${thanksNoPPV}`;
    }
    return thanksRestored;
  }
  if (!today) {
    return `${thanksNoDisconnect} ${thanksNoPPV}`;
  }
  return thanksNoDisconnect;
};

export const payMoreKeepAccess = ({ pastDue }) => `
  Please pay at least ${formatCurrency(pastDue)} today to keep access to your Xfinity
  service.
`;

export const payMoreReactivate = ({ pastDue }) => `
  Please pay at least ${formatCurrency(pastDue)} today to reactivate your Xfinity service.
`;

export const payMore = ({ softDisconnected, ...rest }) => (
  softDisconnected
    ? payMoreReactivate(rest)
    : payMoreKeepAccess(rest)
);

export const payRemainingKeep = ({ pastDue }) => `
  Pay the remaining balance of ${formatCurrency(pastDue)} to keep access to your Xfinity service.
`;

export const payRemainingReactivate = ({ pastDue }) => `
  Pay the remaining balance of ${formatCurrency(pastDue)} to reactivate your Xfinity service.
`;

export const payRemaining = ({ softDisconnected, ...rest }) => (
  softDisconnected
    ? payRemainingReactivate(rest)
    : payRemainingKeep(rest)
);

export const toReactivatePay = ({
  pastDue,
  balance,
  amount,
  raw,
}) => red(`
  To reactivate your service, please pay your full balance of
  ${formatCurrency((amount >= pastDue ? balance : pastDue))}
  as soon as possible.
`, raw);

export const avoidInterruptionPay = ({
  pastDue,
  balance,
  amount = 0,
  raw,
  account = {},
}) => {
  const relevantBalance = amount >= pastDue ? balance : pastDue;

  const isXapped = getIsXapCurrent(account)
    || getIsXapExit(account)
    || getIsXapExitInProgress(account);

  let message = `This payment is less than the minimum amount due. You'll need to pay an additional
    ${formatCurrency(relevantBalance - amount)} to avoid service suspension.
  `;

  if (isXapped) {
    message = `This payment is less than the minimum amount due. Please pay an additional
      ${formatCurrency(relevantBalance - amount)} now.
    `;
  }

  return red(message, raw);
};

export const avoidConsequencesPay = ({ softDisconnected, xapExit, ...rest }) => (
  softDisconnected && !xapExit
    ? toReactivatePay(rest)
    : avoidInterruptionPay(rest)
);
