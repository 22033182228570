import {
  GET_INTERNET_TIER,
  GET_INTERNET_TIER_SUCCESS,
  GET_INTERNET_TIER_FAILURE,
} from '../actions/internetTier';

const INITIAL_STATE = { loading: true, cached: false };

export default function internetTierReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_INTERNET_TIER:
      return {
        ...state,
        cached: true,
        loading: true,
      };
    case GET_INTERNET_TIER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        tierData: action.payload,
      };
    case GET_INTERNET_TIER_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };
    default:
      return state;
  }
}
