import React from 'react';
import { isNowPage } from '../../../helpers/now';

const { pathname } = window.location;

const PolarisFooter = () => (
  <xc-footer
    client-id="myAccountWeb"
    slim-footer
    {...(!isNowPage(pathname) && { theme: 'light' })}
  />
);

export default PolarisFooter;
