import React from 'react';
import { Loading, PageSection } from 'adc-ui-components';
import dateformat from 'dateformat';
import Card from './Card';
import GenericError from '../containers/Errors/GenericError';
import PageTitle from './PageTitle';

const Terms = ({
  loading,
  error,
  title,
  text,
  acceptedDateTime,
  isHarness,
}) => {
  if (error) {
    return <GenericError isHarness={isHarness} />;
  }
  if (loading) {
    return <Loading isHarness={isHarness} />;
  }
  return (
    <PageSection tracking-module="terms" classes="ui-grey hero">
      <div className="hgroup">
        <PageTitle>Terms and Conditions</PageTitle>
        {acceptedDateTime && (
          <p>
            Last agreed to on
            {' '}
            {dateformat(acceptedDateTime, 'mmmm d, yyyy')}
          </p>
        )}
      </div>
      <Card severity="secondary">
        <div className="link-group">
          <button type="button" onClick={() => window.print()} className="button--text do-not-print">
            Print &amp; Download
          </button>
        </div>
        <h2>{title}</h2>
        <p className="pre-line">
          {text}
        </p>
      </Card>
    </PageSection>
  );
};

export default Terms;
