import React, { useEffect, useRef } from 'react';

const PageTitle = (props) => {
  const {
    documentTitleRoot = 'XFINITY | Bill Pay',
    documentTitle = '',
    className = 'heading2',
    children,
  } = props;

  const pageTitleRef = useRef();

  useEffect(() => {
    const { current: pageTitle } = pageTitleRef;
    if (pageTitle) {
      pageTitle.focus();
    }
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    document.title = `${documentTitleRoot}${documentTitle}`;
  }, [documentTitleRoot, documentTitle]);

  return <h1 className={className} tabIndex="-1" ref={pageTitleRef}>{children}</h1>;
};

export default PageTitle;
