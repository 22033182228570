import React from 'react';
import dateformat from 'dateformat';

import Modal from '../Modal';
import withCmsContent from '../../helpers/withCmsContent';
import { addDays, createDateObject } from '../../helpers/date';
import { formatDateRange } from '../../helpers/ltip';
import { formatCurrency } from '../../helpers/formatText';

export default withCmsContent('content', {
  'collections-2': 'P2PUnqualified',
})(({
  content,
  closeModal,
  isOpen,
  bill: {
    promiseToPay: {
      customerEligibilityChecked: {
        eligibleForPromiseToPay,
        maxP2PDateBeforeCycle,
        minP2PAmtBeforeCycle,
        maxP2PDateAfterCycle,
        minP2PAmtAfterCycle,
      } = {},
    } = {},
  } = {},
}) => {
  if (!eligibleForPromiseToPay) return null;
  const earlyDate = createDateObject(maxP2PDateBeforeCycle);
  const lateStart = addDays(earlyDate, 1);
  const lateEnd = createDateObject(maxP2PDateAfterCycle);
  const contentData = {
    earlyAmount: formatCurrency(minP2PAmtBeforeCycle),
    earlyDate: earlyDate && dateformat(earlyDate, 'mmm d'),
    ...(lateEnd && {
      lateAmount: formatCurrency(minP2PAmtAfterCycle),
      lateDateRange: formatDateRange(lateStart, lateEnd),
    }),
  };
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
    >
      <div className="text-center">
        <h2>{content('heading', contentData)}</h2>
        <p>
          {lateEnd && content('description2', contentData)}
          {!lateEnd && content('description1', contentData)}
        </p>
        <div className="action">
          <button type="button" className="button button--primary" onClick={closeModal}>
            {content('closeModal', contentData)}
          </button>
        </div>
      </div>
    </Modal>
  );
});
