import { p2pProps } from '../../helpers/p2p';

import {
  cancelWarning,
  paymentDateWarn,
} from './messages';
import { flagEnabled } from '../../helpers/featureFlags';

const P2PConfirmNote = ({ children, ...props }) => {
  const def = children || null;
  const p2pMessaging = flagEnabled('p2pMessaging');
  if (!p2pMessaging) return def;
  const {
    isP2P,
    softDisconnected,
    today,
    p2pDate,
    p2pMinAmount,
    laterDate,
    laterAmount,
    qualifies,
  } = p2pProps(props);
  if (!isP2P) return def;
  if (qualifies) {
    if (today) return null;
    return cancelWarning({ softDisconnected });
  }
  return paymentDateWarn({
    p2pDate,
    p2pMinAmount,
    laterDate,
    laterAmount,
  });
};

export default P2PConfirmNote;
