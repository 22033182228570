import getConfig from '../config';

const { env } = getConfig();
let mockState;
let mock;
if (env !== 'prod') {
  mock = ({
    balance,
    pastDue,
    payment,
    days,
    disco = false,
    confirm = false,
    nullReq,
    nonP2P,
  } = {}) => {
    const when = new Date(new Date().setHours(12 + days * 24, 0, 0, 0));
    const billDate = new Date(new Date().setHours(12 - 7 * 24 - (disco ? (10 * 24) : 0)));
    const dueDate = new Date(new Date().setHours(12 - 5 * 24 - (disco ? (10 * 24) : 0)));
    const p2pDate = new Date(new Date().setHours(12 + 2 * 24));
    const maxP2PDate = new Date(new Date().setHours(12 + 12 * 24));
    const discoDate = new Date(new Date().setHours(12 + 3 * 24));
    const maxP2PDateBeforeCycle = nullReq === 'before'
      ? null
      : p2pDate.toISOString().substring(0, 10);
    const minP2PAmtBeforeCycle = nullReq === 'before' ? null : pastDue;
    return {
      bill: {
        summary: {
          billDateInMillis: +billDate,
          billDate: billDate.toISOString(),
          dueDateInMillis: +dueDate,
          dueDate: dueDate.toISOString(),
          autoPayDateInMillis: null,
          autoPayDate: null,
          autopayUnenrolledCause: null,
          autopayUnenrolledDate: null,
          autopayInstrumentStatus: null,
          promiseToPayDateInMillis: null,
          promiseToPayDate: null,
          softDisconnected: disco,
          softDisconnectedDate: discoDate.toISOString().substring(0, 10),
          statementAvailable: true,
          maxPaymentAmountErrorLimit: 1143.75,
          maxPaymentAmountWarningLimit: 228.75,
          previousBalance: 10,
          pastDueBalance: pastDue,
          pastDueBalanceRemaining: (days || !confirm) ? pastDue : Math.max(0, pastDue - payment),
          paymentReceived: (days || !confirm) ? 0 : payment,
          newCharges: balance - pastDue,
          statementBalance: balance,
          recentPayments: 0,
          pendingPayments: 0,
          balanceDue: (days || !confirm) ? balance : Math.max(0, balance - payment),
          disputeAmount: 0,
          status: 'BALANCE_PAST_DUE',
          customerGuaranteeCreditIssued: false,
          totalAmountDue: balance,
          firstBillPending: false,
          billType: 'BRITE',
          autoPayEnabled: false,
          autoPayValid: null,
          paymentRecieved: (days || !confirm) ? 0 : payment,
        },
        delinquency: {
          delinquencyStatus: 'DELINQUENT',
        },
        ...(!nonP2P && {
          promiseToPay: {
            requirements: [
              ...(nullReq !== 'before' ? [{
                date: p2pDate.toISOString().substring(0, 10),
                amount: pastDue,
              }] : []),
              ...(nullReq !== 'after' && nullReq !== 'sameDay' ? [{
                date: maxP2PDate.toISOString().substring(0, 10),
                amount: balance,
              }] : []),
            ],
            reasonsForNotEligible: [],
            existingPromiseToPayInfo: null,
            customerEligibilityChecked: {
              eligibleForPromiseToPay: true,
              maxP2PDateBeforeCycle,
              minP2PAmtBeforeCycle,
              ...(
                {
                  after: null,
                  sameDay: {
                    maxP2PDateAfterCycle: maxP2PDateBeforeCycle,
                    minP2PAmtAfterCycle: minP2PAmtBeforeCycle,
                  },
                }[nullReq] || {
                  maxP2PDateAfterCycle: maxP2PDate.toISOString().substring(0, 10),
                  minP2PAmtAfterCycle: nullReq === 'after' ? null : balance,
                }
              ),
              softDisconnected: disco,
              softDisconnectDate: discoDate.toISOString().substring(0, 10),
            },
          },
        }),
      },
      form: {
        date: when,
        amount: payment,
        instrument: {
          type: 'PaymentCard',
          cardType: 'Visa',
          last4DigitsCardNumber: '4552',
        },
      },
    };
  };

  const extensions = {
    // P2P type
    disco: { disco: true },
    late: { disco: false },
    nonP2P: { nonP2P: true },

    // Payment amount
    current: { payment: 146.09 },
    pastDue: { payment: 25.74 },
    // legacy alias for current
    full: { payment: 146.09 },
    partial: { payment: 20 },

    // Payment schedule
    today: { days: 0 },
    // in time for past-due payment
    inTime: { days: 1 },
    inTimePast: { days: 1 },
    // in time for full payment
    inTimeFull: { days: 4 },
    // too late for any p2p
    after: { days: 20 },

    // Requirement nulls
    noBefore: { nullReq: 'before' },
    noAfter: { nullReq: 'after' },
    sameDay: { nullReq: 'sameDay' },
  };

  const mockConfig = (stateName) => {
    const names = stateName.split('.');
    const ok = !names.find(name => !(name in extensions));
    if (!ok) {
      return null;
    }
    const base = {
      balance: 146.09,
      pastDue: 25.74,
    };
    return names.reduce((o, n) => ({ ...o, ...extensions[n] }), base);
  };

  mockState = env !== 'prod' && window.location.search.replace(/^\?/, '').split(/&/).reduce((o, e) => {
    const [name, ...rest] = e.split(/=/);
    const value = unescape(rest.join('='));
    if (name === 'payment_mock') {
      return { ...o, config: mockConfig(value) };
    }
    return o;
  }, {});
}

export default (
  (env !== 'prod' && mockState.config)
    ? (confirm) => {
      const state = mock({ ...mockState.config, confirm });
      if (!state) return null;
      return { ...state, confirm };
    }
    : () => null
);
