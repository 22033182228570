import {
  GET_LATE_FEE_CREDIT,
  GET_LATE_FEE_CREDIT_SUCCESS,
  GET_LATE_FEE_CREDIT_FAILURE,
  ENROLL_LATE_FEE_CREDIT,
  ENROLL_LATE_FEE_CREDIT_SUCCESS,
  ENROLL_LATE_FEE_CREDIT_FAILURE,
} from '../actions/latefeecredit';

const INITIAL_STATE = { loading: true };

export default function lateFeeCreditReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_LATE_FEE_CREDIT:
      return {
        ...state,
        loading: true,
      };
    case GET_LATE_FEE_CREDIT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        latefeecredit: action.payload,
      };
    case GET_LATE_FEE_CREDIT_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case ENROLL_LATE_FEE_CREDIT:
      return {
        ...state,
        loading: true,
        enrollFailure: false,
        enrollSuccess: false,
        error: false,
      };
    case ENROLL_LATE_FEE_CREDIT_SUCCESS:
      return {
        ...state,
        loading: false,
        enrollSuccess: true,
        latefeecredit: {
          ...state.latefeecredit,
          ...action.payload,
        },
      };
    case ENROLL_LATE_FEE_CREDIT_FAILURE:
      return {
        ...state,
        loading: false,
        enrollFailure: true,
        error: true,
      };
    default:
      return state;
  }
}
