import React from 'react';
import { IconCheckGreen } from 'adc-ui-components';
import IconCircleExclamationRed from './svgs/IconCircleExclamationRed';
import { getIsDisconnected } from '../helpers/account';

import { FORCE_LOGIN_URL, openInNewWindow, xaLinkWithLogin } from '../helpers/externalLinks';
import Pushdown from './Pushdown';

const LiteDropDown = ({
  account = {},
  bill: {
    summary = {},
    delinquency = {},
  } = {},
  macaroon = {},
}) => {
  const {
    accountNumber,
    fullAccountNumber,
    serviceAddress,
    status,
    xap: {
      state,
      xapExit,
    } = {},
  } = account;
  const {
    pastDueBalance,
    softDisconnected,
  } = summary;

  const { walledgarden, lite } = macaroon;

  const displayAccountNumber = fullAccountNumber || accountNumber;

  const isDisconnected = getIsDisconnected(status);
  const isDelinquent = delinquency && delinquency.delinquencyStatus === 'DELINQUENT';

  const isLtipEligible = (
    state === 'XAP_CURRENT'
    && xapExit === 'NOT_STARTED'
    && pastDueBalance > 0
    && !isDelinquent
    && !softDisconnected
  );
  const showP2PDrop = (
    (walledgarden || lite)
    && !isLtipEligible
    && !isDisconnected
    && (isDelinquent || softDisconnected)
  );
  let copy;

  if (isLtipEligible) {
    copy = {
      title: `We've confirmed your account number: ${displayAccountNumber}`,
      subtitle: 'Your bill is past due and your service will be affected',
      body: [
        `We'd like you to have some options.  Log in to pay the full balance, or make
        monthly installments in addition to your bill.`,
      ],
      icon: IconCircleExclamationRed,
      ctaText: null,
      ctaLink: FORCE_LOGIN_URL,
      ctaLinkText: 'Log in',
    };
  } else if (showP2PDrop) {
    copy = {
      title: 'Great! We\'ve confirmed your account',
      body: [
        `Account Number: ${displayAccountNumber}`,
      ],
      icon: IconCheckGreen,
      ctaText: 'Sign in and chat to see if you qualify for flexible payment options. ',
      ctaOnClick: openInNewWindow,
      ctaLink: xaLinkWithLogin('skill.billing.p2p'),
      ctaLinkText: 'Chat with Xfinity Assistant',
    };
  } else {
    copy = {
      title: 'Great! We\'ve confirmed your account',
      body: [
        `Account Number: ${displayAccountNumber}`,
        serviceAddress && `Service Address: ${serviceAddress}`,
      ],
      icon: IconCheckGreen,
      ctaText: null,
      ctaLink: FORCE_LOGIN_URL,
      ctaLinkText: 'Sign in to view full bill details and more',
    };
  }

  const cta = copy.ctaLink && copy.ctaLinkText && (
    <a href={copy.ctaLink} onClick={copy.ctaOnClick}>{copy.ctaLinkText}</a>
  );

  return (
    <Pushdown
      title={copy.title}
      icon={(<copy.icon className="svg-icon--42" />)}
    >
      {copy.subtitle && (<p className="body2">{copy.subtitle}</p>)}
      {copy.body && copy.body.map(item => item && <p className="body3" key={item}>{item}</p>)}
      {copy.ctaText && (
        <p className="body3">
          {copy.ctaText}
          <span>{cta}</span>
        </p>
      )}
      {!copy.ctaText && cta}
    </Pushdown>
  );
};

export default LiteDropDown;
