import React from 'react';
import dateFormat from 'dateformat';

import PaymentInstrument from '../components/PaymentInstrument';
import { titlecase, formatCurrency } from './formatText';
import { getPaymentCardType, cleanInstrumentNumber } from './paymentInstrument';
import encryptRsaOaep from './encryption';
import { cleanDollarAmount } from './validation/amount';
import { getIsToday } from './date';
import { COLLECTIONS, METHODS, PAYMENT_SETUP } from './routes';
import { getSearchState, sanitizeUrl } from './url';
import { flagEnabled } from './featureFlags';

const fixCardType = type => (type === 'American Express' ? 'AmericanExpress' : titlecase(type));

/**
 * Normalize a payment card such that
 *  - cardNumber contains no spaces or dashes
 *  - cardType is populated
 *  - expirationDate is derived from expirationMonth and expirationYear
 */
export const normalizeCard = ({
  cardNumber,
  cvv,
  expirationMonth,
  expirationYear,
}) => ({
  cvv,
  cardNumber: cleanInstrumentNumber(cardNumber),
  cardType: fixCardType(getPaymentCardType(cardNumber)),
  expirationMonth: parseInt(expirationMonth, 10),
  expirationYear: parseInt(expirationYear, 10),
  expirationDate:
    `${
      parseInt(expirationMonth, 10).toString().padStart(2, 0)
    }${
      (parseInt(expirationYear, 10) % 100).toString().padStart(2, 0)
    }`,
});

export const encryptNewInstrument = async (type, form, jwk) => {
  if (type === 'PaymentCard') {
    const card = normalizeCard(form);
    return {
      kid: jwk.kid,
      cvv: card.cvv,
      cardType: card.cardType,
      expirationDate: card.expirationDate,
      cardNumber: await encryptRsaOaep(card.cardNumber, jwk),
    };
  }
  if (type === 'Bank') {
    return {
      kid: jwk.kid,
      bankAccountType: form.account,
      bankRoutingNumber: form.routeNumber,
      encodedAccountNumber: await encryptRsaOaep(form.bankNumber, jwk),
    };
  }
  throw new Error(`Unsupported instrument type: ${type}`);
};

export const encryptPayment = async (type, form, jwk) => {
  if (type === 'PaymentCard') {
    const card = normalizeCard(form);
    return {
      PaymentCard: {
        cvv: card.cvv,
        cardType: card.cardType,
        expirationDate: card.expirationDate,
        encodedAccountNumber: await encryptRsaOaep(card.cardNumber, jwk),
      },
    };
  }
  if (type === 'Bank') {
    return {
      Bank: {
        bankAccountType: form.account,
        bankRoutingNumber: form.routeNumber,
        encodedAccountNumber: await encryptRsaOaep(form.bankNumber, jwk),
      },
    };
  }
  throw new Error(`Unsupported instrument type: ${type}`);
};

export const encryptAutopay = async (type, form, jwk) => {
  if (type === 'PaymentCard') {
    const card = normalizeCard(form);
    return {
      cvv: card.cvv,
      cardType: card.cardType,
      expirationDate: card.expirationDate,
      cardNumber: await encryptRsaOaep(card.cardNumber, jwk),
    };
  }
  if (type === 'Bank') {
    return {
      bankAccountType: form.account,
      bankRoutingNumber: form.routeNumber,
      encodedAccountNumber: await encryptRsaOaep(form.bankNumber, jwk),
    };
  }
  throw new Error(`Unsupported instrument type: ${type}`);
};

const restartServiceConditions = (bill, paymentData) => bill.summary.softDisconnected
  && paymentData.amount < bill.summary.pastDueBalanceRemaining;

const avoidInterruptionConditions = (bill, paymentData) => {
  const dueDate = bill.summary.dueDateInMillis;
  const date = +new Date(paymentData.date);
  const latePayment = bill.summary.pastDueBalanceRemaining && date > dueDate;
  const lowPayment = paymentData.amount < bill.summary.pastDueBalanceRemaining;

  return latePayment || lowPayment;
};

export const formmatReviewCard = (bill, paymentData) => {
  const dueDate = bill.summary.dueDateInMillis;
  const date = +new Date(paymentData.date);
  const now = +new Date();
  const dueDateString = (dueDate && dueDate >= now) ? dueDate : false;

  if (restartServiceConditions(bill, paymentData)) {
    const pastDue = bill.summary.pastDueBalanceRemaining - paymentData.amount;
    return {
      type: 'alert',
      message: `To reactivate your service, please make a minimum payment of ${pastDue} as soon as possible.`,
    };
  }

  if (avoidInterruptionConditions(bill, paymentData)) {
    const pastDue = bill.summary.pastDueBalanceRemaining - paymentData.amount;
    const message = [];

    if (pastDue > 0) {
      message.push(`Please schedule a minimum payment of ${pastDue} today.`);
    }

    if (dueDate && dueDate >= now) {
      message.push(`To avoid service suspension, the latest payment date you should select is ${dueDateString}.`);
    } else {
      message.push('To avoid service suspension, schedule a payment as soon as possible.');
    }

    return {
      type: 'warning',
      message: message.join(' '),
    };
  }

  if (date > dueDate || bill.summary.balanceDue > paymentData.amount) {
    const remainingBalance = bill.summary.balanceDue - paymentData.amount;

    if (date > dueDate && remainingBalance > 0) {
      if (dueDate && dueDate >= now) {
        return {
          type: 'warning',
          message: `To avoid late fees, please pay your remaining balance of ${remainingBalance} by ${dueDateString}.`,
        };
      }

      return {
        type: 'warning',
        message: `To avoid late fees, please pay your remaining balance of ${remainingBalance} as soon as possible.`,
      };
    }

    const message = [];

    if (remainingBalance > 0) {
      message.push(`Please schedule a minimum payment of ${remainingBalance}.`);
    }

    if (date > dueDate) {
      if (dueDate && dueDate >= now) {
        message.push(`To avoid late fees, the latest payment date you should select is ${dueDateString}.`);
      } else {
        message.push('To avoid late fees, schedule a payment as soon as possible.');
      }
    }

    return {
      type: 'warning',
      message: message.join(' '),
    };
  }

  if (paymentData.amount > bill.summary.balanceDue) {
    const credit = paymentData.amount - bill.summary.balanceDue;
    return {
      type: 'success',
      message: `This payment is more than you owe. A credit balance for ${credit} will be reflected on your account.`,
    };
  }

  return false;
};


export const cpcDataToPaymentFormData = ({
  channelData: {
    customerDetails: {
      billingArrangementId,
    } = {},
  } = {},
  cpcData: {
    customerId,
    cardDetails: {
      cardLast4Digits,
      cardType,
      expirationDate,
      token: cardToken,
    } = {},
    bankDetails: {
      bankAccountLast4Digits,
      bankAccountType,
      token: bankToken,
    } = {},
  } = {},
} = {}) => ({
  paymentMethodOption: cardType ? {
    type: 'PaymentCard',
    cardType,
    last4DigitsCardNumber: cardLast4Digits,
    expirationDate,
  } : {
    type: 'Bank',
    bankAccountType,
    bankAccountNumber: bankAccountLast4Digits,
  },
  token: cardToken || bankToken,
  walletId: customerId === `D${billingArrangementId}` ? customerId : null,
});

export const setReviewData = (formData, bill = {}, instruments = [], email) => {
  const {
    paymentAmountOption, paymentMethodOption, account, bankNumber,
    cardNumber, customAmount,
  } = formData;

  const amount = paymentAmountOption === 'custom' ? cleanDollarAmount(customAmount) : paymentAmountOption;
  let instrument = {
    type: paymentMethodOption,
  };
  let showStoredPaymentTerms = false;
  if (typeof paymentMethodOption === 'object') {
    instrument = paymentMethodOption;
  } else if (paymentMethodOption === 'Bank') {
    instrument = {
      ...instrument,
      bankAccountType: account,
      bankAccountNumber: bankNumber,
    };
    showStoredPaymentTerms = formData.savePayment;
  } else if (paymentMethodOption === 'PaymentCard') {
    instrument = {
      ...instrument,
      cardType: getPaymentCardType(cardNumber),
      last4DigitsCardNumber: cardNumber.substring(cardNumber.length - 4),
    };
    showStoredPaymentTerms = formData.savePayment;
  } else {
    instrument = instruments.find((item = {}) => item.token === paymentMethodOption);
  }

  const dueDate = bill.summary && bill.summary.dueDateInMillis;
  // Prefer global parseFloat. IE doesn't support Number.parseFloat
  const payingInFull = bill.summary && parseFloat(amount) >= bill.summary.balanceDue;
  const pleaseAllowOneDay = 86400000;
  const enrollingBeforeDue = (pleaseAllowOneDay + new Date().getTime()) < dueDate;
  const autopayStartDate = !payingInFull && enrollingBeforeDue && new Date(dueDate);

  return {
    ...formData,
    amount,
    instrument,
    email,
    autopayStartDate,
    showStoredPaymentTerms,
  };
};

export const formatAutopaySummaryData = (bill = {}, instrument, autopay) => {
  const data = [];
  if (bill.summary && bill.summary.balanceDue > 0) {
    data.push({
      label: 'Next payment amount',
      value: formatCurrency(Math.max(0, bill.summary.balanceDue)),
    });
  }
  if (bill.summary && bill.summary.autoPayDate) {
    data.push({
      label: 'Scheduled date',
      value: dateFormat(bill.summary.autoPayDate, 'dddd, mmmm d, yyyy', true),
    });
  }
  if (instrument) {
    data.push({
      label: 'Payment method',
      value: <PaymentInstrument autopay={autopay} instrument={instrument} />,
    });
  }
  return data;
};

export const formatPaymentSummaryData = (data = {}) => {
  const {
    amount, date, instrument, email,
    confirmationNumber, authorizationNumber, services,
  } = data;

  const isToday = date && getIsToday(date);
  const dayName = isToday ? 'Today' : dateFormat(date, 'dddd');
  const formattedDate = dateFormat(date, 'mmmm d, yyyy');

  return [
    {
      label: 'Amount',
      value: (amount || amount === 0) && formatCurrency(amount),
    },
    { label: 'Date', value: date && `${dayName}, ${formattedDate}` },
    { label: 'Payment method', value: instrument && <PaymentInstrument instrument={instrument} hideBadges /> },
    { label: 'Confirmation number', value: confirmationNumber },
    { label: 'Authorization number', value: authorizationNumber },
    { label: 'Your services', value: services },
    { label: 'Confirmation email', value: email },
  ].filter(item => !!item.value);
};

export const formatTemporaryServicesData = (data = {}) => {
  const { additionalInfo: { servicePause: { amount, plan } } } = data;

  return [
    { label: 'Services', value: plan },
    { label: 'Amount', value: amount },
    { label: 'Start Date', value: 'Plan will start within 1-2 days of processing' },
  ];
};

const COLLECTIONS_PARAMS = ['pauseConsentId', 'cartId'];

/**
 * Stash collections in sessionStorage (cleared when browser exits),
 * clearing when the user navigates outside of permissible pages
 */
// Stashed state is valid for this long
const COLLECTIONS_TTL = 9e5; // 15 minutes in ms
// Key for stashed state
const COLLECTIONS_STASH = 'COLLECTIONS_STATE';
// Pages for which we retain the state, even if we don't use it.
const COLLECTIONS_STATE_VALID = [COLLECTIONS, METHODS];

export const getCollectionsState = ({ query, path }) => {
  const searchState = getSearchState({ query, params: COLLECTIONS_PARAMS });
  if (COLLECTIONS_STATE_VALID.find(valid => path.startsWith(valid))) {
    // Only do this part if the user was sent here by XA
    if (Object.keys(searchState).length === COLLECTIONS_PARAMS.length) {
      // Stash it in sessionStorage
      sessionStorage.setItem(COLLECTIONS_STASH, JSON.stringify({
        from: Date.now(),
        ...searchState,
      }));
      return searchState;
    }
    // If the user came here from a local page, or refreshed the page, check if we've got a stash
    const sessJson = sessionStorage.getItem(COLLECTIONS_STASH);
    if (sessJson) {
      // If so, parse it
      try { // In case parse fails.
        const { from, ...stash } = JSON.parse(sessJson);
        // Verify that it's not too old, and if not, return the state.
        if (Date.now() - from < COLLECTIONS_TTL) return stash;
      } catch (e) {
        // fall through
      }
    }
  }
  // We get here if:
  //  - User has browsed to a page through which we do not want to retain a collections session.
  //  - There is no stash in the sessionStore
  //  - The session JSON is broken
  //  In all these cases, clear the stash and return nothing.
  sessionStorage.removeItem(COLLECTIONS_STASH);
  return undefined;
};

const PAYMENT_SETUP_PARAMS = ['crsId', 'continueUrl', 'fallbackUrl', 'consentId'];

export const getPaymentSetupState = ({ query, path }) => {
  if (path !== PAYMENT_SETUP) return undefined;
  const state = getSearchState({
    query,
    params: PAYMENT_SETUP_PARAMS,
    caseInsensitive: true,
  });
  // Both of these have been used
  state.fallbackUrl = sanitizeUrl(state.continueUrl || state.fallbackUrl);
  delete state.continueUrl;
  return state;
};

export const paymentFlags = ({ isLite, instruments }) => {
  const loginForBank = isLite && !flagEnabled('enableLiteACH');
  const canBank = instruments && !instruments.bankblockStatus;
  const showBank = !loginForBank && canBank;
  const showCard = instruments && !instruments.cardblockStatus;
  const applePayEnabled = flagEnabled('applePayEnabled');
  const applePayAvailable = !isLite && !!window.ApplePaySession && applePayEnabled;
  const methods = [
    showBank && 'Bank Account',
    showCard && 'Debit/Credit Card',
    applePayAvailable && 'Apple Pay',
  ].filter(a => !!a);
  const paymentHeader = methods.length > 1 ? 'Payment Method' : methods[0];
  return {
    loginForBank,
    canBank,
    showCard,
    showBank,
    applePayEnabled,
    applePayAvailable,
    methods,
    paymentHeader,
  };
};

export const getCpcPageType = ({ showBank, showCard, isLite }) => [
  // !isLite, !showBank, !showCard - nothing to show, so show error
  undefined,
  // !isLite, !showBank, showCard
  'CardOrExisting',
  // !isLite, showBank, !showCard - No "BankOrExisting" / "AchOrExisting" template exists
  undefined,
  // !isLite, showBank, showCard
  'CardBankOrExisting',
  // isLite, !showBank, !showCard - again, nothing to show
  undefined,
  // isLite, !showBank, showCard
  'CardOnly',
  // isLite, showBank, !showCard
  'AchOnly',
  // isLite, showBank, showCard
  'CardOrBank',
][(isLite ? 4 : 0) + (showBank ? 2 : 0) + (showCard ? 1 : 0)];
