import {
  GET_LTIP_OPTIONS,
  GET_LTIP_OPTIONS_SUCCESS,
  GET_LTIP_OPTIONS_FAILURE,
} from '../actions/ltip';

const INITIAL_STATE = {
  loading: true,
  options: {},
  cache: {},
};

const reducers = {
  [GET_LTIP_OPTIONS]: (state, { payload }) => ({
    ...state,
    loading: !state.loaded,
    loaded: state.loaded || false,
    refreshing: state.loaded || false,
    promise: payload,
  }),
  [GET_LTIP_OPTIONS_SUCCESS]: (
    state,
    {
      payload: {
        params: {
          otherPaymentAmount,
        } = {},
        response,
      } = {},
    },
  ) => ({
    ...state,
    loading: false,
    options: response,
    // if `otherPaymentAmount` is not undefined, we never want that response
    // becoming the minimum.
    ...(otherPaymentAmount === undefined && { minimum: response }),
    cache: {
      ...state.cache,
      [otherPaymentAmount]: response,
    },
    loaded: true,
    refreshing: false,
  }),
  [GET_LTIP_OPTIONS_FAILURE]: (state, { payload }) => ({
    ...state,
    loading: false,
    loaded: true,
    refreshing: false,
    error: payload,
  }),
};

export default function ltipReducer(state = INITIAL_STATE, action) {
  return (
    reducers[action.type]
      ? reducers[action.type](state, action)
      : state
  );
}
