import { titlecase, formatCurrency } from './formatText';
import { addMonths } from './date';

// leave as "months" but may need to update this
// to accept "frequency" as a value for different units
// const getDateNounFromFrequency = (frequency, numberOfPayments) => (
const getDateNounFromFrequency = numberOfPayments => (
  numberOfPayments > 1 ? 'months' : 'month'
);

export const getPaymentPlanKeyValue = ({
  frequency,
  numberOfPayments,
  paymentAmount,
} = {}) => ({
  label: `${titlecase(frequency)} installment amount`,
  value: `
    ${formatCurrency(paymentAmount)} (${numberOfPayments} ${getDateNounFromFrequency(numberOfPayments)})
  `,
});

export const formatPaymentPlanSummaryData = (data = {}) => {
  const {
    installmentPlan = {},
  } = data;

  const paymentPlanKeyValue = getPaymentPlanKeyValue(installmentPlan);

  return [
    paymentPlanKeyValue,
    { label: 'Plan start date', value: 'Plan will start within 1-3 days of processing' },
  ].filter(item => !!item.value);
};

export const setPaymentPlanReviewData = (
  formData,
  { summary: { pastDueBalanceRemaining, billDate } = {} } = {},
  { paymentOptionsPlan } = {},
  goodFaithPayment,
) => {
  const { paymentPlanOption, customAmount, paymentAmountOption } = formData;
  const amount = paymentAmountOption === 'custom' ? parseFloat(customAmount.replace(/^\$/, '')) : goodFaithPayment;
  const installmentPlan = (amount >= pastDueBalanceRemaining)
    ? undefined
    : paymentOptionsPlan.find(({ trackingCode }) => trackingCode === paymentPlanOption);
  const billDueDate = billDate && addMonths(billDate, 1);

  return {
    ...formData,
    installmentPlan,
    billDueDate,
  };
};
