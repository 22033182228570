import React from 'react';

const icon = ({ className }) => (
  <span className={`svg-icon ${className}`}>
    <svg xmlns="http://www.w3.org/2000/svg">
      <path fill="currentColor" d="M4.089 7.063l5.237-5.237L8 .5.5 8 8 15.5l1.326-1.326-5.237-5.236H15.5V7.062z" fillRule="evenodd" />
    </svg>
  </span>
);

export default icon;
