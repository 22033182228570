import {
  NEW_PAYMENT,
} from './routes';

const errorsMap = [
  {
    id: 'SH400.11.16',
    message: 'Expiration date in the past.',
    route: NEW_PAYMENT,
    // +{name} indicates that the field should get the error state, but not the message.
    fields: ['expirationMonth', '+expirationYear'],
  },
  {
    id: 'SH400.11.80',
    message: 'Payment card expired',
    route: NEW_PAYMENT,
    fields: ['expirationMonth', '+expirationYear'],
  },
  {
    id: 'SH400.11.81',
    route: NEW_PAYMENT,
  },
  {
    id: 'SH400.11.15',
    message: 'Credit card number is not valid.',
    route: NEW_PAYMENT,
    fields: ['cardNumber'],
  },
  {
    id: 'SH400.11.14',
    message: 'Invalid credit card information. Please make sure you entered your card\'s expiration date and CVV number correctly.',
    route: NEW_PAYMENT,
    fields: ['expirationMonth', '+expirationYear', '+cvv'],
  },
  {
    id: 'SH400.10.1038',
    message: 'Address is missing. Address line 1',
    route: NEW_PAYMENT,
    fields: ['line1'],
  },
  {
    id: 'SH500.10.1028',
    message: 'Bank account routing number length is invalid',
    route: NEW_PAYMENT,
    fields: ['routeNumber'],
  },
  {
    id: ['SH400.5050.00', 'SH400.5051.01'],
    message: 'Payment Declined',
    route: NEW_PAYMENT,
    fields: ['paymentMethodOption'],
  },
  {
    id: ['SH400.1017', 'SH400.3024'],
    message: 'We\'re unable to process this transaction for the entered amount',
    route: NEW_PAYMENT,
    fields: ['paymentAmountOption'],
  },
];

export const getErrorFromResponse = (response) => {
  const code = (response.data && (response.data.code || response.data.errorCode)) || null;
  const c = code || '';
  const message = response.data && (response.data.clientMessage || response.data.message);
  const error = errorsMap.find(e => (
    Array.isArray(e.id)
      ? (!!e.id.find(i => c.startsWith(i) || c.startsWith(message)))
      : (c.startsWith(e.id) || c.startsWith(message))
  )) || {};

  return Object.assign({}, response.data, error, {
    message: error.message || message,
    isMapped: !!error.id,
  });
};

export const scrollToInvalid = (errors = {}) => {
  const invalidName = Object.keys(errors).find(key => key !== 'error'); // might need to check for undefined
  if (invalidName) {
    const invalidInput = document.getElementsByName(invalidName)[0];
    window.scrollTo(0, window.pageYOffset
      + (invalidInput.getBoundingClientRect().top - 50));
    invalidInput.focus();
  } else {
    window.scrollTo(0, 0);
  }
};
