import React from 'react';
import Card from './Card';
import { getPaymentMessage } from '../helpers/paymentMessage';

const PaymentMessage = ({
  bill,
  payment,
  status,
  type,
}) => {
  const message = getPaymentMessage({
    bill, payment, status, type,
  });

  if (!message.message) {
    return '';
  }

  return (
    <Card severity={message.severity}>
      <p>{message.message}</p>
    </Card>
  );
};

export default PaymentMessage;
