import {
  GET_NOW_INSTRUMENTS,
  GET_NOW_INSTRUMENTS_SUCCESS,
  GET_NOW_INSTRUMENTS_FAILURE,
} from '../actions/nowWalletInstruments';


const INITIAL_STATE = { loading: true };

export default function nowWalletInstrumentsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_NOW_INSTRUMENTS:
      return {
        ...state,
        loading: true,
      };
    case GET_NOW_INSTRUMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        ...action.payload,
      };
    case GET_NOW_INSTRUMENTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };
    default:
      return state;
  }
}
