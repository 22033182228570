import apiClient from '../middleware/apiClient';
import { SSM_USER } from '../helpers/apis';

export const GET_USER = 'GET_USER';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_FAILURE = 'GET_USER_FAILURE';


function dispatchGetUser(now = false) {
  const endpoint = now ? `${SSM_USER}?now=true` : SSM_USER;
  return {
    type: GET_USER,
    payload: apiClient.fetch(endpoint),
  };
}

function dispatchGetUserSuccess(user) {
  return {
    type: GET_USER_SUCCESS,
    payload: user,
  };
}

function dispatchGetUserFailure(error) {
  return {
    type: GET_USER_FAILURE,
    payload: error,
  };
}

export const getUser = now => async (dispatch, getState) => {
  const {
    user: { cached } = {},
  } = getState();
  if (cached) {
    return null;
  }

  try {
    const response = await dispatch(dispatchGetUser(now)).payload;
    return dispatch(dispatchGetUserSuccess(response));
  } catch (error) {
    dispatch(dispatchGetUserFailure(error));
    throw error;
  }
};
