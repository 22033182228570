import { GET_STEP_UP_LOADING, GET_STEP_UP_PERMIT, GET_STEP_UP_FAILURE } from '../actions/stepUp';

export default function stepUpReducer(state = {}, action) {
  switch (action.type) {
    case GET_STEP_UP_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case GET_STEP_UP_PERMIT:
      return {
        ...state,
        loading: false,
        error: false,
      };
    case GET_STEP_UP_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };
    default:
      return state;
  }
}
