import { connect } from 'react-redux';

import ListScheduled from '../../components/ListScheduled';

export default connect(({
  scheduledPayments: {
    payments: scheduledPayments,
    loading: scheduledPaymentsLoading,
  },
  account: {
    account,
    loading: accountLoading,
  },
}) => ({
  scheduledPayments,
  account,
  loading: scheduledPaymentsLoading || accountLoading,
}))(ListScheduled);
