import apiClient from '../middleware/apiClient';
import { LATE_FEE_CREDIT, LATE_FEE_CREDIT_ENROLL } from '../helpers/apis';
import { dispatchSendTransaction } from './adobe';

// Fetch Payment
export const GET_LATE_FEE_CREDIT = 'GET_LATE_FEE_CREDIT';
export const GET_LATE_FEE_CREDIT_SUCCESS = 'GET_LATE_FEE_CREDIT_SUCCESS';
export const GET_LATE_FEE_CREDIT_FAILURE = 'GET_LATE_FEE_CREDIT_FAILURE';

function dispatchGetLateFeeCredit() {
  return {
    type: GET_LATE_FEE_CREDIT,
    payload: apiClient.fetch(LATE_FEE_CREDIT),
  };
}

function dispatchGetLateFeeCreditSuccess(latefeecredit) {
  return {
    type: GET_LATE_FEE_CREDIT_SUCCESS,
    payload: latefeecredit,
  };
}

function dispatchGetLateFeeCreditFailure(error) {
  return {
    type: GET_LATE_FEE_CREDIT_FAILURE,
    payload: error,
  };
}

// TODO update to cache
// if (getState().latefeecredit) {
//     return null;
// }
export const getLateFeeCredit = () => async (dispatch) => {
  try {
    const response = await dispatch(dispatchGetLateFeeCredit()).payload;
    return dispatch(dispatchGetLateFeeCreditSuccess(response));
  } catch (error) {
    dispatch(dispatchGetLateFeeCreditFailure(error));
    throw error;
  }
};

export const ENROLL_LATE_FEE_CREDIT = 'ENROLL_LATE_FEE_CREDIT';
export const ENROLL_LATE_FEE_CREDIT_SUCCESS = 'ENROLL_LATE_FEE_CREDIT_SUCCESS';
export const ENROLL_LATE_FEE_CREDIT_FAILURE = 'ENROLL_LATE_FEE_CREDIT_FAILURE';

function dispatchEnrollLateFeeCredit() {
  return {
    type: ENROLL_LATE_FEE_CREDIT,
    payload: apiClient.fetch(LATE_FEE_CREDIT_ENROLL, { method: 'POST' }),
  };
}

function dispatchEnrollLateFeeCreditSuccess(latefeecredit) {
  return {
    type: ENROLL_LATE_FEE_CREDIT_SUCCESS,
    payload: latefeecredit,
  };
}

function dispatchEnrollLateFeeCreditFailure(error) {
  return {
    type: ENROLL_LATE_FEE_CREDIT_FAILURE,
    payload: error,
  };
}

export const enrollLateFeeCredit = () => async (dispatch) => {
  try {
    const response = await dispatch(dispatchEnrollLateFeeCredit()).payload;
    dispatch(dispatchSendTransaction({
      name: 'self service transaction',
      action: 'self service transaction',
      transaction: 'billing:latefee:enroll',
    }));
    return dispatch(dispatchEnrollLateFeeCreditSuccess(response));
  } catch (error) {
    dispatch(dispatchEnrollLateFeeCreditFailure(error));
    throw error;
  }
};
