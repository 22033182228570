import {
  GET_TERMS,
  GET_TERMS_SUCCESS,
  GET_TERMS_FAILURE,
  GET_ACCEPTED_CONTRACTS,
  GET_ACCEPTED_CONTRACTS_SUCCESS,
  GET_ACCEPTED_CONTRACTS_FAILURE,
} from '../actions/contracts';

import { keyedReducer } from '../helpers/redux';

export default keyedReducer({
  terms: {},
  loading: 0,
}, {
  [GET_TERMS]: (
    {
      loading,
      terms,
      ...state
    },
    {
      payload: {
        contractId,
      },
    },
  ) => ({
    ...state,
    loading: loading + 1,
    terms: {
      ...terms,
      [contractId]: {},
    },
  }),
  [GET_TERMS_SUCCESS]: (
    {
      loading,
      terms: oldTerms,
      ...state
    },
    {
      payload: {
        contractId,
        terms,
      },
    },
  ) => ({
    ...state,
    loading: loading - 1,
    error: false,
    terms: {
      ...terms,
      [contractId]: terms,
    },
  }),
  [GET_TERMS_FAILURE]: ({ loading, ...state }) => ({
    ...state,
    error: true,
    loading: loading - 1,
  }),
  [GET_ACCEPTED_CONTRACTS]: ({ loading, ...state }) => ({
    ...state,
    loading: loading + 1,
    accepted: {},
  }),
  [GET_ACCEPTED_CONTRACTS_SUCCESS]: ({ loading, ...state }, { payload }) => ({
    ...state,
    accepted: payload,
    loading: loading - 1,
  }),
  [GET_ACCEPTED_CONTRACTS_FAILURE]: ({ loading, ...state }) => ({
    ...state,
    error: true,
    loading: loading - 1,
  }),
});
