import React, {
  createContext,
  useContext,
  useRef,
} from 'react';

export const CpcContext = createContext({});
export const CpcProvider = ({ children }) => {
  const cpc = useRef();

  return (
    <CpcContext.Provider value={cpc}>
      {children}
    </CpcContext.Provider>
  );
};

const useCpc = () => {
  const ctx = useContext(CpcContext);
  if (!ctx) throw new Error('Cpc context not available');
  const submitForm = () => ctx.current.submitForm();

  return ({
    submitForm,
  });
};

export default useCpc;
