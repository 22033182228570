import React from 'react';
import { IconCircleExclamation, PageSection } from 'adc-ui-components';
import PageTitle from '../../components/PageTitle';
import getConfig from '../../config';

const { homeDomain } = getConfig();

const XfinityStoreLink = () => (
  <a href="https://www.xfinity.com/stores"> Xfinity Store</a>
);
const title = 'You can\'t add a new payment method yet';
const message = 'We need to verify your contact information to keep your account secure. Make sure to add your phone number and email address if you haven\'t already.';
const description = (
  <>
    To pay your bill today, call 1-800-934-6489 to make an automated phone payment or visit an
    <XfinityStoreLink />
    .
  </>
);
const StepUpError = () => (
  <PageSection className="page-section--banner">
    <div className="hgroup">
      <IconCircleExclamation className="mb6 svg-icon--42" />
      <PageTitle>{title}</PageTitle>
      <p className="body2">{message}</p>
      <br />
      <p className="body2">{description}</p>
    </div>

    <div className="action action--right-centered">
      <div className="action__item">
        <a className="button button--primary" href={`https://${homeDomain}/settings/xid`}>
          Go to contact information
        </a>
      </div>
    </div>
  </PageSection>
);

export default StepUpError;
