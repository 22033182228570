import React, { useState } from 'react';

import Cookies from 'js-cookie';
import config from '../config';
import Modal from './Modal';
import { openInNewWindow } from '../helpers/externalLinks';

/*
    Modal that should be present when a lite user is either deliquent
    or disconnected. Different messaging based on status.
*/
const { accountPopupCookie } = config();
const LiteBillModal = ({
  heading,
  description,
  ctaLink,
  ctaText,
}) => {
  const [isOpen, setIsOpen] = useState(true);
  const closeModal = () => {
    Cookies.set(accountPopupCookie, true);
    setIsOpen(false);
  };
  const handleClick = (event) => {
    openInNewWindow(event);
    closeModal();
  };
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
    >
      <div className="text-center">
        <h2>{heading}</h2>
        <p>{description}</p>
        <div className="action">
          <div className="action">
            <a className="button button--primary" href={ctaLink} onClick={handleClick}>
              {ctaText}
            </a>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default LiteBillModal;
