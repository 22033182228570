import React from 'react';
import FlexPromo from '../../components/FlexPromo';
import PeacockPromo from '../../components/PeacockPromo';
import CmsPromo from '../CmsPromo';
import { flagEnabled } from '../../helpers/featureFlags';

const Promotions = ({
  peacockPromo,
  showFlexPromo,
  account,
  preload,
}) => {
  // CMS promo should only show if hardcoded promos are disabled
  // CMS promo makes its own business decisions via segment; it should
  //  occur before getIsXapExit check, which is for suppressing the hardcoded
  //  promos for XAP Exit customers.
  if (flagEnabled('cmsPromoModule') && !(peacockPromo && peacockPromo.show) && !showFlexPromo) {
    return <CmsPromo page="web-billing" preload={preload} />;
  }
  if (peacockPromo && peacockPromo.show) {
    const { scenario: { type } } = peacockPromo;
    return <PeacockPromo peacockPromo={peacockPromo} type={type > 2 ? 'button' : 'link'} />;
  }
  if (showFlexPromo) {
    return <FlexPromo />;
  }
  return null;
};

export default Promotions;
