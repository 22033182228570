import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { PageSection, LoadingCard } from 'adc-ui-components';

import { getBill } from '../../actions/bill';
import { getAccount } from '../../actions/account';
import { getPaymentInstruments } from '../../actions/instruments';

import AutopaySignupForm from './AutopaySignupForm';
import getConfig from '../../config';
import { flagEnabled } from '../../helpers/featureFlags';
import { getAutopay } from '../../actions/autopay';

const {
  paymentSetup: {
    defaultContinue,
    validAffiliates,
    redirectDelay = 0,
  },
} = getConfig();

class AutopaySignup extends Component {
  componentDidMount() {
    const {
      handleGetAutopay,
      handleGetBill,
      handleGetAccount,
      handleGetPaymentInstruments,
      crsId,
    } = this.props;

    // needed for determining to hide "automatic payments" badge on PaymentInstrument
    handleGetAutopay();
    // needed for instruments and jwk regardless of status
    handleGetPaymentInstruments();
    // needed for billing address, and to check for account errors and redirect
    handleGetAccount();

    // if not consent flow
    if (!(flagEnabled('consent.enabled') && crsId)) {
      // need to detect if autopay and redirect
      handleGetBill();
    }

    this.onHandleRedirect();
  }

  componentDidUpdate() {
    const { loading } = this.props;
    if (!loading) {
      this.onHandleRedirect();
    }
  }

  onHandleRedirect() {
    const {
      bill: {
        summary: {
          autoPayEnabled,
          autoPayValid,
        } = {},
      } = {},
      auth: {
        crs: {
          continueUrl,
        } = {},
        fallbackUrl,
      } = {},
      affiliate,
    } = this.props;
    if (
      !flagEnabled('paymentSetup.enabled')
      || (autoPayEnabled && autoPayValid)
      || (affiliate && validAffiliates.indexOf(affiliate) === -1)
    ) {
      setTimeout(() => {
        window.location = fallbackUrl || continueUrl || defaultContinue;
      }, redirectDelay);
    }
  }

  render() {
    const {
      loading,
      autopay,
      bill: {
        summary: {
          autoPayEnabled,
          autoPayValid,
        } = {},
      } = {},
      affiliate,
    } = this.props;
    // Hold the loading screen if we're just going to redirect in a moment.
    const skipRender = !flagEnabled('paymentSetup.enabled')
    || (autoPayEnabled && autoPayValid)
    || (affiliate && validAffiliates.indexOf(affiliate) === -1);
    return (
      <PageSection className="ui-grey">
        {(loading || skipRender) && <LoadingCard />}
        {!(loading || skipRender) && <AutopaySignupForm autopay={autopay} />}
      </PageSection>
    );
  }
}

const mapStateToProps = ({
  auth: { crsId, fallbackUrl },
  autopay: { autopay, loading: autopayLoading },
  bill: { bill, loading: billLoading },
  instruments: { loading: instrumentsLoading },
  account: { loading: accountLoading, account: { affiliate } = {} },
}) => {
  const consentEnabled = flagEnabled('consent.enabled');
  const isConsent = consentEnabled && crsId;

  return {
    autopay,
    bill,
    fallbackUrl,
    ...(consentEnabled ? { crsId, affiliate } : {}),
    loading: (accountLoading || instrumentsLoading)
      || (!isConsent && billLoading)
      || autopayLoading,
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  handleGetAutopay: getAutopay,
  handleGetBill: getBill,
  handleGetAccount: getAccount,
  handleGetPaymentInstruments: getPaymentInstruments,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AutopaySignup);
