import React, { Component } from 'react';
import dateformat from 'dateformat';
import { PageSection, IconCircleExclamation, HarnessExitButton } from 'adc-ui-components';
import Card from '../../components/Card';
import KeyValueGroup from '../../components/KeyValueGroup';
import KeyValue from '../../components/KeyValue';

import { kibanaLog } from '../../helpers/logger';
import { formatCurrency } from '../../helpers/formatText';
import PageTitle from '../../components/PageTitle';

class CardAndBankBlocked extends Component {
  componentDidMount() {
    // todo, make this better
    kibanaLog('card_and_bank_blocked');
  }

  render() {
    const {
      billSummary,
      billSummary: {
        balanceDue,
        dueDate,
      } = {},
      isLite,
      isHarness,
    } = this.props;
    return (
      <PageSection className="ui-grey page-section--banner">
        <div className="hgroup mb24">
          <IconCircleExclamation className="mb6 svg-icon--42" />
          <PageTitle>You&apos;ll need to make a payment in person</PageTitle>
          <p className="body2">Please visit an Xfinity Store near you.</p>
        </div>

        <div className="action mb30">
          {!isLite && !isHarness && <a target="_blank" rel="noopener noreferrer" className="button button--primary" href="https://www.xfinity.com/support/service-center-locations/">Find a Store</a>}
          {!isLite && isHarness && <HarnessExitButton>Find a Store</HarnessExitButton>}
          {isLite && <a target="_blank" rel="noopener noreferrer" className="button button--primary" href="https://www.xfinity.com/support/service-center-locations/">Find a Store</a>}
        </div>

        {billSummary && (
          <div className="text-left">
            <h2>Current Balance</h2>
            <Card>
              <KeyValueGroup>
                <KeyValue className="key-value--inline-at-768" label="Amount" value={formatCurrency(balanceDue)} />
                <KeyValue className="key-value--inline-at-768" label="Date due" value={dateformat(dueDate, 'dddd, mmmm d, yyyy', true)} />
              </KeyValueGroup>
            </Card>
          </div>
        )}
      </PageSection>
    );
  }
}

export default CardAndBankBlocked;
