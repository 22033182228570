/**
  Memoizes a function.

  @param factoryFn A function that accepts some set of arguments and returns a result
* */
const memoized = factoryFn => () => {
  const configs = {};
  return (...params) => {
    const args = params.slice(0, factoryFn.length);
    const key = JSON.stringify(args);
    if (!configs[key]) {
      configs[key] = factoryFn(...args);
    }
    return configs[key];
  };
};

export default memoized;
