import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { compareDays, createDateObject, getIsToday } from '../../../helpers/date';
import { NEW_PAYMENT } from '../../../helpers/routes';

export const MakeAnotherPaymentComp = ({
  bill: {
    summary: {
      balanceDue,
    } = {},
    promiseToPay: {
      existingPromiseToPay,
    } = {},
  } = {},
  confirm: {
    date,
    amount,
  } = {},
}) => {
  if (amount !== undefined && balanceDue !== undefined) {
    const futureBalance = (!date || getIsToday(date))
      ? balanceDue
      : Math.max(0, balanceDue - amount);
    if (amount && futureBalance === 0) return null;
  }
  if (existingPromiseToPay) {
    const {
      promiseToPayDate,
      promiseToPayAmount,
    } = existingPromiseToPay;
    const isEnough = amount >= promiseToPayAmount;
    const isInTime = compareDays(
      createDateObject(date),
      createDateObject(promiseToPayDate),
      (a, b) => a <= b,
    );
    if (isEnough && isInTime) {
      return null;
    }
  }

  return (
    <div className="action__item">
      <Link to={NEW_PAYMENT} className="button button--primary">Make Another Payment</Link>
    </div>
  );
};

const mapStateToProps = ({
  payment: { confirm },
  bill: { bill },
}) => ({
  confirm,
  bill,
});

export default connect(mapStateToProps, null)(MakeAnotherPaymentComp);
