import React from 'react';

const BillOutline = ({ className = '' }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
    <path d="M26.5 26.5h-13m9 3h-9m13-6h-13m16 11h-19a2 2 0 0 1-2-2v-25a2 2 0 0 1 2-2h19a2 2 0 0 1 2 2v25a2 2 0 0 1-2 2z" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" />
    <path d="M19.244 17.448c-.63 0-1.441-.16-2.05-.459l-.149 1.292c.534.235.955.363 1.955.395V20h1v-1.399c1-.235 2.029-.961 2.029-1.975 0-1.26-1.062-1.687-2.172-1.975-.758-.192-1.289-.288-1.289-.769 0-.469.498-.629 1.074-.629.588 0 1.307.149 1.861.384l.14-1.259c-.47-.139-1.643-.278-1.643-.321V11h-1v1.1c-1 .182-1.913.801-1.913 1.879 0 1.388 1.174 1.762 2.007 1.954.704.171 1.425.31 1.425.833.001.511-.636.682-1.275.682z" fill="#fff" />
  </svg>
);

export default BillOutline;
