import React, { useEffect } from 'react';
import { FormControl } from 'adc-ui-components';
import { Field, connect } from 'formik';

import SelectField from '../form-elements/SelectField';
import { months, years, renderOptions } from '../../helpers/date';
import { joinClass } from '../../helpers/component';

import vAll from '../../helpers/validation/all';
import vRequired from '../../helpers/validation/required';


const validateExpDate = allValues => () => {
  if (!allValues.expirationYear || !allValues.expirationMonth) {
    return undefined; // `required` handles empty values.
  }

  const pad = n => (n < 10 ? `0${n}` : n);

  const today = new Date();
  const todayMonth = pad(today.getMonth() + 1);
  const todayYear = today.getFullYear();
  return `${todayYear}${todayMonth}` <= `${allValues.expirationYear}${allValues.expirationMonth}` ? undefined : true;
};

const ExpirationFields = ({
  formik: {
    setFieldValue,
    values: allValues,
    touched: {
      expirationMonth: isExpirationMonthTouched,
      expirationYear: isExpirationYearTouched,
    },
    values: {
      expirationMonth,
      expirationYear,
    },
    errors: {
      expirationMonth: expirationMonthError,
      expirationYear: expirationYearError,
    },
    submitCount,
  },
  inline,
  required = true,
}) => {
  useEffect(() => {
    if (expirationYear === undefined) {
      setFieldValue('expirationYear', String(new Date().getFullYear()));
    }
    if (expirationMonth === undefined) {
      setFieldValue('expirationMonth', '01');
    }
  // Only want to run this on component mount for sanity.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const isFormSubmitted = !!submitCount;
  const hasAttemptedInput = (
    (!!expirationMonth && isExpirationMonthTouched)
    && (!!expirationYear && isExpirationYearTouched)
  );
  const shouldShowError = (
    (isFormSubmitted || hasAttemptedInput)
    && (expirationMonthError || expirationYearError)
  );
  const shouldShowErrorHighlight = shouldShowError;

  const expirationError = expirationMonthError || expirationYearError;

  return (
    <div className={joinClass('form-control', inline && 'form-control--inline-at-768')}>
      <h3 className="label form-control__label">Expiration</h3>
      <div className="form-control__input">
        <div className="form-control-group form-control-group--flex mb0">
          <div className="form-control-group__item">
            <FormControl>
              <label className="visuallyhidden" htmlFor="expirationMonth">Expiration month</label>
              <Field
                component={SelectField}
                id="expirationMonth"
                name="expirationMonth"
                validate={vAll(required && vRequired, validateExpDate(allValues))}
                aria-describedby="expirationError"
                suppressError
                forceError={shouldShowErrorHighlight}
              >
                <option value="" disabled hidden>MM</option>
                {renderOptions(months)}
              </Field>
            </FormControl>
          </div>
          <div className="form-control-group__item">
            <FormControl>
              <label className="visuallyhidden" htmlFor="expirationYear">Expiration year</label>
              <Field
                component={SelectField}
                id="expirationYear"
                name="expirationYear"
                validate={vAll(required && vRequired, validateExpDate(allValues))}
                aria-describedby="expirationError"
                suppressError
                forceError={shouldShowErrorHighlight}
              >
                <option value="" disabled hidden>YYYY</option>
                {renderOptions(years)}
              </Field>
            </FormControl>
          </div>
        </div>
        {shouldShowError && (
          <span id="expirationError" className="form-control__error">
            { typeof expirationError === 'string' ? expirationError : 'Please select a valid expiration date' }
          </span>
        )}
      </div>
    </div>
  );
};

export default connect(ExpirationFields);
