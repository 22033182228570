const MILLISECOND = 1;
const SECOND = 1000 * MILLISECOND;
export const MINUTE = 60 * SECOND;
export const idleTimeout = 15 * MINUTE;

let lastActivity = Date.now();

export const markActivity = () => {
  lastActivity = Date.now();
};

/**
 * Polls for a period of inactivity before resolving a promise
 * when time limit of inactivity is reached.
 * @param {number} timeLimit - The time limit for inactivity in milliseconds.
 * @returns {Promise<void>}
 */
export const waitForPeriodOfInactivity = timeLimit => new Promise((resolve) => {
  const poll = () => {
    const timeSinceLastActivity = Date.now() - lastActivity;
    if (timeSinceLastActivity >= timeLimit) {
      resolve();
    } else {
      setTimeout(poll, timeLimit - timeSinceLastActivity);
    }
  };
  poll();
});
