import React from 'react';

import { AUTOPAY, AUTOPAY_EDIT } from '../helpers/routes';
import { formatAutopaySummaryData } from '../helpers/payments';

import Button from './Button';
import Card from './Card';
import KeyValueGroup from './KeyValueGroup';
import KeyValue from './KeyValue';

const AutopaySummary = ({
  autopay,
  bill,
  instrument,
  showEdit,
  showUpdate,
}) => (
  <Card
    className="card--flex-start"
    actionRight
    action={(showEdit || showUpdate) && (
      <>
        {showEdit && <Button text to={AUTOPAY}>Edit</Button>}
        {showUpdate && <Button text to={AUTOPAY_EDIT}>Update Payment Method</Button>}
      </>
    )}
  >
    <KeyValueGroup>
      {formatAutopaySummaryData(bill, instrument, autopay).map(item => (
        <KeyValue className="key-value--inline-at-768" key={item.label} {...item} />
      ))}
    </KeyValueGroup>
  </Card>
);


export default AutopaySummary;
