import memoized from '../memoized';

const EIGHTCONSECUTIVENUMBERS = /\d{9}/;
const NUMBERS = /\d/g;

const ConsecutiveNumericAddressFactory = memoized(message => (value = '') => (
  EIGHTCONSECUTIVENUMBERS.test(value) || (value.match(NUMBERS) || []).length > 12
    ? message
    : undefined
));

export default ConsecutiveNumericAddressFactory;
