/**
 * Runs all passed in validations from left to right. Pass as many validations as you want.
 * @param {function|falsy} - a validator. Should accept a value and return a string if validation
 * fails or null/undefined if validation passes.  Falsy values are skipped.
 * @returns {function} - validator that returns the first error
 * @example ```
 *  vAll(
 *    vRequired('Username can\'t be blank'),
 *    vMin('Username must be at least 3 characters')
 *  )
 * ```
 */
const vAll = (...validators) => value => validators
  .reduce((err, validator) => (err || (!validator ? undefined : validator(value))), undefined);

export default vAll;
