import React from 'react';

const CalendarBill = ({ className = '' }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
    <g fill="none" stroke="currentColor">
      <path d="M34.497 17.742v14.75a2 2 0 0 1-2 2h-25a2 2 0 0 1-2-2v-17m14.711 0H5.497v-5a2 2 0 0 1 2-2h12.628" strokeLinecap="round" strokeLinejoin="round" />
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M11.997 5.992v6" />
      <path strokeLinejoin="round" d="M9.497 22.49h21" />
      <path strokeMiterlimit="10" d="M16.497 18.492v12m7-11.179v11.179" />
      <path d="M27.997 3.492a8.5 8.5 0 1 0 8.5 8.5 8.5 8.5 0 0 0-8.5-8.5z" strokeLinecap="round" strokeLinejoin="round" />
      <path strokeLinecap="round" strokeLinejoin="round" d="M30.5 20.25l-.003 10.244h-21V18.49H22.5" />
      <path strokeLinejoin="round" d="M9.497 26.494h21" />
      <path d="M30.08 9.692a2.024 2.024 0 0 0-1.996-1.389 1.807 1.807 0 0 0-1.996 1.736c0 .955.868 1.389 2.083 1.823 1.128.434 1.909.781 1.996 1.736 0 1.909-1.91 1.909-2.17 1.909a2.119 2.119 0 0 1-2.17-1.649m2.257 3.471v-1.648m0-7.464V6.654" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
    </g>
  </svg>
);

export default CalendarBill;
