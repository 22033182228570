import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { getHasAccountAutopayToken } from '../../helpers/instruments';
import { AUTOPAY_EDIT } from '../../helpers/routes';

import { getAutopay } from '../../actions/autopay';
import { getAccount } from '../../actions/account';

import Cash from '../svgs/Cash';
import NextBestAction from '../NextBestAction';

export const ACCOUNT_TOKEN = 'ACCOUNT_TOKEN';

class AccountTokenNba extends Component {
  componentDidMount() {
    const {
      handleGetAutopay,
      handleGetAccount,
    } = this.props;

    handleGetAutopay();
    handleGetAccount();
    this.triggerSetLoaded();
  }

  componentDidUpdate() {
    this.triggerSetLoaded();
  }

  triggerSetLoaded() {
    const {
      setLoaded,
      loading,
      hasAccountAutopayToken,
    } = this.props;

    if (loading) {
      return;
    }

    if (!hasAccountAutopayToken) {
      setLoaded(ACCOUNT_TOKEN, 'FAILED');
      return;
    }

    setLoaded(ACCOUNT_TOKEN, 'READY');
  }

  render() {
    const {
      dismissNba,
      loading,
      hasAccountAutopayToken,
      options = { showLink: true },
    } = this.props;

    if (loading) {
      return null;
    }

    if (!hasAccountAutopayToken) {
      return null;
    }

    const data = {
      id: ACCOUNT_TOKEN,
      trackId: 'paymentMethodsAutopayAccountToken',
      svgIcon: Cash,
      color: 'alert',
      name: 'Want to manage your automatic payment method?',
      description: `Your current automatic payment method isn't connected to any users on your account.${!options.showLink ? ' Please update your payment method below.' : ''}`,
      link: options.showLink && AUTOPAY_EDIT,
      linkText: 'Update Your Payment Method',
      dismissible: true,
    };

    return <NextBestAction nba={data} dismissNba={dismissNba} />;
  }
}

const mapStateToProps = ({
  autopay: {
    autopay = {},
    loading: autopayLoading,
  },
  account: {
    account = {},
    loading: accountLoading,
  },
}) => {
  const loading = accountLoading || autopayLoading;

  return {
    autopay,
    account,
    loading,
    hasAccountAutopayToken: !loading && getHasAccountAutopayToken(autopay, account),
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  handleGetAutopay: getAutopay,
  handleGetAccount: getAccount,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AccountTokenNba);
