import React from 'react';
import { FormBoolean } from 'adc-ui-components';
import { Field } from 'formik';

import Address from './Address';
import BankFields from './BankFields';
import Card from '../Card';
import BooleanField from '../form-elements/BooleanField';

const BankPaymentMethod = ({
  nested,
  forceBillingAddress,
  values: { billingAddressCheckbox },
  setFieldValue,
  required = true,
}) => {
  const cardClass = nested ? 'card--secondary' : '';

  return (
    <>
      <BankFields {...{ required, setFieldValue, cardClass }} />

      <div className="card-group__item">
        <Card className={cardClass}>
          {!forceBillingAddress && (
            <FormBoolean
              label="Billing address is different from service address"
              inputId="billingAddressCheckbox"
            >
              <Field
                id="billingAddressCheckbox"
                name="billingAddressCheckbox"
                component={BooleanField}
                type="checkbox"
              />
            </FormBoolean>
          )}
          {forceBillingAddress && <p className="body1">Billing Address</p>}

          {(forceBillingAddress || billingAddressCheckbox) && <Address required={required} />}
        </Card>
      </div>
    </>
  );
};

export default BankPaymentMethod;
