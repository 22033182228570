import { p2pProps } from '../../helpers/p2p';
import {
  cancelWarning,
  paymentDateWarn,
} from './messages';
import { flagEnabled } from '../../helpers/featureFlags';

const P2PReviewNote = ({ children, ...props }) => {
  const def = children || null;
  const p2pMessaging = flagEnabled('p2pMessaging');
  if (!p2pMessaging) return def;
  const {
    isP2P,
    p2pDate,
    softDisconnected,
    laterDate,
    p2pMinAmount,
    laterAmount,
    qualifies,
    today,
  } = p2pProps(props);
  if (!isP2P) return def;
  if (qualifies) {
    if (today) return null;
    return cancelWarning({ softDisconnected });
  }
  // If this doesn't qualify, but there's options, note them.
  // If this doesn't qualify because it's not in time, note it.
  return paymentDateWarn({
    p2pDate,
    laterDate,
    p2pMinAmount,
    laterAmount,
  });
};

export default P2PReviewNote;
