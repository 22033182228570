import React, { Component } from 'react';
import DayPicker from 'react-day-picker';

import IconCalendar from '../svgs/Calendar';
import { bindListeners } from '../../helpers/component';
import {
  ESC,
  TAB,
  UP,
  DOWN,
  LEFT,
  RIGHT,
  PAGE_UP,
  PAGE_DOWN,
  SPACE,
  ENTER,
} from '../../helpers/ui-keys';

import {
  isDate,
  isSameMonth,
  parseDate as parseDateDefault,
} from '../../helpers/date';

const getModifiersForDay = (day, mods = {}) => Object.keys(mods).filter(name => (
  !!mods[name]
  && (Array.isArray(mods[name]) ? mods[name] : [mods[name]]).some(mod => (
    (
      !!mod
      && typeof mod === 'function'
      && mod(day)
    ) || false
  ))
));

export function OverlayComponent({
  input,
  selectedDay,
  month,
  children,
  classNames,
  ...props
}) {
  return (
    <div className={classNames.overlayWrapper} {...props}>
      <div className={classNames.overlay}>{children}</div>
    </div>
  );
}

const getInitialMonthFromProps = (props) => {
  const { dayPickerProps, format, value } = props;
  let day;
  if (value) {
    if (isDate(value)) {
      day = value;
    } else {
      day = props.parseDate(value, format, dayPickerProps.locale);
    }
  }
  return (
    dayPickerProps.initialMonth || dayPickerProps.month || day || new Date(Date.now())
  );
};

const getInitialStateFromProps = (props) => {
  const {
    dayPickerProps,
    formatDate,
    format,
    typedValue,
    showOverlay,
  } = props;
  let { value } = props;
  if (value && isDate(value)) {
    value = formatDate(value, format, dayPickerProps.locale);
  }

  return {
    value,
    typedValue,
    showOverlay,
    month: getInitialMonthFromProps(props),
    selectedDays: dayPickerProps.selectedDays,
  };
};

const defaultFormat = d => (
  isDate(d)
    ? `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`
    : ''
);

const defaultParse = s => new Date(parseDateDefault(s));

export default class DayPickerInput extends Component {
  constructor(props) {
    super(props);
    this.input = null;
    this.calendar = null;
    this.daypicker = null;
    this.clickTimeout = null;
    this.hideTimeout = null;
    this.inputBlurTimeout = null;
    this.inputFocusTimeout = null;

    this.state = getInitialStateFromProps(props);

    bindListeners(this, [
      'hideAfterDayClick',
      'handleCalendarClick',
      'handleCalendarBlur',
      'handleInputBlur',
      'handleInputChange',
      'handleCalendarKeyDown',
      'handleCalendarKeyUp',
      'handleDayClick',
      'handleMonthChange',
      'handleOverlayFocus',
      'handleOverlayBlur',
    ]);
  }

  componentDidUpdate(prevProps) {
    const newState = {};

    // Current props
    const {
      value,
      formatDate,
      format,
      dayPickerProps,
    } = this.props;

    // Update the input value if `value`, `dayPickerProps.locale` or `format`
    // props have changed
    if (
      value !== prevProps.value
      || dayPickerProps.locale !== prevProps.dayPickerProps.locale
      || format !== prevProps.format
    ) {
      if (isDate(value)) {
        newState.value = formatDate(value, format, dayPickerProps.locale);
      } else {
        newState.value = value;
      }
    }

    // Update the month if the months from props changed
    const prevMonth = prevProps.dayPickerProps.month;
    if (
      dayPickerProps.month
      && dayPickerProps.month !== prevMonth
      && !isSameMonth(dayPickerProps.month, prevMonth)
    ) {
      newState.month = dayPickerProps.month;
    }

    // Updated the selected days from props if they changed
    if (prevProps.dayPickerProps.selectedDays !== dayPickerProps.selectedDays) {
      newState.selectedDays = dayPickerProps.selectedDays;
    }

    if (Object.keys(newState).length > 0) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState(newState);
    }
  }

  componentWillUnmount() {
    cancelAnimationFrame(this.hideTimeout);
    clearTimeout(this.clickTimeout);
    clearTimeout(this.inputFocusTimeout);
    clearTimeout(this.inputBlurTimeout);
    clearTimeout(this.overlayBlurTimeout);
  }

  getInput() {
    return this.input;
  }

  getDayPicker() {
    return this.daypicker;
  }

  /**
   * Update the component's state and fire the `onDayChange` event passing the
   * day's modifiers to it.
   *
   * @param {Date} day - Will be used for changing the month
   * @param {String} value - Input field value
   * @private
   */
  updateState(day, value, callback) {
    const {
      dayPickerProps: { disabledDays, selectedDays, modifiers },
      onDayChange,
      formatDate,
    } = this.props;
    const modObj = {
      disabled: disabledDays,
      selected: selectedDays,
      ...modifiers,
    };
    const modReducer = (obj, modifier) => ({ ...obj, [modifier]: true });
    let date = day;
    let mods = getModifiersForDay(date, modObj).reduce(modReducer, {});
    const complete = () => {
      if (callback) callback();
      if (onDayChange) onDayChange(date, mods, this);
    };
    if (!mods.disabled) {
      this.setState({ month: date, value, typedValue: '' }, complete);
    } else {
      date = new Date(Date.now());
      mods = getModifiersForDay(date, modObj).reduce(modReducer, {});
      // If the user manually enters an invalid date, disallow that; reset to today instead.
      this.setState({ month: date, value: formatDate(date), typedValue: '' }, complete);
    }
  }

  /**
   * Show the Day Picker overlay.
   *
   * @memberof DayPickerInput
   */
  showDayPicker() {
    const { parseDate, format, dayPickerProps } = this.props;
    const { value, showOverlay } = this.state;
    if (showOverlay) {
      return;
    }
    // Reset the current displayed month when showing the overlay
    const month = value
      ? parseDate(value, format, dayPickerProps.locale) // Use the month in the input field
      : getInitialMonthFromProps(this.props); // Restore the month from the props
    this.setState(
      state => ({
        showOverlay: true,
        month: month || state.month,
      }),
      () => {
        const { onDayPickerShow } = this.props;
        if (onDayPickerShow) onDayPickerShow();
      },
    );
  }

  /**
   * Hide the Day Picker overlay
   *
   * @memberof DayPickerInput
   */
  hideDayPicker() {
    const { showOverlay } = this.state;
    if (showOverlay === false) {
      return;
    }
    this.setState({ showOverlay: false }, () => {
      const { onDayPickerHide } = this.props;
      if (onDayPickerHide) onDayPickerHide();
    });
  }

  hideAfterDayClick() {
    const { hideOnDayClick } = this.props;
    if (!hideOnDayClick) {
      return;
    }
    this.hideTimeout = requestAnimationFrame(() => {
      this.overlayHasFocus = false;
      this.hideDayPicker();
    });
  }

  handleCalendarClick(e) {
    this.showDayPicker();
    const { inputProps: { onFocus } } = this.props;
    // Set `overlayHasFocus` after a timeout so the overlay can be hidden when
    // the input is blurred
    this.inputFocusTimeout = setTimeout(() => {
      this.overlayHasFocus = false;
    }, 2);
    if (onFocus) {
      e.persist();
      onFocus(e);
    }
  }

  // When the calendar is blurred, the overlay should disappear.
  handleCalendarBlur(e) {
    const { inputProps: { onBlur } } = this.props;
    this.inputBlurTimeout = setTimeout(() => {
      if (!this.overlayHasFocus) {
        this.hideDayPicker();
      }
    }, 1);
    if (onBlur) {
      e.persist();
      onBlur(e);
    }
  }

  handleOverlayFocus(e) {
    const { keepFocus } = this.props;
    e.preventDefault();
    this.overlayHasFocus = true;
    if (
      !keepFocus
      || !this.calendar
      || typeof this.calendar.focus !== 'function'
    ) {
      return;
    }
    this.calendar.focus();
  }

  handleOverlayBlur() {
    // We need to set a timeout otherwise IE11 will hide the overlay when
    // focusing it
    this.overlayBlurTimeout = setTimeout(() => {
      this.overlayHasFocus = false;
    }, 3);
  }

  handleInputBlur(e) {
    const {
      dayPickerProps: { locale },
      format,
      inputProps,
      onDayChange,
      parseDate,
    } = this.props;
    if (inputProps.onChange) {
      e.persist();
      inputProps.onChange(e);
    }
    const { value } = e.target;
    if (value.trim() === '') {
      this.setState({ value, typedValue: '' });
      if (onDayChange) onDayChange(undefined, {}, this);
      return;
    }
    const day = parseDate(value, format, locale);
    if (!day) {
      // Day is invalid: we save the value in the typedValue state
      this.setState({ value, typedValue: value });
      if (onDayChange) onDayChange(undefined, {}, this);
      return;
    }
    this.updateState(day, value);
  }

  handleInputChange(e) {
    const { inputProps: { onChange } } = this.props;
    if (onChange) {
      e.persist();
      onChange(e);
    }
    const { value } = e.target;
    this.setState({ value, typedValue: value });
  }

  adjustDate(days = 0, months = 0) {
    const {
      dayPickerProps: {
        locale,
        disabledDays,
        selectedDays,
        modifiers,
      },
      onDayChange,
      parseDate,
      formatDate,
      format,
    } = this.props;
    const { value, month } = this.state;
    const curDate = parseDate(value, format, locale);
    const cur = (curDate).getTime();
    const newValue = new Date(days * 864e5 + cur);
    const newMonth = new Date(+month);
    if (months) {
      newValue.setMonth(newValue.getMonth() + months);
      newMonth.setMonth(month.getMonth() + months);
    }
    const mods = getModifiersForDay(newValue, {
      disabled: disabledDays,
      selected: selectedDays,
      ...modifiers,
    });
    if (mods.indexOf('disabled') === -1 && !(months && !isSameMonth(newValue, newMonth))) {
      this.updateState(
        newValue,
        formatDate(newValue, format, locale),
        () => {
          if (onDayChange) {
            onDayChange(newValue, mods, this);
          }
        },
      );
    } else if (months) {
      this.setState({ month: newMonth });
    }
  }

  handleCalendarKeyDown(e) {
    const { inputProps: { onKeyDown } } = this.props;
    if (e.keyCode === TAB) {
      this.hideDayPicker();
      return true;
    }
    if ([UP, DOWN, LEFT, RIGHT, PAGE_UP, PAGE_DOWN, SPACE, ENTER].indexOf(e.keyCode) !== -1) {
      e.preventDefault();
    }
    if (e.keyCode === UP) {
      this.adjustDate(-7);
    } else if (e.keyCode === DOWN) {
      this.adjustDate(7);
    } else if (e.keyCode === LEFT) {
      this.adjustDate(-1);
    } else if (e.keyCode === RIGHT) {
      this.adjustDate(1);
    } else if (e.keyCode === PAGE_UP) {
      this.adjustDate(0, -1);
    } else if (e.keyCode === PAGE_DOWN) {
      this.adjustDate(0, 1);
    } else if (e.keyCode === SPACE || e.keyCode === ENTER) {
      this.hideDayPicker();
      this.input.focus();
      e.preventDefault();
      return false;
    }
    if (onKeyDown) {
      e.persist();
      onKeyDown(e);
    }
    return true;
  }

  handleCalendarKeyUp(e) {
    const { inputProps: { onKeyUp } } = this.props;
    if (e.keyCode === ESC) {
      this.hideDayPicker();
    } else {
      this.showDayPicker();
    }
    if (onKeyUp) {
      e.persist();
      onKeyUp(e);
    }
  }

  handleMonthChange(month) {
    const { dayPickerProps } = this.props;
    this.setState({ month }, () => {
      if (
        dayPickerProps
        && dayPickerProps.onMonthChange
      ) {
        dayPickerProps.onMonthChange(month);
      }
    });
  }

  handleDayClick(day, modifiers, e) {
    const {
      clickUnselectsDay,
      dayPickerProps,
      onDayChange,
      formatDate,
      format,
    } = this.props;
    if (dayPickerProps.onDayClick) {
      dayPickerProps.onDayClick(day, modifiers, e);
    }

    // Do nothing if the day is disabled
    if (
      modifiers.disabled
      || (
        dayPickerProps
        && dayPickerProps.classNames
        && modifiers[dayPickerProps.classNames.disabled]
      )
    ) {
      return;
    }

    // If the clicked day is already selected, remove the clicked day
    // from the selected days and empty the field value
    if (modifiers.selected && clickUnselectsDay) {
      let { selectedDays } = this.state;
      if (Array.isArray(selectedDays)) {
        selectedDays = selectedDays.slice(0);
        const selectedDayIdx = selectedDays.indexOf(day);
        selectedDays.splice(selectedDayIdx, 1);
      } else if (selectedDays) {
        selectedDays = null;
      }

      this.setState(
        { value: '', typedValue: '', selectedDays },
        this.hideAfterDayClick,
      );

      if (onDayChange) {
        onDayChange(undefined, modifiers, this);
      }
      return;
    }

    const value = formatDate(day, format, dayPickerProps.locale);
    this.setState({ value, typedValue: undefined, month: day }, () => {
      if (onDayChange) {
        onDayChange(day, modifiers, this);
      }
      this.hideAfterDayClick();
    });
  }

  renderOverlay() {
    const {
      classNames,
      dayPickerProps,
      parseDate,
      formatDate,
      format,
      overlayComponent: Overlay,
    } = this.props;
    const { selectedDays, value, month } = this.state;
    let selectedDay;
    if (!selectedDays && value) {
      const day = parseDate(value, format, dayPickerProps.locale);
      if (day) {
        selectedDay = day;
      }
    } else if (selectedDays) {
      selectedDay = selectedDays;
    }
    let onTodayButtonClick;
    if (dayPickerProps.todayButton) {
      // Set the current day when clicking the today button
      onTodayButtonClick = () => (
        this.updateState(
          new Date(Date.now()),
          formatDate(new Date(Date.now()), format, dayPickerProps.locale),
          this.hideAfterDayClick,
        )
      );
    }
    return (
      <Overlay
        classNames={classNames}
        month={month}
        selectedDay={selectedDay}
        input={this.input}
        tabIndex={0} // tabIndex is necessary to catch focus/blur events on Safari
        onFocus={this.handleOverlayFocus}
        onBlur={this.handleOverlayBlur}
      >
        <DayPicker
          ref={(el) => {
            this.daypicker = el;
          }}
          onTodayButtonClick={onTodayButtonClick}
          {...dayPickerProps}
          month={month}
          selectedDays={selectedDay}
          onDayClick={this.handleDayClick}
          onMonthChange={this.handleMonthChange}
        />
      </Overlay>
    );
  }

  render() {
    const {
      component: Input,
      inputProps: {
        // Filter out error-inducing props
        formatDate,
        overlayComponent,
        onDayChange,
        ...inputProps
      },
      classNames: {
        container,
      } = {},
      style,
      placeholder,
    } = this.props;
    const {
      value,
      typedValue,
      showOverlay,
    } = this.state;
    return (
      <div className={container} style={style}>
        <Input
          ref={(el) => {
            this.input = el;
          }}
          placeholder={placeholder}
          {...inputProps}
          value={typedValue || value}
          onBlur={this.handleInputBlur}
          onChange={this.handleInputChange}
          dispatch={null}
        />
        <div
          ref={(el) => {
            this.calendar = el;
          }}
          name={inputProps.name}
          role="button"
          tabIndex="-1"
          onClick={this.handleCalendarClick}
          onBlur={this.handleCalendarBlur}
          onKeyDown={this.handleCalendarKeyDown}
          onKeyUp={this.handleCalendarKeyUp}
          title="Select a date"
        >
          <IconCalendar className="date-picker__icon svg-icon--28" />
        </div>
        {showOverlay && this.renderOverlay()}
      </div>
    );
  }
}

DayPickerInput.defaultProps = {
  dayPickerProps: {},
  value: '',
  typedValue: '',
  placeholder: 'YYYY-mm-dd',
  format: 'L',
  formatDate: defaultFormat,
  parseDate: defaultParse,
  showOverlay: false,
  hideOnDayClick: true,
  clickUnselectsDay: false,
  keepFocus: true,
  component: 'input',
  inputProps: {},
  overlayComponent: OverlayComponent,
  classNames: {
    container: 'DayPickerInput',
    overlayWrapper: 'DayPickerInput-OverlayWrapper',
    overlay: 'DayPickerInput-Overlay',
  },
};
