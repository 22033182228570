import React from 'react';
import Modal from './Modal';
import SvgIcon from './svgs/SvgIcon';

const ModalShingle = ({
  featurePath,
  icon,
  headline,
  message,
  dismiss,
  tracking,
  action,
  actionText,
  actionClass,
}) => (
  <Modal
    isOpen
    onRequestClose={dismiss}
    dismissable={!!dismiss}
  >
    <div tracking-module={tracking} className="text-center">
      <div><SvgIcon icon={icon} /></div>
      <div className="hgroup">
        <h2>{headline}</h2>
        {message}
      </div>
      {action && actionText && <a className={actionClass} href={action}>{actionText}</a>}
    </div>
  </Modal>
);

export default ModalShingle;
