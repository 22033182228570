import getConfig from '../config';

const { billServicesUrl } = getConfig();

export const SET_SUCCESS = 'SET_SUCCESS';
export const SET_ERROR = 'SET_ERROR';
export const RESET_USER_MESSAGES = 'RESET_USER_MESSAGES';
export const MESSAGE_SHOWN = 'MESSAGE_SHOWN';

async function dispatchSetSuccess({
  heading,
  body,
  link,
  linkText,
  showPushdown = false,
} = {}) {
  return {
    type: SET_SUCCESS,
    payload: {
      heading,
      body,
      link: link ?? billServicesUrl,
      linkText: linkText ?? 'Back to Billing',
      showPushdown,
    },
  };
}

function dispatchSetError({
  text,
  link,
  linkText,
  showModal = false,
} = {}) {
  return {
    type: SET_ERROR,
    payload: {
      text,
      link,
      linkText,
      showModal,
    },
  };
}

function dispatchResetUserMessages() {
  return {
    type: RESET_USER_MESSAGES,
  };
}

function dispatchMessageShown() {
  return {
    type: MESSAGE_SHOWN,
  };
}

export const setSubmit = () => dispatch => dispatch(dispatchResetUserMessages());
export const setSuccess = success => async (dispatch) => {
  dispatch(await dispatchSetSuccess(success));
};
export const setError = error => dispatch => dispatch(dispatchSetError(error));
export const resetUserMessages = () => dispatch => dispatch(dispatchResetUserMessages());
export const messageShown = () => dispatch => dispatch(dispatchMessageShown());
