import {
  SUBMIT_PAYMENT, SUBMIT_PAYMENT_SUCCESS, SUBMIT_PAYMENT_FAILURE,
  REVIEW_PAYMENT, CONFIRM_PAYMENT, RESET_PAYMENT, CLEAR_ERROR,
} from '../actions/payment';

const INITIAL_STATE = { submitting: false };

export default function paymentReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SUBMIT_PAYMENT:
      return {
        ...state,
        submitting: true,
      };
    case SUBMIT_PAYMENT_SUCCESS:
      return {
        ...state,
        submitting: false,
        error: false,
        review: {
          ...state.review,
          cardNumber: null,
          cvv: null,
          expirationMonth: null,
          expirationYear: null,
          bankNumber: null,
          routeNumber: null,
        },
        confirm: {
          ...state.review,
          // Clear PCI
          cardNumber: null,
          cvv: null,
          expirationMonth: null,
          expirationYear: null,
          bankNumber: null,
          routeNumber: null,
          ...action.payload,
        },
      };
    case SUBMIT_PAYMENT_FAILURE:
      return {
        ...state,
        submitting: false,
        error: action.payload,
      };
    case REVIEW_PAYMENT:
      return {
        ...state,
        confirm: null,
        review: action.payload,
      };
    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };
    case CONFIRM_PAYMENT:
      return {
        ...INITIAL_STATE,
        confirm: state.confirm,
      };
    case RESET_PAYMENT:
      return INITIAL_STATE;
    default:
      return state;
  }
}
