import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import Modal from './Modal';
import CtaLink from './CtaLink';

import { getCMSPackage } from '../actions/cms';
import useImpression from '../hooks/useImpression';

const cmsName = 'xap-ineligible-modal';

const XAPIneligibleInterstitial = ({
  handleGetCopy,
  loading,
  contentReady,
  content: {
    heading,
    description,
    cta: ctas = [],
    pairs: metadata = [],
  } = {},
}) => {
  const isOpen = contentReady;
  const primaryCta = ctas.find(cta => cta.type === 'primary');
  const secondaryCta = ctas.find(cta => cta.type === 'secondary');
  const adobeComponentName = metadata.find((item = {}) => item.key === 'adobeComponentName');
  const componentName = adobeComponentName && adobeComponentName.value;

  useEffect(() => handleGetCopy(cmsName).catch(() => null), [handleGetCopy]);
  useImpression({
    placementName: cmsName,
    componentName,
    when: !loading,
    if: isOpen,
    id: 'xap-ineligible-interstitial',
  });

  return (
    <Modal
      dismissable={false}
      isOpen={isOpen}
    >
      <div tracking-module="xap-ineligible-interstitial" className="text-center">
        <h2>{heading}</h2>
        <p>{description}</p>
        <div className="action">
          {primaryCta && (
            <CtaLink
              linkClass="button button--primary"
              {...primaryCta}
            />
          )}
          {secondaryCta && (
            <CtaLink
              linkClass="button button--text"
              {...secondaryCta}
            />
          )}
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = ({
  cms: {
    packages: {
      xapIneligibleModal: {
        loading = true,
        error,
        content = {},
      } = {},
    },
  },
}) => ({
  loading,
  error,
  content,
  contentReady: !loading && !error,
});

const mapDispatchToProps = dispatch => ({
  handleGetCopy: packageName => dispatch(getCMSPackage(packageName, { storeKey: 'xapIneligibleModal' })),
});

export default connect(mapStateToProps, mapDispatchToProps)(XAPIneligibleInterstitial);
