import qs from 'qs';
import BrowserInterface from '@harness/browser-interface';
import { fromByteArray as b64FromByteArray } from 'base64-js';
import { TextEncoder } from 'text-encoding-shim';

import apiClient from '../middleware/apiClient';
import {
  APPLE_PAY,
} from '../helpers/apis';

import { getApplePaymentRequest } from '../helpers/applePay';


const { location } = window;
const searchParams = qs.parse(location.search.slice(1));

export const SET_APPLE_PAY = 'SET_APPLE_PAY';
export const SET_APPLE_PAY_SUCCESS = 'SET_APPLE_PAY_SUCCESS';
export const SET_APPLE_PAY_FAILURE = 'SET_APPLE_PAY_FAILURE';

function dispatchSetApplePay() {
  return {
    type: SET_APPLE_PAY,
    payload: window.ApplePaySession.canMakePaymentsWithActiveCard('merchant.com.comcast.cim.myaccount'),
  };
}

function dispatchSetApplePaySuccess(payload) {
  return {
    type: SET_APPLE_PAY_SUCCESS,
    payload,
  };
}

function dispatchSetApplePayFailure() {
  return {
    type: SET_APPLE_PAY_FAILURE,
  };
}

export const setApplePay = () => async (dispatch) => {
  // The harness will set mp=ApplePay if it is allowed in the harness
  const { mp: mobilePayment } = searchParams;
  if (mobilePayment === 'ApplePay') {
    return dispatch(dispatchSetApplePaySuccess());
  }

  if (!window.ApplePaySession) {
    return dispatch(dispatchSetApplePayFailure());
  }

  return dispatch(dispatchSetApplePay()).payload
    .then((canMakePaymentsWithActiveCard) => {
      if (canMakePaymentsWithActiveCard) {
        return dispatch(dispatchSetApplePaySuccess());
      }
      return dispatch(dispatchSetApplePayFailure());
    });
};

export const BEGIN_APPLE_PAY = 'BEGIN_APPLE_PAY';
export const GET_APPLE_PAY_SESSION = 'GET_APPLE_PAY_SESSION';
export const GET_APPLE_PAY_SESSION_SUCCESS = 'GET_APPLE_PAY_SESSION_SUCCESS';
export const GET_APPLE_PAY_SESSION_FAILURE = 'GET_APPLE_PAY_SESSION_FAILURE';
export const APPLE_PAY_SUCCESS = 'APPLE_PAY_SUCCESS';
export const CANCEL_APPLEPAY_SESSION = 'CANCEL_APPLEPAY_SESSION';

function dispatchBeginApplePay() {
  return {
    type: BEGIN_APPLE_PAY,
  };
}

function dispatchGetApplePaySession(data) {
  return {
    type: GET_APPLE_PAY_SESSION,
    data,
    payload: apiClient.fetch(APPLE_PAY, { method: 'POST', body: JSON.stringify(data) }),
  };
}

function dispatchGetApplePaySessionSuccess(payment) {
  return {
    type: GET_APPLE_PAY_SESSION_SUCCESS,
    payload: payment,
  };
}

function dispatchGetApplePaySessionFailure(error) {
  return {
    type: GET_APPLE_PAY_SESSION_FAILURE,
    payload: error,
  };
}

function dispatchApplePaySuccess(payload) {
  return {
    type: APPLE_PAY_SUCCESS,
    payload,
  };
}

function dispatchCancelApplePaySession() {
  return {
    type: CANCEL_APPLEPAY_SESSION,
  };
}

const getApplePaySessionFromHarness = amount => dispatch => new Promise(((resolve, reject) => {
  const applePaymentRequest = getApplePaymentRequest(amount);

  dispatch(dispatchBeginApplePay());

  BrowserInterface.addListener(({ type, payload }) => {
    if (type === 'MOBILE_PAYMENT_AUTHORIZE') {
      // match object structure from getApplePaySession
      const applePayBytes = (new TextEncoder('utf-8')).encode(payload.token);
      const applePayBase64result = b64FromByteArray(applePayBytes);
      resolve({
        payload: {
          amount: amount || 1.00,
          data: applePayBase64result,
          type: payload.type,
        },
      });
      dispatch(dispatchApplePaySuccess(payload));
    } else if (type === 'MOBILE_PAYMENT_ABORT') {
      const error = { type: 'cancel' };
      reject(error);
      dispatch(dispatchCancelApplePaySession());
    }
  });

  BrowserInterface.sendMessageToNative({
    type: 'MOBILE_PAYMENT_REQUEST',
    payload: {
      type: 'ApplePay',
      amount: amount || 1.00,
      label: applePaymentRequest.total.label,
      merchantIdentifier: 'merchant.com.comcast.cim.myaccount',
      currencyCode: applePaymentRequest.currencyCode,
      countryCode: applePaymentRequest.countryCode,
      supportedNetworks: applePaymentRequest.supportedNetworks,
      merchantCapabilities: 'PKMerchantCapability3DS',
      requiredBillingAddressFields: ['PKAddressFieldName', 'PKAddressFieldPostalAddress', 'PKAddressFieldEmail'],
    },
  });
}));

export const getApplePaySession = amount => (dispatch, getState) => {
  const { harness: { isHarness } } = getState();
  const paymentRequest = getApplePaymentRequest(amount);

  if (isHarness) {
    return dispatch(getApplePaySessionFromHarness(amount));
  }

  // Autopay case
  if (!amount) {
    paymentRequest.total.type = 'pending';
  }

  return new Promise((resolve, reject) => {
    // Create apple pay session with paymentRequest data object
    const applePaySession = new window.ApplePaySession(2, paymentRequest);

    applePaySession.onpaymentauthorized = (event) => {
      const applePayPayload = JSON.stringify(event.payment.token.paymentData);
      const applePayBytes = (new TextEncoder('utf-8')).encode(applePayPayload);
      const applePayBase64result = b64FromByteArray(applePayBytes);

      const payload = {
        type: 'ApplePay',
        amount: amount || 1.00,
        data: applePayBase64result,
      };
      // Happy path exit point
      resolve({ payload, applePaySession });
      return dispatch(dispatchApplePaySuccess(payload));
    };


    applePaySession.oncancel = (event) => {
      dispatch(dispatchCancelApplePaySession());
      reject(event);
    };

    applePaySession.onvalidatemerchant = event => dispatch(dispatchGetApplePaySession({
      validationURL: event.validationURL,
      merchantIdentifier: 'merchant.com.comcast.cim.myaccount',
      domainName: location.host,
      displayName: 'Comcast',
    })).payload
      .then((merchantSession) => {
        // session only valid for 5 minutes
        // pass merchantSession object/identifier/string to apple's completeMV function
        // (handshake)
        applePaySession.completeMerchantValidation(merchantSession);
        // User is now presented to confirm/authorize payment on their device iPhone or watch.
        return dispatch(dispatchGetApplePaySessionSuccess());
      })
      .catch((error) => {
        reject(error);
        applePaySession.abort();
        return dispatch(dispatchGetApplePaySessionFailure(error));
      });
    applePaySession.begin();

    return dispatch(dispatchBeginApplePay());
  });
};

export default getApplePaymentRequest;
