import {
  COLLECTIONS,
  COLLECTIONS_REVIEW,
  COLLECTIONS_CARD,
  COLLECTIONS_BANK,
  COLLECTIONS_CONFIRMATION,

  NEW_PAYMENT,
  NEW_PAYMENT_REVIEW,
  NEW_PAYMENT_CARD,
  NEW_PAYMENT_BANK,
  NEW_PAYMENT_CONFIRMATION,
  AUTHENTICATION_REQUIRED,

  PAYMENT_PLAN,
  PAYMENT_PLAN_BANK,
  PAYMENT_PLAN_CARD,
  PAYMENT_PLAN_REVIEW,
  PAYMENT_PLAN_CONFIRMATION,

  AUTOPAY,
  AUTOPAY_NEW_CARD,
  AUTOPAY_NEW_BANK,
  AUTOPAY_EDIT,

  METHODS,
  METHOD_NEW,
  METHOD_NEW_CARD,
  METHOD_NEW_BANK,
  METHOD_EDIT,

  HOME,
  SCHEDULED_PAYMENT_EDIT,
  SCHEDULED_PAYMENT_REVIEW,
  SCHEDULED_PAYMENT_CONFIRM,
  SCHEDULED_PAYMENT_CANCEL_CONFIRM,

  PAYMENT_SETUP,

  TERMS_AUTOPAY,
  TERMS_STORED_PAYMENT,
  TERMS_ECOBILL,
} from './routes';

import { getIsToday } from './date';

import impressionCache from './impressionCache';
import { randomId } from './uids';

const baseDigitalData = {
  schemaVersion: 0.11,
  page: {
    category: {
      primaryCategory: 'myaccount',
      subCategory1: 'billing',
      businessType: 'resi',
      siteType: 'selfservice',
      designType: 'responsive',
    },
    codebase: {
      name: 'my account payments',
      releaseVersion: '12/12/2018', // using release date for now
    },
    affiliate: {
      channel: 'web',
      name: 'comcast',
    },
    pageInfo: {
      language: 'en',
      attributes: {
        experience: 'web',
      },
    },
    attributes: {},
  },
  siteInfo: {
    sessionID: randomId().substring(0, 9)
               + randomId().substring(0, 9), // just random string
    // visitorID: 'TODO - use value from item 2 below'
  },
  user: [
    {
      profile: [{
        profileInfo: {
          customerType: 'existing',
          authenticationType: 'active authenticated',
          recognizationType: 'cookie recognized',
        },
      }],
    },
  ],
};

/**
 * Log all DTM calls to console.
 * @param enabled
 *  A local flag, e.g., `const enabled = getConfig().REACT_APP_DTMLOG === 'TRUE'`
 * @constructor
 */
export const DTM2Console = (enabled = false) => {
  if (enabled === true) {
    window.trackingDebug = true;
  }
};

export const getTrackingData = (
  macaroon = {},
  account = {},
  isHarness = false,
  crs = {},
) => {
  const digitalData = Object.assign({}, baseDigitalData);

  // Override the `experience: 'web'` flag to `native` when app is in harness.
  if (isHarness) {
    digitalData.page.pageInfo.attributes.experience = 'native';
  }
  if ('affiliate' in account) {
    digitalData.page.pageInfo.attributes.referrerId = account.affiliate;
  }
  if (macaroon.guid) {
    digitalData.user[0].profile[0].profileInfo = {
      ...digitalData.user[0].profile[0].profileInfo,
      userGUID: macaroon.guid,
      type: macaroon.mainAccountRoles.indexOf('PRIMARY') !== -1 ? 'primary' : 'SECONDARY',
    };
  }

  if (macaroon.mainAccountNumber) {
    const acct = (macaroon.accounts || []).find(acc => (
      acc.accountNumber === macaroon.mainAccountNumber
    ));
    if (acct) {
      digitalData.user[0].segment = {
        marketID: acct.accountNumber.substring(0, 8),
      };
      digitalData.user[0].profile[0].profileInfo = {
        ...digitalData.user[0].profile[0].profileInfo,
        preActivationState: 'Active',
        authGUID: acct.guid,
      };
    }
  }


  if (macaroon.lite) {
    const { profileInfo } = digitalData.user[0].profile[0];
    if (macaroon.walledgarden) {
      profileInfo.authenticationType = 'walledgarden authenticated';
    } else {
      profileInfo.authenticationType = 'lite authenticated';
    }
  }

  if (crs && crs.customerGuid) {
    digitalData.user[0].profile[0].profileInfo = {
      ...digitalData.user[0].profile[0].profileInfo,
      userGUID: crs.customerGuid,
      type: 'CONSENT',
      customerType: 'new',
    };
  }
  return digitalData;
};

export const addComponent = component => impressionCache.add(component);
export const setComponent = (id, component) => impressionCache.set(id, component);
export const flushComponent = more => impressionCache.flush(more);

const screenList = {
  [NEW_PAYMENT]: 'payment',
  [NEW_PAYMENT_BANK]: 'new bank',
  [NEW_PAYMENT_CARD]: 'new card',
  [NEW_PAYMENT_REVIEW]: 'review',
  [NEW_PAYMENT_CONFIRMATION]: 'confirm',
  [AUTHENTICATION_REQUIRED]: 'auth-required',

  [COLLECTIONS]: 'collections',
  [COLLECTIONS_BANK]: 'new bank',
  [COLLECTIONS_CARD]: 'new card',
  [COLLECTIONS_REVIEW]: 'review',
  [COLLECTIONS_CONFIRMATION]: 'confirm',

  [PAYMENT_PLAN]: 'payment plan',
  [PAYMENT_PLAN_BANK]: 'new bank',
  [PAYMENT_PLAN_CARD]: 'new card',
  [PAYMENT_PLAN_REVIEW]: 'review',
  [PAYMENT_PLAN_CONFIRMATION]: 'confirm',

  [COLLECTIONS]: 'collections',
  [COLLECTIONS_BANK]: 'new bank',
  [COLLECTIONS_CARD]: 'new card',
  [COLLECTIONS_REVIEW]: 'review',
  [COLLECTIONS_CONFIRMATION]: 'confirm',

  [PAYMENT_PLAN]: 'payment plan',
  [PAYMENT_PLAN_BANK]: 'new bank',
  [PAYMENT_PLAN_CARD]: 'new card',
  [PAYMENT_PLAN_REVIEW]: 'review',
  [PAYMENT_PLAN_CONFIRMATION]: 'confirm',

  [AUTOPAY]: 'autopay',
  [AUTOPAY_NEW_CARD]: 'new card',
  [AUTOPAY_NEW_BANK]: 'new bank',
  [AUTOPAY_EDIT]: 'edit',

  [METHODS]: 'methods',
  [METHOD_NEW]: 'new',
  [METHOD_NEW_CARD]: 'new card',
  [METHOD_NEW_BANK]: 'new bank',
  [METHOD_EDIT]: 'edit',

  [HOME]: 'scheduled payments',
  [SCHEDULED_PAYMENT_EDIT]: 'edit',
  [SCHEDULED_PAYMENT_REVIEW]: 'review',
  [SCHEDULED_PAYMENT_CONFIRM]: 'confirm',
  [SCHEDULED_PAYMENT_CANCEL_CONFIRM]: 'cancel confirmation',

  [TERMS_AUTOPAY]: 'autopay',
  [TERMS_STORED_PAYMENT]: 'stored payment',
  [TERMS_ECOBILL]: 'ecobill',

  [PAYMENT_SETUP]: 'billingsetupmodule',
};

export const mapRouteToName = (match) => {
  if (!match || !match.path) {
    return false;
  }

  return screenList[match.url] || screenList[match.path] || match.path;
};

const categoryList = {
  [NEW_PAYMENT_BANK]: 'payment',
  [NEW_PAYMENT_CARD]: 'payment',
  [NEW_PAYMENT_REVIEW]: 'payment',
  [NEW_PAYMENT_CONFIRMATION]: 'payment',

  [COLLECTIONS_BANK]: 'collections',
  [COLLECTIONS_CARD]: 'collections',
  [COLLECTIONS_REVIEW]: 'collections',
  [COLLECTIONS_CONFIRMATION]: 'collections',

  [PAYMENT_PLAN_BANK]: 'xap',
  [PAYMENT_PLAN_CARD]: 'xap',
  [PAYMENT_PLAN_REVIEW]: 'xap',
  [PAYMENT_PLAN_CONFIRMATION]: 'xap',

  [AUTOPAY_NEW_CARD]: 'autopay',
  [AUTOPAY_NEW_BANK]: 'autopay',
  [AUTOPAY_EDIT]: 'autopay',

  [METHOD_NEW]: 'methods',
  [METHOD_NEW_CARD]: 'methods',
  [METHOD_NEW_BANK]: 'methods',
  [METHOD_EDIT]: 'methods',

  [SCHEDULED_PAYMENT_EDIT]: 'scheduled payments',
  [SCHEDULED_PAYMENT_REVIEW]: 'scheduled payments',
  [SCHEDULED_PAYMENT_CONFIRM]: 'scheduled payments',
  [SCHEDULED_PAYMENT_CANCEL_CONFIRM]: 'scheduled payments',

  [TERMS_AUTOPAY]: 'terms',
  [TERMS_STORED_PAYMENT]: 'terms',
  [TERMS_ECOBILL]: 'terms',

  [PAYMENT_SETUP]: 'billing',
};

export const mapRouteToSubCategory = (match) => {
  if (!match || !match.path) {
    return false;
  }

  return categoryList[match.url] || categoryList[match.path];
};

const getPaymentTypeAnalyticsString = (isScheduled, formObj) => {
  const scheduledString = isScheduled ? 'scheduled:' : '';
  const { paymentMethodOption, instrument } = formObj;

  let method = 'token';

  if (paymentMethodOption === 'PaymentCard') {
    method = `card:${instrument.cardType}`;
  }

  if (paymentMethodOption === 'Bank') {
    method = `bank:${instrument.bankAccountType}`;
  }

  if (paymentMethodOption === 'Apple Pay') {
    method = 'applepay';
  }

  let tokenString;

  if (instrument) {
    tokenString = instrument.type === 'PaymentCard' ? `card:${instrument.cardType}` : `bank:${instrument.bankAccountType}`;
  }

  return `onetimepay:${scheduledString}${method === 'token' ? `${tokenString}:ewallet` : method}`.toLowerCase();
};

export const newPaymentTrackingPayload = (
  summary = {},
  savedPaymentMethods = [],
  showLateFeeCredit = false,
  isLite = false,
  isXapExit = false,
) => {
  let pageLoadEvent = 'payment start';

  const { balanceDue, pastDueBalanceRemaining } = summary || {};

  // If user has saved payment instruments
  if (savedPaymentMethods.length) {
    pageLoadEvent += ', ewallet impression';
  }

  const component = [];

  const paymentAmountOptions = {};

  if (!isLite && showLateFeeCredit) {
    component.push({
      placementName: 'myaccount|billing',
      componentName: 'late-fee-banner',
    });
  }
  if (isLite && isXapExit) {
    component.push({
      placementName: 'myaccount|billing|lite',
      componentName: 'bannerxap',
    });
  }

  if (balanceDue > 0.00) {
    paymentAmountOptions.balanceDue = balanceDue;
  }

  if (pastDueBalanceRemaining > 0.00) {
    paymentAmountOptions.pastDueBalanceRemaining = pastDueBalanceRemaining;
  }

  return {
    component,
    pageLoadEvent,
    transaction: 'single payment',
    trackName: 'c-tracking-log-page',
    deleteComponent: true,
    deleteEvents: true,
    attributes: {
      isBillPaymentStart: true,
      hasEwallet: !!savedPaymentMethods.length,
    },
    billPay: {
      paymentAmountOptions,
    },
  };
};

export const newPaymentReviewTrackingPayload = (
  reviewMessage = '',
  showLateFeeCredit = false,
  isLite = false,
) => {
  const component = [];

  if (reviewMessage.length) {
    component.push([{
      placementName: 'myaccount|billing',
      componentName: reviewMessage,
    }]);
  }

  // we don't show late fee credit banner on review anymore
  // this might not be needed?
  if (!isLite && showLateFeeCredit) {
    component.push([{
      placementName: 'myaccount|billing',
      componentName: 'late-fee-banner',
    }]);
  }

  return {
    attributes: {
      isBillPaymentReview: true,
    },
    deleteComponent: true,
    component,
  };
};

export const newPaymentConfirmationTrackingPayload = (
  summary = {},
  formObj = {},
  showFlexPromo,
  peacockPromo = { show: false },
) => {
  const isScheduled = formObj.date && !getIsToday(formObj.date);
  const { balanceDue, pastDueBalanceRemaining } = summary || {};
  const component = [];

  const attributes = { isBillPaymentConfirm: true };

  if (isScheduled) {
    attributes.isSinglePaymentScheduled = true;
  } else {
    attributes.isSinglePaymentToday = true;
  }

  const billPay = {
    paymentType: getPaymentTypeAnalyticsString(isScheduled, formObj),
    amount: formObj.amount,
    billingChannel: 'my account',
  };

  if (balanceDue) {
    billPay.amountDue = balanceDue;
    billPay.isTotalBalancePaid = balanceDue <= 0;
  }

  if (pastDueBalanceRemaining) {
    billPay.amountPastDueRemaining = pastDueBalanceRemaining;
  }

  if (showFlexPromo && !peacockPromo.show) {
    component.push({
      placementName: 'myaccount|billing|payment|confirm',
      componentName: 'flex-promo-banner',
    });
  }

  if (peacockPromo.show) {
    component.push({
      placementName: 'myaccount|offer',
      componentName: peacockPromo.scenario.l3Value,
    });
  }

  return {
    transaction: 'Single Payment',
    pageLoadEvent: `Single payment ${isScheduled ? 'scheduled' : 'today'}`,
    trackName: 'c-tracking-log-page',
    deleteComponent: true,
    deleteEvents: true,
    component,
    attributes,
    billPay,
  };
};
