import { p2pProps } from '../../helpers/p2p';
import {
  paymentDateOk,
  paymentDateWarn,
  existingPromiseDateWarn,
} from './messages';
import { flagEnabled } from '../../helpers/featureFlags';

const P2PPaymentDate = ({ children, ...props }) => {
  const def = children || null;
  const p2pMessaging = flagEnabled('p2pMessaging');
  if (!p2pMessaging) return def;
  const {
    isP2P,
    p2pDate,
    qualifies,
    p2pMinAmount,
    qualifyingDate,
    showLater,
    laterDate,
    laterAmount,
    pastDue,
    existingPromiseDate,
    softDisconnected,
    isExistingP2P,
  } = p2pProps(props);

  if (isExistingP2P) {
    if (qualifies) {
      return paymentDateOk({ ...(showLater && { qualifyingDate }) });
    }
    return existingPromiseDateWarn({
      softDisconnected,
      pastDue,
      existingPromiseDate,
    });
  }
  if (!isP2P) return def;
  if (qualifies) {
    return paymentDateOk({ ...(showLater && { qualifyingDate }) });
  }
  // If date is not OK, warn.
  return paymentDateWarn({
    p2pDate,
    laterDate,
    p2pMinAmount,
    laterAmount,
  });
};

export default P2PPaymentDate;
