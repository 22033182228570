import React, { Component } from 'react';
import GenericError from '../containers/Errors/GenericError';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  render() {
    const { hasError } = this.state;
    const { isLite, isHarness } = this.props;
    if (hasError) {
      // You can render any custom fallback UI
      return (
        <GenericError isLite={isLite} isHarness={isHarness} isErrorBoundary />
      );
    }

    const { children } = this.props;

    return children;
  }
}
export default ErrorBoundary;
