// Convert NAME_OF_THING to NameOfThing
export const snakeToPascal = s => s
  .toLowerCase()
  .replace(/(^|_)\w/g, b => (
    b.substring(Math.max(b.length - 1, 0)).toUpperCase()
  ));

// Filter an object to only its keys with defined values.
export const objAsDefined = (props) => {
  const defined = {};
  const defKeys = Object.keys(props).filter(key => props[key] !== undefined);
  defKeys.forEach((key) => {
    defined[key] = props[key];
  });
  return defined;
};
