// Disabling this because helper files are implicitly multi-export.
// eslint-disable-next-line import/prefer-default-export
export const getSearchState = ({ query, params, caseInsensitive }) => (
  query.replace(/^\?/, '').split('&').reduce((state, item) => {
    const [name, ...rest] = item.split('=');
    const value = decodeURIComponent(rest.join('='));
    const paramName = params.find((caseInsensitive
      ? param => param.toLowerCase() === name.toLowerCase()
      : param => param === name
    ));
    if (!!paramName && !!value) {
      return { ...state, [paramName]: value };
    }
    return state;
  }, {})
);

export const sanitizeUrl = (url, defaultUrl) => {
  if (!url) return undefined;
  try {
    const parsed = new URL(url);
    if (parsed.protocol !== 'https:') {
      return undefined;
    }
    return url;
  } catch (e) {
    return undefined;
  }
};
