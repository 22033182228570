import Cookies from 'js-cookie';
import get from 'lodash.get';

export const getIsXapCurrent = (account) => {
  const xap = get(account, 'xap') || {};
  return !!(xap.status && xap.state === 'XAP_CURRENT');
};
export const getIsXapExit = account => get(account, 'xap.xapExit') === 'NOT_STARTED';
export const getIsXapExitInProgress = account => get(account, 'xap.xapExit') === 'IN_PROGRESS';

export const xapDismissed = id => !!Cookies.get(id);

export const setXapDismissed = (id) => {
  Cookies.set(id, 1, { domain: '.xfinity.com', path: '/' });
};

export const XAP_INELIGIBLE_ERROR = 'CON400.LTIP.80.5';
export const PLACEHOLDER_PAYMENT_PLAN_OPTIONS = [{
  paymentAmount: 0,
  numberOfPayments: 3,
  frequency: 'monthly',
}, {
  paymentAmount: 0,
  numberOfPayments: 6,
  frequency: 'monthly',
}];
