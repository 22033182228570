import React from 'react';

const style = {
  fill: 'none',
  stroke: 'currentColor',
  strokeMiterlimit: 10,
};

const icon = () => (
  <svg style={{ marginLeft: '6px', width: '22px', height: '22px' }} focusable="false" viewBox="0 0 22 22">
    <g fill="none">
      <circle style={style} cx="11" cy="11" r="10" />
      <path d="M13.14 15.665l-.168.682c-.5.198-.902.35-1.2.452-.298.103-.645.155-1.04.155-.606 0-1.078-.15-1.415-.444-.336-.295-.505-.67-.505-1.127 0-.176.012-.357.038-.542.025-.184.066-.393.122-.626l.626-2.216c.056-.212.103-.413.14-.604.04-.19.058-.363.058-.52 0-.282-.058-.48-.175-.59-.116-.112-.338-.17-.67-.17-.16 0-.327.027-.497.077-.17.052-.316.1-.438.146l.167-.684c.41-.167.803-.31 1.178-.43.375-.118.73-.177 1.065-.177.602 0 1.067.145 1.393.437.327.29.49.67.49 1.134 0 .097-.01.266-.034.51-.022.242-.064.465-.125.667L11.524 14c-.05.178-.097.38-.137.61-.04.226-.06.4-.06.515 0 .293.065.493.196.6.132.106.36.16.682.16.152 0 .324-.028.516-.08.19-.053.33-.1.417-.14zm.157-9.27c0 .386-.145.714-.436.984-.29.27-.64.406-1.05.406-.41 0-.762-.136-1.055-.407-.294-.27-.44-.6-.44-.984s.146-.713.44-.986c.293-.274.645-.41 1.055-.41.41 0 .76.137 1.05.41.292.273.437.602.437.986z" fill="currentColor" />
    </g>
  </svg>
);

export default icon;
