import memoized from '../memoized';

const ZIPCODE = /^[0-9]{5}(?:-[0-9]{4})?$/;

const ZipCodeFactory = memoized(message => (value = '') => (
  !ZIPCODE.test(value)
    ? message
    : undefined
));

export default ZipCodeFactory;
