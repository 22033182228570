import {
  GET_CMS_SEGMENTS,
  GET_CMS_SEGMENTS_SUCCESS,
  GET_CMS_SEGMENTS_FAILURE,
  GET_CMS_PACKAGE,
  GET_CMS_PACKAGE_SUCCESS,
  GET_CMS_PACKAGE_FAILURE,
  GET_CMS_CAMPAIGNS,
  GET_CMS_CAMPAIGNS_SUCCESS,
  GET_CMS_CAMPAIGNS_FAILURE,
} from '../actions/cms';

export const INITIAL_STATE = {
  segments: {
    loading: false,
    data: undefined,
  },
  campaigns: {
    loading: false,
    data: undefined,
  },
  packages: {},
};

export default function cmsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_CMS_SEGMENTS:
      return {
        ...state,
        segments: {
          ...state.segments,
          promise: action.payload,
          loading: true,
          error: undefined,
        },
      };
    case GET_CMS_SEGMENTS_SUCCESS:
      return {
        ...state,
        segments: {
          ...state.segments,
          loading: false,
          data: action.payload,
        },
      };
    case GET_CMS_SEGMENTS_FAILURE:
      return {
        ...state,
        segments: {
          ...state.segments,
          loading: false,
          error: action.payload,
          data: undefined,
        },
      };
    case GET_CMS_PACKAGE:
      return {
        ...state,
        packages: {
          ...state.packages,
          [action.storePackageKey]: {
            ...state.packages[action.storePackageKey],
            cached: true,
            loading: true,
          },
        },
      };
    case GET_CMS_PACKAGE_SUCCESS:
      return {
        ...state,
        packages: {
          ...state.packages,
          [action.storePackageKey]: {
            ...state.packages[action.storePackageKey],
            loading: false,
            error: false,
            content: action.payload,
          },
        },
      };
    case GET_CMS_PACKAGE_FAILURE:
      return {
        ...state,
        packages: {
          ...state.packages,
          [action.storePackageKey]: {
            ...state.packages[action.storePackageKey],
            loading: false,
            error: true,
          },
        },
      };
    case GET_CMS_CAMPAIGNS:
      return {
        ...state,
        campaigns: {
          ...state.campaigns,
          promise: action.payload,
          loading: true,
          error: undefined,
        },
      };
    case GET_CMS_CAMPAIGNS_SUCCESS:
      return {
        ...state,
        campaigns: {
          ...state.campaigns,
          loading: false,
          data: action.payload,
        },
      };
    case GET_CMS_CAMPAIGNS_FAILURE:
      return {
        ...state,
        campaigns: {
          ...state.campaigns,
          loading: false,
          error: action.payload,
          data: undefined,
        },
      };
    default:
      return state;
  }
}
