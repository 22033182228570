/* eslint-disable import/prefer-default-export */
import apiClient from '../middleware/apiClient';

export const GET_CHANNEL_DATA = 'GET_CHANNEL_DATA';
export const GET_CHANNEL_DATA_SUCCESS = 'GET_CHANNEL_DATA_SUCCESS';
export const GET_CHANNEL_DATA_FAILURE = 'GET_CHANNEL_DATA_FAILURE';

export const getChannelData = () => async (dispatch, getState) => {
  const { promise } = getState();
  if (promise) return promise;

  try {
    const response = await dispatch({
      type: GET_CHANNEL_DATA,
      payload: apiClient.fetch('/apis/channel-data'),
    }).payload;
    return dispatch({
      type: GET_CHANNEL_DATA_SUCCESS,
      payload: response,
    }).payload;
  } catch (error) {
    dispatch({
      type: GET_CHANNEL_DATA_FAILURE,
      payload: error,
    });
    throw error;
  }
};
