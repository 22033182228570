import { useEffect, useState } from 'react';

const useLocalState = (name, init) => {
  const [state, setState] = useState(() => {
    const value = localStorage.getItem(name);
    if (value) return JSON.parse(value);
    if (typeof init === 'function') return init();
    return init;
  });

  // Update the state in localstorage when changed.
  const storeState = (value) => {
    if (typeof value === 'function') {
      setState((prev) => {
        const v = value(prev);
        localStorage.setItem(name, JSON.stringify(v));
        return v;
      });
    } else {
      localStorage.setItem(name, JSON.stringify(value));
      setState(value);
    }
  };

  // Ensures multiple windows stay in sync.
  useEffect(() => {
    const listener = ({ key, newValue }) => {
      if (key !== name) return;
      setState(JSON.parse(newValue));
    };
    window.addEventListener('storage', listener);
    return () => window.removeEventListener('storage', listener);
  }, [name]);

  return [state, storeState];
};

export default useLocalState;
