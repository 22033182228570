import React from 'react';
import { LoadingCard } from 'adc-ui-components';
import DefaultLayout from './DefaultLayout';

const CmsPromo = ({
  show = false,
  loading,
  presentation,
  layout,
}) => {
  if (!show) return null;

  if (loading) {
    return <LoadingCard />;
  }
  /**
   * TODO: Once more templates are defined, switch based on `presentation`
   * e.g., if (presentation = 'right') return <RightLayout layout={layout} />;
   */
  return <DefaultLayout layout={layout} />;
};

export default CmsPromo;
