import qs from 'qs';

import apiClient from '../middleware/apiClient';

import {
  CMS_SEGMENTS,
  CMS_PACKAGE_URL,
  CMS_CAMPAIGN_URL,
} from '../helpers/apis';

let overrideSegment = null;

// Exposing internal state for testing.
export const getOverrideSegment = () => {
  if (overrideSegment === null) {
    overrideSegment = qs.parse(window.location.search.replace(/^\?/, '')).segment;
  }
  if (!overrideSegment) return null;
  return overrideSegment.split(',').map((name, index) => ({
    name,
    priority: (100 - index),
    segmentId: name,
    segmentShortId: name,
  }));
};

export const clearOverrideSegment = () => {
  overrideSegment = null;
};


export const GET_CMS_SEGMENTS = 'GET_CMS_SEGMENTS';
export const GET_CMS_SEGMENTS_SUCCESS = 'GET_CMS_SEGMENTS_SUCCESS';
export const GET_CMS_SEGMENTS_FAILURE = 'GET_CMS_SEGMENTS_FAILURE';

function dispatchGetCMSSegments() {
  const localSegment = getOverrideSegment();
  if (localSegment) {
    return {
      type: GET_CMS_SEGMENTS,
      payload: Promise.resolve({
        segments: localSegment,
      }),
    };
  }
  return {
    type: GET_CMS_SEGMENTS,
    payload: apiClient.fetch(CMS_SEGMENTS),
  };
}

export const getCMSSegments = () => async (dispatch, getState) => {
  const { auth: { macaroon: { lite } }, cms: { segments: { data } = {} } = {} } = getState();
  if (lite) {
    // Mock out the failed call to avoid making a real one.
    const error = new Error('Lite user cannot make segments call');
    const rejection = Promise.reject(error);
    // Silence "unhandled rejection" errors.
    rejection.catch(() => {});
    dispatch({ type: GET_CMS_SEGMENTS, payload: rejection });
    dispatch({ type: GET_CMS_SEGMENTS_FAILURE, payload: error });
    return undefined;
  }
  if (data) {
    return data;
  }
  try {
    const { segments = [] } = await dispatch(dispatchGetCMSSegments()).payload;
    const payload = segments.sort((a, b) => b.priority - a.priority);
    dispatch({
      type: GET_CMS_SEGMENTS_SUCCESS,
      payload,
    });
    return payload;
  } catch (error) {
    dispatch({
      type: GET_CMS_SEGMENTS_FAILURE,
      payload: error,
    });
    throw error;
  }
};

export const GET_CMS_PACKAGE = 'GET_CMS_PACKAGE';
export const GET_CMS_PACKAGE_SUCCESS = 'GET_CMS_PACKAGE_SUCCESS';
export const GET_CMS_PACKAGE_FAILURE = 'GET_CMS_PACKAGE_FAILURE';

const dispatchGetCMSPackage = (packageName, storePackageKey) => ({
  type: GET_CMS_PACKAGE,
  storePackageKey,
  payload: (async () => {
    try {
      return await apiClient.fetch(CMS_PACKAGE_URL(packageName, !!getOverrideSegment()));
    } catch (shelbyError) {
      try {
        return await window.fetch(`/cms-fallback/${packageName}.json`).then(resp => resp.json());
      } catch (localError) {
        throw shelbyError;
      }
    }
  })(),
});


function dispatchGetCMSPackageSuccess(storePackageKey, payload) {
  return {
    type: GET_CMS_PACKAGE_SUCCESS,
    storePackageKey,
    payload,
  };
}

function dispatchGetCMSPackageFailure(storePackageKey, error) {
  return {
    type: GET_CMS_PACKAGE_FAILURE,
    storePackageKey,
    payload: error,
  };
}

export const getCMSPackage = (
  packageName,
  { storeKey = null } = {},
) => async (dispatch, getState) => {
  const storePackageKey = storeKey || packageName;

  const {
    cms: {
      packages: {
        [storePackageKey]: { cached, content } = {},
      },
    } = {},
  } = getState();

  if (cached) return content;

  try {
    const response = await dispatch(dispatchGetCMSPackage(packageName, storePackageKey)).payload;

    // The content should always be the first element in the "layout" array
    const { layout: [layout] = [] } = response || {};

    if (layout && layout.constructor === Object) {
      dispatch(dispatchGetCMSPackageSuccess(storePackageKey, layout));
      return layout;
    }

    throw new Error(`Invalid CMS response for "${packageName}"`);
  } catch (error) {
    dispatch(dispatchGetCMSPackageFailure(storePackageKey, error));
    throw error;
  }
};

export const GET_CMS_CAMPAIGNS = 'GET_CMS_CAMPAIGNS';
export const GET_CMS_CAMPAIGNS_SUCCESS = 'GET_CMS_CAMPAIGNS_SUCCESS';
export const GET_CMS_CAMPAIGNS_FAILURE = 'GET_CMS_CAMPAIGNS_FAILURE';

export const getCMSCampaigns = (forceReload = false) => async (dispatch, getState) => {
  const { cms: { campaigns: { data } = {} } = {} } = getState();
  if (data && !forceReload) return data;

  try {
    const promise = apiClient.fetch(CMS_CAMPAIGN_URL(!!getOverrideSegment()));
    dispatch({
      type: GET_CMS_CAMPAIGNS,
      payload: promise,
    });
    const payload = await promise;
    dispatch({
      type: GET_CMS_CAMPAIGNS_SUCCESS,
      payload,
    });
    return payload;
  } catch (error) {
    dispatch({
      type: GET_CMS_CAMPAIGNS_FAILURE,
      payload: error,
    });
    throw error;
  }
};
