import { fromByteArray } from 'base64-js';

import { TextEncoder } from 'text-encoding-shim';

// Normalizes the available crypto API.
import 'webcrypto-shim';


export default async function encryptRsaOaep(text, jwk) {
  const { subtle } = window.crypto;

  // Edge breaks if jwk contains `use` field.
  const tmpJwk = { ...jwk };
  delete tmpJwk.use;

  // signature algorithm.
  const sigAlgo = {
    name: 'RSA-OAEP',
    hash: { name: 'SHA-1' },
  };

  // Import the JWK
  const publicKey = await subtle.importKey(
    'jwk',
    tmpJwk,
    sigAlgo,
    true,
    ['encrypt'],
  );

  // Encrypt the text.
  const result = await subtle.encrypt(
    sigAlgo,
    publicKey,
    (new TextEncoder()).encode(text),
  );
  const encrypted = fromByteArray(new Uint8Array(result));
  return encrypted;
}
