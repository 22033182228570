export const bindListeners = (context, names) => {
  names.forEach((name) => {
    const self = context;
    self[name] = context[name].bind(context);
  });
};

export const joinClass = (...classes) => classes.reduce((list, name) => {
  if (name) {
    list.push(name);
  }
  return list;
}, []).join(' ');
