import apiClient from '../middleware/apiClient';
import { MOVE_BILL_DUE_DATE } from '../helpers/apis';

// Fetch Account
export const GET_MOVE_BILL_DUE_DATE = 'GET_MOVE_BILL_DUE_DATE';
export const GET_MOVE_BILL_DUE_DATE_SUCCESS = 'GET_MOVE_BILL_DUE_DATE_SUCCESS';
export const GET_MOVE_BILL_DUE_DATE_FAILURE = 'GET_MOVE_BILL_DUE_DATE_FAILURE';

function dispatchGetMoveBillDueDate() {
  return {
    type: GET_MOVE_BILL_DUE_DATE,
    payload: apiClient.fetch(MOVE_BILL_DUE_DATE),
  };
}

function dispatchGetMoveBillDueDateSuccess(account) {
  return {
    type: GET_MOVE_BILL_DUE_DATE_SUCCESS,
    payload: account,
  };
}

function dispatchGetMoveBillDueDateFailure(error) {
  return {
    type: GET_MOVE_BILL_DUE_DATE_FAILURE,
    payload: error,
  };
}

export const getMoveBillDueDate = () => async (dispatch, getState) => {
  if (getState().moveBillDueDate.cached) {
    return null;
  }

  try {
    const response = await dispatch(dispatchGetMoveBillDueDate()).payload;
    return dispatch(dispatchGetMoveBillDueDateSuccess(response));
  } catch (error) {
    dispatch(dispatchGetMoveBillDueDateFailure(error));
    throw error;
  }
};
