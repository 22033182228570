import React from 'react';

const CardPayment = () => (
  <svg version="1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 32">
    <path d="M3 0h44c2 0 3 1 3 3v26c0 2-1 3-3 3H3c-2 0-3-1-3-3V3c0-2 1-3 3-3z" fill="#e6eaed" />
    <path d="M0 5h50v7H0V5zm5 19h8v5H5v-5z" fill="#646a70" />
    <path d="M4 14c1 3 0 7 2 8 2 0 2-6 4-7 2 0 0 5 2 6 2 0 2-4 4-4 1-1 1 3 3 3l4-3h23" fill="none" stroke="#646a70" />
  </svg>
);

export default CardPayment;
