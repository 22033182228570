import React from 'react';
import { IconCheckGreen } from 'adc-ui-components';
import getConfig from '../../config';
import PageTitle from '../../components/PageTitle';

const { billServicesUrl } = getConfig();

const CancelConfirm = () => (
  <>
    <div className="hgroup">
      <IconCheckGreen className="mb6 svg-icon--42" />
      <PageTitle>Your scheduled payment has been cancelled successfully.</PageTitle>
    </div>

    <div className="action action--right-centered">
      <div className="action__item">
        <a className="button button--primary" href={billServicesUrl}>
          Back to Billing
        </a>
      </div>
    </div>
  </>
);

export default CancelConfirm;
