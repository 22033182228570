import React from 'react';
import { FormControl } from 'adc-ui-components';
import { Field } from 'formik';

import InputField from '../form-elements/InputField';

import { RequiredFactory } from '../../helpers/validation/required';
import vAll from '../../helpers/validation/all';
import ConsecutiveNumericNameFactory from '../../helpers/validation/consecutiveNumericName';

const vRequired = RequiredFactory();
const vConsecutiveNumericName = ConsecutiveNumericNameFactory();

const FirstLastName = ({ required }) => (
  <div className="form-control-group form-control-group--flex-at-768">
    <div className="form-control-group__item">
      <FormControl inputId="firstName" label="First name">
        <Field
          id="firstName"
          name="firstName"
          component={InputField}
          type="text"
          title="First name"
          validate={vAll(
            required && vRequired('Please enter first name'),
            vConsecutiveNumericName('Invalid entry'),
          )}
        />
      </FormControl>
    </div>

    <div className="form-control-group__item">
      <FormControl inputId="lastName" label="Last name">
        <Field
          id="lastName"
          name="lastName"
          component={InputField}
          type="text"
          title="Last name"
          validate={vAll(
            required && vRequired('Please enter last name'),
            vConsecutiveNumericName('Invalid entry'),
          )}
        />
      </FormControl>
    </div>
  </div>
);


export default FirstLastName;
