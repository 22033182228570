import React from 'react';
import getConfig from '../config';

import XfinityFlex from './svgs/XfinityFlex';

const { cwaRoot } = getConfig();

const FlexPromo = () => (
  <div className="card card--flex-promo mb30">
    <div className="card__image">
      <img className="desktop" src={`${cwaRoot}maw/flex_promo_d@2x.jpg`} alt="xfinity flex desktop" />
      <img className="mobile" src={`${cwaRoot}maw/flex_promo_m@2x.jpg`} alt="xfinity flex mobile" />
    </div>
    <div className="card__content">
      <div className="hgroup">
        <h3 className="mb6">
          <span className="visuallyhidden">Xfinity Flex</span>
          <XfinityFlex />
        </h3>
        <p className="body1">Add Flex at no extra cost</p>
        <p>
          {`Search less. Watch more. With a personalized streaming dashboard, powered by our
            4K streaming device + Voice Remote.`}
        </p>
      </div>
      <a className="button button--primary" href="https://www.xfinity.com/addflex" target="_blank" rel="noopener noreferrer">Add Now</a>
    </div>
  </div>
);

export default FlexPromo;
