import React from 'react';
import { useFeatureFlagsPolaris, usePolaris } from '../../../helpers/polaris';
import LegacyHeader from './LegacyHeader';
import PolarisHeader from './PolarisHeader';

const PageHeader = (props) => {
  const isGlobalNav = usePolaris();
  const flags = useFeatureFlagsPolaris();
  if (isGlobalNav === undefined) return null;
  if (!isGlobalNav) return <LegacyHeader {...props} />;
  return <PolarisHeader {...props} flags={flags} />;
};

export default PageHeader;
