import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import dateformat from 'dateformat';

import { getBill } from '../../actions/bill';
import { getMoveBillDueDate } from '../../actions/moveBillDueDate';

import NextBestAction from '../NextBestAction';
import BillClock from '../svgs/BillClock';
import CalendarBill from '../svgs/CalendarBill';
import getConfig from '../../config';

const { homeDomain } = getConfig();

export const MOVE_BILL_DUE_DATE = 'MOVE_BILL_DUE_DATE';

const getNbaData = (bill = {}, billDueDateData) => {
  const baseNba = {
    id: MOVE_BILL_DUE_DATE,
    name: 'New bill due date requested',
    color: 'minty',
    svgIcon: BillClock,
    link: `https://${homeDomain}/#/settings/bill/move-bill-date`,
    linkText: 'View Status',
    linkTarget: '_self',
    dismissible: true,
  };

  if (!billDueDateData || bill.summary.pastDueBalanceRemaining) {
    return false;
  }

  const dateToday = new Date().getTime();
  const dueDate = billDueDateData.upcomingRegularBillingPeriodInfo && `${dateformat(billDueDateData.upcomingRegularBillingPeriodInfo.dueDate, 'dS', true)} of each month`;

  if (billDueDateData.dateOfChangeRequest && billDueDateData.cancelAllowed) {
    return {
      ...baseNba,
      trackId: 'bill due date | pending',
      description: `Request pending for the ${dueDate}. Change or cancel this request by ${dateformat(billDueDateData.dateCancelAllowedThrough, 'mmm dd, yyyy', true)}.`,
    };
  }

  const fromBillDate = billDueDateData.upcomingRegularBillingPeriodInfo && (
    new Date(billDueDateData.upcomingRegularBillingPeriodInfo.fromBillDate).getTime()
  );

  if (!billDueDateData.cancelAllowed && billDueDateData.upcomingRegularBillingPeriodInfo
    && fromBillDate > dateToday) {
    return {
      ...baseNba,
      trackId: 'bill due date | in-progress',
      description: `Request in progress for the ${dueDate}. The new bill due date will go into effect on ${dateformat(billDueDateData.upcomingRegularBillingPeriodInfo.fromBillDate, 'mmm dd, yyyy', true)}.`,
    };
  }

  if (billDueDateData.upcomingRegularBillingPeriodInfo
    && fromBillDate <= dateToday) {
    return {
      ...baseNba,
      link: `https://${homeDomain}/#/billing/past-statements`,
      trackId: 'bill due date | success',
      svgIcon: BillClock,
      name: 'The bill due date has been updated successfully!',
      linkText: 'View Statement',
      description: `Your new bill due date is now the ${dueDate}.`,
    };
  }

  return {
    ...baseNba,
    trackId: 'bill due date | start',
    svgIcon: CalendarBill,
    name: 'Want to change when your bill is due each month?',
    linkText: 'Get Started',
    description: 'No problem! Simply choose a day that works for you.',
  };
};

class MoveBillDueDateNba extends Component {
  componentDidMount() {
    const {
      handleGetBill,
      handleGetMoveBillDueDate,
    } = this.props;

    handleGetBill();
    handleGetMoveBillDueDate();
    this.triggerSetLoaded();
  }

  componentDidUpdate() {
    this.triggerSetLoaded();
  }

  triggerSetLoaded() {
    const {
      setLoaded,
      loading,
      nbaData,
    } = this.props;

    if (loading) {
      return;
    }

    if (!nbaData) {
      setLoaded(MOVE_BILL_DUE_DATE, 'FAILED');
      return;
    }

    setLoaded(MOVE_BILL_DUE_DATE, 'READY');
  }

  render() {
    const {
      dismissNba,
      loading,
      nbaData,
    } = this.props;

    if (loading) {
      return null;
    }

    if (!nbaData) {
      return null;
    }

    return <NextBestAction nba={nbaData} dismissNba={dismissNba} />;
  }
}

const mapStateToProps = ({
  bill: {
    bill = {},
    loading: billLoading,
  },
  moveBillDueDate: {
    moveBillDueDate,
    loading: moveBillDueDateLoading,
  },
}) => {
  const loading = billLoading || moveBillDueDateLoading;

  return {
    loading,
    nbaData: !loading && getNbaData(bill, moveBillDueDate),
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  handleGetBill: getBill,
  handleGetMoveBillDueDate: getMoveBillDueDate,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MoveBillDueDateNba);
