import React from 'react';

const Cash = ({ className = '' }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
    <g>
      <path strokeMiterlimit="10" stroke="currentColor" fill="none" d="M31.51 19.72v6.71a1.05 1.05 0 0 1-1 1.07H6.54a1.05 1.05 0 0 1-1-1.07V13.57a1.05 1.05 0 0 1 1-1.07h24a1 1 0 0 1 1 1z" />
      <path strokeMiterlimit="10" stroke="currentColor" fill="none" d="M8.51 27.38v2a1 1 0 0 0 1 1.06h24.04a1 1 0 0 0 1-1.06V16.5a1 1 0 0 0-1-1h-2.11M6 23a4 4 0 0 1 4 4m17 0a4 4 0 0 1 4-4m0-6a4 4 0 0 1-4-4m-17 0a4 4 0 0 1-4 4" />
      <circle cx="18.51" cy="20" r="4.5" strokeLinecap="round" strokeLinejoin="round" stroke="currentColor" fill="none" />
    </g>
  </svg>
);

export default Cash;
