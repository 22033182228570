import React from 'react';

const Dismiss = ({ className = '' }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22">
    <g fillRule="evenodd" fill="none">
      <path d="M3.222 18.778c4.296 4.296 11.26 4.296 15.556 0 4.296-4.296 4.296-11.26 0-15.556-4.296-4.296-11.26-4.296-15.556 0-4.296 4.296-4.296 11.26 0 15.556z" fill="#0272b6" />
      <path d="M15.243 6.757l-8.486 8.486m8.486 0L6.757 6.757" strokeWidth="2" stroke="#fff" strokeLinecap="round" />
    </g>
  </svg>
);

export default Dismiss;
