import apiClient from '../middleware/apiClient';
import {
  SCHEDULED_PAYMENTS, CANCEL_SCHEDULED_PAYMENT_URL, SCHEDULED_PAY,
} from '../helpers/apis';
import { getErrorFromResponse } from '../helpers/errors';

// Update scheduled payment
export const UPDATE_SCHEDULED_PAYMENT = 'UPDATE_SCHEDULED_PAYMENT';
export const UPDATE_SCHEDULED_PAYMENT_SUCCESS = 'UPDATE_SCHEDULED_PAYMENT_SUCCESS';
export const UPDATE_SCHEDULED_PAYMENT_FAILURE = 'UPDATE_SCHEDULED_PAYMENT_FAILURE';

function dispatchUpdateScheduledPayment(data) {
  return {
    type: UPDATE_SCHEDULED_PAYMENT,
    payload: apiClient.fetch(SCHEDULED_PAY, { method: 'POST', body: JSON.stringify(data) }),
  };
}

function dispatchUpdateScheduledPaymentSuccess(payload) {
  return {
    type: UPDATE_SCHEDULED_PAYMENT_SUCCESS,
    payload,
  };
}

function dispatchUpdateScheduledPaymentFailure(error) {
  return {
    type: UPDATE_SCHEDULED_PAYMENT_FAILURE,
    payload: error,
  };
}

export const updateScheduledPayment = data => async (dispatch) => {
  try {
    await dispatch(dispatchUpdateScheduledPayment(data)).payload;
    return dispatch(dispatchUpdateScheduledPaymentSuccess(data));
  } catch (error) {
    dispatch(dispatchUpdateScheduledPaymentFailure(getErrorFromResponse(error)));
    throw error;
  }
};

// Cancel scheduled payment
export const CANCEL_SCHEDULED_PAYMENT = 'CANCEL_SCHEDULED_PAYMENT';
export const CANCEL_SCHEDULED_PAYMENT_SUCCESS = 'CANCEL_SCHEDULED_PAYMENT_SUCCESS';
export const CANCEL_SCHEDULED_PAYMENT_FAILURE = 'CANCEL_SCHEDULED_PAYMENT_FAILURE';

function dispatchCancelScheduledPayment(data) {
  return {
    type: CANCEL_SCHEDULED_PAYMENT,
    payload: apiClient.fetch(CANCEL_SCHEDULED_PAYMENT_URL, { method: 'POST', body: JSON.stringify(data) }),
  };
}

function dispatchCancelScheduledPaymentSuccess(payload) {
  return {
    type: CANCEL_SCHEDULED_PAYMENT_SUCCESS,
    payload,
  };
}

function dispatchCancelScheduledPaymentFailure(error) {
  return {
    type: CANCEL_SCHEDULED_PAYMENT_FAILURE,
    payload: error,
  };
}

export const cancelScheduledPayment = id => async (dispatch, getState) => {
  const { auth: { macaroon: { guid } } } = getState();
  const payload = {
    guid,
    payment_id: id,
  };

  try {
    await dispatch(dispatchCancelScheduledPayment(payload)).payload;
    return dispatch(dispatchCancelScheduledPaymentSuccess(payload));
  } catch (error) {
    dispatch(dispatchCancelScheduledPaymentFailure(error));
    throw error;
  }
};

// Fetch scheduled payments
export const FETCH_SCHEDULED = 'FETCH_SCHEDULED';
export const FETCH_SCHEDULED_SUCCESS = 'FETCH_SCHEDULED_SUCCESS';
export const FETCH_SCHEDULED_FAILURE = 'FETCH_SCHEDULED_FAILURE';

function dispatchFetchScheduledPayments() {
  return {
    type: FETCH_SCHEDULED,
    payload: apiClient.fetch(SCHEDULED_PAYMENTS),
  };
}

function dispatchFetchScheduledPaymentsSuccess(paymentList) {
  return {
    type: FETCH_SCHEDULED_SUCCESS,
    payload: paymentList,
  };
}

function dispatchFetchScheduledPaymentsFailure(error) {
  return {
    type: FETCH_SCHEDULED_FAILURE,
    payload: error,
  };
}

export const fetchScheduledPayments = (options = {}) => async (dispatch, getState) => {
  const { scheduledPayments } = getState();
  if (scheduledPayments.payments.length && !options.force) {
    return null;
  }

  try {
    const response = await dispatch(dispatchFetchScheduledPayments()).payload;
    return dispatch(dispatchFetchScheduledPaymentsSuccess(response));
  } catch (error) {
    dispatch(dispatchFetchScheduledPaymentsFailure(error));
    throw error;
  }
};

export const RESET_ERRORS = 'SCHEDULED_PAYMENTS:RESET_ERRORS';
export const resetErrors = () => dispatch => dispatch({
  type: RESET_ERRORS,
});
