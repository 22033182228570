import apiClient from '../middleware/apiClient';
import { PAYMENT_PLAN } from '../helpers/apis';
import { reviewPayment } from './payment';

import { setReviewData } from '../helpers/payments';
import memoized from '../helpers/memoized';
import getConfig from '../config';
import { setPaymentPlanReviewData } from '../helpers/paymentPlan';
import { XAP_INELIGIBLE_ERROR } from '../helpers/xap';

const { maxOptionsPaymentPlan } = getConfig();

// get payment plan
export const GET_PAYMENT_PLAN = 'GET_PAYMENT_PLAN';
export const GET_PAYMENT_PLAN_SUCCESS = 'GET_PAYMENT_PLAN_SUCCESS';
export const GET_PAYMENT_PLAN_FAILURE = 'GET_PAYMENT_PLAN_FAILURE';

const memoizedFetchPaymentPlan = memoized(value => apiClient.fetch(PAYMENT_PLAN(value)))();

function dispatchGetPaymentPlan() {
  return {
    type: GET_PAYMENT_PLAN,
    payload: memoizedFetchPaymentPlan(undefined),
  };
}

function dispatchGetPaymentPlanSuccess(payload) {
  return {
    type: GET_PAYMENT_PLAN_SUCCESS,
    payload,
  };
}

function dispatchGetPaymentPlanFailure(error) {
  const paymentPlanIneligible = !!(error.data && error.data.code === XAP_INELIGIBLE_ERROR);
  const loadError = !paymentPlanIneligible && error;

  return {
    type: GET_PAYMENT_PLAN_FAILURE,
    payload: { error: loadError, paymentPlanIneligible },
  };
}

// TODO: need to sort before we truncate
const truncatePaymentPlanOptions = (options = []) => {
  if (maxOptionsPaymentPlan === 1) {
    return options.filter(plan => plan.numberOfPayments === 12);
  }
  return options.slice(0, maxOptionsPaymentPlan);
};


const transformPaymentPlanResponse = (response = {}) => ({
  ...response,
  paymentOptionsPlan: truncatePaymentPlanOptions(response.paymentOptionsPlan),
});

export const getPaymentPlan = () => async (dispatch, getState) => {
  const { paymentPlan } = getState();
  if (paymentPlan.data) {
    return null;
  }

  try {
    const response = await dispatch(dispatchGetPaymentPlan()).payload;
    return dispatch(dispatchGetPaymentPlanSuccess(transformPaymentPlanResponse(response)));
  } catch (error) {
    dispatch(dispatchGetPaymentPlanFailure(error));
    throw error;
  }
};

// recalc payment plan
export const RECALC_PAYMENT_PLAN = 'RECALC_PAYMENT_PLAN';
export const RECALC_PAYMENT_PLAN_SUCCESS = 'RECALC_PAYMENT_PLAN_SUCCESS';
export const RECALC_PAYMENT_PLAN_FAILURE = 'RECALC_PAYMENT_PLAN_FAILURE';

const dispatchRecalcPaymentPlan = value => ({
  type: RECALC_PAYMENT_PLAN,
  payload: memoizedFetchPaymentPlan(value),
});

function dispatchRecalcPaymentPlanSuccess(payload) {
  return {
    type: RECALC_PAYMENT_PLAN_SUCCESS,
    payload,
  };
}

function dispatchRecalcPaymentPlanFailure(error) {
  return {
    type: RECALC_PAYMENT_PLAN_FAILURE,
    payload: error,
  };
}

export const recalcPaymentPlan = value => async (dispatch, getState) => {
  const {
    paymentPlan: {
      minimum: {
        goodFaithPayment,
      } = {},
    } = {},
  } = getState();
  const amount = value === goodFaithPayment ? undefined : value;
  try {
    const response = await dispatch(dispatchRecalcPaymentPlan(amount)).payload;
    return dispatch(dispatchRecalcPaymentPlanSuccess(transformPaymentPlanResponse(response)));
  } catch (error) {
    dispatch(dispatchRecalcPaymentPlanFailure(error));
    throw error;
  }
};

export const REVIEW_PAYMENT_PLAN = 'REVIEW_PAYMENT_PLAN';

function dispatchReviewPaymentPlan(data) {
  return {
    type: REVIEW_PAYMENT_PLAN,
    payload: data,
  };
}

export const reviewPaymentPlan = values => async (dispatch, getState) => {
  const {
    instruments: { instruments: { instruments } },
    bill: { bill = {} },
    auth: { macaroon: { preferredEmail } },
    paymentPlan: { data: paymentPlan = {}, minimum: { goodFaithPayment } = {} },
  } = getState();

  // should contain all data from
  // both forms
  // TODO get payment form values from somewhere
  const userData = {
    ...values,
  };

  const paymentReviewData = setReviewData(userData, bill, instruments, preferredEmail);
  const paymentPlanReviewData = setPaymentPlanReviewData(
    userData,
    bill,
    {
      paymentOptionsPlan: paymentPlan.paymentOptionsPlan,
    },
    // Explicit check, since `goodFaithPayment` can be 0.
    goodFaithPayment,
  );

  // set the payment reducer data for submitPayment function
  // TODO: refactor submitPayment to take full payload vs grab from store
  dispatch(reviewPayment(values));

  return dispatch(dispatchReviewPaymentPlan({
    ...paymentReviewData,
    ...paymentPlanReviewData,
  }));
};
