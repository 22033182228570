/* eslint-disable import/prefer-default-export */
/**
 * Filter a list of campaigns by the segments the user is eligible for,
 * given a list of prioritized segments
 * @param Array<Segment> eligibleSegments List of segments the user is eligible for from SSM
 * @param Array<Campaign> campaigns List of campaigns from CMS
 * @param String validPage the page on which this campaign is called
 * @return Array<Campaign> the filtered list of campaigns
 */
export const filterCampaigns = (segments, campaigns, validPage) => {
  // If any list is empty, return empty array.
  if (!segments || !segments.length || !campaigns || !campaigns.length) return [];

  // Create a map of campaigns matching those segments
  const campaignSegments = campaigns.reduce((map, campaign = {}) => {
    const { state, targeted: { segment } = {}, presentation } = campaign;
    // Campaign must be active
    if (state !== 'active') return map;
    // To ensure the first campaign matching a segment wins, do not overwrite
    if (map[segment]) return map;
    // Skip campaigns that are not applicable to this page.
    if (validPage) {
      if (!presentation.some(({ page }) => page === validPage)) return map;
    }
    return { ...map, [segment]: campaign };
  }, {});
  // Map the eligible segment names to campaigns that match.
  return segments.map(({ name }) => campaignSegments[name]).filter(a => !!a);
};
