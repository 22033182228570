import chunk from 'lodash.chunk';
import * as routes from './routes';

export const titlecase = (txt) => {
  const text = (txt === undefined || txt === null) ? '' : txt;
  return text.toString().toLowerCase().replace(/\b([a-z])/g, ch => ch.toUpperCase());
};

export const formatCurrency = (number) => {
  const negativeSign = number < 0 ? '-' : '';
  const [wholePart, decPart] = Math.abs(number).toFixed(2).split('.');

  const leadingPlaces = wholePart.length % 3;
  const leadingPart = wholePart.slice(0, leadingPlaces);

  const commaDigits = wholePart.split('').slice(leadingPlaces);
  const commaizedPart = chunk(commaDigits, 3)
    .map(digits => digits.join(''))
    .join(',');

  const firstComma = leadingPart && commaizedPart ? ',' : '';
  return [negativeSign, '$', leadingPart, firstComma, commaizedPart, '.', decPart].join('');
};

export const arrayToSentence = group => group.slice(0, -2).join(', ')
  + (group.slice(0, -2).length ? ', ' : '')
  + group.slice(-2).join(' and ');

export const getNavTitle = (pathname) => {
  // strip trailing slash from pathname if one exists
  const path = pathname.slice(-1) === '/' ? pathname.slice(0, -1) : pathname;
  switch (path) {
    case routes.NEW_PAYMENT:
    case routes.NEW_PAYMENT_REVIEW:
    case routes.NEW_PAYMENT_CARD:
    case routes.NEW_PAYMENT_BANK:
    case routes.NEW_PAYMENT_CONFIRMATION:
      return 'Make a Payment';
    default:
      return 'My Account';
  }
};

const frequencies = {
  per: { MONTHLY: '/mo.', WEEKLY: '/wk.', YEARLY: '/yr.' },
  long: { MONTHLY: 'Monthly', WEEKLY: 'Weekly', YEARLY: 'Yearly' },
  unit: { MONTHLY: 'months', WEEKLY: 'weeks', YEARLY: 'years' },
};

export const formatFrequency = (f, type = 'per') => (frequencies[type] || {})[f] || '';
