import { useState, useEffect } from 'react';
import { addComponent } from '../helpers/adobe';

export const normalizeComponent = ({ placement, component, l3 }) => {
  if (l3) {
    const tmp = l3.split('>');
    if (tmp.length > 1) return [tmp.shift(), tmp.join('>')];
    return [undefined, l3];
  }
  if (!component) return [];
  return [placement, component];
};

/**
 * Emit an impression by adding it to the analytics component stack, as a promise, so as to
 *  defer the page load event until it's considered ready.
 *  There MUST be either a `componentName` or `l3` value.
 * @param {Object} options
 * @param {String} [options.componentName] - Name of the component.
 * @param {String} [options.placementName] - Name of the placement.
 * @param {String} [options.l3] - joined l3 value of component (i.e.,
 *  `${placementName}>${componentName}`)
 * @param [options.when=true] if false, will delay the page load event until it's not.
 * @param [options.if=true] if false when ready, will not emit the impression.
 * @returns void
 */
const useImpression = ({
  placementName: placement,
  componentName: component,
  l3,
  when = true,
  if: visible = true,
  timeout = 5000,
  id = '[Not specified]',
}) => {
  const [deferred] = useState({});
  const [timedOut, setTimedOut] = useState(false);
  const [placementName, componentName] = normalizeComponent({ placement, component, l3 });
  useEffect(() => {
    deferred.promise = new Promise((resolve) => {
      deferred.resolve = resolve;
    });
    addComponent(deferred.promise);
    let handle = setTimeout(() => {
      setTimedOut(true);
      handle = null;
    }, timeout);
    return () => {
      if (handle) clearTimeout(handle);
    };
  }, [deferred, setTimedOut, timeout]);
  useEffect(() => {
    if (deferred.resolve && (when || timedOut)) {
      if (!when) {
        // eslint-disable-next-line no-console
        console.warn('[Adobe] timed out waiting for impression', { placementName, componentName, id });
      }
      deferred.resolve((when && visible && componentName)
        ? {
          componentName,
          ...(placementName && { placementName }),
        }
        : undefined);
    }
  }, [when, visible, componentName, placementName, deferred, timedOut, id]);
};

export default useImpression;
