import { p2pProps } from '../../helpers/p2p';
import {
  paymentSuccessfulTitle,
  paymentArrangementTitle,
} from './messages';
import { flagEnabled } from '../../helpers/featureFlags';

const P2PConfirmTitle = ({ children, ...props }) => {
  const def = children || null;
  const p2pMessaging = flagEnabled('p2pMessaging');
  if (!p2pMessaging) return def;
  const {
    isP2P,
    qualifies,
    today,
  } = p2pProps(props);
  if (!isP2P) return def;
  if (!qualifies) {
    return paymentSuccessfulTitle;
  }
  if (!today) {
    return paymentArrangementTitle;
  }
  return paymentSuccessfulTitle;
};

export default P2PConfirmTitle;
