import React, { useEffect, useRef } from 'react';

const TooltipMask = ({ onClick, uid }) => {
  const self = useRef(null);
  useEffect(() => {
    if (self.current) {
      self.current.focus();
    }
  }, []);
  return (
    <div
      ref={self}
      onClick={onClick}
      onKeyDown={onClick}
      role="button"
      tabIndex="0"
      className="tooltip-button__mask"
      aria-describedby={uid}
    />
  );
};

export default TooltipMask;
