import React from 'react';
import { useHistory } from 'react-router-dom';
import { FormBoolean } from 'adc-ui-components';
import { Formik, Form, Field } from 'formik';

import GoBack from '../../components/GoBack';
import BooleanField from '../../components/form-elements/BooleanField';
import Card from '../../components/Card';
import ErrorMessage from '../../components/ErrorMessage';
import { RequiredFactory } from '../../helpers/validation/required';

import PageTitle from '../../components/PageTitle';

import {
  METHOD_NEW_BANK,
  METHOD_NEW_CARD,
} from '../../helpers/routes';

const vRequired = RequiredFactory();

const options = [
  {
    label: 'New bank account',
    value: METHOD_NEW_BANK,
  },
  {
    label: 'New credit/debit card',
    value: METHOD_NEW_CARD,
  },
];

const ChooseMethod = () => {
  const history = useHistory();

  const onFormSubmit = ({ method }) => {
    history.push(method);
  };

  return (
    <Formik
      onSubmit={onFormSubmit}
      initialValues={{}}
    >
      {({ errors, submitCount }) => (
        <Form noValidate>
          <PageTitle>Add Payment Method</PageTitle>

          {!!submitCount && errors.method && (
            <ErrorMessage errorMessage={errors.method} />
          )}

          <div className="card-group mb24">
            {options.map(option => (
              <div className="card-group__item" key={option.value}>
                <Card>
                  <FormBoolean
                    label={option.label}
                    inputId={option.value}
                  >
                    <Field
                      id={option.value}
                      type="radio"
                      name="method"
                      value={option.value}
                      component={BooleanField}
                      suppressError
                      forceError={errors.method}
                      validate={vRequired('Please select a payment method type')}
                    />
                  </FormBoolean>
                </Card>
              </div>
            ))}
          </div>

          <div className="action action--right">
            <div className="action__item">
              <button
                className="button button--primary"
                type="submit"
              >
                Continue
              </button>
            </div>
            <div className="action__item">
              <GoBack>Back</GoBack>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ChooseMethod;
