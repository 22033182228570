import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { IconCheckGreen } from 'adc-ui-components';
import get from 'lodash.get';
import PageTitle from '../../components/PageTitle';

import { HOME } from '../../helpers/routes';
import { formatCurrency } from '../../helpers/formatText';
import { confirmPayment } from '../../actions/payment';
import getConfig from '../../config';
import PaymentSummary from '../../components/PaymentSummary';

const { homeDomain } = getConfig();

class PaymentConfirm extends Component {
  constructor() {
    super();

    this.state = {};
  }

  componentDidMount() {
    const { confirm, history, handleConfirmPayment } = this.props;

    if (!confirm) {
      history.push(HOME);
    }

    this.setState({ confirm });

    handleConfirmPayment();
  }

  render() {
    const {
      bill,
      isHarness,
    } = this.props;

    const { confirm } = this.state;

    if (!confirm) {
      return null;
    }

    const {
      balanceDue,
    } = (get(bill, 'bill.summary') || {});

    return (
      <>
        <div className="hgroup">
          <IconCheckGreen className="mb6 svg-icon--42" />
          <PageTitle>Your scheduled payment was updated successfully!</PageTitle>
          {(balanceDue > 0) && (
            <p className="body2">
              <b>
                {`Current balance due ${formatCurrency(balanceDue)}`}
              </b>
              .
            </p>
          )}
        </div>

        <div className="action action--right-centered no-print">
          <div className="action__item">
            <Link to="/" className="button button--primary">Done</Link>
          </div>
        </div>

        <div className="text-left">
          <div className="payment-section">
            <div className="hgroup hgroup--inline">
              <h2>Payment Updated</h2>
              {!isHarness && (
                <a href={`https://${homeDomain}/#/billing#upcoming-payments`}>
                  View Payment History
                </a>
              )}
            </div>
            <PaymentSummary showPrint={!isHarness} summary={confirm} />
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  confirm: state.scheduledPayments.confirm,
  bill: state.bill,
  isHarness: state.harness.isHarness,
});

const mapDispatchToProps = dispatch => ({
  handleConfirmPayment: bindActionCreators(confirmPayment, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentConfirm);
