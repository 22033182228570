import React from 'react';
import Pushdown from './Pushdown';
import SvgIcon from './svgs/SvgIcon';

const PushdownShingle = ({
  dismiss,
  icon,
  headline,
  message,
  tracking,
  action,
  actionText,
  actionClass,
}) => (
  <Pushdown
    icon={<SvgIcon icon={icon} className="svg-icon--42" />}
    title={headline}
    dismiss={dismiss}
    tracking={tracking}
    action={action}
    actionText={actionText}
    actionClass={actionClass}
  >
    {message}
  </Pushdown>
);


export default PushdownShingle;
