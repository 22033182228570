import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import {
  IconCheckGreen,
  LoadingCard,
  PageSection,
} from 'adc-ui-components';

import { sendChannelTracking } from '../../actions/channelTracking';
import { XFINITY_ASSISTANCE_PLAN_COMPLETED } from '../../helpers/channelTracking';

import { PAYMENT_PLAN, AUTOPAY_EDIT } from '../../helpers/routes';
import { XA_XAP_EXITED_INTENT } from '../../helpers/intents';
import { confirmPayment } from '../../actions/payment';

import XfinityAssistantBanner from '../../components/XfinityAssistantBanner';
import PageTitle from '../../components/PageTitle';
import Card from '../../components/Card';
import PaymentSummary from '../../components/PaymentSummary';
import AutopaySummary from '../../components/AutopaySummary';
import KeyValueGroup from '../../components/KeyValueGroup';
import KeyValue from '../../components/KeyValue';


import paymentMock from '../../helpers/paymentMock';
import withCmsContent from '../../helpers/withCmsContent';
import { formatCurrency } from '../../helpers/formatText';

import getConfig from '../../config';

const { homeDomain } = getConfig();

class PaymentPlanConfirm extends Component {
  constructor() {
    super();

    this.state = {};
  }

  componentDidMount() {
    const {
      history,
      confirm,
      handleConfirmPayment,
      bill,
      handleSendChannelTracking,
    } = this.props;

    if (!confirm) {
      const mock = paymentMock(true);
      if (mock) {
        const { bill: mockBill, form } = mock;
        Object.assign(bill, { bill: mockBill });
        this.setState({ confirm: form });
        return;
      }
      history.push(PAYMENT_PLAN);
      return;
    }
    this.setState({ confirm });

    handleSendChannelTracking({ id: XFINITY_ASSISTANCE_PLAN_COMPLETED, interactionType: 'BILLING_PAYMENTS' });

    handleConfirmPayment();
  }

  render() {
    const {
      bill,
      autopay,
      autopaySignupSuccess,
      paymentPlanConfirm,
      content,
    } = this.props;
    const { confirm } = this.state;

    if (!confirm) {
      return null;
    }

    const autopaySuccessful = confirm.autopay && autopaySignupSuccess;
    const autopayUnsuccessful = confirm.autopay && !autopaySignupSuccess;

    const paymentSectionLoading = bill.loading || (confirm.autopay && autopay.loading);

    const billsCopy = (() => {
      // happy path: no plan error and plan confirmation
      if (paymentPlanConfirm && paymentPlanConfirm.installmentPlan) {
        if (paymentPlanConfirm.installmentPlan.numberOfPayments > 1) {
          return content('heading.subtitle.plural', {
            payments: paymentPlanConfirm.installmentPlan.numberOfPayments,
          });
        }
        return content('heading.subtitle.single');
      }
      // full payment via LTIP flow: no plan error and no plan confirmation
      return content('heading.subtitle.noPlan');
    })();

    return (
      <PageSection className="page-section--banner">
        <div className="hgroup mb24">
          <IconCheckGreen className="mb6 svg-icon--42" />
          <PageTitle>{content('heading.title')}</PageTitle>
          {billsCopy && <p>{billsCopy}</p>}
        </div>

        <div className="action action--center no-print">
          <div className="action__item">
            <a href={`https://${homeDomain}/billing/services`} className="button button--primary">
              {content('form.done')}
            </a>
          </div>
        </div>

        <div className="text-left">
          {confirm.autopay && (
            <div className="payment-section">
              {paymentSectionLoading && <LoadingCard />}
              {!paymentSectionLoading && (
                <>
                  {autopaySuccessful && (
                    <>
                      <h2>Automatic Payments are On</h2>
                      <AutopaySummary
                        instrument={confirm.instrument}
                        showEdit
                      />
                    </>
                  )}
                  {autopayUnsuccessful && (
                    <Card severity="danger">
                      <p>
                        {'Unfortunately, we couldn\'t set up automatic payments. Please try again.'}
                        <br />
                        <Link to={AUTOPAY_EDIT}>Set Up Automatic Payments</Link>
                      </p>
                    </Card>
                  )}
                </>
              )}
            </div>
          )}

          <div className="payment-section">
            <div className="hgroup hgroup--inline">
              <h2>Payment Confirmed</h2>
              <a href={`https://${homeDomain}/#/billing#upcoming-payments`}>
                View Payment History
              </a>
            </div>

            <PaymentSummary
              autopay={autopay}
              showPrint
              summary={confirm}
            />
          </div>

          {paymentPlanConfirm && paymentPlanConfirm.installmentPlan && (
            <>
              <div className="payment-section">
                <div className="hgroup hgroup--inline">
                  <h2>{content('ltip.title')}</h2>
                </div>
              </div>

              <div className="payment-section">
                <div className="card-group">
                  <div className="card-group__item">
                    <Card
                      className="card--flex-start"
                      actionRight
                    >
                      <KeyValueGroup>
                        <KeyValue
                          className="key-value--inline-at-768"
                          label={content('ltip.fields.amount')}
                          value={(
                            <>
                              {formatCurrency(paymentPlanConfirm.installmentPlan.paymentAmount)}
                              {' ('}
                              {paymentPlanConfirm.installmentPlan.numberOfPayments}
                              {' months)'}
                            </>
                          )}
                        />
                        <KeyValue
                          className="key-value--inline-at-768"
                          label={content('ltip.fields.startDate.label')}
                          value={content('ltip.fields.startDate.value')}
                        />
                      </KeyValueGroup>
                    </Card>
                  </div>
                  <div className="card-group__item">
                    <Card className="text-left">
                      <p>{content('ltip.footer')}</p>
                    </Card>
                  </div>
                </div>
              </div>
            </>
          )}

          <XfinityAssistantBanner intent={XA_XAP_EXITED_INTENT} />
        </div>
      </PageSection>
    );
  }
}

const mapStateToProps = ({
  payment: { confirm },
  paymentPlan: { confirm: paymentPlanConfirm },
  bill,
  autopay,
  autopay: { autopaySignupSuccess },
  account: { account },
}) => ({
  paymentPlanConfirm,
  confirm,
  bill,
  autopay,
  autopaySignupSuccess,
  account,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  handleConfirmPayment: confirmPayment,
  handleSendChannelTracking: sendChannelTracking,
}, dispatch);

export default withCmsContent('content', {
  'ltip-payment-plan': 'Confirmation',
})(connect(mapStateToProps, mapDispatchToProps)(PaymentPlanConfirm));
