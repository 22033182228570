import Cookies from 'js-cookie';
import get from 'lodash.get';

import config from '../config';
import { getIsXapExit } from './xap';
import apiClient from '../middleware/apiClient';
import {
  arrayToSentence,
} from './formatText';
import { flagEnabled } from './featureFlags';
import { xaLinkWithLogin } from './externalLinks';
import { kibanaLog } from './logger';

const x1TV = videoService => (videoService.devices.find(device => device.x1) ? 'X1 TV' : null);
const instantTV = videoService => ((videoService.instantTV && !videoService.devices.length) ? 'Instant TV' : null);
const getVideoServiceName = service => (service.name === 'video' ? (x1TV(service) || instantTV(service) || 'TV') : 'TV');
const mapServiceToName = (service) => {
  const serviceMap = {
    home: 'Home',
    internet: 'Internet',
    storeFront: 'Flex',
    video: getVideoServiceName(service),
    voice: 'Voice',
  };

  return serviceMap[service.name];
};
const getSubscribedServices = (account = {}) => Object.keys({ ...account.services })
  .reduce((arr, name) => {
    const service = account.services[name];
    const isMobile = name === 'mobile';
    const isSubscribed = service.subscribed;

    if (isSubscribed && !isMobile) {
      return [
        ...arr,
        {
          ...service,
          name,
        },
      ];
    }
    return arr;
  }, [])
  .map(mapServiceToName);

export const getServiceList = account => arrayToSentence(getSubscribedServices(account));

const {
  accountSwitcherCookieName,
  legacyAccountSwitcherCookieName,
  accountSwitcherCookieDomain,
  accountPopupCookie,
} = config();

// Read from a cookie to select the correct account
// and set the CSP auth header appropriately.
export const setCspAccountToken = ({ accounts = [] }, index) => {
  const selectedAccount = accounts[index];

  if (selectedAccount) {
    const { cspToken } = selectedAccount;
    apiClient.setHeaders({ 'CSP-Authorization': `LoginToken ${cspToken}` });
  }
};

export const getAccountIndex = ({ accounts = [] }) => {
  const selectedIndex = accounts.findIndex(({ isSelectedAccount }) => !!isSelectedAccount);
  return selectedIndex > -1 ? selectedIndex : 0;
};

export const setAccountIndex = (accountIndex, validate = true) => {
  // Guard against the accountIndex becoming out of sync with the value stored in the cookie
  // possibly because of multiple tabs being open with different accounts selected.
  if (validate) {
    const cookieIndex = parseInt(Cookies.get(accountSwitcherCookieName), 10);

    if (Number.isInteger(cookieIndex) && accountIndex !== cookieIndex) {
      // If the account index doesn't match what's in the cookie then reload the app.
      window.location.reload();
      throw new Error(`setAccountIndex called with ${accountIndex} does not match cookie value ${cookieIndex}`);
    }
  }

  Cookies.set(
    accountSwitcherCookieName,
    accountIndex.toString(10),
    {
      domain: accountSwitcherCookieDomain,
      expires: 1, // 1 day from now, js-cookie supports this shorthand
      path: '/',
    },
  );

  // Temporarily set the legacy account switcher cookie for a smooth rollout
  // until account selection changes are deployed for all frontend apps
  // TODO: Remove this call when maw_cspTokenIndex is cleaned up
  Cookies.set(
    legacyAccountSwitcherCookieName,
    accountIndex.toString(10),
    {
      domain: accountSwitcherCookieDomain,
      expires: 1, // 1 day from now, js-cookie supports this shorthand
      path: '/',
    },
  );
};

export const getIsDisconnected = status => status === 'DISCONNECTED';

/**
 * Between consent and account, the number of emails is too darn high.
 * @param state Current application state.
 * @return some kind of email address to ecobill the user with
 */
export const getPreferredEmail = (account = {}, macaroon = {}) => {
  const { emailType, emailAddress } = account.customerEmail || {};
  const { preferredEmail } = macaroon || {};
  return (
    // User's preferred email address (from CSP)
    preferredEmail
    // User's preferred email address (from SSM Consent API)
    || (
      (emailType === 'preferredEmail')
      && emailAddress
    )
  );
};

export const getAccountPerms = (macaroon, accountIndex) => get(macaroon, `accounts[${accountIndex}].roles`) || [];

const getLOBs = ({ services = {} } = {}) => (
  ['internet', 'video', 'voice', 'home', 'mobile', 'stream', 'storeFront'].reduce((o, type) => {
    const acc = Object.assign({}, o);
    if (type === 'stream') {
      acc[type] = get(services, 'video.instantTV');
    } else {
      acc[type] = get(services, `[${type}].subscribed`);
    }
    return acc;
  }, {})
);

export const getIsPreactive = (account) => {
  const inTheLastNHours = (date, hours) => Date.now() - new Date(date).getTime() < (36e5 * hours);
  const thirtyDaysInHours = 30 * 24;

  const isPrimary = !!(account.users && account.users.find(item => item.role === 'PRIMARY' && item.loggedInUser));
  const isDisconnected = account.status === 'DISCONNECTED';

  return (
    isPrimary
    && !isDisconnected
    && inTheLastNHours(account.createDate, thirtyDaysInHours)
  );
};

export const getShowFlexPromo = (account, { tier = { tierOfService: 'Missing tier information' } }) => {
  const services = getLOBs(account);
  const isValidTier = !!(tier.tierOfService !== 'Internet Essentials' && tier.tierOfService !== 'Missing tier information');

  // feature flag on
  // has internet, no video, no storeFront, no stream, no prepaid, no internet essentials
  return flagEnabled('global.flexPromo')
    && (account && !account.prepaid)
    && !getIsXapExit(account)
    && isValidTier
    && services.internet
    && !services.storeFront
    && !services.video
    && !services.stream;
};

// Determine Display Type of Peacock Promo (US1727582)
export const getShowPeacockPromo = (account, { tier = { tierOfService: 'Missing tier information' } }) => {
  const results = {
    show: false,
    link: 'http://www.xfinity.com/peacock',
    linkCTA: 'Fly to More Info',
    header: 'Get Peacock Premium at no extra cost',
    message: '',
    scenario: {
      type: null,
      l3Value: '',
    },
  };

  if (!account) {
    return results;
  }

  const accountLOBs = (account && getLOBs(account)) || {};

  const isPreactive = getIsPreactive(account);
  const hasX1 = (account.services.video.devices).length > 0
    ? (account.services.video.devices).some(device => device.x1)
    : false;
  const hasHSD = accountLOBs.internet || false;
  const hasFlex = accountLOBs.storeFront || false;
  const hasVideo = accountLOBs.video || false;
  const isValidTier = !!(tier.tierOfService !== 'Internet Essentials' && tier.tierOfService !== 'Missing tier information');

  if (isPreactive && hasHSD && hasFlex) {
    // Show Scenario 1 Promo (Preactive + HSD + Flex)
    results.show = true;
    results.message = ', now included. Just say “Peacock” into your Voice Remote after your Xfinity products are set up at home.';
    results.scenario.type = 1;
    results.scenario.l3Value = 'peacock|preactive-flex-peacock-awareness';
  } else if (isPreactive && hasX1 && hasVideo) {
    // Show Scenario 2 Promo (Preactive + X1)
    results.show = true;
    results.message = ', now included. Just say “Peacock” into your Voice Remote after your Xfinity products are set up at home.';
    results.scenario.type = 2;
    results.scenario.l3Value = 'peacock|preactive-flex-x1-awareness';
  } else if (!isPreactive && hasHSD && !hasVideo && !hasFlex) {
    // Show Scenario 3 Promo (Non-Preactive + HSD + Non-Video)
    results.show = true;
    results.link = 'http://www.xfinity.com/buy/plan/addon/flex';
    results.linkCTA = 'Add Flex Now';
    results.header = 'Get Flex and Peacock Premium at no extra cost';
    results.message = '. Now included on our Flex 4K streaming device + Voice Remote. Plus watch your Netflix and more.';
    results.scenario.type = 3;
    results.scenario.l3Value = 'peacock|existing-flex-peacock-upsell';
  } else if (!isPreactive && hasHSD && hasFlex) {
    // Show Scenario 4 Promo (Non-Preactive + HSD + Flex)
    results.show = true;
    results.message = ', now included. Just say “Peacock” into your Voice Remote.';
    results.scenario.type = 4;
    results.scenario.l3Value = 'peacock|existing-flex-peacock-awareness';
  } else if (!isPreactive && hasX1 && hasVideo) {
    // Show Scenario 5 Promo (Non-Preactive + X1)
    results.show = true;
    results.message = ', now included. Just say “Peacock” into your Voice Remote.';
    results.scenario.type = 5;
    results.scenario.l3Value = 'peacock|existing-flex-x1-awareness';
  }

  // show if flag is enabled, tier is valid, and not prepaid
  results.show = flagEnabled('global.peacockPromo')
    && isValidTier
    && account && !account.prepaid;

  return results;
};

export const getShowLiteBillPopup = (account, bill) => {
  if (!account || !bill) return null;
  let modalContents;
  const {
    summary: {
      pastDueBalanceRemaining,
      softDisconnected,
    } = {},
  } = bill;

  const isDelinquent = bill.delinquency && bill.delinquency.delinquencyStatus === 'DELINQUENT';
  const isDisconnected = getIsDisconnected(account.status);
  const hasBalanceDue = pastDueBalanceRemaining > 0;
  const isPopupShown = Cookies.get(accountPopupCookie) || false;

  const modalLink = {
    ctaText: 'Chat for options',
    ctaLink: xaLinkWithLogin('skill.billing.p2p'),
  };

  if (!isDisconnected
    && isDelinquent
    && !softDisconnected
    && hasBalanceDue
    && !isPopupShown
  ) {
    modalContents = {
      heading: 'Your bill is past due',
      description: 'You can pay your past due balance now to avoid possible service interruptions, or sign in to chat with Xfinity Assistant to see if you qualify for flexible payment options.',
      ...modalLink,
    };
  }

  if (isDelinquent
    && softDisconnected
    && hasBalanceDue
  ) {
    modalContents = {
      heading: 'Your bill is past due and service has been suspended',
      description: 'You can pay your past due balance now to restore service, or sign in to chat with Xfinity Assistant to see if you qualify for flexible payment options.',
      ...modalLink,
    };
  }

  return modalContents;
};

export const hasEverHadXfinityServices = services => Object.values(services).some(
  (service) => {
    if (service.productClass === 'XFINITY' && service.status) {
      return true;
    }
    if (service.productClass === null && service.status) {
      kibanaLog('Let into Core with null productClass');
      return true;
    }
    return false;
  },
);

export const hasEverHadNowServices = services => Object.values(services).some(
  (service) => {
    if (service.productClass === 'NOW' && service.status) {
      return true;
    }
    if (service.productClass === null && service.status) {
      kibanaLog('Let into NOW with null productClass');
      return true;
    }
    return false;
  },
);
