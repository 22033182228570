/**
  Selection utilities for use with Field@normalize

  A field normalizer will blow away the user's selection if it needs to change something.
  This helper contains methods that you can use to maintain a sane user experience.

  example of use:


  Before:

    class FancyField extends Component {
      constructor() {
        this.normalizeField = this.normalizeField.bind(this);
      }
      normalizeField(value) {
        let result = magicTransform(value);
        return result;
      }
      render() {
        <Field
          name="myField"
          normalize={this.normalizeField}
        />
      }
    }

  After:

  const stripRx = /[$.\s]/g;

  class FancyField extends Component {
    constructor() {
      this.normalizeField = this.normalizeField.bind(this);
    }
    normalizeField(value) {
      let result = magicTransform(value);
      // Populate the intermediate and final values for the selection change object,
      //  and restore the user's selection.
      completeSelection(this, 'myField', value, result);
      return result;
    }
    // should be called on keyDown; can be used for multiple fields.
    storeSelection(event) {
      // start a selection change action.
      //   stripRx tells the selection restore function what characters
      //   to skip over when the user presses backspace or delete
      return storeSelection(this, event, stripRx);
    }
    render() {
      <Field
        name="myField"
        normalize={this.normalizeField}
        onKeyDown={this.storeSelection}
      />
    }
  }

*/

const consumeSelection = (inst, name) => {
  const context = inst;
  const result = context.private_selections[name];
  delete context.private_selections[name];
  return result ? {
    ...result,
    ready: !!(result.target && result.intermediate && result.value),
    atEnd: result.start === result.old.length && result.start === result.end,
  } : {};
};

const restoreSelection = (inst, name) => {
  const {
    ready,
    atEnd,
    old,
    intermediate,
    value,
    start,
    end,
    setSelection,
    strip,
    newStart,
  } = consumeSelection(inst, name);

  if (!ready || atEnd) {
    // No change made or changed at end of line; default behavior OK.
    return;
  }
  if (intermediate === value) {
    // no adjustments made; default behavior already correct.
    return;
  }
  // A change occurred at not-the-end
  if (value !== old) {
    const delta = (strip(value).length - strip(old).length);
    const repl = end - start;
    if (repl) {
      // User had a selection
      setSelection(end + delta);
      return;
    }

    if (delta < 0) {
      // a non-whitespace character was removed.
      if (newStart < start) {
        // User used backspace
        setSelection(start + delta);
        return;
      }
      // else user used delete
      setSelection(start);
      return;
    } if (delta > 0) {
      // character inserted
      setSelection(start + delta);
      return;
    }
    // Set the selection.
    setSelection(start);
    return;
  }
  if (strip(intermediate) === strip(value)) {
    // Only dash / space changes made; normalization removes these and replaces
    // with patterned whitespace, so in most cases, just restore the previous selection.
    const delta = (intermediate.length - old.length);
    if (delta < 0) {
      if (newStart < start) {
        // backspace on whitespace before end of string (any change at EOS is covered above)
        // move to the left of the space
        setSelection(start + delta);
        return;
      }
      // delete on whitespace before end of string
      // move to the right of the space
      setSelection(start - delta);
      return;
    }
    setSelection(start, end);
    return;
  }
  // No change made; old === value.  Indicates non-numeric entered in middle.
  setSelection(start, end);
};

/**
  Store the selection from a keyDown event
  @param inst component instance to store data on
  @param { target } from the keyDown event
  @param stripRx characters to ignore when backspacing or deleting
*/
export const storeSelection = (inst, { target }, stripRx) => {
  const { name } = target;
  const context = inst;
  if (!context.private_selections) {
    context.private_selections = {};
  }
  if (
    context.private_selections[name]
    && context.private_selections[name].intermediate
    && context.private_selections[name].value
  ) {
    restoreSelection(context, name);
  }
  context.private_selections[name] = {
    target,
    name,
    start: target.selectionStart,
    end: target.selectionEnd,
    old: target.value,
    intermediate: undefined,
    value: undefined,
    newStart: undefined,
    setSelection: (s, e) => {
      const tgt = target;
      tgt.selectionStart = s;
      tgt.selectionEnd = Number.isNaN(e) ? s : e;
    },
    strip: stripRx ? (str => str.replace(stripRx, '')) : (str => str),
  };
};

/**
 * Complete the selection object and restore it
 * @param inst class instance to store selection data on
 * @param name name of field to restore
 * @param intermediate the unnormalized value
 * @param value the normalized value
 */
export const completeSelection = (inst, name, intermediate, value) => {
  if (inst.private_selections && inst.private_selections[name]) {
    const { target } = inst.private_selections[name];
    Object.assign(inst.private_selections[name], {
      intermediate,
      value,
      newStart: target.selectionStart,
    });
    requestAnimationFrame(() => restoreSelection(inst, name));
  }
};
