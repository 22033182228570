import { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import config from '../config';
import { sendPageloadEvent } from '../actions/adobe';
import { DTM2Console } from '../helpers/adobe';

class WithTrackingBase extends Component {
  componentDidMount() {
    const { computedMatch, handleSendPageloadEvent } = this.props;
    const logDTM = config().REACT_APP_DTMLOG === 'TRUE';

    DTM2Console(logDTM);
    handleSendPageloadEvent(computedMatch);
  }

  componentDidUpdate(prevProps) {
    const { location: { pathname: nextPage }, handleSendPageloadEvent, computedMatch } = this.props;
    const { location: { pathname: prevPage } } = prevProps;
    if (prevPage !== nextPage) {
      handleSendPageloadEvent(computedMatch);
    }
  }

  render() {
    const { children } = this.props;
    return children;
  }
}

const mapDispatchToProps = dispatch => ({
  handleSendPageloadEvent: bindActionCreators(sendPageloadEvent, dispatch),
});

// You have to connect() to any reducers that you wish to connect to yourself
const WithTracking = connect(
  null,
  mapDispatchToProps,
)(WithTrackingBase);

export default WithTracking;
