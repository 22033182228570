import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { PageSection, IconCircleExclamation } from 'adc-ui-components';

import PageTitle from '../../components/PageTitle';

import { kibanaLog } from '../../helpers/logger';

class NoMatch extends Component {
  componentDidMount() {
    const { match } = this.props;
    // todo, make this better
    kibanaLog('no_match', {
      route: match,
    });
  }

  render() {
    return (
      <PageSection className="ui-grey page-section--banner">
        <IconCircleExclamation className="mb6 svg-icon--42" />

        <div className="hgroup">
          <PageTitle>Not Found</PageTitle>
          <p>The page you&apos;re looking for is not here.</p>
        </div>

        <Link className="button button--primary" to="/">Go Back</Link>
      </PageSection>
    );
  }
}

export default NoMatch;
