import {
  GET_MOVE_BILL_DUE_DATE,
  GET_MOVE_BILL_DUE_DATE_SUCCESS,
  GET_MOVE_BILL_DUE_DATE_FAILURE,
} from '../actions/moveBillDueDate';

const INITIAL_STATE = { loading: true, cached: false };

export default function moveBillDueDateReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_MOVE_BILL_DUE_DATE:
      return {
        ...state,
        cached: true,
        loading: true,
      };
    case GET_MOVE_BILL_DUE_DATE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        moveBillDueDate: action.payload,
      };
    case GET_MOVE_BILL_DUE_DATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };
    default:
      return state;
  }
}
