export const states = [
  'AL',
  'AK',
  'AZ',
  'AR',
  'CA',
  'CO',
  'CT',
  'DC',
  'DE',
  'FL',
  'GA',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MD',
  'MA',
  'MI',
  'MN',
  'MS',
  'MO',
  'MT',
  'NE',
  'NV',
  'NH',
  'NJ',
  'NM',
  'NY',
  'NC',
  'ND',
  'OH',
  'OK',
  'OR',
  'PA',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VT',
  'VA',
  'WA',
  'WV',
  'WI',
  'WY',
];

export const formatBillingAddress = (account = {}, form = {}) => {
  const billingAddress = {};

  billingAddress.person = {
    firstName: form.firstName || (account.person && account.person.firstName) || '',
    lastName: form.lastName || (account.person && account.person.lastName) || '',
  };

  billingAddress.address = (
    form.billingAddressCheckbox
      ? {
        addressLine1: form.line1,
        addressLine2: form.line2,
        city: form.city,
        state: form.state,
        zip: form.zip,
      }
      : account.serviceAddress
  );
  return billingAddress;
};
