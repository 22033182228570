import React from 'react';

const CmsCta = ({ cta }) => (
  <a
    key={cta.url}
    className={`button button--${cta.type || 'text'}`}
    href={cta.url}
    target={cta.behavior}
    rel="noopener noreferrer"
  >
    {cta.description || cta.label}
  </a>
);

export default CmsCta;
