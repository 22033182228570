import React from 'react';

import { Link } from 'react-router-dom';
import AutopaySummary from '../../components/AutopaySummary';
import KeyValue from '../../components/KeyValue';
import NextBestActions from '../../components/nbas';
import PageTitle from '../../components/PageTitle';
import { ACCOUNT_TOKEN } from '../../components/nbas/AccountTokenNba';

import { AUTOPAY_EDIT } from '../../helpers/routes';
import getConfig from '../../config';

const { billServicesUrl } = getConfig();

const Summary = ({
  bill,
  bill: {
    summary: {
      autoPayEnabled,
      autoPayValid,
    } = {},
  } = {},
  autopay,
  autopay: {
    instrument,
  } = {},
  openModal,
}) => {
  if (!autoPayEnabled && !autopay) {
    return (
      <>
        <PageTitle>Automatic Payments are Off</PageTitle>

        <div className="card-group">
          <div className="card-group__item">
            <div className="card card--action-right-at-768">
              <div className="card__content">
                <KeyValue className="key-value--inline-at-768" label="Automatic payments" value="Off" />
              </div>
              <div className="card__action">
                <Link to={AUTOPAY_EDIT} className="button button--text">Set up Automatic Payments</Link>
              </div>
            </div>
          </div>
        </div>

        <div className="action action--right-at-768">
          <a className="button" href={billServicesUrl}>Go Back</a>
        </div>
      </>
    );
  }
  if (autoPayEnabled && !autoPayValid) {
    return (
      <div className="card-group">
        <div className="card-group__item card--alert">
          <div className="card card--action-right-at-768">
            <div className="card__content">
              There&apos;s an issue with your automatic payment. Your automatic payment was not
              processed. Please update your automatic payment settings.
            </div>
            <div className="card__action">
              <Link to={AUTOPAY_EDIT} className="button button--text">Set up Automatic Payments</Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <>
      <div className="hgroup">
        <PageTitle>Automatic Payments are On</PageTitle>
        <p>Your full balance will be automatically charged on your bill due date.</p>
      </div>

      <div className="card-group">
        <div className="card-group__item">
          <div className="card card--action-right-at-768">
            <div className="card__content">
              <KeyValue className="key-value--inline-at-768" label="Automatic payments" value="Enabled" />
            </div>
            <div className="card__action">
              <button className="button button--text" type="button" onClick={openModal}>Turn Off</button>
            </div>
          </div>
        </div>
        <div className="card-group__item">
          <AutopaySummary
            autopay={autopay}
            bill={bill}
            instrument={instrument}
            showUpdate
          />
        </div>
      </div>

      <div className="action action--right-at-768">
        <a className="button" href={billServicesUrl}>Go Back</a>
      </div>
    </>
  );
};

const SummaryWithNbas = ({ nextBestActions = [], ...props }) => {
  const modifiedNbas = nextBestActions.map((nba) => {
    if (nba.id === ACCOUNT_TOKEN) {
      return {
        ...nba,
        options: {
          showLink: true,
        },
      };
    }

    return nba;
  });

  return (
    <>
      {!!modifiedNbas.length && <NextBestActions nbaList={modifiedNbas} />}
      <Summary {...props} />
    </>
  );
};

export default SummaryWithNbas;
