import React from 'react';

const CmsImage = ({
  image: {
    alt,
    link: {
      image: {
        src = [],
      } = {},
    } = {},
  } = {},
}) => {
  const { image } = src.find(({ breakpoint }) => breakpoint === 'default');
  return <img src={image} alt={alt} />;
};

export default CmsImage;
