import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { IconCheckGreen } from 'adc-ui-components';

import { messageShown } from '../actions/userMessages';
import Pushdown from './Pushdown';
import getConfig from '../config';

const { billServicesUrl } = getConfig();

class SuccessPushDown extends Component {
  componentDidMount() {
    const {
      handleMessageShown,
    } = this.props;

    handleMessageShown();
  }


  render() {
    const {
      heading,
      body,
      link = billServicesUrl,
      linkText = 'Back to Billing',
    } = this.props;

    return (

      <Pushdown
        className="pushdown--success"
        title={heading}
        icon={<IconCheckGreen className="svg-icon--36" />}
      >
        {body && <p className="body3">{body}</p>}
        {link && <a href={link}>{linkText}</a>}
      </Pushdown>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  handleMessageShown: bindActionCreators(messageShown, dispatch),
});

export default connect(null, mapDispatchToProps)(SuccessPushDown);
