import {
  FETCH_SCHEDULED,
  FETCH_SCHEDULED_SUCCESS,
  FETCH_SCHEDULED_FAILURE,
  UPDATE_SCHEDULED_PAYMENT,
  UPDATE_SCHEDULED_PAYMENT_SUCCESS,
  UPDATE_SCHEDULED_PAYMENT_FAILURE,
  CANCEL_SCHEDULED_PAYMENT,
  CANCEL_SCHEDULED_PAYMENT_SUCCESS,
  CANCEL_SCHEDULED_PAYMENT_FAILURE,
  RESET_ERRORS,
} from '../actions/scheduledPayments';

import {
  RESET_PAYMENT,
} from '../actions/payment';

import {
  FORCE_LOGIN_URL,
} from '../helpers/externalLinks';

const INITIAL_STATE = { loading: true, payments: [] };

export default function scheduledPaymentsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case CANCEL_SCHEDULED_PAYMENT:
    case UPDATE_SCHEDULED_PAYMENT:
      return {
        ...state,
        submitting: true,
        submitError: null,
        confirm: null,
      };
    case UPDATE_SCHEDULED_PAYMENT_SUCCESS:
      return {
        ...state,
        confirm: action.payload,
        submitting: false,
        payments: state.payments.map((payment) => {
          if (payment.id === action.payload.payment_id) {
            return {
              ...payment,
              amount: action.payload.amount,
              date: action.payload.date,
            };
          }

          return payment;
        }),
      };
    case CANCEL_SCHEDULED_PAYMENT_SUCCESS:
      return {
        ...state,
        submitting: false,
        payments: state.payments.filter(payment => payment.id !== action.payload.payment_id),
      };
    case CANCEL_SCHEDULED_PAYMENT_FAILURE:
    case UPDATE_SCHEDULED_PAYMENT_FAILURE:
      return {
        ...state,
        submitError: action.payload,
        submitting: false,
      };
    case FETCH_SCHEDULED:
      return {
        ...state,
        loading: true,
      };
    case FETCH_SCHEDULED_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
        error: null,
      };
    case FETCH_SCHEDULED_FAILURE:
      if (action.payload.status === 403 && action.payload.data.code === 'MA403.01') {
        window.location.replace(FORCE_LOGIN_URL);
      }
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case RESET_ERRORS:
      return {
        ...state,
        error: null,
      };
    case RESET_PAYMENT:
      return {
        ...state,
        review: null,
        confirm: null,
      };
    default:
      return state;
  }
}
