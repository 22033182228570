import React, { useCallback } from 'react';

import { joinClass } from '../helpers/component';
import Dismiss from './svgs/Dismiss';

const Pushdown = ({
  className,
  icon,
  title,
  children,
  action,
  actionText,
  actionClass = 'button button--primary',
  dismiss,
  tracking,
  disableScroll = false,
}) => {
  const onRender = useCallback((element) => {
    // This means the element's been removed, so the component probably unmounted.
    // OR we don't want to scroll if disableScroll is set.
    if (!element || disableScroll) return;
    // Delay by a frame to let any existing scrolls complete.
    requestAnimationFrame(() => {
      element.scrollIntoView();
    });
  }, [disableScroll]);

  return (
    <div ref={onRender} className={joinClass('pushdown', className)} tracking-module={tracking}>
      <div className="pushdown__wrapper">
        {icon && (
          <div className="pushdown__icon">
            {icon}
          </div>
        )}

        <div className="pushdown__content">
          <div className="hgroup">
            {title && <h3 className="body2">{title}</h3>}
            {children}
          </div>
        </div>

        {action && (
          <div className="pushdown__action">
            <a className={actionClass} href={action}>{actionText}</a>
          </div>
        )}
        {dismiss && (
          <div className="pushdown__action">
            <button
              type="button"
              className="card__dismiss button button--text"
              custom-tracking="true"
              onClick={dismiss}
            >
              <Dismiss className="svg-icon svg-icon--22" />
              <span className="visuallyhidden">Dismiss</span>
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Pushdown;
