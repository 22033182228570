export const ESC = 27;
export const TAB = 9;
export const UP = 38;
export const DOWN = 40;
export const LEFT = 37;
export const RIGHT = 39;
export const PAGE_UP = 33;
export const PAGE_DOWN = 34;
export const SPACE = 32;
export const ENTER = 13;
