import {
  GET_USER,
  GET_USER_SUCCESS,
  GET_USER_FAILURE,
} from '../actions/user';

const INITIAL_STATE = { loading: true, cached: false };

export default function userReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_USER:
      return {
        ...state,
        cached: true,
        loading: true,
      };
    case GET_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        user: action.payload,
      };
    case GET_USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };
    default:
      return state;
  }
}
