import React from 'react';
import ReactModal from 'react-modal';
import { LoadingCard } from 'adc-ui-components';

// set app element for a11y
// we don't need it for tests
if (process.env.NODE_ENV !== 'test') {
  ReactModal.setAppElement('#root');
}

const Modal = ({
  isOpen,
  onRequestClose,
  contentLabel,
  children,
  fullPage,
  loading,
  dismissable = true,
}) => {
  const modalClass = {
    base: 'modal__content',
    afterOpen: '',
    beforeClose: '',
  };
  const overlayClass = {
    base: `modal ${fullPage ? 'modal--full-page' : ''}`,
    afterOpen: 'modal--is-open',
    beforeClose: '',
  };

  const closeButton = dismissable && (
    <button type="button" className="modal__close button button--text" onClick={onRequestClose}>
      <span className="visuallyhidden">Close Dialog</span>
    </button>
  );

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel={contentLabel}
      className={modalClass}
      overlayClassName={overlayClass}
    >
      {loading && <LoadingCard />}

      {!loading && !fullPage && (
        <div className="card">
          {closeButton}

          <div className="card__content">
            {children}
          </div>
        </div>
      )}

      {!loading && fullPage && (
        <>
          {closeButton}
          {children}
        </>
      )}
    </ReactModal>
  );
};

export default Modal;
