import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import dateformat from 'dateformat';

import {
  SCHEDULED_PAYMENT_EDIT,
  resolveRoute,
} from '../helpers/routes';
import { formatCurrency } from '../helpers/formatText';

import Card from './Card';
import KeyValue from './KeyValue';
import CancelModal from '../containers/Scheduled/CancelModal';
import { getIsXapCurrent, getIsXapExit, getIsXapExitInProgress } from '../helpers/xap';

const CompactScheduledPayment = ({ payment, account, history }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const openModal = () => setModalIsOpen(true);
  const closeModal = () => setModalIsOpen(false);
  const { canModify, canCancel } = payment;
  const showAction = canModify || canCancel;
  const canShowModify = canModify
    && !getIsXapCurrent(account)
    && !getIsXapExit(account)
    && !getIsXapExitInProgress(account);

  const modifyLink = canShowModify && (
    <div className="link-group__item mb0">
      <Link to={resolveRoute(SCHEDULED_PAYMENT_EDIT, { id: payment.id })}>
        Manage
      </Link>
    </div>
  );

  const cancelLink = canCancel && (
    <div className="link-group__item mb0">
      <button type="button" className="button button--text" onClick={openModal}>
        Cancel Payment
      </button>
    </div>
  );

  const action = (
    <div className="link-group">
      { modifyLink }
      { cancelLink }
    </div>
  );

  return (
    <>
      { modalIsOpen && (
        <CancelModal
          payment={payment}
          closeModal={closeModal}
          history={history}
        />
      )}
      <Card
        action={showAction && action}
        className="card--action-right-at-768"
      >
        <KeyValue
          className="key-value--inline-at-768"
          label={dateformat(payment.date, 'dddd, mmm dS', true)}
          value={formatCurrency(payment.amount)}
          badge={payment.promisedToPay ? 'PAYMENT ARRANGEMENT' : null}
        />
      </Card>
    </>
  );
};

export default CompactScheduledPayment;
