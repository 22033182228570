import memoized from '../memoized';

const THREECONSECUTIVENUMBERS = /\d{4}/;
const NUMBERS = /\d/g;

const ConsecutiveNumericNameFactory = memoized(message => (value = '') => (
  THREECONSECUTIVENUMBERS.test(value) || (value.match(NUMBERS) || []).length > 6
    ? message
    : undefined
));

export default ConsecutiveNumericNameFactory;
