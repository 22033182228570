export const mergeDataFromEvent = (data = {}, analyticsData = {}) => {
  const digitalData = analyticsData;
  // eslint-disable-next-line
  const profileInfo = analyticsData.user[0].profile[0].profileInfo;
  const eventData = data;

  // Reset data on page load
  profileInfo.attributes = {};

  /**
   * data stores all custom parameters to be passed to digitalData object
   * data parameters are mapped with "Copy of Self Service Spec OverviewDRAFTNOTES.xlsx" doc
   * attached to https://rally1.rallydev.com/#45396894333ud/detail/userstory/58842904540
   */

  /**
   * Don't see use for this yet. Due to "Copy of Self Service Spec OverviewDRAFTNOTES.xlsx"
   * "classification" and "sub classification" are encoded in eVar
   */

  // digitalData.page.category.primaryCategory = data.classification
  // || digitalData.page.category.primaryCategory;
  // digitalData.page.category.subCategory1 = data.subClassification
  // || digitalData.page.category.subCategory1;

  // remove subCategory2 and set it with screename in sendPageloadEvent
  if (digitalData.page.category.subCategory2) {
    delete digitalData.page.category.subCategory2;
  }

  // Delete component array, for example when route changes
  if (eventData.deleteComponent) {
    delete digitalData.component;
  }
  if (eventData.component && eventData.component.length) {
    digitalData.component = eventData.component;
  }

  // Pete: Not sure we need this anymore
  // digitalData.page.pageInfo.pageName = data.pageName
  // || AdobeTracking.getEventPage($location.path());

  if (eventData.pageLoadEvent) {
    digitalData.page.attributes.pageLoadEvent = eventData.pageLoadEvent;
  } else {
    delete digitalData.page.attributes.pageLoadEvent;
  }

  /**
   * If there is eVar field looks like funnelType is corresponding to Transaction column in
   * "Copy of Self Service Spec OverviewDRAFTNOTES.xlsx"
   */
  if (eventData.transaction) {
    digitalData.transaction = {
      attributes: {
        funnelType: eventData.transaction,
      },
    };
    if (eventData.billPay) {
      digitalData.transaction.attributes.billPay = eventData.billPay;
    }
    eventData.trackName = eventData.trackName || 'c-tracking-log-dom'; // 'self-ServiceTransaction'
  } else {
    // Removing property if it was populated by previous events (not sure whether it is necessary)
    delete digitalData.transaction;
  }

  if (eventData.attributes) {
    profileInfo.attributes = eventData.attributes;
  }

  if (eventData.deleteEvents) {
    delete digitalData.event;
  }

  if (eventData.event) {
    digitalData.event = digitalData.event || [];
    digitalData.event.push(eventData.event);
    digitalData.event[digitalData.event.length - 1].eventInfo = {
      ...digitalData.event[digitalData.event.length - 1].eventInfo,
    };
    digitalData.event[digitalData.event.length - 1]
      .eventInfo.eventPage = eventData.event.eventPage || eventData.pageName;
  }

  eventData.trackName = eventData.trackName || 'c-tracking-log-page';

  // should be refactored cos we don't have to save full events list any longer
  if (eventData.trackName === 'c-tracking-log-dom' && digitalData.event) {
    return digitalData.event[digitalData.event.length - 1].eventInfo;
  }

  return digitalData;
};

export default mergeDataFromEvent;
