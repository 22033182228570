import React from 'react';

import { Provider } from 'react-redux';
import DefaultLayout from './DefaultLayout';
import WithTracking from '../WithTracking';
import { showPage } from '../../helpers/polaris';

const DefaultLayoutWithTracking = ({ store, ...props }) => {
  const content = (
    <WithTracking {...props}>
      <DefaultLayout {...props} />
    </WithTracking>
  );
  if (store) {
    // This route has its own store and is probably unauthed.
    // Disable Polaris' FOUC hiding
    showPage();
    return (
      <Provider store={store}>
        {content}
      </Provider>
    );
  }
  return content;
};

export default DefaultLayoutWithTracking;
