import {
  getIsToday,
  parseDate,
  compareDays,
  noonOf,
  createDateObject,
  today,
} from './date';
import { getIsXapExit, getIsXapCurrent, getIsXapExitInProgress } from './xap';

export const getMeetsP2PPrerequisites = ({
  bill: {
    promiseToPay: {
      customerEligibilityChecked,
    } = {},
  } = {},
}) => (customerEligibilityChecked || {}).eligibleForPromiseToPay;

// Comparator for `compareDays`.
const onOrBefore = (a, b) => a <= b;

// Because it's looped, avoid calling `noonOf(createDateObject).toJSON`
// so many times
export const hasQualifyingScheduledPayments = (
  {
    promiseToPayDate,
    promiseToPayAmount,
  },
  payments,
) => {
  const strP2PDate = noonOf(createDateObject(promiseToPayDate)).toJSON().substring(0, 10);
  return payments.reduce((
    sum,
    { amount, date },
  ) => {
    const strPaymentDate = noonOf(createDateObject(date)).toJSON().substring(0, 10);
    if (strPaymentDate > strP2PDate) {
      return sum;
    }
    return sum + amount;
  }, 0) <= promiseToPayAmount;
};

/**
 * Distills a bill and values/review/confirm into a set of p2p-relevant data
 */
export const p2pProps = ({
  account,
  plan,
  bill: {
    summary: {
      pastDueBalanceRemaining,
      balanceDue,
      softDisconnected,
      promiseToPayDate,
    } = {},
    promiseToPay: {
      customerEligibilityChecked,
      existingPromiseToPayInfo,
    } = {},
  } = {},
  values,
  review: {
    amount: reviewAmount,
    date: reviewDate,
  } = {},
  confirm: {
    amount: confirmAmount,
    date: confirmDate,
  } = {},
  keepItShort,
  scheduledPayments = [],
}) => {
  const {
    brokenReasons,
    promiseToPayAmount,
    promiseToPayDate: existingAgreementDate,
  } = existingPromiseToPayInfo || {};
  const {
    paymentAmountOption,
    customAmount,
    date: formDate,
  } = {
    ...values,
    ...(plan && !plan.otherAmount && {
      paymentAmountOption: plan.minimumPayment,
      customAmount: undefined,
    }),
  };
  const {
    eligibleForPromiseToPay,
    maxP2PDateBeforeCycle,
    maxP2PDateAfterCycle,
    minP2PAmtBeforeCycle,
    minP2PAmtAfterCycle,
  } = customerEligibilityChecked || {};
  const requirements = [
    { date: maxP2PDateBeforeCycle, amount: minP2PAmtBeforeCycle },
    { date: maxP2PDateAfterCycle, amount: minP2PAmtAfterCycle },
  ].filter(({ amount }) => !!amount);
  // Edge condition: dates are the same.
  if (
    requirements.length === 2
    && requirements[0].date === requirements[1].date
  ) {
    // Take the higher amount (sort desc, pop the end off)
    requirements
      .sort((a, b) => (b.amount - a.amount))
      .pop();
  }
  const [earlyRequirement, lateRequirement] = requirements;
  const p2pDate = earlyRequirement && parseDate(earlyRequirement.date);
  const existingPromiseDate = existingAgreementDate && parseDate(existingAgreementDate);
  const p2pMinAmount = (
    (plan && plan.minimumAmount)
    || (earlyRequirement && earlyRequirement.amount)
  );

  const isExistingP2P = (
    pastDueBalanceRemaining >= 0
    && existingPromiseToPayInfo
    && (brokenReasons || []).length === 0
    && promiseToPayAmount
    && existingAgreementDate
    && compareDays(today(), existingAgreementDate, onOrBefore)

    // User does not have scheduled payments before promise date that sum to
    //  promised amount.
    && hasQualifyingScheduledPayments(existingPromiseToPayInfo, scheduledPayments)
  );

  const date = parseDate(formDate || reviewDate || confirmDate);
  const formAmount = paymentAmountOption && String(paymentAmountOption === 'custom' ? customAmount : paymentAmountOption).replace(/^\$/, '');
  const amount = parseFloat(formAmount || reviewAmount || confirmAmount);
  const laterDate = lateRequirement && parseDate(lateRequirement.date);
  const laterAmount = lateRequirement && lateRequirement.amount;
  const onOrBeforeLaterDate = laterDate && compareDays(date, laterDate, onOrBefore);
  const onOrBeforeEarlyDate = p2pDate && compareDays(date, p2pDate, onOrBefore);

  const inTimeToQualify = (
    onOrBeforeEarlyDate
    || (
      !!laterDate
      && amount >= laterAmount
      && onOrBeforeLaterDate
    )
  );

  const inTimeForPromise = (
    !!existingPromiseDate
    && amount >= promiseToPayAmount
    && compareDays(date, existingPromiseDate, onOrBefore)
  );
  const isToday = getIsToday(date);

  const qualifies = (
    (
      (onOrBeforeEarlyDate && amount >= p2pMinAmount)
      || (
        !!lateRequirement && (
          onOrBeforeLaterDate
          && amount >= laterAmount
        )
      )
    )
    && (
      !(plan && plan.validDates)
      || compareDays(date, plan.validDates[1].getTime(), onOrBefore)
    )
  );

  const [qualifyingDate, qualifyingAmount] = (
    qualifies
    && (
      (laterDate && date > p2pDate)
        ? [laterDate, laterAmount]
        : [p2pDate, p2pMinAmount]
    )
  ) || [];

  const xapExit = getIsXapExit(account);
  const xapCurrent = getIsXapCurrent(account);
  const xapExitInProgress = getIsXapExitInProgress(account);
  const result = {
    isP2P: eligibleForPromiseToPay && !(promiseToPayDate && existingAgreementDate),
    softDisconnected,
    today: isToday,
    inTimeToQualify,
    date,
    p2pDate,
    p2pMinAmount,
    inTimeForPromise,
    qualifies,
    qualifyingDate,
    qualifyingAmount,
    showLater: qualifyingDate !== date && !keepItShort,
    ...(lateRequirement && { laterDate, laterAmount }),
    pastDue: pastDueBalanceRemaining,
    amount,
    balance: balanceDue,
    xapExit,
    xapCurrent,
    xapExitInProgress,
    isExistingP2P,
    existingPromiseDate,
  };
  return result;
};
