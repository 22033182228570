import React, { Component } from 'react';
import { connect } from 'react-redux';
import { PageSection, LoadingCard } from 'adc-ui-components';
import { bindActionCreators } from 'redux';
import { Switch } from 'react-router-dom';

import { getPaymentInstruments } from '../../actions/instruments';
import { getAccount } from '../../actions/account';
import { getAutopay } from '../../actions/autopay';
import { getBill } from '../../actions/bill';
import { getUser } from '../../actions/user';
import { getNowInstruments } from '../../actions/nowWalletInstruments';
import { getIsDisconnected } from '../../helpers/account';
import { joinClass } from '../../helpers/component';

import NextBestActions from '../../components/nbas';
import AccountTokenNba, { ACCOUNT_TOKEN } from '../../components/nbas/AccountTokenNba';
import TrackingRoute from '../TrackingRoute';
import RemoveInstrumentModal from '../../components/RemoveInstrumentModal';
import SuccessPushdown from '../../components/SuccessPushdown';
import ErrorPushdown from '../../components/ErrorPushdown';
import PermsError from '../Errors/PermsError';
import UnhandledError from '../Errors/UnhandledError';

import {
  METHOD_NEW,
  METHOD_NEW_CARD,
  METHOD_NEW_BANK,
  METHOD_EDIT,
  NOW_METHOD_NEW_CARD,
  NOW_METHOD_EDIT,
  NOW_METHOD_NEW_CARD_CONFIRM,
  NOW_METHOD_EDIT_CONFIRM,
} from '../../helpers/routes';

import ListMethods from './ListMethods';
import AddMethod from './AddMethod';
import EditMethod from './EditMethod';
import ChooseMethod from './ChooseMethod';
import Confirmation from './Confirmation';

class ManageInstruments extends Component {
  constructor() {
    super();
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.onRemoveClick = this.onRemoveClick.bind(this);

    this.state = {
      modalIsOpen: false,
      removingInstrument: null,
    };
  }

  componentDidMount() {
    const {
      handleGetPaymentInstruments,
      handleGetAccount,
      handleGetBill,
      handleGetUser,
      handleGetNowInstruments,
      mainAccountRoles,
    } = this.props;

    // need instruments for edit/add/list
    handleGetPaymentInstruments();
    // need account for billing address for add/edit
    handleGetAccount();
    // needed for if autopay is enabled/bill due amount/date
    handleGetBill();
    // needed to determine if user has NOW services. Pass true to add param to endpoint
    handleGetUser(true);

    // users with NOW services will always have a PRIMARY role
    if (mainAccountRoles.indexOf('PRIMARY') > -1) {
      // needed to determine if an instrument is attached to a NOW service
      handleGetNowInstruments();
    }
  }

  onRemoveClick(instrument) {
    this.setState({ removingInstrument: instrument });
    this.openModal();
  }

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  render() {
    const {
      autopay,
      instruments,
      successMessage,
      errorMessage,
      loading,
      canMakePayment,
      loadError,
      nextBestActions,
    } = this.props;

    if (loadError) {
      return <UnhandledError dismissable={false} />;
    }

    if (!canMakePayment) {
      return (
        <PermsError
          error="!canMakePayment"
        />
      );
    }

    const {
      modalIsOpen,
      removingInstrument,
    } = this.state;

    const isConfirmation = window.location.pathname.includes('/confirmation');

    return (
      <>
        {successMessage.showPushdown && <SuccessPushdown {...successMessage} />}
        {errorMessage && <ErrorPushdown {...errorMessage} />}
        <PageSection className={joinClass('ui-grey', isConfirmation && 'page-section--banner')}>
          {nextBestActions && <NextBestActions nbaList={nextBestActions} />}

          {loading && <LoadingCard />}

          {!loading && (
            <Switch>
              <TrackingRoute exact path={METHOD_NEW} component={ChooseMethod} />
              <TrackingRoute
                exact
                path={[METHOD_NEW_CARD, NOW_METHOD_NEW_CARD]}
                component={AddMethod}
              />
              <TrackingRoute exact path={METHOD_NEW_BANK} component={AddMethod} />
              <TrackingRoute
                exact
                path={[METHOD_EDIT, NOW_METHOD_EDIT]}
                render={matchProps => (
                  <EditMethod {...matchProps} onRemoveClick={this.onRemoveClick} />
                )}
              />
              <TrackingRoute
                exact
                path={[
                  NOW_METHOD_EDIT_CONFIRM,
                  NOW_METHOD_NEW_CARD_CONFIRM,
                ]}
                component={Confirmation}
              />
              <TrackingRoute
                render={() => (
                  <ListMethods
                    autopay={autopay}
                    onRemoveClick={this.onRemoveClick}
                    instruments={instruments}
                  />
                )}
              />
            </Switch>
          )}
          {modalIsOpen && (
            <RemoveInstrumentModal
              closeModal={this.closeModal}
              instrument={removingInstrument}
            />
          )}
        </PageSection>
      </>
    );
  }
}

const mapStateToProps = ({
  auth: { macaroon: { mainAccountRoles } },
  autopay: {
    autopay,
    loading: autopayLoading,
  },
  instruments: {
    instruments,
    loading: instrumentsLoading,
    error: instrumentsError,
  },
  account: {
    loading: accountLoading,
    account: {
      prepaid: isPrepaid,
      status,
    } = {},
    error: accountError,
  },
  user: {
    user,
    loading: userLoading,
    error: userError,
  },
  userMessages: {
    success = {},
    error,
    link,
    linkText,
  },
}) => ({
  user,
  autopay,
  successMessage: success,
  errorMessage: { ...error, link, linkText },
  instruments: instruments.instruments,
  loading: accountLoading || instrumentsLoading || autopayLoading || userLoading,
  canMakePayment: !isPrepaid && !getIsDisconnected(status),
  loadError: accountError || instrumentsError || userError,
  nextBestActions: [
    {
      id: ACCOUNT_TOKEN,
      component: AccountTokenNba,
    },
  ],
  mainAccountRoles,
});

const mapDispatchToProps = dispatch => ({
  handleGetAutopay: bindActionCreators(getAutopay, dispatch),
  handleGetPaymentInstruments: bindActionCreators(getPaymentInstruments, dispatch),
  handleGetAccount: bindActionCreators(getAccount, dispatch),
  handleGetUser: bindActionCreators(getUser, dispatch),
  handleGetNowInstruments: bindActionCreators(getNowInstruments, dispatch),
  handleGetBill: getBill,
});

export default connect(mapStateToProps, mapDispatchToProps)(ManageInstruments);
