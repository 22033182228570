import React from 'react';

const IconCircleExclamationRed = ({ className = '', ...props }) => (
  <span className={`svg-icon ${className}`} {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36">
      <g fill="none" fillRule="evenodd">
        <path d="M18 0C8.059 0 0 8.059 0 18s8.059 18 18 18 18-8.059 18-18A18 18 0 0018 0" fill="#FB543F" />
        <path d="M19.408 26.877a1.408 1.408 0 11-2.816 0 1.408 1.408 0 012.816 0zm-.409-19.163c.2 0 .39.084.525.235.135.15.204.35.188.556l-1.004 13.958a.725.725 0 01-.708.68.724.724 0 01-.708-.68L16.288 8.505a.745.745 0 01.188-.556.701.701 0 01.525-.235h1.998z" fill="#FFF" />
      </g>
    </svg>
  </span>
);

export default IconCircleExclamationRed;
