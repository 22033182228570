import React, { Component, createRef } from 'react';
import { FormBoolean, FormControl } from 'adc-ui-components';
import { Field } from 'formik';

import vAll from '../../helpers/validation/all';
import { onlyDigits, hasNonDigit } from '../../helpers/validation/nonDigit';
import { MinFactory } from '../../helpers/validation/minMax';

import Card from '../Card';
import Modal from '../Modal';
import IconCircleI from '../svgs/CircleI';
import InputField from '../form-elements/InputField';
import BooleanField from '../form-elements/BooleanField';
import FirstLastName from './FirstLastName';
import { RequiredFactory } from '../../helpers/validation/required';
import getConfig from '../../config';

const vRequired = RequiredFactory();
const vMinLength = MinFactory();

const { cwaRoot } = getConfig();

class BankFields extends Component {
  constructor() {
    super();

    this.clearErrors = this.clearErrors.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.bankWarningRef = createRef(null);

    this.state = {
      showError: false,
      modalIsOpen: false,
    };
  }

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  clearErrors() {
    this.setState({ showError: false });
  }

  renderModal() {
    const { modalIsOpen } = this.state;

    return (
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={this.closeModal}
        contentLabel="Bank Account Info"
      >
        <div tracking-module="bank-account-routing">
          <p className="body2 mb24">You can find your 9-digit routing number and account number on the bottom left corner of a check.</p>

          <div className="mb24 text-center">
            <img src={`${cwaRoot}svg/bank_account_routing.svg`} width="260" height="142" alt="Bank Account Routing" />
          </div>

          <div className="action action--centered">
            <div className="action__item">
              <button type="button" className="button button--primary" onClick={this.closeModal}>Done</button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }

  render() {
    const { cardClass, setFieldValue, required = true } = this.props;
    const { showError, error, bankWarningRef } = this.state;

    const checkRoutingWarnings = (value) => {
      if (hasNonDigit(value)) {
        this.bankWarningRef.current && this.bankWarningRef.current.focus();
        this.setState({ showError: 'routeNumber', error: 'Please enter numeric characters only' });
        return onlyDigits(value);
      }

      if (value.length > 9) {
        this.bankWarningRef.current && this.bankWarningRef.current.focus();
        this.setState({ showError: 'routeNumber', error: 'Cannot exceed 9 digits' });
        return value.slice(0, 9);
      }

      this.clearErrors();
      return value;
    };

    const checkBankWarnings = (value) => {
      if (hasNonDigit(value)) {
        this.bankWarningRef.current && this.bankWarningRef.current.focus();
        this.setState({ showError: 'bankNumber', error: 'Please enter numeric characters only' });
        return onlyDigits(value);
      }

      if (value.length > 17) {
        this.bankWarningRef.current && this.bankWarningRef.current.focus();
        this.setState({ showError: 'bankNumber', error: 'Cannot exceed 17 digits' });
        return value.slice(0, 17);
      }

      this.clearErrors();
      return value;
    };

    return (
      <>
        {this.renderModal()}

        <div className="card-group__item">
          <Card className={cardClass}>
            <div className="form-control-group form-control-group--boolean-flex-at-768">
              <h3 className="label">Account type</h3>
              <FormBoolean label="Checking account" inputId="paymentAccountChecking" labelClass="body2">
                <Field
                  id="paymentAccountChecking"
                  name="account"
                  component={BooleanField}
                  type="radio"
                  value="Checking"
                  validate={required && vRequired('Please select an account type')}
                />
              </FormBoolean>
              <FormBoolean label="Savings account" inputId="paymentAccountSavings" labelClass="body2">
                <Field
                  id="paymentAccountSavings"
                  name="account"
                  component={BooleanField}
                  type="radio"
                  value="Savings"
                  validate={required && vRequired('Please select an account type')}
                />
              </FormBoolean>
            </div>
          </Card>
        </div>

        <div className="card-group__item">
          <Card className={cardClass}>

            <FirstLastName required={required} />

            <div className="form-control-group form-control-group--flex-at-768">
              <div className="form-control-group__item">
                <FormControl>
                  <div className="form-control__label">
                    <label htmlFor="routeNumber" className="d-inline-block">
                      Routing number
                    </label>
                    <button
                      className="button button--text"
                      type="button"
                      onClick={this.openModal}
                    >
                      <IconCircleI />
                      <span className="visuallyhidden">More information about routing number</span>
                    </button>
                  </div>
                  <Field
                    id="routeNumber"
                    name="routeNumber"
                    component={InputField}
                    type="text"
                    minLength="9"
                    pattern="^[0-9]+$"
                    className={showError === 'routeNumber' && 'error'}
                    aria-required
                    validate={vAll(
                      required && vRequired('Please enter a valid routing number'),
                      vMinLength(9, 'Please check your 9-digit routing number and try again'),
                    )}
                    onChange={e => setFieldValue('routeNumber', checkRoutingWarnings(e.target.value))}
                    onBlur={this.clearErrors}
                  />
                  {showError === 'routeNumber' && (
                    /* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */
                    <span className="form-control__error" tabIndex="0" role="alert" ref={bankWarningRef}>{error}</span>
                  )}
                </FormControl>
              </div>
              <div className="form-control-group__item">
                <FormControl>
                  <div className="form-control__label">
                    <label htmlFor="bankNumber" className="d-inline-block">
                      Bank account number
                    </label>
                    <button
                      className="button button--text"
                      type="button"
                      onClick={this.openModal}
                    >
                      <IconCircleI />
                      <span className="visuallyhidden">More information about bank account number</span>
                    </button>
                  </div>

                  <Field
                    id="bankNumber"
                    name="bankNumber"
                    component={InputField}
                    type="text"
                    pattern="^[0-9]+$"
                    className={showError === 'bankNumber' && 'error'}
                    validate={required && vRequired('Please enter a valid account number')}
                    onChange={e => setFieldValue('bankNumber', checkBankWarnings(e.target.value))}
                    onBlur={this.clearErrors}
                  />
                  {showError === 'bankNumber' && (
                    /* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */
                    <span className="form-control__error" tabIndex="0" role="alert" ref={bankWarningRef}>{error}</span>
                  )}
                </FormControl>
              </div>
            </div>

          </Card>
        </div>
      </>
    );
  }
}

export default BankFields;
