import { formatCurrency } from './formatText';
import getConfig from '../config';

const { makePaymentUrl, billServicesUrl } = getConfig();

export const getAutopaySuccessMessage = (isUpdate, lateFee, summary = {}, lateFeeCreditStatus) => {
  let heading = 'Automatic payments have been set up successfully.';
  let body = 'It may take up to 15 minutes for automatic payments to be reflected on your account.';
  let link = billServicesUrl;
  let linkText = 'Back to billing';

  if (isUpdate) {
    heading = 'The payment method was updated successfully.';
  }

  if (summary.pastDueBalance > 0) {
    body = `Changes will be reflected in 15 minutes. You have a remaining past due balance of ${formatCurrency(summary.pastDueBalance)} due now. Please make an additional payment to avoid late fees`;
    link = makePaymentUrl;
    linkText = 'Make a Payment';
  }

  if (lateFee) {
    body = `You'll recieve a one-time late fee credit of ${formatCurrency(lateFee)} on your next bill after your first automatic payment is processed.`;
  }

  if (lateFee && lateFeeCreditStatus && summary.pastDueBalance > 0) {
    body = `This change will be reflected on your account within 15 minutes. Please pay your unpaid balance of ${formatCurrency(summary.pastDueBalance)} and you will receive your one-time late fee credit of ${formatCurrency(lateFee)} after your payment has processed.`;
  }

  return {
    heading,
    body,
    link,
    linkText,
    showPushdown: true,
  };
};

export default getAutopaySuccessMessage;
