import React from 'react';

const icon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 32">
    <path d="M3 0a3 3 0 0 0-3 3v25a4 4 0 0 0 4 4h42a4 4 0 0 0 4-4V4a4 4 0 0 0-4-4z" fill="#26a6d1" />
    <path d="M30 14v1.4h4V17h-4v1.4h4.5l2.2-2.1-2-2.1H30zm-20 2.7h2.5l-1.3-3zm25.6-4.1l2.3 2.3 2.3-2.3h2.3L39 16.2l3.5 3.6h-2.4L38 17.4l-2.4 2.4H28v-7.2zm-25.8 0h2.8l3 6.8v-6.8h3l2.6 4.9 2.2-5h3v7.3h-1.8v-5.7L22 19.8h-1.6l-2.7-5.7v5.7h-3.8l-.7-1.6H9.3l-.8 1.6h-2zM4 21.2h5.7l.7-1.6H12l.7 1.6h6.2V20l.6 1.2h3.2l.6-1.2v1.2h13l1.5-1.5 1.5 1.5H46l-4.8-5 4.8-5h-6.6L38 12.6 36.5 11H22.3l-1.2 2.6-1.2-2.6h-5.7v1.2l-.6-1.2H8.7z" fill="#fff" />
  </svg>
);

export default icon;
