import React from 'react';
import { FormBoolean } from 'adc-ui-components';
import { Field } from 'formik';

import BooleanField from '../form-elements/BooleanField';
import PaymentInstrument from '../PaymentInstrument';

const ApplePayMethod = (props) => {
  const { autopay } = props;
  return (
    <div className="card-group__item">
      <div className="card card--action-right-at-768 card--form-control">
        <div className="card__content">
          <FormBoolean
            label={<PaymentInstrument autopay={autopay} instrument={{ type: 'ApplePay' }} />}
            labelClass="body2"
            inputId="applePay"
          >
            <Field
              id="applePay"
              name="paymentMethodOption"
              type="radio"
              component={BooleanField}
              value="Apple Pay"
              aria-required
              {...props}
            />
          </FormBoolean>
        </div>
      </div>
    </div>
  );
};

export default ApplePayMethod;
