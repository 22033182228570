import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { Formik, Form } from 'formik';
import dateformat from 'dateformat';
import { FormControl } from 'adc-ui-components';

import { setSubmit } from '../../actions/userMessages';

import { sendChannelTracking } from '../../actions/channelTracking';
import PageTitle from '../../components/PageTitle';
import ErrorMessage from '../../components/ErrorMessage';
import CardGroup from '../../components/CardGroup';
import Card from '../../components/Card';
import CancelModal from './CancelModal';
import PaymentAmountField from '../../components/form-fields/PaymentAmountField';
import { PaymentDateField } from '../../components/form-fields/PaymentDate';
import { vDollarAmount, cleanDollarAmount } from '../../helpers/validation/amount';
import { scrollToInvalid } from '../../helpers/errors';

import {
  HOME,
  SCHEDULED_PAYMENT,
  SCHEDULED_PAYMENT_REVIEW,
  resolveRoute,
} from '../../helpers/routes';

class EditScheduledPaymentForm extends Component {
  constructor(props) {
    super(props);
    this.form = React.createRef();

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);

    this.state = {
      payment: {},
    };
  }

  componentDidMount() {
    const {
      scheduledFormErrors,
      handleSetError,
      payment = {},
      history,
      handleSendChannelTracking,
    } = this.props;

    if (!payment.canModify) {
      history.push(SCHEDULED_PAYMENT);
    }

    if (payment.promisedToPay) {
      handleSendChannelTracking({ id: 'P2P_MODIFY_IN_PROGRESS', interactionType: 'BILLING_PAYMENTS' });
    }

    // set the payment on mount so we don't
    // get weird stuff when we delete and redirect
    // to confirmation
    this.setState({ payment });

    if (!scheduledFormErrors) {
      return null;
    }

    if (scheduledFormErrors.isMapped) {
      // runs "on load" and validates the form immediately with the initialValues
      return this.form.current.validateForm();
    }

    if (scheduledFormErrors.message) {
      // persist the unmapped error in the state
      // then clear them on resubmit
      return handleSetError({ text: scheduledFormErrors.message });
    }

    return handleSetError({ showModal: true });
  }

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  render() {
    const {
      handleSetSubmit,
      handleReviewSubmit,
      history,
      initialValues,
      errorMessage,
      bill,
      email,
      scheduledFormErrors,
      handleResetErrors,
    } = this.props;

    const {
      modalIsOpen,
      payment,
    } = this.state;

    if (!payment) {
      return <Redirect to={HOME} />;
    }

    const onFormSubmit = ({ amount, date }) => {
      handleSetSubmit();

      const review = resolveRoute(SCHEDULED_PAYMENT_REVIEW, { id: payment.id });

      const payload = {
        date,
        email,
        payment_id: payment.id,
        instrument_token: payment.token,
        schedule_date: dateformat(date, 'yyyy-mm-dd', true),
        amount: cleanDollarAmount(amount),
      };

      handleReviewSubmit(payload);
      return history.push(review);
    };

    const action = payment.canModify && payment.canCancel && (
      <div className="link-group__item">
        <button type="button" className="button button--text" onClick={this.openModal}>
          Cancel Payment
        </button>
      </div>
    );

    const validate = () => {
      // errors from api on review
      if (scheduledFormErrors) {
        const submitError = {};

        if (scheduledFormErrors.isMapped) {
          if (scheduledFormErrors.fields) {
            scheduledFormErrors.fields.forEach((name) => {
              // Allows for the error map to contain grouped errors with only
              // one field reporting the error for the group (e.g., ['expirationMonth',
              // '+expirationYear', '+cvv'])
              if (name[0] === '+') {
                submitError[name.substring(1)] = ' ';
              } else {
                submitError[name] = scheduledFormErrors.message;
              }
            });
          }
        }

        if (Object.keys(submitError).length) {
          scrollToInvalid(submitError);
          // once the errors are set on the fields
          // reset the server error so we can try
          // to submit again
          handleResetErrors();
          return submitError;
        }
      }

      return {};
    };

    return (
      <Formik
        onSubmit={onFormSubmit}
        initialValues={initialValues}
        validate={validate}
        ref={this.form}
      >
        {({ values, setFieldValue, setFieldError }) => (
          <Form
            name="EditScheduledPayment"
            noValidate
          >
            <PageTitle>Manage scheduled payment</PageTitle>

            {modalIsOpen && (
              <CancelModal
                payment={payment}
                closeModal={this.closeModal}
                history={history}
              />
            )}

            {errorMessage && <ErrorMessage errorMessage={errorMessage} />}

            <CardGroup>
              <Card action={action} className="card--action-right-at-768">
                <FormControl
                  className="form-control--inline-at-768"
                  inputId="amount"
                  label="Amount"
                >
                  <PaymentAmountField
                    validate={vDollarAmount}
                    className="customAmount"
                    model="amount"
                    setFieldValue={setFieldValue}
                    setFieldError={setFieldError}
                  />
                </FormControl>
              </Card>

              <Card>
                <PaymentDateField
                  className="form-control--inline-at-768"
                  bill={bill}
                  label="Scheduled Date"
                  values={values}
                  setFieldValue={setFieldValue}
                />
              </Card>
            </CardGroup>

            <div className="action action--right pt6">
              <div className="action__item">
                <button className="button button--primary" type="submit">Continue</button>
              </div>
              <div className="action__item">
                <Link className="button" to={HOME}>Cancel</Link>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const pmtId = ownProps.match.params.id;
  const {
    scheduledPayments: { payments },
    auth: {
      macaroon: {
        preferredEmail: email,
      } = {},
    },
  } = state;

  const payment = payments.find(item => item.id === pmtId) || {};
  // Prefer global parseFloat. IE doesn't support Number.parseFloat
  const amount = parseFloat(payment.amount);

  const initialValues = {
    ...payment,
    amount: amount && amount.toFixed(2),
    ...ownProps.scheduledFormData,
  };

  return {
    payment,
    email,
    initialValues: {
      date: dateformat(initialValues.date, 'm/d/yyyy', true),
      amount: initialValues.amount.toString(),
    },
  };
};

const mapDispatchToProps = dispatch => ({
  handleSetSubmit: bindActionCreators(setSubmit, dispatch),
  handleSendChannelTracking: bindActionCreators(sendChannelTracking, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditScheduledPaymentForm);
