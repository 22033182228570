import React from 'react';
import { FormBoolean } from 'adc-ui-components';
import { Field } from 'formik';

import Card from '../Card';
import BooleanField from '../form-elements/BooleanField';

import {
  TERMS_AUTOPAY,
  TERMS_STORED_PAYMENT,
} from '../../helpers/routes';

const AutpayCheckbox = ({
  showContract,
  showStored,
  crsId,
  labelCopy = 'Turn on automatic payments using the provided payment method',
}) => {
  const autopayTermsLink = `${TERMS_AUTOPAY}${crsId ? `?crsId=${crsId}` : ''}`;
  const storedPaymentsTermsLink = `${TERMS_STORED_PAYMENT}${crsId ? `?crsId=${crsId}` : ''}`;

  return (
    <div className="card-group">
      <div className="card-group__item">
        <Card>
          <FormBoolean>
            <Field
              id="autopay"
              name="autopay"
              type="checkbox"
              component={BooleanField}
            />
            <label className="form-control__label" htmlFor="autopay">
              <span className="autopay-label">
                <span className="payment-instrument-helper-text">
                  {labelCopy}
                </span>
              </span>
            </label>
          </FormBoolean>
        </Card>
      </div>
      {showContract && showStored && (
        <div className="card-group__item">
          <Card>
            <p>
              {`Your full balance will be automatically charged on your bill due date each month, beginning with
              your next bill. Cancel anytime. Please allow one business day for us to process
              your payment. `}
              <a href={autopayTermsLink} target="_blank" rel="noopener noreferrer">Automatic Payments</a>
              {' and '}
              <a href={storedPaymentsTermsLink} target="_blank" rel="noopener noreferrer">Stored Payment Methods</a>
              {' '}
              Terms &amp; Conditions apply.
            </p>
          </Card>
        </div>
      )}
      {showContract && !showStored && (
        <div className="card-group__item">
          <Card>
            <p>
              {`Your full balance will be automatically charged on your bill due date each month, beginning with
              your next bill. Cancel anytime. Please allow one business day for us to process
              your payment. `}
              <a href={autopayTermsLink} target="_blank" rel="noopener noreferrer">Automatic Payments Terms & Conditions</a>
              {' apply.'}
            </p>
          </Card>
        </div>
      )}
    </div>
  );
};


export default AutpayCheckbox;
