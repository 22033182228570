import React, { Component } from 'react';
import Cookies from 'js-cookie';

import { joinClass } from '../../helpers/component';

export const CPNI = 'CPNI';
export const PAST_DUE = 'PAST_DUE';

const getDismissed = () => (Cookies.get('dismissed') || '').split(',');

class Nbas extends Component {
  constructor(props) {
    super(props);

    this.setLoaded = this.setLoaded.bind(this);
    this.onDismissNba = this.onDismissNba.bind(this);

    const dismissed = getDismissed();

    this.state = {
      nbaList: props.nbaList.reduce((acc, nba = {}) => {
        if (dismissed.indexOf(nba.id) === -1) {
          acc.push({
            ...nba,
            status: 'loading',
          });
        }

        return acc;
      }, []),
    };
  }

  onDismissNba(nba) {
    const { nbaList } = this.state;

    const dismissed = getDismissed();

    Cookies.set('dismissed', [...dismissed, nba.id].join(','));

    this.setState({
      nbaList: nbaList.filter(nbaListItem => nbaListItem.id !== nba.id),
    });
  }

  setLoaded(nba, value) {
    this.setState(state => ({
      ...state,
      nbaList: state.nbaList.map((nbaListItem) => {
        let { status } = nbaListItem;

        if (nbaListItem.id === nba) {
          status = value;
        }

        return {
          ...nbaListItem,
          status,
        };
      }),
    }));
  }

  render() {
    const {
      className,
    } = this.props;

    const {
      nbaList,
    } = this.state;

    // needs to be smarter and only wait for the limit set
    const loading = nbaList.some(nba => nba.status === 'loading');
    const hasViableNba = loading || nbaList.some(nba => nba.status === 'READY');

    return (
      <>
        {
          // loading card looks weird, ask UX?
          // {loading && <LoadingCard className="mb24" />}
          // only hide the NBAs until they're done loading because we want
          // the components to mount
        }
        {hasViableNba && (
          <div className={joinClass(
            'card-group mb24 next-best-actions',
            loading && 'hidden',
            className,
          )}
          >
            {nbaList.map(({ id, component: NbaComponent, options }) => (
              <NbaComponent
                key={id}
                options={options}
                setLoaded={this.setLoaded}
                dismissNba={this.onDismissNba}
              />
            ))}
          </div>
        )}
      </>
    );
  }
}

export default Nbas;
