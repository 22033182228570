import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import dateFormat from 'dateformat';
import { Link } from 'react-router-dom';
import {
  IconCheckGreen,
  LoadingCard,
  PageSection,
} from 'adc-ui-components';

import { sendChannelTracking } from '../../actions/channelTracking';
import { XFINITY_ASSISTANCE_PLAN_COMPLETED } from '../../helpers/channelTracking';
import { COLLECTIONS, AUTOPAY_EDIT } from '../../helpers/routes';
import { confirmPayment } from '../../actions/payment';
import PageTitle from '../../components/PageTitle';
import Card from '../../components/Card';
import PaymentPlanSummary from '../../components/PaymentPlanSummary';
import AutopaySummary from '../../components/AutopaySummary';

import paymentMock from '../../helpers/paymentMock';
import withCmsContent from '../../helpers/withCmsContent';

import getConfig from '../../config';
import KeyValueGroup from '../../components/KeyValueGroup';
import KeyValue from '../../components/KeyValue';
import { formatCurrency } from '../../helpers/formatText';

import PaymentInstrument from '../../components/PaymentInstrument';
import Button from '../../components/Button';

const { homeDomain } = getConfig();

class PaymentPlanConfirm extends Component {
  constructor() {
    super();

    this.state = {};
  }

  componentDidMount() {
    const {
      history,
      confirm,
      handleConfirmPayment,
      handleSendChannelTracking,
      bill,
    } = this.props;

    if (!confirm) {
      const mock = paymentMock(true);
      if (mock) {
        const { bill: mockBill, form } = mock;
        Object.assign(bill, { bill: mockBill });
        this.setState({ confirm: form });
        return;
      }
      history.push(COLLECTIONS);
      return;
    }
    this.setState({ confirm });

    handleSendChannelTracking({ id: XFINITY_ASSISTANCE_PLAN_COMPLETED, interactionType: 'BILLING_PAYMENTS' });

    handleConfirmPayment();
  }

  render() {
    const {
      bill,
      autopay,
      autopaySignupSuccess,
      paymentPlanConfirm,
      content,
      pauseConsentId,
      servicePause,
      cartError,
    } = this.props;
    const { confirm } = this.state;

    if (!confirm) {
      return null;
    }

    const autopaySuccessful = confirm.autopay && autopaySignupSuccess;
    const autopayUnsuccessful = confirm.autopay && !autopaySignupSuccess;

    const paymentSectionLoading = bill.loading || (confirm.autopay && autopay.loading);

    const billsCopy = (() => {
      // happy path: no plan error and plan confirmation
      if (paymentPlanConfirm && paymentPlanConfirm.installmentPlan) {
        if (paymentPlanConfirm.installmentPlan.numberOfPayments > 1) {
          return content('heading.subtitle.plural', {
            payments: paymentPlanConfirm.installmentPlan.numberOfPayments,
          });
        }
        return content('heading.subtitle.single');
      }
      // full payment via LTIP flow: no plan error and no plan confirmation
      return content('heading.subtitle.noPlan');
    })();

    return (
      <PageSection className="page-section--banner">
        <div className="hgroup mb24">
          <IconCheckGreen className="mb6 svg-icon--42" />
          <PageTitle>{content('heading.title')}</PageTitle>
          {billsCopy && <p>{billsCopy}</p>}
          <p>{content('temporaryServices')}</p>
          {confirm.plan.isLtip && (
            <p>{content('ltipInfo', confirm.plan)}</p>
          )}
        </div>

        <div className="action action--center no-print">
          <div className="action__item">
            <a href={`https://${homeDomain}/billing/services`} className="button button--primary">
              {content('form.done')}
            </a>
          </div>
        </div>

        {pauseConsentId && !cartError && (
          <>
            <div className="payment-section">
              <div className="hgroup hgroup--inline">
                <h2>{content('services.title')}</h2>
              </div>
            </div>


            <div className="payment-section">
              <div className="card-group">
                <div className="card-group__item">
                  <Card
                    className="card--flex-start"
                    actionRight
                  >
                    <KeyValueGroup className="key-value-group--mobile-table">
                      <KeyValue
                        className="key-value--inline-at-768"
                        label={content('services.fields.services.label')}
                        value={content(`services.plans.${servicePause.lineOfService}`)}
                      />
                      <KeyValue
                        className="key-value--inline-at-768"
                        label={content('services.fields.amount.label')}
                        value={`${formatCurrency(servicePause.monthlyCharges)}/mo.`}
                      />
                      <KeyValue
                        className="key-value--inline-at-768"
                        label={content('services.fields.startDate.label')}
                        value={content('services.fields.startDate.value')}
                      />
                    </KeyValueGroup>
                  </Card>
                </div>
                <div className="card-group__item">
                  <Card className="text-left">
                    <p>{content('services.footer')}</p>
                  </Card>
                </div>
              </div>
            </div>
          </>
        )}
        {pauseConsentId && cartError && (
          <>
            <PageTitle>{content('services.title')}</PageTitle>
            <div className="payment-section">
              <div className="card-group">
                <div className="card-group__item">
                  <Card
                    className="card--flex-start"
                    severity="warning"
                  >
                    <p>{content('services.error')}</p>
                  </Card>
                </div>
              </div>
            </div>
          </>
        )}


        <div className="payment-section">
          <div className="hgroup hgroup--inline">
            <h2>{content('payment.title')}</h2>
            <a href={`https://${homeDomain}/#/billing#upcoming-payments`}>
              {content('payment.viewHistory')}
            </a>
          </div>
        </div>

        <div className="payment-section">
          <div className="card-group">
            <div className="card-group__item">
              <Card
                className="card--flex-start"
                actionRight
                action={(
                  <Button text onActivate={() => window.print()}>
                    {content('payment.print')}
                  </Button>
                )}
              >
                <KeyValueGroup className="key-value-group--mobile-table">
                  <KeyValue
                    className="key-value--inline-at-768"
                    label={content('payment.fields.amount')}
                    value={formatCurrency(confirm.amount)}
                  />
                  <KeyValue
                    className="key-value--inline-at-768"
                    label={content('payment.fields.date')}
                    value={dateFormat(confirm.date, 'dddd, mmmm d, yyyy')}
                  />
                  <KeyValue
                    className="key-value--inline-at-768"
                    label={content('payment.fields.method')}
                    value={<PaymentInstrument instrument={confirm.instrument} hideBadges />}
                  />
                  <KeyValue
                    className="key-value--inline-at-768"
                    label={content('payment.fields.confNo')}
                    value={confirm.confirmationNumber}
                  />
                  {confirm.authorizationNumber && (
                    <KeyValue
                      className="key-value--inline-at-768"
                      label={content('payment.fields.authNo')}
                      value={confirm.authorizationNumber}
                    />
                  )}
                  <KeyValue
                    className="key-value--inline-at-768"
                    label={content('payment.fields.email')}
                    value={confirm.email}
                  />
                </KeyValueGroup>
              </Card>
            </div>
            <div className="card-group__item">
              <Card className="text-left">
                <p>{content('payment.footer')}</p>
              </Card>
            </div>
          </div>
          {!confirm.isToday && confirm.plan.isP2P && (
            <Card className="text-left" severity="warning">{content('payment.p2pWarning')}</Card>
          )}
        </div>

        {confirm.plan.isLtip && (
          <>
            <div className="payment-section">
              <div className="hgroup hgroup--inline">
                <h2>{content('ltip.title')}</h2>
              </div>
            </div>

            <div className="payment-section">
              {confirm.enrolled && (
                <div className="card-group">
                  <div className="card-group__item">
                    <Card
                      className="card--flex-start"
                      actionRight
                    >
                      <KeyValueGroup className="key-value-group--mobile-table">
                        <KeyValue
                          className="key-value--inline-at-768"
                          label={content('ltip.fields.amount.label')}
                          value={(
                            <>
                              {formatCurrency(confirm.plan.installment)}
                              {' ('}
                              {confirm.plan.noOfPayments}
                              {' '}
                              {confirm.plan.freqUnit}
                              {')'}
                            </>
                          )}
                        />
                        <KeyValue
                          className="key-value--inline-at-768"
                          label={content('ltip.fields.startDate.label')}
                          value={content('ltip.fields.startDate.value')}
                        />
                      </KeyValueGroup>
                    </Card>
                  </div>
                  <div className="card-group__item">
                    <Card className="text-left">
                      <p>{content('ltip.footer')}</p>
                    </Card>
                  </div>
                </div>
              )}
              {!confirm.enrolled && (
                <Card severity="danger" className="text-left">
                  {content('ltip.enrollFailed')}
                </Card>
              )}
            </div>
          </>
        )}

        <div className="text-left">
          {confirm.autopay && (
            <div className="payment-section">
              {paymentSectionLoading && <LoadingCard />}
              {!paymentSectionLoading && (
                <>
                  {autopaySuccessful && (
                    <>
                      <h2>Automatic Payments are On</h2>
                      <AutopaySummary
                        instrument={confirm.instrument}
                        showEdit
                      />
                    </>
                  )}
                  {autopayUnsuccessful && (
                    <Card severity="danger">
                      <p className>
                        {'Unfortunately, we couldn\'t set up automatic payments. Please try again.'}
                        <br />
                        <Link to={AUTOPAY_EDIT}>Set Up Automatic Payments</Link>
                      </p>
                    </Card>
                  )}
                </>
              )}
            </div>
          )}


          { /* don't show section if they are making a full payment */}
          {paymentPlanConfirm && paymentPlanConfirm.installmentPlan && (
            <div className="payment-section">
              <PaymentPlanSummary
                summary={paymentPlanConfirm}
              />
            </div>
          )}
        </div>
      </PageSection>
    );
  }
}

const mapStateToProps = ({
  auth: { pauseConsentId },
  bill,
  autopay,
  autopay: { autopaySignupSuccess },
  account: { account },
  collections: {
    confirm,
    collections: {
      consentCart: {
        consentGroups: [{
          consentItems: [{
            chargeDetails: [{
              monthlyCharges,
            } = {}] = [],
            lineOfService,
          } = {}] = [],
        } = {}] = [],
      } = {},
    } = {},
    error: cartError,
  } = {},
}) => ({
  confirm,
  bill,
  autopay,
  autopaySignupSuccess,
  account,
  pauseConsentId,
  servicePause: {
    monthlyCharges,
    lineOfService,
  },
  cartError,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  handleConfirmPayment: confirmPayment,
  handleSendChannelTracking: sendChannelTracking,
}, dispatch);

export default withCmsContent('content', {
  'ltip-payment-plan': 'Confirmation',
  'collections-2': 'Confirmation',
})(connect(mapStateToProps, mapDispatchToProps)(PaymentPlanConfirm));
