import React, { useState, useEffect } from 'react';

import Modal from './Modal';
import CtaLink from './CtaLink';

import { xapDismissed, setXapDismissed } from '../helpers/xap';
import useImpression from '../hooks/useImpression';

const XapInterstitial = ({
  eligible,
  accountLoading,
  cmsPackage,
  handleGetCopy,
  loading,
  content: {
    heading,
    description,
    primaryCta,
    secondaryCta,
    placementName,
    componentName,
    dismissedCookieName,
  } = {},
}) => {
  const dismissedCookie = dismissedCookieName ? xapDismissed(dismissedCookieName) : undefined;
  const [dismissed, setDismissed] = useState(dismissedCookie);
  const contentReady = !accountLoading && (!eligible || dismissed === false);
  const isOpen = contentReady && !loading && eligible && dismissed === false;

  useImpression({
    placementName,
    componentName,
    when: contentReady,
    if: isOpen,
    id: 'xap-interstitial',
  });

  useEffect(() => {
    // Check for the dismissed cookie once we have the dismissedCookieName value from CMS
    dismissedCookieName && setDismissed(xapDismissed(dismissedCookieName));
  }, [dismissedCookieName]);

  useEffect(() => {
    eligible && cmsPackage && handleGetCopy(cmsPackage).catch(() => null);
  }, [eligible, cmsPackage, handleGetCopy]);

  const closeModal = () => {
    setXapDismissed(dismissedCookieName);
    setDismissed(true);
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
    >
      <div tracking-module="xap-interstitial" className="text-center">
        <h2>{heading}</h2>
        <p>{description}</p>
        <div className="action">
          {primaryCta && (
            <CtaLink
              onClick={closeModal}
              linkClass="button button--primary"
              {...primaryCta}
            />
          )}
          {secondaryCta && (
            <CtaLink
              onClick={closeModal}
              linkClass="button button--text"
              {...secondaryCta}
            />
          )}
        </div>
      </div>
    </Modal>
  );
};

export default XapInterstitial;
