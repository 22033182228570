import ClientFactory, { ErrorHandler } from 'maw-fetch';
import { kibanaServiceLog } from '../helpers/logger';

const onError = ErrorHandler();

const client = ClientFactory({
  logger: { log: () => {} },
  kibanaServiceLog,
  onError,
});

export default client;
