import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { PageSection, LoadingCard } from 'adc-ui-components';
import { Switch, Redirect } from 'react-router-dom';

import { sendPageloadEvent } from '../../actions/adobe';
import {
  fetchScheduledPayments,
  updateScheduledPayment,
  resetErrors,
} from '../../actions/scheduledPayments';
import { getIsDisconnected } from '../../helpers/account';
import { getAccount } from '../../actions/account';
import { getBill } from '../../actions/bill';

import { setSubmit, setError, setSuccess } from '../../actions/userMessages';

import TrackingRoute from '../TrackingRoute';
import LiteDropDown from '../../components/LiteDropDown';
import PermsError from '../Errors/PermsError';
import UnhandledError from '../Errors/UnhandledError';

import {
  SCHEDULED_PAYMENT_EDIT,
  SCHEDULED_PAYMENT_REVIEW,
  SCHEDULED_PAYMENT_CONFIRM,
  SCHEDULED_PAYMENT_CANCEL_CONFIRM,
  HOME,
} from '../../helpers/routes';

import { joinClass } from '../../helpers/component';
import EditPaymentForm from './EditPaymentForm';
import Review from './Review';
import Confirmation from './Confirmation';
import CancelConfirmation from './CancelConfirmation';
import ListScheduled from './ListScheduled';

class Scheduled extends Component {
  constructor() {
    super();

    this.handleReviewSubmit = this.handleReviewSubmit.bind(this);

    this.state = {
      scheduledFormData: {},
    };
  }

  componentDidMount() {
    const {
      handleFetchScheduledPayments,
      handleGetBill,
      handleGetAccount,
    } = this.props;
    this.sendPageLoad();
    // need scheduled payments for edit/list
    handleFetchScheduledPayments();
    // need bill for due date on date picker and review messaging
    handleGetBill();
    // need account for eligiblity
    handleGetAccount();
  }

  componentDidUpdate(prevProps) {
    this.sendPageLoad(prevProps);
  }

  sendPageLoad(prevProps = {}) {
    const {
      location: { pathname: nextPage },
      handleSendPageloadEvent,
      loading,
    } = this.props;
    if (loading) return;
    const {
      location: { pathname: prevPage } = {},
      loading: wasLoading,
    } = prevProps;
    if (prevPage !== nextPage || wasLoading) {
      // mock the computed match since we're in the parent
      const computedMatch = {
        isExact: true,
        path: nextPage,
      };

      handleSendPageloadEvent(computedMatch, {});
    }
  }

  handleReviewSubmit(values) {
    this.setState({
      scheduledFormData: values,
    });
  }

  render() {
    const {
      isHarness,
      isLite,
      macaroon,
      location,
      canMakePayment,
      handleSetSubmit,
      errorMessage,
      account,
      bill,
      loading,
      handleUpdateScheduledPayment,
      handleSetSuccess,
      submitting,
      submitError,
      handleSetError,
      handleResetErrors,
      loadError,
    } = this.props;

    const {
      scheduledFormData,
    } = this.state;

    if (loadError) {
      return <UnhandledError dismissable={false} />;
    }

    if (!canMakePayment) {
      return (
        <PermsError
          isLite={isLite}
          isHarness={isHarness}
          error="!canMakePayment"
        />
      );
    }

    const isConfirmation = location.pathname === SCHEDULED_PAYMENT_CONFIRM
      || location.pathname === SCHEDULED_PAYMENT_CANCEL_CONFIRM;

    return (
      <>
        {isLite && (
          <LiteDropDown
            account={account}
            bill={bill}
            macaroon={macaroon}
          />
        )}
        <PageSection className={joinClass('ui-grey', isConfirmation && 'page-section--banner')}>
          {loading && <LoadingCard />}

          {!loading && (
            <Switch>
              <TrackingRoute exact path={SCHEDULED_PAYMENT_CONFIRM} component={Confirmation} />
              <TrackingRoute
                exact
                path={SCHEDULED_PAYMENT_CANCEL_CONFIRM}
                component={CancelConfirmation}
              />
              <TrackingRoute
                exact
                path={SCHEDULED_PAYMENT_EDIT}
                render={matchProps => (
                  <EditPaymentForm
                    handleSetSubmit={handleSetSubmit}
                    scheduledFormData={scheduledFormData}
                    scheduledFormErrors={submitError}
                    handleReviewSubmit={this.handleReviewSubmit}
                    errorMessage={errorMessage}
                    bill={bill}
                    loading={loading}
                    handleSetError={handleSetError}
                    handleResetErrors={handleResetErrors}
                    handleSetSuccess={handleSetSuccess}
                    {...matchProps}
                  />
                )}
              />
              <TrackingRoute
                exact
                path={SCHEDULED_PAYMENT_REVIEW}
                render={(matchProps) => {
                  if (!Object.keys(scheduledFormData).length) {
                    return <Redirect to={HOME} />;
                  }

                  return (
                    <Review
                      handleSetSubmit={handleSetSubmit}
                      handleSetSuccess={handleSetSuccess}
                      review={scheduledFormData}
                      handleUpdateScheduledPayment={handleUpdateScheduledPayment}
                      bill={bill}
                      submitting={submitting}
                      isHarness={isHarness}
                      {...matchProps}
                    />
                  );
                }}
              />
              <TrackingRoute component={ListScheduled} />
            </Switch>
          )}
        </PageSection>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    bill: { bill, loading: billLoading },
    cms: {
      packages: {
        // xapModal will only be in the state if we called for the content, assume it's not there
        xapModal: {
          loading: xapModalLoading = false,
        } = {},
      } = {},
    },
    scheduledPayments: {
      loading: paymentsLoading,
      submitting,
      submitError,
      error: scheduledPaymentsError,
    },
    harness: { isHarness },
    account: {
      loading: accountLoading,
      account,
      account: {
        prepaid: isPrepaid,
        status,
      } = {},
      error: accountError,
    },
    auth: {
      macaroon,
      macaroon: {
        lite: isLite,
      } = {},
    },
    userMessages: {
      error: errorMessage,
      showModal,
    },
  } = state;

  const isDisconnected = getIsDisconnected(status);

  return {
    isLite,
    macaroon,
    isHarness,
    account,
    bill,
    submitError,
    canMakePayment: !isPrepaid && !isDisconnected,
    submitting,
    loading: accountLoading
      || paymentsLoading
      || billLoading
      || xapModalLoading,
    errorMessage: !showModal && errorMessage,
    loadError: scheduledPaymentsError || accountError,
  };
};

const mapDispatchToProps = dispatch => ({
  handleSendPageloadEvent: bindActionCreators(sendPageloadEvent, dispatch),
  handleFetchScheduledPayments: bindActionCreators(fetchScheduledPayments, dispatch),
  handleGetAccount: bindActionCreators(getAccount, dispatch),
  handleGetBill: bindActionCreators(getBill, dispatch),
  handleUpdateScheduledPayment: bindActionCreators(updateScheduledPayment, dispatch),
  handleResetErrors: bindActionCreators(resetErrors, dispatch),
  handleSetSubmit: bindActionCreators(setSubmit, dispatch),
  handleSetSuccess: bindActionCreators(setSuccess, dispatch),
  handleSetError: bindActionCreators(setError, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Scheduled);
