import common from './common';

const homeDomain = 'customer.local.xfinity.com:5000';

const CONFIG = {
  ...common,
  ...process.env,
  homeDomain,
  billServicesUrl: `https://${homeDomain}/billing/services`,
  billingUrl: `https://${homeDomain}/#/billing`,
  makePaymentUrl: `https://${homeDomain}/#/new`,
  cwaRoot: 'https://staging-static.cimcontent.net/common-web-assets/',
  paymentSetup: {
    ...common.paymentSetup,
    // Where to go if no continueUrl specified (from consent flow)
    defaultContinue: `https://${homeDomain}/`,
  },
  shelbyCMSUrl: 'https://sdx.qa.xfinity.com/cms/data/',
  collectionsBack: 'https://www.stg.xfinity.com/buy/plan/xap',
  oauthUrl: 'https://oauth-st.xfinity.com',
};

export default CONFIG;
