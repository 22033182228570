import React from 'react';

import Card from './Card';
import Button from './Button';
import KeyValueGroup from './KeyValueGroup';
import KeyValue from './KeyValue';
import { formatPaymentSummaryData } from '../helpers/payments';
import { joinClass } from '../helpers/component';
import { getIsToday } from '../helpers/date';

const PaymentSummary = ({
  summary,
  showPrint,
  className = '',
}) => {
  const scheduled = !getIsToday(summary.date);
  return (
    <div className={joinClass('card-group', className)}>
      <div className="card-group__item">
        <Card
          className="card--flex-start"
          actionRight
          action={showPrint && (
            <Button text onActivate={() => window.print()}>Print / Save</Button>
          )}
        >
          <KeyValueGroup>
            {formatPaymentSummaryData(summary).map(item => (
              <KeyValue className="key-value--inline-at-768" key={item.label} {...item} />
            ))}
          </KeyValueGroup>
        </Card>
      </div>
      <div className="card-group__item">
        <Card>
          {scheduled && (
            <p>Please allow 1 business day for us to process your payment.</p>
          )}
          {!scheduled && (
            <p>
              Please allow 1 business day for us to process your payment.
              <a
                href="https://www.xfinity.com/Corporate/Customers/Policies/onetimepayment"
                rel="noreferrer"
                target="_blank"
              >
                &nbsp;Payment Terms & Conditions
              </a>
            </p>
          )}
        </Card>
      </div>
    </div>
  );
};

export default PaymentSummary;
