import React from 'react';
import getConfig from '../config';

const { cwaRoot } = getConfig();

/* eslint-disable max-len */
const PeacockPromo = ({ peacockPromo, type }) => (
  <div className="card card--peacock-promo card--flex-at-768 mb30">
    <div className="card__image">
      <img className="desktop" src={`${cwaRoot}maw/peacock_promo_d@2x.png`} alt="peacock desktop" />
      <img className="mobile" src={`${cwaRoot}maw/peacock_promo_m@2x.png`} alt="peacock mobile" />
    </div>
    <div className="card__content">
      <div className="hgroup">
        <p className="body1">{peacockPromo.header}</p>
        <p>
          Watch iconic shows and movies, like 30 Rock, House, and Shrek, with unlimited access to NBCUniversal&rsquo;s new Peacock streaming service
          {`${peacockPromo.message}`}
        </p>
      </div>
      <a className={type === 'button' ? 'button button--primary' : ''} href={peacockPromo.link} target="_blank" rel="noopener noreferrer">{peacockPromo.linkCTA}</a>
    </div>
  </div>
);
/* eslint-enable max-len */

export default PeacockPromo;
