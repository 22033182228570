import React, { useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Formik, Form } from 'formik';
import {
  Route, Switch, Link, useHistory,
} from 'react-router-dom';
import GoBack from '../../components/GoBack';
import PaymentInstrument from '../../components/PaymentInstrument';
import { joinClass } from '../../helpers/component';
import { setSuccess } from '../../actions/userMessages';
import { getAutopaySuccessMessage } from '../../helpers/userMessages';
import { kibanaLog } from '../../helpers/logger';
import {
  METHODS,
  METHOD_NEW_BANK,
  METHOD_NEW_CARD,
  NOW_METHOD_NEW_CARD,
  NOW_METHOD_NEW_CARD_CONFIRM,
} from '../../helpers/routes';

import AddBank from '../../components/AddBank';
import AddCard from '../../components/AddCard';
import useCpc from '../../hooks/useCpc';
import { getPaymentInstruments } from '../../actions/instruments';
import { getAutopay } from '../../actions/autopay';
import { isNowPage } from '../../helpers/now';
import withStepUp from '../../helpers/withStepUp';
import { flagEnabled } from '../../helpers/featureFlags';

const AddInstrumentForm = ({
  isHarness,
  type,
  handleSetSuccess,
  initialValues,
  handleGetPaymentInstruments,
  handleGetAutopay,
  location: { pathname },
}) => {
  const isNow = isNowPage(pathname);
  const [isCpcLoading, setCpcLoading] = useState(true);
  const cpc = useCpc();
  const history = useHistory();

  const cpcLoadingEventHandler = ({ isLoading }) => {
    // We need a timer here for the loading state of the button
    // to match the loading state of the component.
    // See why this is necessary on L#15 of src/hooks/useCpcDispatcher.js.
    setTimeout(() => setCpcLoading(isLoading), 1000);
  };

  const onAddInstrumentSubmit = async (values) => {
    try {
      const cpcPromise = await cpc.submitForm();
      const {
        cpcData: { cardDetails, bankDetails, submissionDetails },
      } = cpcPromise;
      const { actionTaken, cpcStatus } = submissionDetails;

      if (cpcStatus === 'ERROR') {
        // CPC component is handling displaying error msg
        return;
      }
      kibanaLog('cpc_submit_success');
      if (isNow) {
        history.replace(NOW_METHOD_NEW_CARD_CONFIRM);
        return;
      }

      handleGetPaymentInstruments({ force: true, showLoading: true });
      history.push(METHODS);

      let instrument;

      if (type === 'Bank') {
        const { bankAccountLast4Digits, bankAccountType } = bankDetails;
        instrument = {
          bankAccountType,
          bankAccountNumber: bankAccountLast4Digits,
          type: 'Bank',
        };
      } else {
        const { cardLast4Digits, cardType } = cardDetails;
        instrument = {
          cardType,
          last4DigitsCardNumber: cardLast4Digits,
          type: 'PaymentCard',
        };
      }
      let successPushDown = {
        heading: (
          <>
            <PaymentInstrument instrument={instrument} hideBadges hideIcon />
            {' was added successfully.'}
          </>
        ),
        showPushdown: true,
      };

      if (actionTaken === 'tokenize_with_autopay') {
        handleGetAutopay({ force: true });
        successPushDown = getAutopaySuccessMessage(false);
      }
      handleSetSuccess(successPushDown);
    } catch (e) {
      // CPC component will handle displaying error msg
    }
  };

  return (
    <Formik onSubmit={onAddInstrumentSubmit} initialValues={initialValues}>
      {({ isSubmitting }) => (
        <Form noValidate>
          <div className="payment-section">
            <Switch>
              <Route
                exact
                path={METHOD_NEW_BANK}
                render={() => (
                  <AddBank
                    displayAutoPayEnroll
                    displayStoredPaymentOption
                    requireStoredPaymentSelection
                    onCpcLoading={cpcLoadingEventHandler}
                  />
                )}
              />
              <Route
                exact
                path={[METHOD_NEW_CARD, NOW_METHOD_NEW_CARD]}
                render={() => (
                  <AddCard
                    displayAutoPayEnroll={!isNow}
                    displayStoredPaymentOption
                    requireStoredPaymentSelection
                    isNowPage={isNow}
                    onCpcLoading={cpcLoadingEventHandler}
                  />
                )}
              />
            </Switch>
          </div>

          <div className="action action--right pt6">
            <div className="action__item">
              <button
                type="submit"
                className={joinClass(
                  'button button--primary',
                  isSubmitting && 'is-loading',
                )}
                disabled={isCpcLoading || isSubmitting}
              >
                {isNow ? 'Add' : 'Save'}
              </button>
            </div>
            {!isHarness && !isNow && (
              <div className="action__item">
                {flagEnabled('stepUpEnabled') ? (
                  <Link to={METHODS} className="button">
                    Back
                  </Link>
                ) : (
                  <GoBack>Back</GoBack>
                )}
              </div>
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
};

const mapStateToProps = (state, ownProps) => {
  const {
    userMessages: { error: errorMessage, showModal },
  } = state;

  const { location } = ownProps;

  const type = location.pathname === METHOD_NEW_BANK ? 'Bank' : 'PaymentCard';

  const initialValues = {};

  if (type === 'Bank') {
    initialValues.account = 'Checking';
  }

  return {
    type,
    initialValues: {
      ...initialValues,
      billingAddressCheckbox: false,
      autopay: false,
    },
    isHarness: state.harness.isHarness,
    errorMessage: !showModal && errorMessage,
  };
};

const mapDispatchToProps = dispatch => ({
  handleSetSuccess: bindActionCreators(setSuccess, dispatch),
  handleGetAutopay: bindActionCreators(getAutopay, dispatch),
  handleGetPaymentInstruments: bindActionCreators(
    getPaymentInstruments,
    dispatch,
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStepUp(AddInstrumentForm));
