/* eslint-disable no-shadow */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import JumpComp from '../components/Jump';
import { generateUUID } from '../helpers/uids';
import useTransform from '../hooks/useTransform';
import { getChannelData } from '../actions/cpc';

const Jump = ({
  accountChannelData,
  channelData, // {Object} Additional channel data
  getChannelData,
  channelDataReady,
  isNowPage,
  user,
  ...props
}) => {
  useEffect(() => {
    if (!channelDataReady) {
      getChannelData();
    }
  }, [channelDataReady, getChannelData]);
  const nowChannel = 'NOW_TOKEN';
  const [trackingId] = useState(generateUUID());
  const defaultChannelData = {
    ...accountChannelData,
    ...channelData,
    channelDetails: {
      ...accountChannelData?.channelDetails,
      ...channelData?.channelDetails,
      sourceServerId: window.location.hostname,
      trackingId,
      sessionId: window.MAW?.sessionID,
    },
    customerDetails: {
      ...accountChannelData?.customerDetails,
      ...channelData?.customerDetails,
    },
  };
  const nowChannelData = {
    ...defaultChannelData,
    channelDetails: {
      ...defaultChannelData.channelDetails,
      channelName: nowChannel,
      sourceSystemId: `${nowChannel}_CPC`,
      enableFraudManager: true,
    },
    customerDetails: {
      ...defaultChannelData.customerDetails,
      billingArrangementId: null,
      emailAddress: user?.preferredEmail,
      phone: user?.homePhone || user?.mobilePhone,
    },
    channelCustomData: {
      channelCustomDataField8: `${nowChannel}_SERVICE`,
      channelCustomDataField10: accountChannelData?.customerDetails?.billingArrangementId,
    },
  };
  const finalChannelData = useTransform(() => ({
    ...(isNowPage ? nowChannelData : defaultChannelData),
  }), [accountChannelData]);

  if (!channelDataReady) return null;
  return (
    <JumpComp
      {...props}
      channelData={finalChannelData}
      channelDataReady={channelDataReady}
    />
  );
};

const mapStateToProps = ({
  cpc: {
    loading: channelDataLoading,
    channelData,
  } = {},
  user: { user } = {},
}) => ({
  channelDataReady: !channelDataLoading && !!channelData,
  accountChannelData: channelData,
  user,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  getChannelData,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Jump);
