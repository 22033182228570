import getConfig from '../config';

const { homeDomain } = getConfig();
const continueUrl = encodeURIComponent(`https://${homeDomain}/#/billing`);

// eslint-disable-next-line import/prefer-default-export
export const FORCE_LOGIN_URL = `https://${window.location.host}/oauth/force_connect/?continue=${continueUrl}`;

export const openInNewWindow = (event = {}) => {
  const {
    currentTarget: {
      href,
    } = {},
  } = event;

  // taken from h/s
  const y = window.screen.height - 612;
  const x = window.screen.width - 415;
  const newWindow = window.open('', 'Xfinity Assistant', `top=${y},left=${x},width=375,height=582`);
  newWindow.opener = null;
  newWindow.location = href;

  event.stopPropagation();
  event.preventDefault();

  return false;
};

export const xaLinkWithLogin = (intent) => {
  const self = new URL(window.location);
  self.pathname = '/oauth/callback';
  const url = new URL('https://oauth.xfinity.com/oauth/authorize');
  url.searchParams.append('client_id', window.MAW.env === 'local' ? 'my-account-web-development' : 'my-account-web');
  url.searchParams.append('response_type', 'code');
  url.searchParams.append('redirect_uri', self.toString());
  url.searchParams.append('state', `https://www.xfinity.com/xfinityassistant/?intent=${encodeURIComponent(intent)}`);
  return url.toString();
};
