import React from 'react';

const XfinityAssistant = ({
  className,
  color1 = 'currentColor',
  color2 = '#FFF',
}) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60">
    <circle cx="30" cy="30" r="30" fill={color1} />
    <path
      fill={color2}
      d="M35.1 24.5a2.6 2.6 0 110 5.1 2.6 2.6 0 010-5.1zm-10.2 0a2.5 2.5 0 110 5.1 2.5 2.5 0 010-5.1zm-1.7-5.1c-3.8 0-6.8 3-6.8 6.8v3.4c0 3.8 3 6.8 6.8 6.8h13.6c3.8 0 6.8-3 6.8-6.8v-3.4c0-3.8-3-6.8-6.8-6.8zm0-3.4h13.6C42.4 16 47 20.6 47 26.2v3.4c0 5.6-4.6 10.2-10.2 10.2H23.2L13 44.9V26.2C13 20.6 17.6 16 23.2 16z"
    />
  </svg>
);

export default XfinityAssistant;
