import React from 'react';

const Toggle = ({
  children,
  label,
  inputId,
  className,
  labelClass,
  toggleContent,
}) => (
  <div className={`form-toggle ${className}`}>
    <div className="form-toggle__input">
      {children}
      {label && (
        <label className="form-toggle__label" htmlFor={inputId}>
          <span className={`form-toggle__label-text ${labelClass}`}>{label}</span>
        </label>
      )}
      {toggleContent && <span className="form-toggle__content">{toggleContent}</span>}
    </div>
  </div>
);

export default Toggle;
