import React, { useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link, Route, Switch } from 'react-router-dom';
import { Formik, Form } from 'formik';

import { joinClass } from '../../helpers/component';
import { getAutopaySuccessMessage } from '../../helpers/userMessages';
import { setSubmit, setSuccess } from '../../actions/userMessages';
import { getPaymentInstruments } from '../../actions/instruments';
import { getAutopay } from '../../actions/autopay';
import { kibanaLog } from '../../helpers/logger';
import withStepUp from '../../helpers/withStepUp';

import {
  AUTOPAY,
  AUTOPAY_NEW_BANK,
  AUTOPAY_NEW_CARD,
} from '../../helpers/routes';

import NextBestActions from '../../components/nbas';
import AddBank from '../../components/AddBank';
import AddCard from '../../components/AddCard';
import useCpc from '../../hooks/useCpc';
import useSessionStorageCleanup from '../../hooks/useSessionStorageCleanup';

const AddInstrument = ({
  isHarness,
  nextBestActions,
  bill,
  enrollSuccess,
  latefeecredit,
  handleSetSubmit,
  handleSetSuccess,
  handleGetPaymentInstruments,
  handleGetAutopay,
  history,
}) => {
  const [isCpcLoading, setCpcLoading] = useState(true);
  const cpc = useCpc();

  useSessionStorageCleanup('maw_autopayTermsChecked');

  const cpcLoadingEventHandler = ({ isLoading }) => {
    // We need a timer here for the loading state of the button
    // to match the loading state of the component.
    // See why this is necessary on L#15 of src/hooks/useCpcDispatcher.js.
    setTimeout(() => setCpcLoading(isLoading), 1000);
  };

  const onAddInstrumentSubmit = async () => {
    try {
      const cpcPromise = await cpc.submitForm();
      const { cpcData: { submissionDetails: { cpcStatus } } } = cpcPromise;
      if (cpcStatus === 'ERROR') {
        // CPC component is handling displaying error msg
        return;
      }
      const isUpdate = bill.summary.autoPayEnabled;
      kibanaLog('cpc_submit_success');
      handleSetSubmit();
      handleGetPaymentInstruments({ force: true, showLoading: false });
      handleGetAutopay({ force: true });

      history.push(AUTOPAY);

      handleSetSuccess(getAutopaySuccessMessage(
        isUpdate,
        enrollSuccess && latefeecredit.lateFee,
      ));
    } catch (e) {
      // CPC component will handle displaying error msg
    }
  };

  return (
    <>
      {!!nextBestActions.length && (
        <NextBestActions nbaList={nextBestActions} />
      )}

      <Formik onSubmit={onAddInstrumentSubmit} initialValues={{}}>
        {({
          isSubmitting,
        }) => (
          <Form noValidate>
            <div className="payment-section">
              <Switch>
                <Route
                  exact
                  path={AUTOPAY_NEW_BANK}
                  render={() => (
                    <AddBank
                      enrollInAutopay
                      onCpcLoading={cpcLoadingEventHandler}
                    />
                  )}
                />
                <Route
                  exact
                  path={AUTOPAY_NEW_CARD}
                  render={() => (
                    <AddCard
                      enrollInAutopay
                      onCpcLoading={cpcLoadingEventHandler}
                    />
                  )}
                />
              </Switch>
            </div>

            <div className="action action--right pt6">
              <div className="action__item">
                <button
                  type="submit"
                  className={joinClass(
                    'button button--primary',
                    isSubmitting && 'is-loading',
                  )}
                  disabled={isCpcLoading || isSubmitting}
                >
                  Set Up
                </button>
              </div>
              {!isHarness && (
                <div className="action__item">
                  <Link to={AUTOPAY} className="button">
                    Back
                  </Link>
                </div>
              )}
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  const {
    bill: { bill = {} },
    latefeecredit: { latefeecredit, enrollSuccess },
    harness: { isHarness },
  } = state;

  const { location } = ownProps;

  const type = location.pathname === AUTOPAY_NEW_BANK ? 'Bank' : 'PaymentCard';

  const initialValues = {};

  if (type === 'Bank') {
    initialValues.account = 'Checking';
  }

  return {
    latefeecredit,
    enrollSuccess,
    type,
    bill,
    initialValues: {
      ...initialValues,
      billingAddressCheckbox: false,
      isDefault: 'no',
    },
    isHarness,
  };
};

const mapDispatchToProps = dispatch => ({
  handleSetSubmit: bindActionCreators(setSubmit, dispatch),
  handleSetSuccess: bindActionCreators(setSuccess, dispatch),
  handleGetAutopay: bindActionCreators(getAutopay, dispatch),
  handleGetPaymentInstruments: bindActionCreators(getPaymentInstruments, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStepUp(AddInstrument));
