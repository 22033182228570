import { useCallback, useEffect } from 'react';
// import { logger } from '../helpers/logger';

// const c = logger('useEventListener').enable(false);

/**
 * Add an event listener to any element while the component is in the document
 * @param {EventTarget} target The element / DOM node / other EventTarget to listen on
 * @param {string} eventName The name of the event to listen for
 * @param {(event?: Event) => boolean | undefined} handler The handler for the event
 * @param {any[]} deps Any dependencies for the function a'la useCallback
 */
export default function useEventListener(target, eventName, handler, deps) {
  // We disable this because the useCallback is implicit
  //   We expect `handler` to change for each pass, so adding
  //   it to the dependency list would be equivalent to `useCallback` with no
  //   deps, which is not what we want.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const listener = useCallback(handler, deps);
  useEffect(() => {
    if (target) {
      // c.log(`Listening to ${eventName} on`, target);
      target.addEventListener(eventName, listener);
    }
    return () => {
      if (target) {
        // c.log(`Unlistening from ${eventName} on`, target);
        target.removeEventListener(eventName, listener);
      }
    };
  }, [target, eventName, listener]);
}
