import React from 'react';
import { Field } from 'formik';
import Plans from './Plans';

const SelectPlan = ({ plans, name, onChange }) => (
  <Field name={name}>
    {({ field }) => (<Plans field={field} plans={plans} onChange={onChange} />)}
  </Field>
);

export default SelectPlan;
