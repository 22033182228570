import React from 'react';
import { Route } from 'react-router-dom';

import PageHeader from '../../components/layout/PageHeader';
import PageFooter from '../../components/layout/PageFooter';

const DefaultLayout = ({
  component: Component,
  isHarness,
  device,
  ...props
}) => (
  <Route
    {...props}
    render={matchProps => (
      <>
        <PageHeader isHarness={isHarness} {...matchProps} />
        <main id="page-main" className="page-main" tabIndex="-1" role="main">
          <Component isHarness={isHarness} {...matchProps} {...props} />
        </main>
        <PageFooter />
      </>
    )}
  />
);

export default DefaultLayout;
