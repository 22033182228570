import React from 'react';
import { LoadingCard } from 'adc-ui-components';

import Card from './Card';
import KeyValueGroup from './KeyValueGroup';
import KeyValue from './KeyValue';
import { formatPaymentPlanSummaryData } from '../helpers/paymentPlan';
import { joinClass } from '../helpers/component';
import withCmsContent from '../helpers/withCmsContent';

const PaymentSummary = ({
  summary,
  loading,
  isReview,
  className = '',
  content,
}) => (
  <>
    <h2>{content('title')}</h2>
    <div className={joinClass('card-group', className)}>
      <div className="card-group__item">
        <Card>
          <KeyValueGroup>
            {formatPaymentPlanSummaryData(summary).map(item => (
              <KeyValue className="key-value--inline-at-768" key={item.label} {...item} />
            ))}
          </KeyValueGroup>
        </Card>
      </div>
      {!isReview && (
        <div className="card-group__item">
          <Card>
            <p>
              {content('readOnly')}
            </p>
          </Card>
        </div>
      )}
      {isReview && (
        <div className="card-group__item">
          <Card>
            <p>
              {content('planInfo')}
            </p>
          </Card>
        </div>
      )}
      {loading && (
        <div className="card-group__item">
          <LoadingCard />
        </div>
      )}
    </div>
  </>
);

export default withCmsContent('content', {
  'ltip-payment-plan': 'summary',
})(PaymentSummary);
