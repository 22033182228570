import React from 'react';
import { joinClass } from '../../../helpers/component';

import Plan from './Plan';

const Plans = ({
  plans = [],
  field,
}) => (
  <div className="payment-section">
    <div
      className={joinClass(
        'card-group card-group--grid-at-768',
        `card-group--grid-${plans.length}-at-768`,
        'card-group--grid-at-768-nowrap',
      )}
    >
      {plans.map(plan => (
        <Plan
          key={`${field.name}_${plan.value}`}
          field={field}
          plan={plan}
          aria-required="true"
        />
      ))}
    </div>
  </div>
);

export default Plans;
