import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Terms from '../components/Terms';
import { getTerms, getAccepted } from '../actions/contracts';

class TermsContainer extends Component {
  componentDidMount() {
    this.fetchContract();
  }

  componentDidUpdate() {
    this.fetchContract();
  }

  fetchContract() {
    const {
      contractId,
      fetchTerms,
      fetchAccepted,
    } = this.props;
    if (contractId) {
      fetchTerms(contractId);
    }
    fetchAccepted();
  }

  render() {
    return <Terms {...this.props} />;
  }
}

export default connect(
  (
    {
      contracts: {
        loading,
        error,
        terms,
        accepted: {
          contracts = [],
        } = {},
      },
    },
    { contractId },
  ) => ({
    loading: !!loading,
    error,
    acceptedDateTime:
      (contract => contract.acceptedDateTime || contract.recordedDateTime)(
        contracts.find(
          contract => terms[contractId] && terms[contractId].title === contract.title,
        ) || {},
      ),
    ...terms[contractId],
  }),
  dispatch => bindActionCreators(
    {
      fetchTerms: getTerms,
      fetchAccepted: getAccepted,
    },
    dispatch,
  ),
)(TermsContainer);
