import React, { useEffect } from 'react';
import { FormBoolean, LoadingCard } from 'adc-ui-components';
import { Field } from 'formik';

import { joinClass } from '../../helpers/component';
import { formatCurrency } from '../../helpers/formatText';
import { RequiredFactory } from '../../helpers/validation/required';
import { cleanDollarAmount } from '../../helpers/validation/amount';
import { PLACEHOLDER_PAYMENT_PLAN_OPTIONS } from '../../helpers/xap';
import withCmsContent from '../../helpers/withCmsContent';

import BooleanField from '../form-elements/BooleanField';
import Card from '../Card';

const vRequired = RequiredFactory();

const PaymentPlanOptions = ({
  paymentOptionsPlan,
  error,
  onRecalcPayment,
  refreshing,
  customAmount,
  minimumPayment,
  paymentAmountOption,
  pastDueBalance,
  setFieldValue,
  touched,
  errors,
  disabled,
  content,
}) => {
  const { customAmount: customAmountError } = errors;

  const isCustomAmount = paymentAmountOption === 'custom';
  const parsedPaymentAmount = (isCustomAmount && customAmount)
    ? parseFloat(cleanDollarAmount(customAmount))
    : null;
  const fullBalancePaid = isCustomAmount && parsedPaymentAmount >= pastDueBalance;

  const paymentOptions = disabled ? PLACEHOLDER_PAYMENT_PLAN_OPTIONS : paymentOptionsPlan;

  const isSingle = paymentOptions.length === 1;
  const msg = (isSingle ? content('singleOption') : content('paymentOptions')) || {};

  useEffect(() => {
    if (customAmount === undefined && isCustomAmount) {
      return;
    }
    if (
      disabled
      || fullBalancePaid
      || (parsedPaymentAmount !== null && parsedPaymentAmount < minimumPayment)
      // This is null if no custom amount.
      || parsedPaymentAmount === null
    ) {
      onRecalcPayment();
      return;
    }

    if (paymentAmountOption === null || customAmountError) return;

    if (parsedPaymentAmount !== null) {
      onRecalcPayment(parsedPaymentAmount);
    }
  }, [
    paymentAmountOption,
    customAmount,
    customAmountError,
    onRecalcPayment,
    parsedPaymentAmount,
    fullBalancePaid,
    setFieldValue,
    pastDueBalance,
    minimumPayment,
    disabled,
    isCustomAmount,
  ]);

  return (
    <div className={joinClass('payment-section', fullBalancePaid && 'is-disabled')}>
      <div className="hgroup">
        <h2>{msg('title')}</h2>
        <p>
          {msg('description')}
          {' '}
          {msg('cta') && (
            <a
              href={msg('cta.href')}
              target={msg('cta.target')}
              rel="noopener noreferrer"
            >
              {msg('cta.text')}
            </a>
          )}
        </p>
      </div>

      {error && (
        <p className="form-control__error" id="paymentPlanOptionError">
          {msg('error.general')}
        </p>
      )}

      {refreshing && (
        <LoadingCard />
      )}

      {!refreshing && isSingle && (
        <>
          <div className="card-group card-group--grid-at-768 card-group--grid-1-at-768">
            <div className="card-group__item">
              <div className="card card--action-right-at-768">
                <div className="card__content body2">
                  {msg('installments', { payments: paymentOptions[0].numberOfPayments })}
                </div>

                <div className="card__action pt0">
                  <span className="heading2 ml40">
                    {msg('amount', { amount: formatCurrency(paymentOptions[0].paymentAmount) })}
                  </span>
                </div>
              </div>
            </div>
          </div>
          {msg('alertCard') && (
            <Card className={`card--${msg('alertCard.severity')}`}>
              <p>
                {msg('alertCard.body')}
              </p>
            </Card>
          )}
        </>
      )}

      {!refreshing && !isSingle && (
        <>
          <div className={`card-group card-group--grid-at-768 card-group--grid-${paymentOptions.length}-at-768`}>
            {paymentOptions.map(({
              trackingCode,
              frequency = '',
              numberOfPayments,
              paymentAmount,
            }) => (
              <div className="card-group__item" key={trackingCode}>
                <Card>
                  <FormBoolean
                    label={(
                      <span className="payment-plan-option-label">
                        <span className="heading4 mb6">{msg('installments', { payments: numberOfPayments })}</span>
                        <span className="heading2 mb0">{fullBalancePaid ? '$0.00' : formatCurrency(paymentAmount)}</span>
                        <span className="body3 mb0">{frequency.toLowerCase()}</span>
                      </span>
                  )}
                    inputId={`paymentOption_${trackingCode}`}
                    labelClass="body2"
                  >
                    <Field
                      disabled={disabled || fullBalancePaid}
                      id={`paymentOption_${trackingCode}`}
                      name="paymentPlanOption"
                      component={BooleanField}
                      type="radio"
                      value={trackingCode}
                      aria-required={!fullBalancePaid}
                      aria-describedby="paymentPlanOptionError"
                      validate={!fullBalancePaid && vRequired(true)}
                      className={fullBalancePaid ? 'unchecked' : ''}
                    />
                  </FormBoolean>
                </Card>
              </div>
            ))}
          </div>
          {msg('alertCard') && (
            <Card className={`card--${msg('alertCard.severity')}`}>
              <p>
                {msg('alertCard.body')}
              </p>
            </Card>
          )}
        </>
      )}

    </div>
  );
};

export default withCmsContent('content', {
  'ltip-payment-plan': 'options',
})(PaymentPlanOptions);
