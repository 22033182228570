import React, {
  useEffect,
  useState,
  useRef,
} from 'react';
import useEventListener from '../../hooks/useEventListener';
import useWebComponent from '../../hooks/useWebComponent';
import useInjectedStylesheet from '../../hooks/useInjectedStylesheet';
import useTransform from '../../hooks/useTransform';
import { joinClass } from '../../helpers/component';
import { kibanaLog, logger } from '../../helpers/logger';
import { snakeToPascal, objAsDefined } from './helpers';
import useImpression from '../../hooks/useImpression';
import getConfig from '../../config';
import { dispatchSendTracking } from '../../actions/adobe';
import useCpcDispatcher from '../../hooks/useCpcDispatcher';

const { cpcEnvironment = 'preproduction', cpcUrl = 'https://common-payment.preprod.xfinity.com/' } = getConfig();

const c = logger('JumpComp').enable(false);


const jumpScript = ({ version }) => `${cpcUrl}${encodeURIComponent(version)}/jump/jump-web-component-bundle.js`;

export default function Jump({
  // CPC version to use
  version = '2.3.7',
  // channelData.
  // See: https://etwiki.sys.comcast.net/pages/viewpage.action?spaceKey=XIA&title=Common+Payment+Component+%28CPC%29+Integration+Guide#CommonPaymentComponent(CPC)IntegrationGuide-ChannelDataInputs
  channelData,
  // Will avoid responding with CPC_CONFIG_READY until after `channelDataReady` is `true`.
  channelDataReady,
  // CSS to inject between the CPC's shadowRoot and iframe
  shadowCssUrl,
  // CPC Env.  Valid values are
  // "development" (PS - int - only used for CPC Dev/debug issues)
  // "integration" (PS - int)
  // "preproduction" (PS - HTTE)
  // "production"
  cpcEnv = cpcEnvironment,
  // Page type
  // See: https://etwiki.sys.comcast.net/display/XIA/CPC+Component+List
  cpcPageType,
  // CSS to inject.  Must be a standalone URL, but may be relative to the current domain.
  cpcPageCssUrl,
  // Case of labels
  // CapOnlyFirst [default] - capitalize only the first letter of a label
  // CapAllFirst - capitalize the first letter of each word
  cpcPageLabelCase,
  // CSS height value for the component.  Use only if the built-in dynamic resize isn't working.
  cpcPageHeight,
  // CSS width value for the component.  Use only if the built-in dynamic resize isn't working.
  cpcPageWidth,
  // CSS border value for the component.  Largely unnecessary
  cpcPageBorder,
  // Handler for the CPC_LOADING message. CPC sends this event with a isLoading in the payload.
  onCpcLoading,
  // Handler for the CPC_CONFIG_READY message.  The consuming app MUST respond by dispatching a
  //  CPC_CONFIG_SUBMIT with the completed configuration
  onCpcConfigReady,
  // Handler for the CPC_PAGE_RESIZE message.  Use this to deal with dynamic resizing if it's
  //  visually breaking things outside the CPC.
  onCpcPageResize,
  // Handler for the CPC_READY message.  This fires as soon as the CPC has loaded its initial
  //  iframe, but before its config has been completed.
  onCpcReady,
  // Handler for the CPC_ERROR message.  This fires whenever the user enters data that doesn't
  //  validate.
  onCpcError,
  // Handler for the CPC_FORM_ERROR message.  This fires if the form data is invalid just before
  //  the form is allowed to submit.
  onCpcFormError,
  // Handler for the CPC_FORM_PROCESSING message.  This fires as the CPC form submits.
  onCpcFormProcessing,
  // Handler for the CPC_FORM_SUBMIT_RESPONSE message.  This fires once there is a response to the
  //  form.
  onCpcFormSubmitResponse,
  // equivalent to channelData.config.displayAddressOverride
  // see: https://etwiki.sys.comcast.net/pages/viewpage.action?spaceKey=XIA&title=Common+Payment+Component+%28CPC%29+Integration+Guide#CommonPaymentComponent(CPC)IntegrationGuide-ChannelDataInputs
  displayAddressOverride,
  // equivalent to channelData.config.displayAutoPayEnroll
  displayAutoPayEnroll,
  // equivalent to channelData.config.displayStoredPaymentOption
  displayStoredPaymentOption,
  // equivalent to channelData.config.termsAndConditionsDisplayOption
  termsAndConditionsDisplayOption,
  // equivalent to channelData.config.newPaymentDisplayType
  newPaymentDisplayType,
  // equivalent to channelData.config.disableExpiredCards
  disableExpiredCards,
  // equivalent to channelData.customerDetails.enrollInAutopay
  enrollInAutopay,
  // equivalent to channelData.config.requireStoredPaymentSelection
  requireStoredPaymentSelection,
  // equivalent to channelData.config.selectStoredPaymentOnLoad
  selectStoredPaymentOnLoad,

}) {
  // Source of the script
  const [cpcScriptUrl] = useState(jumpScript({ version }));
  // Used to determine whether to un-hide the CPC
  const [loading, setLoading] = useState(true);
  // A ref for, and the shadowRoot of the rendered component
  const [jumpRef, , jumpShadow] = useWebComponent(cpcScriptUrl);
  // Pending config from CPC_CONFIG_READY to be submitted in CPC_CONFIG_SUMIT
  const [pendingConfig, setPendingConfig] = useState(null);
  const [configured, setConfigured] = useState(false);
  const [lastResize, setLastResize] = useState(null);
  const [impressionReady, setImpressionReady] = useState(false);
  const cpcConsideredLoaded = useRef(false);
  // storePaymentMethod to be true only on autopay flow so initiating value as enrollInAutopay
  const [storePaymentMethod, setStorePaymentMethod] = useState(enrollInAutopay);

  // Inject a stylesheet into the shadow dom, so we can apply styling outside the CPC's iframe
  useInjectedStylesheet(jumpShadow, shadowCssUrl);

  // Complete the channelData object with additional configuration
  const finalChannelData = useTransform(() => {
    // These are values only in channelData.config
    const configExtras = objAsDefined({
      displayAddressOverride,
      displayAutoPayEnroll,
      displayStoredPaymentOption,
      termsAndConditionsDisplayOption,
      newPaymentDisplayType,
      disableExpiredCards,
      requireStoredPaymentSelection,
      selectStoredPaymentOnLoad,
    });
    // These are values only in channelData.customerDetails
    const customerDetailsExtras = objAsDefined({
      enrollInAutopay,
    });
    // Were any set?
    const haveConfigExtras = Object.keys(configExtras).length;
    const haveChannelDataExtras = Object.keys(customerDetailsExtras).length;
    if (channelData) {
      const newFinalChannelData = {
        ...channelData,
        customerDetails: {
          ...channelData?.customerDetails,
          walletId: storePaymentMethod
            ? channelData?.customerDetails?.walletId
            : `D${channelData?.customerDetails?.billingArrangementId}`,
        },
      };

      if (haveConfigExtras) {
        newFinalChannelData.config = {
          ...newFinalChannelData.config,
          ...configExtras,
        };
      }

      if (haveChannelDataExtras) {
        newFinalChannelData.customerDetails.enrollInAutopay = enrollInAutopay;
      }

      return newFinalChannelData;
    }
    return {};
  }, [
    channelData,
    displayAddressOverride,
    displayAutoPayEnroll,
    displayStoredPaymentOption,
    termsAndConditionsDisplayOption,
    newPaymentDisplayType,
    disableExpiredCards,
    storePaymentMethod,
    enrollInAutopay,
    requireStoredPaymentSelection,
    selectStoredPaymentOnLoad,
  ]);

  const dispatcher = useCpcDispatcher(finalChannelData, jumpShadow, cpcPageType, setLoading);

  // Convert config props into configuration for the CPC
  const channelJson = useTransform(() => JSON.stringify(finalChannelData), [finalChannelData]);

  // Check if the CPC was blocked by securiti.ai and report if it has been.
  useEffect(() => {
    if (!jumpShadow) return;
    const iframe = jumpShadow.querySelector('iframe');
    if (iframe.hasAttribute('data-src')) {
      const { host } = new URL(iframe.getAttribute('data-src'));
      // eslint-disable-next-line no-console
      console.warn(`[JumpComp] securiti.ai has blocked the Jump component's iframe.  For development, block 'cdn-prod.securiti.ai'.  Meanwhile get '${host}' whitelisted.`);
    }
  }, [jumpShadow]);

  useEffect(() => {
    // c.log('ready?', {
    //   pendingConfig, finalChannelData, channelDataReady, dispatcher
    // });
    if (!pendingConfig || !finalChannelData || !channelDataReady || !dispatcher) return;
    const action = {
      action: 'CPC_CONFIG_SUBMIT',
      config: {
        ...pendingConfig,
        ...finalChannelData.config,
        cpcPageType,
      },
    };
    kibanaLog('cpc_component_loading');
    dispatcher.dispatch(action);
    setPendingConfig(null);
    setConfigured(true);
  }, [pendingConfig, finalChannelData, channelDataReady, dispatcher, cpcPageType]);

  // Listen for CPC messages
  useEventListener(window, 'message', ({ data, origin }) => {
    // Parse the message data; if it doesn't parse, ignore this message.
    let dataObj;
    try {
      dataObj = JSON.parse(data);
    } catch (e) {
      return;
    }
    // Grab the action
    const { action, ...rest } = dataObj;
    // Only handle messages with an `action` of the form `CPC_...`
    if (!action?.startsWith?.('CPC_')) return;
    // eslint-disable-next-line no-console
    c.info('action', action, origin);
    // Convert from CPC_EVENT_TYPE to onCpcEventType so we can call the passed-in handler.
    const handlerName = `on${snakeToPascal(action)}`;
    if (action === 'CPC_CONFIG_READY') {
      c.log('Capturing pending config');
      setPendingConfig(rest.config);
    }
    if (action === 'CPC_PAGE_RESIZE') {
      // consider CPC component as rendered upon receiving the first instance of this event
      if (!cpcConsideredLoaded.current) {
        cpcConsideredLoaded.current = true;
        kibanaLog('cpc_component_loaded');
      }
      setLastResize(Date.now());
    }
    if (action === 'CPC_INFO_EVENT') {
      dispatchSendTracking('c-tracking-log-dom', dataObj.data);
      if (
        dataObj?.dataLayerKey === 'CpcCardTermsAndConditionSelected'
        || dataObj?.dataLayerKey === 'CpcBankTermsAndConditionSelected'
      ) {
        setStorePaymentMethod(dataObj?.currentValue);
      }
    }
    if (action === 'CPC_FORM_PROCESSING') {
      setLoading(true);
    }
    if (action === 'CPC_FORM_SUBMIT_RESPONSE') {
      setLoading(false);
    }
    if (action === 'CPC_ERROR' || action === 'CPC_FORM_ERROR') {
      if (dataObj?.type !== 'form' && dataObj?.level === 'error') {
        kibanaLog('cpc_error', dataObj);
      }
      setLoading(false);
    }
    const handler = ({
      onCpcLoading,
      onCpcConfigReady,
      onCpcPageResize,
      onCpcReady,
      onCpcError,
      onCpcFormError,
      onCpcFormProcessing,
      onCpcFormSubmitResponse,
    })[handlerName];
    c.log(`${handlerName}`, { covered: !!handler }, rest);
    // Locate and execute the relevant handler, if present.
    handler?.(rest);
  }, [
    cpcPageType,
    onCpcLoading,
    onCpcConfigReady,
    onCpcPageResize,
    onCpcReady,
    onCpcError,
    onCpcFormError,
    onCpcFormProcessing,
    onCpcFormSubmitResponse,
  ]);

  useEffect(() => {
    if (!configured) return () => { };
    const K = 1000;
    const timeout = setTimeout(() => {
      setLastResize((last) => {
        if (Date.now() - last < K) return last;
        setImpressionReady(true);
        return null;
      });
    }, K);
    return () => {
      clearTimeout(timeout);
    };
  }, [lastResize, configured, onCpcReady]);

  // Doesn't actually add an impression, but delays the pageLoadEvent until
  //  after the Jump component has had a chance to render.
  useImpression({
    if: false,
    when: impressionReady,
  });


  // Render the component
  return (
    <div
      className={joinClass(
        'jump--container',
        loading && 'jump--loading card--loading-dots',
      )}
    >
      {
        // If we're loading, add loading dots.
        loading && (
          <div className="loading">
            <div className="loading__content">
              <div className="loading__dots">
                <div className="loading__dot" />
                <div className="loading__dot" />
                <div className="loading__dot" />
              </div>
            </div>
          </div>
        )
      }
      {
        // Render the HTML element for the web component
        <jump-web-component
          ref={jumpRef}
          cpc-env={cpcEnv}
          cpc-page-type={cpcPageType}
          cpc-page-css-url={cpcPageCssUrl && new URL(cpcPageCssUrl, window.origin).toString()}
          cpc-page-label-case={cpcPageLabelCase}
          cpc-page-height={cpcPageHeight}
          cpc-page-width={cpcPageWidth}
          cpc-page-border={cpcPageBorder}
          {...channelJson ? { 'channel-data': channelJson } : {}}
        />
      }
    </div>
  );
}
