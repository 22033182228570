import React from 'react';
import { Link } from 'react-router-dom';

import { joinClass } from '../helpers/component';

const Button = ({
  children,
  className,
  onActivate,
  to,
  href,
  tabIndex,
  text,
  primary,
  utility,
  size,
  ...props
}) => {
  const classes = joinClass(
    'button',
    text && 'button--text',
    primary && 'button--primary',
    utility && 'button--utility',
    size && `button--${size}`,
    className,
  );
  if (to) {
    return (
      <Link
        className={classes}
        tabIndex={tabIndex || '0'}
        to={to}
        {...props}
      >
        {children}
      </Link>
    );
  }
  if (href) {
    return (
      <a
        className={classes}
        tabIndex={tabIndex || '0'}
        href={href}
        {...props}
      >
        {children}
      </a>
    );
  }
  if (onActivate) {
    const keyPress = (event) => {
      const { key } = event;
      if (key === 'Enter' || key === ' ') {
        return onActivate(event);
      }
      return true;
    };
    return (
      <button
        type="button"
        className={classes}
        tabIndex={tabIndex || '0'}
        onKeyPress={keyPress}
        onClick={onActivate}
        {...props}
      >
        {children}
      </button>
    );
  }
  return null;
};

export default Button;
