import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { IconCircleExclamation } from 'adc-ui-components';

import { messageShown } from '../actions/userMessages';
import Pushdown from './Pushdown';

class ErrorPushDown extends Component {
  componentDidMount() {
    const {
      handleMessageShown,
    } = this.props;

    handleMessageShown();
  }


  render() {
    const {
      heading,
      body,
      link,
      linkText,
    } = this.props;

    if (!link && !linkText) {
      return null;
    }

    return (
      <Pushdown
        className="pushdown--error"
        title={heading}
        icon={<IconCircleExclamation className="svg-icon--42" />}
      >
        {body && <p className="body3">{body}</p>}
        {link && linkText && <a href={link}>{linkText}</a>}
      </Pushdown>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  handleMessageShown: bindActionCreators(messageShown, dispatch),
});

export default connect(null, mapDispatchToProps)(ErrorPushDown);
