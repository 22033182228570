import {
  SET_SUCCESS,
  SET_ERROR,
  MESSAGE_SHOWN,
  RESET_USER_MESSAGES,
} from '../actions/userMessages';

const INITIAL_STATE = {};

export default function userMessagesReducer(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case SET_SUCCESS:
      return {
        ...state,
        success: action.payload,
      };
    case SET_ERROR:
      return {
        ...state,
        error: action.payload.text,
        showModal: action.payload.showModal,
        link: action.payload.link,
        linkText: action.payload.linkText,
        messageShown: false,
      };
    case MESSAGE_SHOWN:
      return {
        ...state,
        messageShown: true,
      };
    case RESET_USER_MESSAGES:
      return INITIAL_STATE;
    default:
      return state;
  }
}
