// eslint-disable-next-line import/prefer-default-export
export const isSpanish = (url) => {
  const here = new window.URL(url);
  const { hostname = '' } = here;
  const parts = hostname.split('.');
  const check = parts.length === 4 ? [parts[0], parts[2], parts[3]].join('.') : hostname;
  return (
    (parts.length === 3 || parts.length === 4)
    && check === 'es-payments.xfinity.com'
  );
};
