import { useCallback, useEffect, useState } from 'react';
import useScript from './useScript';
// import { logger } from '../helpers/logger';

// const c = logger('useWebComponent').enable(false);

/**
 * Use a webcomponent with a bundle indicated by `src`
 * @param {string} src script to inject to support the web component
 * @returns {[ref, element, shadowRoot]} A ref to attach to the web component's HTML element,
 *  the element itself once it's populated, and the shadowRoot for that element if present.
 */
export default function useWebComponent(src) {
  const [loading, error] = useScript(src);
  const [element, setElement] = useState(null);
  const [shadowRoot, setShadowRoot] = useState(null);
  const ref = useCallback((el) => {
    // c.log('recieved element on ref', el);
    ref.current = el;
    setElement(el);
  }, []);
  useEffect(() => {
    if (!loading && !error && element) {
      // c.log('Setting shadow root', element.shadowRoot);
      setShadowRoot(element.shadowRoot);
    }
  }, [loading, error, element]);
  return [ref, element, shadowRoot];
}
